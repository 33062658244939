import React, { PropsWithChildren, ReactNode } from "react";

import { SectionHeader } from "src/app/components/Pane/SectionHeader/SectionHeader";
import { DashedBox } from "src/app/components/DashedBox/DashedBox";

interface InfoBoxProps {
  title: ReactNode;
}

function InfoBox({ title, children }: PropsWithChildren<InfoBoxProps>) {
  return (
    <div>
      <SectionHeader name={title} />
      <DashedBox className="info-box">{children}</DashedBox>
    </div>
  );
}

export default InfoBox;
