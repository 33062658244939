import React from "react";
import VerifiedIcon from "src/images/verified.svg";
import { SocialProfileFlags, SocialProfileStatus } from "../../../types";

interface SocialProfileWithIconKey {
  icon: string;
  socialId: string | null;
  socialProvider: string;
  socialUrl: string;
  socialUsername: string;
  status: SocialProfileStatus;
  flags: SocialProfileFlags[];
}

export function ApiPairedIcon(item: SocialProfileWithIconKey) {
  if (item?.flags?.includes(SocialProfileFlags.ApiPaired)) {
    return (
      <div className="verified-badge">
        <img src={VerifiedIcon} alt="Verified" />
      </div>
    );
  }

  return null;
}
