import React, { useRef, useState } from "react";
import "./DropdownMenuHoverItem.scss";

import classNames from "classnames";
import { ReactComponent as ChevronIcon } from "../../../../images/chevron-left.svg";

import { DropdownMenuItem } from "../Dropdown";

interface Props {
  children: React.ReactNode;
  className?: string;
  content?: React.ReactNode;
  menuPosition: "left" | "right";
}

export function DropdownMenuHoverItem(props: Props) {
  const { children, className, content, menuPosition } = props;

  const [showMenu, setShowMenu] = useState(false);

  const ref = useRef(null);

  return (
    <DropdownMenuItem
      className={classNames(className, "dropdown-menu-hover-item")}
      onClick={() => setShowMenu((v) => !v)}
    >
      <span className="dropdown-menu-hover-item__button">
        {children}
        <ChevronIcon className="dropdown-menu-hover-item__chevron" />
      </span>

      {showMenu && (
        <div
          ref={ref}
          className={classNames("dropdown-menu-hover-item__menu", {
            "dropdown-menu-hover-item__menu--left": menuPosition === "left",
            "dropdown-menu-hover-item__menu--right": menuPosition === "right",
          })}
        >
          {content}
        </div>
      )}
    </DropdownMenuItem>
  );
}
