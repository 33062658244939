import React, { Dispatch, SetStateAction } from "react";

import { AddToDropdown } from "src/app/components/AddToDropdown/AddToDropdown";
import { DropdownMenuItem } from "src/app/components/Dropdown/Dropdown";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { SocialProfile } from "src/app/modals/AddSocialProfilesModal/AddSocialProfilesModal";
import { ReactComponent as RocketIcon } from "src/images/rocket-grey.svg";
import { SelectedCreator } from "src/app/modals/AddCreatorsCDTWorkspaceProjectModal/AddCreatorsCDTWorkspaceProjectModal";

interface AddToDropdownSelectedRowsBarProps {
  setShowModalAddCreatorsToWorkspaceProject: Dispatch<SetStateAction<boolean>>;
  setSelectedCreators: Dispatch<SetStateAction<SelectedCreator[]>>;
}

export default function AddToDropdownSelectedRowsBar({
  setShowModalAddCreatorsToWorkspaceProject,
  setSelectedCreators,
}: AddToDropdownSelectedRowsBarProps) {
  const openAddCreatorsToCampaignModal = () => {
    setShowModalAddCreatorsToWorkspaceProject(true);
    setSelectedCreators((prev) => {
      return prev.map((selectedCreator) => ({
        ...selectedCreator,
        socialProfiles: selectedCreator.socialProfiles
          ? selectedCreator.socialProfiles.map((profile: SocialProfile) => ({
              ...profile,
            }))
          : selectedCreator.socialProfiles,
      }));
    });
  };

  return (
    <AddToDropdown>
      <DropdownMenuItem onClick={openAddCreatorsToCampaignModal}>
        <RocketIcon />
        <IDHFormattedMessage id="ws_campaign" defaultMessage="Campaign" />
      </DropdownMenuItem>
    </AddToDropdown>
  );
}
