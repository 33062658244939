import React from "react";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import XssFilter from "src/app/components/XssFilter/XssFilter";
import InfoBox from "./InfoBox";

interface TextDetectionProps {
  text: string;
}

function TextDetection({ text }: TextDetectionProps) {
  return (
    <InfoBox
      title={
        <IDHFormattedMessage
          id="ws_optical_character_recognition"
          defaultMessage="Optical Character Recognition"
        />
      }
    >
      <div
        className="info-box__text"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: XssFilter(text) }}
      />
    </InfoBox>
  );
}

export default TextDetection;
