import { isObjectEmpty } from "./isObjectEmpty";

const trimObject = (object) => {
  const newObj = {};
  Object.keys(object).forEach((key) => {
    newObj[key] = object[key];
  });
  return newObj;
};

export const trimFilters = (reducer) => {
  let newObj = {};
  Object.keys(reducer).forEach((key) => {
    if (typeof reducer[key] === "boolean" || !isObjectEmpty(reducer[key]))
      newObj[key] =
        Array.isArray(reducer[key]) ||
        typeof reducer[key] === "string" ||
        typeof reducer[key] === "boolean"
          ? reducer[key]
          : trimObject(reducer[key]);
  });
  newObj = trimObject(newObj);
  if (typeof newObj !== "boolean" && isObjectEmpty(newObj || {}))
    return undefined;
  return newObj;
};

export const stringifyFilters = (reducer) => {
  const newObj = trimFilters(reducer);
  return JSON.stringify(newObj);
};
