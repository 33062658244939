import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { WrappedComponentProps, injectIntl, useIntl } from "react-intl";
import axios from "axios";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { translateMessage } from "src/app/methods/translateMessage";
import { TaskType } from "src/types";
import { RootState } from "src/redux/reducers";
import { showErrorToast } from "src/utils/methods";
import { API_URLS } from "src/utils/API_URLS";
import { MetricsData, MFState, MFStateEntries } from "./types";
import { CustomInputLabel } from "../../components/CustomInput/CustomInput";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import { ModalColumn } from "../../components/Modal/Modal";
import { isMetricsDataArrayType } from "./utils";

interface AudienceCountryOption {
  label: string;
  locale: string;
  value: string;
}

interface AudienceCountries {
  [key: string]: Array<AudienceCountryOption>;
}

interface MetricOption {
  label: string;
  type: string;
  value: string;
}

interface Props extends WrappedComponentProps<"intl"> {
  state: MFState;
  setState: (type: MFStateEntries, value: any) => void;
  data: any;
  taskType: null | string;
}

export interface SelectOption {
  value: any;
  label: JSX.Element | string;
}

function SocialMetrics(props: Props) {
  const {
    state: {
      selectedMetric,
      isAudienceReach,
      selectedAudienceCountry,
      selectedPlatform,
      metricsData,
    },
    setState,
    data,
    taskType,
  } = props;

  const intl = useIntl();
  const isEdited = !!data;

  const [audienceCountries, setAudienceCountries] =
    useState<AudienceCountries | null>(null);

  const [platformOptions, setPlatformOptions] = useState<Array<SelectOption>>(
    [],
  );
  const [metricOptions, setMetricOptions] = useState<Array<MetricOption>>([]);
  const [audienceCountryOptions, setAudienceCountryOptions] = useState<
    Array<AudienceCountryOption>
  >([]);

  useEffect(() => {
    const getMetricsValues = async () => {
      if (!taskType) {
        return;
      }

      try {
        const url = API_URLS.getProviderMetricsPossibleValues.replace(
          ":metricsType:",
          taskType === TaskType.Creator
            ? "SocialProfile"
            : "Publication",
        );

        const { data } = await axios.get<{ content: MetricsData }>(url);

        setState("metricsData", data.content);
      } catch (error) {
        console.error(error);
        showErrorToast();
      }
    };
    getMetricsValues();
  }, [taskType]);

  useEffect(() => {
    const getAudienceCountries = async () => {
      try {
        const url = API_URLS.getAudienceCountries;
        const { data } = await axios.get(url);

        setAudienceCountries(data.content?.audienceCountries);
      } catch (error) {
        console.error(error);
        showErrorToast();
      }
    };
    getAudienceCountries();
  }, []);

  useEffect(() => {
    if (metricsData) {
      setPlatformOptions(
        Object.keys(metricsData).map((key: string) => ({
          label: (
            <span style={{ textTransform: "capitalize" }}>
              {translateMessage({
                intl,
                id: key?.toLowerCase(),
                defaultMessage: key,
              })}
            </span>
          ),
          value: key,
        })),
      );
    }
  }, [metricsData]);

  useEffect(() => {
    if (selectedPlatform.value) {
      setState("selectedMetric", {});
      setState("selectedAudienceCountry", {});
      setState("isAudienceReach", false);
    }
  }, [selectedPlatform.value]);

  useEffect(() => {
    if (!selectedPlatform?.value && platformOptions.length > 0) {
      setState("selectedPlatform", platformOptions[0]);
    }
  }, [platformOptions, selectedPlatform.value]);

  useEffect(() => {
    if (selectedPlatform.value && !isMetricsDataArrayType(metricsData)) {
      setMetricOptions(
        Object.entries(metricsData[selectedPlatform.value]).map((e) => {
          return {
            label: e[1].key.replace("twitter", "x"),
            value: e[0],
            type: e[1].type,
          };
        }),
      );
    }
  }, [selectedPlatform.value, metricsData]);

  useEffect(() => {
    if (audienceCountries && selectedPlatform.value) {
      setAudienceCountryOptions(
        audienceCountries[selectedPlatform.value] ?? [],
      );
    }
  }, [audienceCountries, selectedPlatform.value]);

  useEffect(() => {
    if (data) {
      const setEditedFieldData = () => {
        const source = data.metaFieldSource;
        const platform = source.split(".")[1];

        setState(
          "selectedPlatform",
          platformOptions.find((item) => item.value === platform) ?? {},
        );

        setState(
          "selectedMetric",
          metricOptions.find((item) => item.value === source) ?? {},
        );

        if (source.includes("audienceGeographicalReach")) {
          const sourceSplitArr = source.split(".");
          const audienceMetricSource = source.slice(0, source.lastIndexOf("."));

          const selectedMetricOption = metricOptions.find(
            (item) => item.value === audienceMetricSource,
          );

          if (selectedMetricOption) {
            selectedMetricOption.value = source;
            setState("selectedMetric", selectedMetricOption);
          }

          setState("isAudienceReach", true);
          if (audienceCountryOptions.length > 0) {
            setState(
              "selectedAudienceCountry",
              audienceCountryOptions.find(
                (item) =>
                  item.value === sourceSplitArr[sourceSplitArr?.length - 1],
              ) ?? {},
            );
          }
        }
      };
      setEditedFieldData();
    }
  }, [data, platformOptions, metricOptions, audienceCountryOptions]);

  return (
    <div className="metric-wrapper">
      <ModalColumn>
        <CustomInputLabel htmlFor="platform">
          {taskType === TaskType.Publication ? (
            <IDHFormattedMessage
              id="ws_publication_type"
              defaultMessage="Publication Type"
            />
          ) : (
            <IDHFormattedMessage id="ws_platform" defaultMessage="Platform" />
          )}
        </CustomInputLabel>
        <CustomSelect
          id="platform"
          value={selectedPlatform}
          options={platformOptions}
          placeholder={translateMessage({
            intl,
            id: "ws_select_platform",
            defaultMessage: "Select platform",
          })}
          onChange={(newValue: SelectOption) =>
            setState("selectedPlatform", newValue)
          }
          disabled={isEdited}
        />
      </ModalColumn>
      <ModalColumn>
        <CustomInputLabel htmlFor="metric">
          <IDHFormattedMessage id="ws_metric" defaultMessage="Metric" />
        </CustomInputLabel>
        <CustomSelect
          id="metric"
          value={selectedMetric}
          options={metricOptions}
          placeholder={translateMessage({
            intl,
            id: "ws_select_metric",
            defaultMessage: "Select metric",
          })}
          isSearchable
          onChange={(newValue: MetricOption) => {
            setState("selectedMetric", newValue);
            if (newValue?.value?.includes("audienceGeographicalReach")) {
              setState("isAudienceReach", true);
            } else {
              setState("isAudienceReach", false);
            }
          }}
          disabled={isEdited}
        />
        {isAudienceReach && (
          <>
            <br />
            <CustomInputLabel htmlFor="metric_audience_reach_country">
              <IDHFormattedMessage
                id="ws_metric_audience_reach_country"
                defaultMessage="Audience Reach country"
              />
            </CustomInputLabel>
            <CustomSelect
              id="metric_audience_reach_country"
              value={selectedAudienceCountry}
              options={audienceCountryOptions}
              placeholder={translateMessage({
                intl,
                id: "ws_select_audience_reach_country",
                defaultMessage: "Select audience reach country",
              })}
              isSearchable
              onChange={(newValue: AudienceCountryOption) => {
                setState("selectedAudienceCountry", newValue);
                selectedMetric.value = `${selectedMetric.value?.slice(
                  0,
                  selectedMetric.value?.indexOf("audienceGeographicalReach"),
                )}audienceGeographicalReach.${newValue.value}`;
              }}
              disabled={isEdited}
            />
          </>
        )}
      </ModalColumn>
    </div>
  );
}

export default injectIntl(SocialMetrics);
