import React from "react";
import "./SettingsTabContent.scss";
import MyAccount from "src/app/components/MyAccount/MyAccount";
import { Language } from "src/app/modals/AccountSettingsModal/components/Language";
import { Button } from "src/app/components/Button/Button";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import Loader from "src/app/components/Loader/Loader";
import classNames from "classnames";
import { General } from "../../tabs/General/General";
import { Members } from "../../tabs/Members/Members";
import { Automations } from "../../tabs/Automations/Automations";
import { SettingsTab } from "../../enums";
import { UniversalSettingsTab } from "../../SettingsModal";
import { Extensions } from "../../tabs/Extensions/Extensions";
import { CreatorDiscovery } from "../../tabs/CreatorDiscovery/CreatorDiscovery";
import { StoreConfiguration } from "../../tabs/StoreConfiguration/StoreConfiguration";
import ManageFields from "../../tabs/ManageFields/ManageFields";
import { WorkspaceSetupTab } from "../../tabs/WorkspaceSetupTab/WorkspaceSetupTab";
import { Teams } from "../../tabs/Teams/Teams";
import { CreatorDatabaseConfiguration } from "../../tabs/CreatorDatabaseConfiguration/CreatorDatabaseConfiguration";
import Integrations from "../../tabs/Integrations/Integrations";
import TemplateManager from "../../tabs/TemplateManager/TemplateManager";

interface Props {
  onClose: () => void;
  tab: UniversalSettingsTab;
  getData: () => void;
  getCreatorDiscoveryData: () => void;
  contextData: any;
  members: any;
  automations: any;
  creatorDiscovery: any;
  loading: boolean;
}

export const SettingsTabContent: React.FC<Props> = (props) => {
  const {
    onClose,
    tab,
    getData,
    contextData,
    getCreatorDiscoveryData,
    members,
    automations,
    creatorDiscovery,
    loading,
  } = props;

  const renderTabContent = () => {
    switch (tab) {
      case SettingsTab.General:
        return (
          <General
            onClose={onClose}
            contextData={contextData}
            getData={getData}
          />
        );

      case SettingsTab.Members:
        if (!members) return null;
        return (
          <Members
            onClose={onClose}
            contextData={contextData}
            getData={getData}
            data={members}
          />
        );

      case SettingsTab.Teams:
        return <Teams onClose={onClose} />;

      case SettingsTab.WorkspaceSetup:
        return <WorkspaceSetupTab onClose={onClose} />;

      case SettingsTab.CreatorDiscovery:
        if (!creatorDiscovery) return null;
        return (
          <CreatorDiscovery
            onClose={onClose}
            data={creatorDiscovery}
            getData={getCreatorDiscoveryData}
          />
        );

      case SettingsTab.TemplateManager:
        return <TemplateManager onClose={onClose} />;

      case SettingsTab.Automations:
        return <Automations onClose={onClose} data={automations} />;

      case SettingsTab.Account:
        return <MyAccount onClose={onClose} />;

      case SettingsTab.Language:
        return <Language onClose={onClose} />;

      case SettingsTab.Extensions:
        return <Extensions contextData={contextData} onClose={onClose} />;

      case SettingsTab.Coupons:
        return <StoreConfiguration onClose={onClose} />;

      case SettingsTab.ManageFields:
        return <ManageFields onClose={onClose} />;

      case SettingsTab.CreatorDatabaseConfiguration:
        return (
          <CreatorDatabaseConfiguration
            contextData={contextData}
            onClose={onClose}
          />
        );
      case SettingsTab.Integrations:
        return <Integrations onClose={onClose} contextData={contextData} />;
      default:
        return tab;
    }
  };

  const Spinner = (
    <div className="settings-tab-content__loader">
      <Loader />
    </div>
  );

  return (
    <div className="settings-tab-content">
      {loading ? Spinner : renderTabContent()}
    </div>
  );
};

interface ButtonsProps {
  className?: string;
  onClose?: () => void;
  children?: React.ReactNode;
}

export function SettingsButtons({
  className,
  onClose,
  children,
}: ButtonsProps) {
  return (
    <div className={classNames("settings-tab-content__buttons", className)}>
      {onClose && (
        <Button
          size="large"
          variant="white-with-black-border"
          onClick={onClose}
          data-qa="close-settings-modal"
        >
          <IDHFormattedMessage id="ws_close" defaultMessage="Close" />
        </Button>
      )}

      {children}
    </div>
  );
}
