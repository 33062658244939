import React from "react";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import InfoBox from "./InfoBox";

interface UrlDetectionProps {
  urls: string[];
}

function UrlDetection({ urls }: UrlDetectionProps) {
  return (
    <InfoBox
      title={<IDHFormattedMessage id="ws_links" defaultMessage="Link" />}
    >
      <div className="info-box__text">
        {urls.map((url) => (
          <div key={url}>
            <a href={url} target="_blank" rel="noreferrer">
              {url}
            </a>
          </div>
        ))}
      </div>
    </InfoBox>
  );
}

export default UrlDetection;
