import React, { useState } from "react";

import { isEmpty } from "lodash";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { TASK_FIELDS_BLACKLIST } from "src/app/Task/components/TaskMetaFields/TaskMetaFields";
import { Metadata } from "src/app/Task/Subtask/Subtask";
import { IMember } from "src/app/project/ProjectTypes";
import { AddedBy } from "src/app/Task/components/AddedBy/AddedBy";
import { GlobalTaskMetaField } from "../../components/GlobalTaskMetaField/GlobalTaskMetaField";

import "./GlobalData.scss";

interface Props {
  taskId: string;
  metadata: Metadata[];
  taskOwner?: IMember | undefined;
  dateCreated?: moment.Moment | null;
}

export const GlobalData: React.FC<Props> = (props) => {
  const { taskId, metadata = [], taskOwner, dateCreated } = props;

  const [showMore, setShowMore] = useState(false);

  const moreFields = metadata?.filter(
    (field) => !TASK_FIELDS_BLACKLIST.includes(field.key as string),
  );

  const lessFields = metadata.filter((field: any) => !isEmpty(field.value));

  const getFields = () => {
    if (showMore) return metadata;

    return lessFields;
  };

  return (
    <section className="global-data">
      {getFields().map((field) => (
        <GlobalTaskMetaField field={field} taskId={taskId} key={field.uuid} />
      ))}
      {moreFields.length > lessFields.length && (
        <div
          className="global-data__show-more"
          onClick={() => setShowMore((prev) => !prev)}
        >
          {showMore ? (
            <IDHFormattedMessage id="ws_show_less" defaultMessage="Show less" />
          ) : (
            <IDHFormattedMessage id="ws_show_more" defaultMessage="Show more" />
          )}
        </div>
      )}

      {taskOwner && dateCreated && (
        <AddedBy
          name={taskOwner.name}
          avatarUrl={taskOwner.avatarUrl}
          dateCreated={dateCreated}
        />
      )}
    </section>
  );
};
