import React, { SetStateAction, Dispatch, useState, useMemo } from "react";

import { useDispatch, useSelector } from "react-redux";

import {
  DropdownMenu,
  DropdownMenuItem,
} from "src/app/components/Dropdown/Dropdown";
import { ReactComponent as DeleteIcon } from "src/images/trash-can.svg";
import { ReactComponent as InfoGreyIcon } from "src/images/info-grey.svg";
import { ReactComponent as ShowcaseIcon } from "src/images/showcase.svg";
import { ReactComponent as LinkIcon } from "src/images/link.svg";
import { ReactComponent as CreatorIcon } from "src/images/avatar.svg";
import { ReactComponent as TickIcon } from "src/images/tick-star.svg";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { useHistory, useParams } from "react-router";
import { openDictionaryDetails } from "src/app/components/DictionaryDetails/methods";
import { openGlobalTaskDetails } from "src/app/components/GlobalTaskDetails/methods";
import { deleteDictionaryElement } from "src/redux";
import { Row } from "src/redux/dictionary/types";
import { deleteGlobalTasks } from "src/redux/creator-database/creatorDatabaseActions";
import { RemoveModal } from "src/app/modals/RemoveModal/RemoveModal";

import "./ActionDropdownMenu.scss";
import { tableDataType } from "src/app/components/Table/Table";
import {
  CreatorShowcaseContext,
  openCreatorShowcase,
} from "src/app/CreatorShowcase/utils";
import { DropdownMenuHoverItem } from "src/app/components/Dropdown/components/DropdownMenuHoverItem";
import { RootState } from "src/redux/reducers";
import { ExtensionTypeEnum, SocialProfileFlags } from "src/types";
import { AppConfig } from "src/config/AppConfig";
import { copyLink } from "src/utils/methods";
import { showToast } from "src/app/methods/showToast";
import { GlideDataGridContext } from "../../types";

interface ActionDropdownMenuProps {
  context: GlideDataGridContext;
  setIsDropdownOpen: Dispatch<SetStateAction<boolean>>;
  rowData: Row;
}

interface IConfirmationModal {
  isVisible: boolean;
  callback: () => void;
}

const initialConfirmationModalSettings = {
  isVisible: false,
  callback: () => {},
};

export function ActionDropdownMenu({
  context,
  setIsDropdownOpen,
  rowData,
}: ActionDropdownMenuProps) {
  const [confirmationModalSettings, setConfirmationModalSettings] =
    useState<IConfirmationModal>(initialConfirmationModalSettings);
  return (
    <>
      <div className="action-dropdown-menu">
        <DropdownMenu>
          <MenuItems
            context={context}
            rowData={rowData}
            setIsDropdownOpen={setIsDropdownOpen}
            setConfirmationModalSettings={setConfirmationModalSettings}
          />
        </DropdownMenu>
      </div>
      {confirmationModalSettings.isVisible && (
        <RemoveModal
          objectNames={[rowData?.title ?? ""]}
          onClose={() => {
            setConfirmationModalSettings(initialConfirmationModalSettings);
            setIsDropdownOpen(false);
          }}
          removeFunction={confirmationModalSettings.callback}
        />
      )}
    </>
  );
}

interface MenuItemsProps {
  context: GlideDataGridContext;
  rowData: Row;
  setIsDropdownOpen: Dispatch<SetStateAction<boolean>>;
  setConfirmationModalSettings: Dispatch<SetStateAction<IConfirmationModal>>;
}

function MenuItems({
  context,
  rowData,
  setIsDropdownOpen,
  setConfirmationModalSettings,
}: MenuItemsProps) {
  const history = useHistory();
  const params = useParams<{ workspaceUuid: string; dictionaryUuid: string }>();
  const dispatch = useDispatch();

  const activeWorkspaceUuid = useSelector(
    (state: RootState) => state.mainReducer.activeWorkspaceUuid,
  );
  const identity = useSelector(
    (state: RootState) => state.mainReducer.identity,
  );
  const workspaceExtensionList = identity?.wsWorkspaceExtensions;

  const instagramAccountVerificationItem = useMemo(
    () =>
      workspaceExtensionList.find(
        (extension: { type: string }) =>
          extension.type === ExtensionTypeEnum.ApiConnection,
      ),
    [workspaceExtensionList],
  );

  const instagramApiPaired = useMemo(() => {
    const ig = rowData.socialProfiles?.find(
      (item) => item.socialProvider === "instagram",
    );
    return ig?.flags.includes(SocialProfileFlags.ApiPaired);
  }, [rowData.socialProfiles]);

  function ShowDetailsMenuItem({
    showDetailsAction,
  }: {
    showDetailsAction: () => void;
  }) {
    return (
      <DropdownMenuItem
        onClick={() => {
          showDetailsAction();
          setIsDropdownOpen(false);
        }}
      >
        <InfoGreyIcon />
        <IDHFormattedMessage
          id="ws_show_details"
          defaultMessage="Show details"
        />
      </DropdownMenuItem>
    );
  }

  function CopyLinkMenuItem() {
    const { encodedData } = instagramAccountVerificationItem?.settings || {};

    const copyProfileLink = () => {
      copyLink(
        `${AppConfig.getAppRoute()}/${activeWorkspaceUuid}/creator-database?displayGlobalTask=${rowData?.uuid}`,
      );
      setIsDropdownOpen(false);
    };

    const copyVerificationUrl = () => {
      copyLink(
        `${AppConfig.getApiHost()}/fb-int/social-onboarding/workspace-onboard?data=${encodedData}`,
      );
      setIsDropdownOpen(false);
    };

    const showCopyVerificationButton = encodedData && !instagramApiPaired;

    return (
      <DropdownMenuHoverItem
        content={
          <>
            <DropdownMenuItem onClick={copyProfileLink}>
              <CreatorIcon />
              <IDHFormattedMessage
                id="ws_creator_profile"
                defaultMessage="Creator Profile"
              />
            </DropdownMenuItem>
            {showCopyVerificationButton && (
              <DropdownMenuItem onClick={copyVerificationUrl}>
                <TickIcon />
                <IDHFormattedMessage
                  id="ws_instagram_account_integration"
                  defaultMessage="Instagram Account Verification"
                />
              </DropdownMenuItem>
            )}
          </>
        }
        menuPosition="right"
      >
        <LinkIcon />
        <IDHFormattedMessage id="ws_copy_link" defaultMessage="Copy link" />
      </DropdownMenuHoverItem>
    );
  }

  function GenerateShowcaseMenuItem({
    openShowcaseAction,
  }: {
    openShowcaseAction: () => void;
  }) {
    return (
      <DropdownMenuItem
        onClick={() => {
          openShowcaseAction();
          setIsDropdownOpen(false);
        }}
      >
        <ShowcaseIcon />
        <IDHFormattedMessage
          id="ws_generate_showcase"
          defaultMessage="Generate showcase"
        />
      </DropdownMenuItem>
    );
  }

  function RemoveMenuItem({ deleteAction }: { deleteAction: () => void }) {
    return (
      <DropdownMenuItem
        className="dropdown__menu-item--danger"
        onClick={() => {
          setConfirmationModalSettings({
            isVisible: true,
            callback: () => {
              deleteAction();
              setIsDropdownOpen(false);
            },
          });
        }}
      >
        <DeleteIcon />
        <IDHFormattedMessage id="ws_remove" defaultMessage="Remove" />
      </DropdownMenuItem>
    );
  }

  switch (context) {
    case tableDataType.Dictionary:
      return (
        <>
          <ShowDetailsMenuItem
            showDetailsAction={() =>
              openDictionaryDetails(history, rowData.uuid)
            }
          />

          <RemoveMenuItem
            deleteAction={() =>
              dispatch(
                deleteDictionaryElement(params.dictionaryUuid, [rowData.uuid]),
              )
            }
          />
        </>
      );

    case tableDataType.CreatorDatabase:
      return (
        <>
          <ShowDetailsMenuItem
            showDetailsAction={() =>
              openGlobalTaskDetails(history, rowData.uuid)
            }
          />

          <GenerateShowcaseMenuItem
            openShowcaseAction={() =>
              openCreatorShowcase({
                history,
                context: CreatorShowcaseContext.CreatorDatabase,
                creatorId: rowData.uuid,
                ids: [rowData.uuid],
                workspaceId: params.workspaceUuid,
              })
            }
          />

          <CopyLinkMenuItem />

          <RemoveMenuItem
            deleteAction={() => dispatch(deleteGlobalTasks([rowData.uuid]))}
          />
        </>
      );

    default:
      return null;
  }
}
