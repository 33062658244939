import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { CustomInputLabel } from "src/app/components/CustomInput/CustomInput";
import CustomSelect from "src/app/components/CustomSelect/CustomSelect";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ModalText } from "src/app/components/Modal/Modal";
import { isStringNumeric } from "src/utils/methods";
import { tableDataType } from "src/app/components/Table/Table";
import { RootState } from "src/redux/reducers";
import { ProjectMetaData } from "src/redux/project/types";
import { FieldIcons } from "../FieldIcons/FieldIcons";
import { mathOperatorOptions, resultFormatOptions } from "../../utils";
import {
  ALLOWED_ELEMENT_TYPES,
  generateGlobalProjectOptions,
  generateGlobalTaskOptions,
  generateOptionsFromGlobalFields,
  generateProjectOptions,
  generateTaskOptions,
} from "./utils";
import { SelectOption } from "../../SocialMetrics";
import { MFState, MFStateEntries, SelectedFormulaElement } from "../../types";

import "./FormulaOverview.scss";

interface FormulaOverviewProps {
  data: any;
  state: MFState;
  setState: (type: MFStateEntries, value: any) => void;
  dataType: tableDataType;
  isNewOverviewForClient?: boolean;
  hasErrors: boolean;
  isGlobalMode: boolean | undefined;
}

export default function FormulaOverview(props: FormulaOverviewProps) {
  const {
    data,
    state: {
      selectedFormulaOperator,
      selectedResultFormat,
      selectedFormulaElements,
      selectedIcon,
      selectedColor,
      addIcon,
      selectedTaskType,
    },
    setState,
    dataType,
    isNewOverviewForClient,
    hasErrors,
    isGlobalMode,
  } = props;

  const [options, setOptions] = useState<SelectedFormulaElement[]>([]);

  const {
    mainReducer: { activeWorkspaceUuid },
    projectReducer: { projectMetaFields, taskType },
    taskReducer: { tasksColumns },
    settingsReducer: { globalProjectMetaFields, globalTaskMetaFields },
  } = useSelector((state: RootState) => state);

  const intl = useIntl();

  useEffect(() => {
    const generateOptionsForDataType = async () => {
      let generatedOptions: SelectedFormulaElement[] = [];

      if (dataType === tableDataType.Task) {
        if (isGlobalMode) {
          const globalFields = [
            ...globalProjectMetaFields,
            ...globalTaskMetaFields,
          ];

          const generatedOptionsFromGlobalFields =
            generateOptionsFromGlobalFields(
              globalFields,
              selectedTaskType.value,
            );

          const generatedGlobalTaskOptions =
            (await generateGlobalTaskOptions(
              selectedTaskType.value,
              activeWorkspaceUuid,
            )) ?? [];

          generatedOptions = [
            ...generatedGlobalTaskOptions,
            ...generatedOptionsFromGlobalFields,
          ];
        } else {
          const generatedTaskOptions = generateTaskOptions({
            tasksColumns,
            isNewOverviewForClient,
            taskType,
          });
          const generatedProjectOptions = generateProjectOptions({
            projectMetaFields,
            filterCallback: (item: ProjectMetaData) =>
              item.taskType === taskType &&
              ALLOWED_ELEMENT_TYPES.includes(item.type) &&
              (isNewOverviewForClient ? item.shared : true),
            prefix: "Overview",
          });
          generatedOptions = [
            ...generatedTaskOptions,
            ...generatedProjectOptions,
          ];
        }
      }

      if (
        dataType === tableDataType.Project ||
        dataType === tableDataType.ProjectDetails
      ) {
        if (isGlobalMode) {
          generatedOptions =
            (await generateGlobalProjectOptions(
              selectedTaskType.value,
              activeWorkspaceUuid,
            )) ?? [];
        } else {
          generatedOptions = generateProjectOptions({
            projectMetaFields,
            filterCallback: (item: ProjectMetaData) =>
              (item.taskType === taskType || item.taskType === null) &&
              ALLOWED_ELEMENT_TYPES.includes(item.type) &&
              (isNewOverviewForClient ? item.shared : true),
          });
        }
      }

      if (data) {
        // Remove currently edited field from generated options array
        const currentlyEditedFieldUuid = data?.uuid || data?.id;
        generatedOptions = [...generatedOptions].filter(
          (generatedOption) =>
            generatedOption.value?.id !== currentlyEditedFieldUuid,
        );
      }

      setOptions(generatedOptions);
    };

    generateOptionsForDataType();
  }, [
    data,
    isGlobalMode,
    tasksColumns,
    projectMetaFields,
    selectedTaskType.value,
    activeWorkspaceUuid,
  ]);

  useEffect(() => {
    if (!data && !hasErrors) {
      setState("selectedFormulaOperator", mathOperatorOptions[0]);
      setState("selectedResultFormat", resultFormatOptions[0]);
    }
  }, [data, hasErrors]);

  useEffect(() => {
    if (hasErrors) return;
    setState("selectedFormulaElements", { a: {}, b: {} });
  }, [selectedTaskType.value, hasErrors]);

  return (
    <div className="formula-overview">
      <ModalText>
        <IDHFormattedMessage
          id="ws_formula_text"
          defaultMessage="Formula allows you to define a field that will use the logical function on the selected dataset. Select the data source and logical function."
        />
      </ModalText>

      <div className="formula-overview__select-wrapper" data-qa="first-formula">
        <span className="formula-overview__select-wrapper-number">1.</span>
        <CustomSelect
          value={selectedFormulaElements?.a}
          onChange={(newValue: SelectOption) =>
            setState("selectedFormulaElements", {
              ...selectedFormulaElements,
              a: newValue,
            })
          }
          options={options}
          isSearchable
          creatable
          placeholder={intl.formatMessage({
            id: "ws_select_field_or_enter_value",
            defaultMessage: "Select field or enter value",
          })}
          formatCreateLabel={(inputText: string) => (
            <span className="formula-overview__select-wrapper-creatable-label">
              <IDHFormattedMessage
                id="ws_enter_value"
                defaultMessage="Enter value"
              />
              &nbsp;
              <span className="formula-overview__select-wrapper-creatable-label-value">
                {inputText}
              </span>
            </span>
          )}
          isValidNewOption={(inputValue: string) => isStringNumeric(inputValue)}
        />
      </div>

      <div
        className="formula-overview__operation-selector"
        data-qa="formula-operator"
      >
        <CustomSelect
          options={mathOperatorOptions}
          value={selectedFormulaOperator}
          onChange={(newValue: SelectOption) =>
            setState("selectedFormulaOperator", newValue)
          }
        />
      </div>

      <div
        className="formula-overview__select-wrapper"
        data-qa="second-formula"
      >
        <span className="formula-overview__select-wrapper-number">2.</span>
        <CustomSelect
          value={selectedFormulaElements?.b}
          onChange={(newValue: SelectOption) =>
            setState("selectedFormulaElements", {
              ...selectedFormulaElements,
              b: newValue,
            })
          }
          options={options}
          isSearchable
          creatable
          placeholder={intl.formatMessage({
            id: "ws_select_field_or_enter_value",
            defaultMessage: "Select field or enter value",
          })}
          formatCreateLabel={(inputText: string) => (
            <span className="formula-overview__select-wrapper-creatable-label">
              <IDHFormattedMessage
                id="ws_enter_value"
                defaultMessage="Enter value"
              />
              &nbsp;
              <span className="formula-overview__select-wrapper-creatable-label-value">
                {inputText}
              </span>
            </span>
          )}
          isValidNewOption={(inputValue: string) => isStringNumeric(inputValue)}
        />
      </div>

      <CustomInputLabel>
        <IDHFormattedMessage
          id="ws_result_format"
          defaultMessage="Result format"
        />
      </CustomInputLabel>
      <div data-qa="result-format-select">
        <CustomSelect
          options={resultFormatOptions}
          value={selectedResultFormat}
          onChange={(newValue: SelectOption) =>
            setState("selectedResultFormat", newValue)
          }
          placeholder={intl.formatMessage({
            id: "ws_select_field_or_enter_value",
            defaultMessage: "Select field or enter value",
          })}
          disabled={!!data}
        />
      </div>

      {dataType !== tableDataType.Task && (
        <FieldIcons
          selectedIcon={selectedIcon}
          setSelectedIcon={(newValue: any) =>
            setState("selectedIcon", newValue)
          }
          selectedColor={selectedColor}
          setSelectedColor={(newValue: any) =>
            setState("selectedColor", newValue)
          }
          addIcon={addIcon}
          setAddIcon={(newValue: any) => setState("addIcon", newValue)}
        />
      )}
    </div>
  );
}
