import React, { ReactNode, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { getSelectDataSetOptions } from "src/redux/select-data-set/selectDataSetActions";
import { RootState } from "src/redux/reducers";
import { Metadata } from "src/app/Task/Subtask/Subtask";
import { tableDataType } from "../../components/Table/Table";
import Select from "../../components/Select/Select";
import { Mode, Variant } from "../../components/Select/types";

interface SelectDropdownProps {
  uuid?: string;
  dataType: tableDataType;
  fieldData: Metadata;
  fieldValue: any;
  wsSelectDataSetUuid?: string;
  onValueChange: any;
  selectOptionType: Mode;
  readOnly?: boolean;
  spaceLeft?: boolean;
  variant?: Variant;
  isRemovable?: boolean;
  stackLabels?: boolean;
  emptyFieldPlaceholder?: ReactNode;
  removeValueButton?: ReactNode;
}

function SelectDropdown({
  uuid,
  dataType,
  fieldData,
  fieldValue,
  wsSelectDataSetUuid,
  onValueChange,
  selectOptionType,
  readOnly = false,
  spaceLeft = false,
  variant,
  isRemovable = true,
  stackLabels,
  emptyFieldPlaceholder,
  removeValueButton,
}: SelectDropdownProps) {
  const selectDataSetList = useSelector(
    (state: RootState) => state.selectDataSetReducer.selectDataSetList,
  );

  const isSelectDataSetRequestLoading = useSelector(
    (state: RootState) =>
      state.selectDataSetReducer.isSelectDataSetRequestLoading,
  );

  const dispatch = useDispatch();

  const [selectOptions, setSelectOptions] = useState<any[]>([]);

  const shouldBeAbleToRemoveValue = fieldValue && !readOnly && isRemovable;

  const determineSelectVariant = () => {
    if (variant) return variant;

    const hasGlobalTaskMetaField = fieldData?.wsGlobalTaskMetaFieldUuid;
    const hasGlobalProjectMetaField = fieldData?.wsGlobalProjectMetaFieldUuid;
    const hasSelectDataSetUuid = fieldData?.data?.wsSelectDataSetUuid;

    if (
      hasGlobalTaskMetaField ||
      hasGlobalProjectMetaField ||
      hasSelectDataSetUuid ||
      !isRemovable ||
      dataType === tableDataType.TemplateFields
    ) {
      return "disable_all";
    }

    return "default";
  };

  useEffect(() => {
    if (
      selectDataSetList[`dataSetType_${wsSelectDataSetUuid}`] &&
      selectOptions !== selectDataSetList[`dataSetType_${wsSelectDataSetUuid}`]
    ) {
      setSelectOptions(
        selectDataSetList[`dataSetType_${wsSelectDataSetUuid}`] || [],
      );
    }
  }, [selectDataSetList]);

  useEffect(() => {
    if (
      !wsSelectDataSetUuid ||
      isSelectDataSetRequestLoading ||
      selectDataSetList[`dataSetType_${wsSelectDataSetUuid}`] ||
      dataType !== tableDataType.GlobalMetaFieldProjectWizardFields
    )
      return;

    dispatch(getSelectDataSetOptions(wsSelectDataSetUuid));
  }, [
    dataType,
    isSelectDataSetRequestLoading,
    selectDataSetList,
    wsSelectDataSetUuid,
  ]);

  useEffect(() => {
    const selectOptionsData = fieldData?.data?.singleSelectOptions;
    if (selectOptionsData) {
      setSelectOptions(selectOptionsData);
    }
  }, [fieldData]);

  return (
    <>
      <Select
        mode={selectOptionType}
        optionsData={selectOptions}
        uuid={uuid || ""}
        fieldValue={fieldValue}
        fieldName={fieldData?.name}
        variant={determineSelectVariant()}
        stackLabels={stackLabels}
        updateValue={onValueChange}
        emptyFieldPlaceholder={!readOnly && emptyFieldPlaceholder}
        dataType={dataType}
        readOnly={readOnly}
        spaceLeft={spaceLeft}
      />
      {shouldBeAbleToRemoveValue && removeValueButton}
    </>
  );
}

export default SelectDropdown;
