import React, { SetStateAction, Dispatch } from "react";
import {
  DragUpdate,
  DraggingStyle,
  DropResult,
  NotDraggingStyle,
  DroppableProvided,
  DroppableStateSnapshot,
  DragDropContext,
  Droppable,
} from "react-beautiful-dnd";

import { Coordinates } from "./types";

interface FieldsListProps {
  onDragEnd: (result: DropResult) => void;
  listRenderer: (
    provided: DroppableProvided,
    snapshot: DroppableStateSnapshot,
    getElementTop: (
      style: DraggingStyle | NotDraggingStyle | undefined,
    ) => number | "auto",
  ) => JSX.Element;
  setCoordinates: Dispatch<SetStateAction<Coordinates | null>>;
}

function FieldsList({
  onDragEnd,
  listRenderer,
  setCoordinates,
}: FieldsListProps) {
  const handleDragUpdate = (update: DragUpdate) => {
    if (!update.destination) {
      return;
    }

    const queryAttr = "data-rbd-drag-handle-draggable-id";

    const { draggableId } = update;
    const destinationIndex = update.destination.index;

    const domQuery = `[${queryAttr}='${draggableId}']`;
    const draggedDOM = document.querySelector(domQuery) as {
      clientHeight: number;
      clientWidth: number;
      parentNode: any;
    } | null;

    if (!draggedDOM) {
      return;
    }
    const { clientHeight, clientWidth } = draggedDOM;

    const clientY =
      parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
      [...draggedDOM.parentNode.children]
        .slice(0, destinationIndex)
        .reduce((total, curr) => {
          const style = curr.currentStyle || window.getComputedStyle(curr);
          const marginBottom = parseFloat(style.marginBottom);
          return total + curr.clientHeight + marginBottom;
        }, 0);

    const computedCoordinates = {
      clientHeight,
      clientWidth,
      clientY,
      clientX: parseFloat(
        window.getComputedStyle(draggedDOM.parentNode).paddingLeft,
      ),
    };

    setCoordinates(computedCoordinates);
  };

  const getElementTop = (
    style: DraggingStyle | NotDraggingStyle | undefined,
  ) => {
    if (style && "top" in style) {
      if (window.innerWidth < 1024) {
        return style.top - 235;
      }

      if (window.innerWidth < 1400) {
        return style.top - 205;
      }

      return style.top - 135;
    }
    return "auto";
  };

  return (
    <DragDropContext onDragUpdate={handleDragUpdate} onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable" direction="vertical">
        {(provided, snapshot) =>
          listRenderer(provided, snapshot, getElementTop)
        }
      </Droppable>
    </DragDropContext>
  );
}

export default FieldsList;
