import responseCodes from "src/utils/responseCodes";
import { getFiltersFromLocation } from "../../../utils/getFiltersFromLocation";
import METHODS from "../helpers/methods";
import {
  CHANGE_SEARCH_AUDIENCE_FILTERS,
  CLEAR_RESULTS,
  CLEAR_SELECTED_USERS,
  CREATE_INFLUENCERS_LIST_SUCCESS,
  CREATE_INFLUENCERS_LIST_FAIL,
  DELETE_INFLUENCERS_FROM_LIST,
  EDIT_NAME_SUCCESS,
  GET_COUNTRIES_SUCCESS,
  GET_INFLUENCERS_LIST_DATA,
  GET_INFLUENCERS_LIST_DATA_SUCCESS,
  GET_INFLUENCERS_LIST_DATA_FAIL,
  GET_INFLUENCERS_LIST,
  GET_INFLUENCERS_LIST_SUCCESS,
  GET_INTERESTS_SUCCESS,
  GET_AUDIENCE_BRAND_AFFINITY_SUCCESS,
  GET_KEYWORDS_SUCCESS,
  POST_SEARCH_AUDIENCE_SUCCESS,
  POST_SEARCH_AUDIENCE_FAIL,
  SELECT_INFLUENCER,
  GET_VELOCITY_SUMMARY_DATA_SUCCESS,
  CLEAR_VELOCITY_SUMMARY_DATA,
  ADD_TO_AUDIENCES_SUCCESS,
  SELECT_MULTIPLE_INFLUENCER,
  DELETE_INFLUENCERS_FROM_LIST_SUCCESS,
  CLEAR_ALL_SELECTED_USERS,
  POST_SEARCH_AUDIENCE,
  CLEAR_ALL_SELECTED_USERS_BOTH_TYPES,
  SET_COUNTRY_DISABLED,
  CLEAR_DISABLED_COUNTRIES,
  ADD_CREATORS_TO_LIST_FROM_SOCIALS_SUCCESS,
  SET_LIST_SOCIAL_PROVIDER,
  POST_REFRESH_AUDIENCE,
  POST_REFRESH_AUDIENCE_SUCCESS,
  GET_INFLUENCER_INTERESTS_SUCCESS,
  CLEAR_INFLUENCERS_LIST_INFO,
  GET_SETTINGS_SUCCESS,
  GET_SETTINGS,
  CLOSE_TRIAL_EXCEEDED_CDT_MODAL,
  POST_LOAD_MORE_SEARCH_RESULTS_SUCCESS,
  POST_LOAD_MORE_SELECTED_INFLUENCERS_SUCCESS,
  DELETE_AWAITING_USER,
  SET_AUDIENCE_LOADING,
  SET_AUDIENCE_DATA,
  SET_IS_INCORRECT_PROVIDER_ERROR,
} from "./audiencesManagerActions";

const initialState = {
  // TODO:: find better names for variables
  interests: [],
  audienceCountries: [],
  audienceBrandAffinity: [],
  influencerCountries: [],
  influencerProviders: [],
  influencerCountriesByProvider: [],
  influencerCities: [],
  influencerIgCategories: [],
  influencerInterests: [],
  keywords: [],

  searchAudienceFilters: getFiltersFromLocation() || {},

  searchAudienceResult: [],
  similarProfiles: [],
  searchAudienceInfo: {},

  selectedInfluencersOnResults: [],
  selectedInfluencersOnResultsUrls: [],
  selectedInfluencersOnList: [],
  selectedInfluencersOnListUrls: [],

  influencersInList: [],
  influencersInListInfo: {},

  influencersList: [],
  influencersListLoading: false,
  velocitySummary: {},
  audienceLoading: false,

  creatorsAddedFromSocials: [],
  awaitingUsers: [],
  pendingUsers: null,

  listSocialProvider: "instagram",

  brandSafetySettings: {},
  recentSearchAudienceQuery: "{}",

  trialExceededCDTModalVisible: false,
  hasAccessToInfluencerInsightsTool: false,
  hasAccessToInfluencerInsightsToolVelocityIndex: false,
  hasAccessToInfluencerInsightsAudienceOverlap: false,
  hasAccessToInfluencerInsightsBrandSafety: false,
};

function audiencesManagerReducer(state = initialState, action) {
  switch (action.type) {
    case GET_SETTINGS:
      return { ...state };
    case GET_SETTINGS_SUCCESS:
      const settings = action.payload.data.content;
      return {
        ...state,
        audienceCountries:
          settings.enabledProvidersAndCountries.audienceCountries,
        influencerCountries:
          settings.enabledProvidersAndCountries.influencerCountries,
        influencerProviders:
          settings.enabledProvidersAndCountries.influencerProviders,
        influencerCountriesByProvider:
          settings.enabledProvidersAndCountries.influencerCountriesByProvider,
        interests: settings.audienceInterests,
        influencerIgCategories: settings.influencerIgCategories,
        influencerInterests: settings.influencerInterests,
        audienceBrandAffinity: settings.brandAffinity,
        keywords: settings.aiTags,
        hasAccessToInfluencerInsightsTool: settings.isEnabled,
        hasAccessToInfluencerInsightsAudienceOverlap:
          settings.isAudienceOverlapEnabled,
        hasAccessToInfluencerInsightsBrandSafety: settings.isBrandSafetyEnabled,
        hasAccessToInfluencerInsightsToolVelocityIndex:
          settings.isVelocityEnabled,
        hasAccessToFullExports: settings.isFullExportsEnabled,
      };
    case GET_INTERESTS_SUCCESS:
      return {
        ...state,
        interests: action.payload.data.content,
      };

    case GET_INFLUENCER_INTERESTS_SUCCESS:
      return {
        ...state,
        influencerInterests: action.payload.data.content,
      };

    case GET_COUNTRIES_SUCCESS:
      const {
        audienceCountries,
        influencerCountries,
        influencerCountriesByProvider,
        influencerProviders,
      } = action.payload.data.content;

      return {
        ...state,
        audienceCountries,
        influencerCountries,
        influencerCountriesByProvider,
        influencerProviders,
      };

    case GET_AUDIENCE_BRAND_AFFINITY_SUCCESS:
      return {
        ...state,
        audienceBrandAffinity: action.payload.data.content.sort((str1, str2) =>
          str1.localeCompare(str2, undefined, { sensitivity: "accent" }),
        ),
      };

    case GET_KEYWORDS_SUCCESS:
      return {
        ...state,
        keywords: action.payload.data.content,
      };

    case SET_AUDIENCE_DATA: {
      const {
        influencerSearchResults,
        similarProfiles,
        pendingUsers,
        ...rest
      } = action.payload.data;

      return {
        ...state,
        pendingUsers,
        searchAudienceResult: influencerSearchResults,
        similarProfiles: similarProfiles || [],
        searchAudienceInfo: rest,
      };
    }

    case SET_AUDIENCE_LOADING:
      return {
        ...state,
        audienceLoading: action.payload.isLoading,
      };

    case POST_SEARCH_AUDIENCE:
      return {
        ...state,
        audienceLoading: true,
        recentSearchAudienceQuery: action.payload.recentSearchAudienceQuery,
      };

    case POST_SEARCH_AUDIENCE_FAIL:
      if (
        action.error.response.data.status_code ===
        responseCodes["402_PAYMENT_REQUIRED"]
      ) {
        return {
          ...state,
          audienceLoading: false,
          searchAudienceResult: [],
          similarProfiles: [],
          trialExceededCDTModalVisible: true,
        };
      }

      return {
        ...state,
      };

    case POST_SEARCH_AUDIENCE_SUCCESS:
      const {
        influencerSearchResults,
        similarProfiles,
        pendingUsers,
        ...rest
      } = action.payload.data.content;
      return {
        ...state,
        pendingUsers,
        searchAudienceResult: influencerSearchResults,
        similarProfiles: similarProfiles || [],
        searchAudienceInfo: rest,
        audienceLoading: false,
      };

    case POST_LOAD_MORE_SEARCH_RESULTS_SUCCESS:
      return {
        ...state,
        pendingUsers: action.payload.data.content.pendingUsers,
        searchAudienceResult: [
          ...state.searchAudienceResult,
          ...action.payload.data.content.influencerSearchResults,
        ],
      };

    case POST_REFRESH_AUDIENCE:
      return state;

    case POST_REFRESH_AUDIENCE_SUCCESS:
      if (action.payload.data.content.pendingUsers !== state.pendingUsers) {
        const timeStampedAudienceResult =
          action.payload.data.content.influencerSearchResults.length > 0
            ? [
                {
                  ...action.payload.data.content.influencerSearchResults[0],
                  profilePictureUrl:
                    action.payload.data.content.influencerSearchResults[0]
                      .profilePictureUrl &&
                    `${
                      action.payload.data.content.influencerSearchResults[0]
                        .profilePictureUrl
                    }&timestamp=${new Date().getTime()}`,
                },
              ]
            : [];

        return {
          ...state,
          pendingUsers:
            action.payload.data.content.pendingUsers === null
              ? state.pendingUsers
              : action.payload.data.content.pendingUsers,
          searchAudienceResult: timeStampedAudienceResult,
          searchAudienceInfo: {
            totalAvgErPercent: action.payload.data.content.totalAvgErPercent,
            totalAvgVelocityIndex:
              action.payload.data.content.totalAvgVelocityIndex,
            totalPotentialReach:
              action.payload.data.content.totalPotentialReach,
            totalResults: action.payload.data.content.totalResults,
          },
          audienceLoading: false,
        };
      }
      return state;

    case CHANGE_SEARCH_AUDIENCE_FILTERS:
      const { searchData } = action.payload;
      return {
        ...state,
        searchAudienceFilters: searchData,
      };

    case SELECT_INFLUENCER:
      const { userId, profileUrl, type } = action.payload;
      const {
        selectedInfluencersOnResults,
        selectedInfluencersOnResultsUrls,
        selectedInfluencersOnList,
        selectedInfluencersOnListUrls,
      } = state;

      const selectedInfluencers =
        type === "results"
          ? selectedInfluencersOnResults
          : selectedInfluencersOnList;

      const selectedInfluencersUrls =
        type === "results"
          ? selectedInfluencersOnResultsUrls
          : selectedInfluencersOnListUrls;

      let newSelectedInfluencers = [];
      if (selectedInfluencers.indexOf(userId) > -1) {
        newSelectedInfluencers = selectedInfluencers.filter(
          (itemId) => itemId !== userId,
        );
      } else {
        newSelectedInfluencers = [...selectedInfluencers, userId];
      }

      let newSelectedInfluencersUrls = [];
      if (selectedInfluencersUrls.indexOf(profileUrl) > -1) {
        newSelectedInfluencersUrls = selectedInfluencersUrls.filter(
          (itemUrl) => itemUrl !== profileUrl,
        );
      } else {
        newSelectedInfluencersUrls = [...selectedInfluencersUrls, profileUrl];
      }

      return {
        ...state,
        selectedInfluencersOnResults:
          type === "results"
            ? newSelectedInfluencers
            : selectedInfluencersOnResults,
        selectedInfluencersOnList:
          type === "selected"
            ? newSelectedInfluencers
            : selectedInfluencersOnList,

        selectedInfluencersOnResultsUrls:
          type === "results"
            ? newSelectedInfluencersUrls
            : selectedInfluencersOnResultsUrls,
        selectedInfluencersOnListUrls:
          type === "selected"
            ? newSelectedInfluencersUrls
            : selectedInfluencersOnList,
      };

    case SELECT_MULTIPLE_INFLUENCER:
      const {
        userIds: multipleUserIds,
        profileUrls: multipleUserUrls,
        type: multipleListType,
      } = action.payload;
      const {
        selectedInfluencersOnResults: selectedOnResults,
        selectedInfluencersOnList: selectedOnList,
        selectedInfluencersOnListUrls: selectedOnListUrls,
        selectedInfluencersOnResultsUrls: selectedOnResultsUrls,
      } = state;

      // new Set is used to remove duplicates if any
      return {
        ...state,
        selectedInfluencersOnResults:
          multipleListType === "results"
            ? [...new Set([...selectedOnResults, ...multipleUserIds])]
            : selectedOnResults,
        selectedInfluencersOnList:
          multipleListType === "selected"
            ? [...new Set([...selectedOnList, ...multipleUserIds])]
            : selectedOnList,
        selectedInfluencersOnResultsUrls:
          multipleListType === "results"
            ? [...new Set([...selectedOnResultsUrls, ...multipleUserUrls])]
            : selectedOnResultsUrls,
        selectedInfluencersOnListUrls:
          multipleListType === "selected"
            ? [...new Set([...selectedOnListUrls, ...multipleUserUrls])]
            : selectedOnListUrls,
      };

    case GET_INFLUENCERS_LIST_DATA:
      return {
        ...state,
        audienceLoading: true,
      };

    case SET_IS_INCORRECT_PROVIDER_ERROR:
      return {
        ...state,
        isIncorrectProviderError: action.payload,
      };

    case GET_INFLUENCERS_LIST_DATA_SUCCESS:
      const {
        listContent,
        listName,
        searchFilters,
        userIds,
        listId,
        awaitingUsers,
        listSocialProvider,
        brandSafetySettings,
        listUserUuids,
      } = action.payload.data.content;
      return {
        ...state,
        searchAudienceFilters: {
          ...METHODS.parseJsonToAudienceSearch(
            searchFilters,
            state.audienceCountries,
            state.influencerCountries,
          ),
        },
        influencersInList: [...listContent.influencerSearchResults],
        influencersInListUuids: listUserUuids,
        influencersInListInfo: {
          totalAvgErPercent: listContent.totalAvgErPercent,
          totalAvgVelocityIndex: listContent.totalAvgVelocityIndex,
          totalPotentialReach: listContent.totalPotentialReach,
          totalResults: listContent.totalResults,
          listName,
          userIds,
          listId,
        },
        awaitingUsers,
        listSocialProvider,
        brandSafetySettings,
        audienceLoading: false,
      };
    case GET_INFLUENCERS_LIST_DATA_FAIL:
      return {
        ...state,
      };

    case POST_LOAD_MORE_SELECTED_INFLUENCERS_SUCCESS:
      return {
        ...state,
        influencersInList: [
          ...state.influencersInList,
          ...action.payload.data.content.listContent.influencerSearchResults,
        ],
      };

    case GET_INFLUENCERS_LIST:
      return {
        ...state,
        influencersListLoading: true,
      };

    case GET_INFLUENCERS_LIST_SUCCESS:
      return {
        ...state,
        influencersList: action.payload.data.content.lists,
        canFilterAuthors: action.payload.data.content.canFilterAuthors,
        influencersListLoading: false,
      };

    case CREATE_INFLUENCERS_LIST_SUCCESS:
      return {
        ...state,
        influencersInList: [...state.selectedInfluencersOnResults],
      };

    case CREATE_INFLUENCERS_LIST_FAIL:
      if (
        action.error.response.data.status_code ===
        responseCodes["402_PAYMENT_REQUIRED"]
      ) {
        return { ...state, trialExceededCDTModalVisible: true };
      }

      return {
        ...state,
      };

    case DELETE_INFLUENCERS_FROM_LIST_SUCCESS:
      return {
        ...state,
        selectedInfluencersOnList: [],
        selectedInfluencersOnListUrls: [],
      };

    case ADD_TO_AUDIENCES_SUCCESS: {
      return {
        ...state,
        selectedInfluencersOnResults: [],
        selectedInfluencersOnResultsUrls: [],
      };
    }

    case CLEAR_RESULTS:
      return {
        ...state,
        searchAudienceResult: [],
        similarProfiles: [],
        searchAudienceInfo: {},
        selectedInfluencersOnResults: [],
        selectedInfluencersOnResultsUrls: [],
        selectedInfluencersOnList: [],
        selectedInfluencersOnListUrls: [],
        influencersInList: [],
        influencersInListInfo: {},
      };

    case EDIT_NAME_SUCCESS:
      return {
        ...state,
        influencersInListInfo: {
          ...state.influencersInListInfo,
          listName: action.meta.previousAction.newName,
        },
      };

    case CLEAR_SELECTED_USERS:
      const {
        type: listType,
        profileUrls: profileUrlsToUnselect,
        userIds: userIdsToUnselect,
      } = action.payload;

      return {
        ...state,
        selectedInfluencersOnResults:
          listType === "results"
            ? state.selectedInfluencersOnResults.filter(
                (item) => !userIdsToUnselect.includes(item),
              )
            : state.selectedInfluencersOnResults,
        selectedInfluencersOnList:
          listType === "selected"
            ? state.selectedInfluencersOnList.filter(
                (item) => !userIdsToUnselect.includes(item),
              )
            : state.selectedInfluencersOnList,
        selectedInfluencersOnResultsUrls:
          listType === "results"
            ? state.selectedInfluencersOnResultsUrls.filter(
                (item) => !profileUrlsToUnselect.includes(item),
              )
            : state.selectedInfluencersOnResultsUrls,
        selectedInfluencersOnListUrls:
          listType === "selected"
            ? state.selectedInfluencersOnListUrls.filter(
                (item) => !profileUrlsToUnselect.includes(item),
              )
            : state.selectedInfluencersOnListUrls,
      };

    case CLEAR_ALL_SELECTED_USERS:
      const { type: clearListType } = action.payload;

      return {
        ...state,
        selectedInfluencersOnResults:
          clearListType === "results" ? [] : state.selectedInfluencersOnResults,
        selectedInfluencersOnList:
          clearListType === "selected" ? [] : state.selectedInfluencersOnList,
        selectedInfluencersOnResultsUrls:
          clearListType === "results"
            ? []
            : state.selectedInfluencersOnResultsUrls,
        selectedInfluencersOnListUrls:
          clearListType === "selected"
            ? []
            : state.selectedInfluencersOnListUrls,
      };

    case CLEAR_ALL_SELECTED_USERS_BOTH_TYPES:
      return {
        ...state,
        selectedInfluencersOnResults: [],
        selectedInfluencersOnList: [],
        selectedInfluencersOnResultsUrls: [],
        selectedInfluencersOnListUrls: [],
      };

    case GET_VELOCITY_SUMMARY_DATA_SUCCESS:
      return {
        ...state,
        velocitySummary: { ...action.payload.data.content },
      };

    case CLEAR_VELOCITY_SUMMARY_DATA:
      return {
        ...state,
        velocitySummary: {},
      };

    case SET_COUNTRY_DISABLED:
      switch (action.payload.type) {
        case "influencer":
          const countriesCopyInflu = [...state.influencerCountries];
          countriesCopyInflu.forEach((country) => {
            if (country.value === action.payload.country.value) {
              country.disabled = action.payload.disabled;
            }
          });
          return {
            ...state,
            influencerCountries: countriesCopyInflu,
          };

        case "audience":
          const countriesCopyAudience = {
            ...state.audienceCountries,
            [state.listSocialProvider]:
              state.audienceCountries[state.listSocialProvider]?.map(
                (country) =>
                  country.value === action.payload.country.value
                    ? { ...country, disabled: action.payload.disabled }
                    : country,
              ) || [],
          };
          return {
            ...state,
            audienceCountries: countriesCopyAudience,
          };
      }
    case CLEAR_DISABLED_COUNTRIES:
      return {
        ...state,
        audienceCountries:
          Object.keys(state.audienceCountries).forEach((socialProvider) => {
            state.audienceCountries[socialProvider].forEach(
              (country) => (country.disabled = "no"),
            );
          }) || state.audienceCountries,
        influencerCountries: state.influencerCountries.map((country) => {
          return {
            ...country,
            disabled: "no",
          };
        }),
      };

    case ADD_CREATORS_TO_LIST_FROM_SOCIALS_SUCCESS:
      return {
        ...state,
        creatorsAddedFromSocials: action.payload.data.content.awaitingUsers,
      };

    case SET_LIST_SOCIAL_PROVIDER:
      return {
        ...state,
        listSocialProvider: action.payload.listSocialProvider,
      };

    case CLEAR_INFLUENCERS_LIST_INFO:
      return {
        ...state,
        influencersInList: null,
        influencersInListInfo: null,
      };

    case CLOSE_TRIAL_EXCEEDED_CDT_MODAL:
      return {
        ...state,
        trialExceededCDTModalVisible: false,
      };

    case DELETE_AWAITING_USER:
      return {
        ...state,
        awaitingUsers: state.awaitingUsers?.filter(
          (item) => item.id !== action.payload.awaitingUserId,
        ),
      };

    default:
      return state;
  }
}

export default audiencesManagerReducer;
