import React from "react";

import { ReactComponent as BinocularIcon } from "../images/statistics/binocular.svg";
import { ReactComponent as BookmarksIcon } from "../images/statistics/bookmarks.svg";
import { ReactComponent as CommentsIcon } from "../images/statistics/comments.svg";
import { ReactComponent as ContentIcon } from "../images/statistics/content.svg";
import { ReactComponent as EngagementsIcon } from "../images/statistics/engagements.svg";
import { ReactComponent as FavouritesInfluencersIcon } from "../images/statistics/favourites-influencers.svg";
import { ReactComponent as FollowersIcon } from "../images/statistics/followers.svg";
import { ReactComponent as HeartIcon } from "../images/statistics/heart.svg";
import { ReactComponent as ImpressionsToFollowersIcon } from "../images/statistics/impressions-to-followers.svg";
import { ReactComponent as LikesIcon } from "../images/statistics/likes.svg";
import { ReactComponent as ReachToFollowersIcon } from "../images/statistics/reach-to-followers.svg";
import { ReactComponent as ReachIcon } from "../images/statistics/reach.svg";
import { ReactComponent as RetweetsIcon } from "../images/statistics/retweets.svg";
import { ReactComponent as SharesIcon } from "../images/statistics/shares.svg";
import { ReactComponent as StickerTapsIcon } from "../images/statistics/sticker-taps.svg";
import { ReactComponent as SwipeUpIcon } from "../images/statistics/swipe-up.svg";
import { ReactComponent as ImpressionsIcon } from "../images/statistics/impressions.svg";
import { ReactComponent as VrIcon } from "../images/statistics/vr.svg";
import { ReactComponent as WatchedFullVideoIcon } from "../images/statistics/watched-full-video.svg";
import { ReactComponent as EerIcon } from "../images/statistics/eer.svg";

const getStatisticHeader = (statistic) => {
  switch (statistic) {
    case "realReach":
      return "real reach";
    case "effectiveEr":
      return "effective er";
    case "piecesOfContent":
      return "pieces of content";
    case "watchedFullVideo":
      return "watched full video";
    case "creatorsEngaged":
      return "creators engaged";
    case "linkClicks":
      return "link clicks";
    case "stickerTaps":
      return "sticker taps";
    case "impressionsToFollowers":
      return "\nimpressions to followers";
    case "reachToFollowers":
      return "\nreach to followers";
    case "videoViews":
      return "video views";
    case "fullVideoViews":
      return "full video views";
    case "minutesWatched":
      return "\n estimated minutes watched";
    case "avgViewPercentage":
      return "\n avg percentage viewed";
    case "sentimentPositiveShare":
      return "positive sentiment\n percentage";
    case "sentimentNeutralShare":
      return "neutral sentiment\n percentage";
    case "sentimentNegativeShare":
      return "negative sentiment\n percentage";
    case "sentimentProductRelatedShare":
      return "product related\n comments percentage";
    case "realReachToFollowers":
      return "real reach to followers";

    default:
      return statistic;
  }
};

const getTooltipMetricName = (statistic) => {
  switch (statistic) {
    case "vr":
      return "views";
    case "impressionsToFollowers":
      return "impressions";
    case "reachToFollowers":
      return "real reach";
    default:
      return "[name]";
  }
};

export { getStatisticHeader, getTooltipMetricName };
