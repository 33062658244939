import React, { Dispatch, ReactNode, SetStateAction } from "react";
import axios from "axios";
import { WrappedComponentProps, injectIntl } from "react-intl";
import { useSelector } from "react-redux";
import classNames from "classnames";

import AnimatedDiv from "src/app/components/AnimatedDiv/AnimatedDiv";
import CustomInput, {
  CustomInputLabel,
} from "src/app/components/CustomInput/CustomInput";
import CustomSelect from "src/app/components/CustomSelect/CustomSelect";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ModalColumn } from "src/app/components/Modal/Modal";
import { tableDataType } from "src/app/components/Table/Table";
import { getTaskTypeOptions } from "src/app/methods/getTaskTypeOptions";
import { translateMessage } from "src/app/methods/translateMessage";
import { TaskType } from "src/types";
import { API_URLS } from "src/utils/API_URLS";
import { RootState } from "src/redux/reducers";
import { MetaFieldType } from "src/app/methods/getMetaFieldTypeOptions";
import { showErrorToast } from "src/utils/methods";
import SocialMetrics, { SelectOption } from "../../SocialMetrics";
import { getTaskMetaFieldType } from "../../functions/taskFieldsFunctions";
import {
  FormulaTab,
  IAccessLinkData,
  MFState,
  MFStateEntries,
  MetaFieldTypeOption,
} from "../../types";
import SalesMetrics from "../SalesMetrics/SalesMetrics";
import MemberTeams from "../MemberTeams/MemberTeams";
import DictionarySubFields from "../DictionarySubFields/DictionarySubFields";
import FormulaOverview from "../FormulaOverview/FormulaOverview";
import { FormulaSwitch } from "../FormulaSwitch/FormulaSwitch";
import RelatedField from "../RelatedField/RelatedField";
import Summary from "../Summary/Summary";
import { UseInCreatorDatabase } from "../UseInCreatorDatabase/UseInCreatorDatabase";
import Currencies from "../Currencies/Currencies";
import { AccessLinkField } from "../AccessLinkField/AccessLinkField";
import EarnedMediaValue from "../EarnedMediaValue/EarnedMediaValue";

interface CustomFieldsProps extends WrappedComponentProps<"intl"> {
  inputLabelText?: ReactNode;
  dataType: tableDataType;
  handleConfirm: (e: React.FormEvent, then?: any) => void;
  data?: any;
  fieldName: string;
  setState: (type: MFStateEntries, value: any) => void;
  state: MFState;
  metaFieldTypeOptions: MetaFieldTypeOption[];
  hidden?: boolean;
  onClose: () => void;
  isNewOverviewForClient?: boolean;
  accessLinkData: IAccessLinkData;
  setAccessLinkData: Dispatch<SetStateAction<IAccessLinkData>>;
  defaultOption?: "accessLink";
  projectId: string;
  taskType: TaskType;
  globalFieldMode?: boolean;
  editingGlobalField?: boolean;
}

function CustomFields({
  intl,
  inputLabelText,
  dataType,
  handleConfirm,
  data,
  fieldName,
  setState,
  state,
  metaFieldTypeOptions,
  hidden,
  onClose,
  isNewOverviewForClient,
  accessLinkData,
  setAccessLinkData,
  defaultOption,
  projectId,
  taskType,
  globalFieldMode,
  editingGlobalField,
}: CustomFieldsProps) {
  const isGlobalMetaField =
    data?.wsGlobalProjectMetaFieldUuid || data?.wsGlobalTaskMetaFieldUuid;
  const isGlobalMode = globalFieldMode || editingGlobalField;
  const isEdited = !!data;

  const {
    dictionaryReducer: {
      dictionaryAutocompleteLoading,
      dictionaryAutoCompletesColumns,
    },
    mainReducer: { identity, activeWorkspaceUuid },
    projectReducer: { errorMessages: projectErrorMessages },
    taskReducer: { errorMessages: taskErrorMessages },
  } = useSelector((reduxState: RootState) => reduxState);

  const getCountConditions = async (selectedTaskType: TaskType | null) => {
    const url = isGlobalMode
      ? API_URLS.getGlobalProjectCountConditions
          .replace(":wsWorkspaceUuid:", activeWorkspaceUuid)
          .replace(":taskType:", selectedTaskType ?? "null")
      : API_URLS.getProjectCountConditions
          .replace(":metricsType:", "TableFormula")
          .replace(":projectUuid:", projectId)
          .replace(":taskType:", taskType)
          .replace(
            ":shared:",
            String((isNewOverviewForClient || data?.shared) ?? false),
          );

    try {
      const { data: response } = await axios.get(url);

      setState(
        "conditionColumns",
        response.content.map(
          (item: {
            conditionName: string;
            existingValues: string[];
            taskMetaFieldUuid: string;
          }) => ({
            label: item.conditionName,
            value: {
              value: item.taskMetaFieldUuid,
              options: item.existingValues.map((option: string) => ({
                label: option,
                value: option,
              })),
            },
          }),
        ),
      );
    } catch (error) {
      console.error(error);
      showErrorToast();
    }
  };

  const getAvailableTargets = async (selectedTaskType: TaskType | null) => {
    const url = isGlobalMode
      ? API_URLS.getGlobalProjectAvailableTargets
          .replace(":wsWorkspaceUuid:", activeWorkspaceUuid)
          .replace(":taskType:", selectedTaskType ?? "null")
      : API_URLS.getProjectAvailableTargets
          .replace(":metricsType:", "TableFormula")
          .replace(":projectUuid:", projectId)
          .replace(
            ":shared:",
            String((isNewOverviewForClient || data?.shared) ?? false),
          );

    try {
      const { data: response } = await axios.get(url);

      setState(
        "availableTargets",
        response.content.map((item: any) => {
          let fieldUuidAndTaskType;

          if (isGlobalMode) {
            fieldUuidAndTaskType = {
              projectMetaFieldUuid: item.wsGlobalProjectMetaFieldUuid,
              projectMetaFieldTaskType: item.wsGlobalProjectMetaFieldTaskType,
            };
          } else {
            fieldUuidAndTaskType = {
              projectMetaFieldUuid: item.projectMetaFieldUuid,
              projectMetaFieldTaskType: item.projectMetaFieldTaskType,
            };
          }

          return {
            label: item.targetName,
            value: item.targetName,
            targetValue: item.targetValue,
            ...fieldUuidAndTaskType,
          };
        }),
      );
    } catch (error) {
      console.error(error);
      showErrorToast();
    }
  };

  return (
    <AnimatedDiv>
      <form onSubmit={handleConfirm}>
        <div className="row-wrapper">
          <ModalColumn>
            <CustomInputLabel htmlFor="fieldTitle">
              {data ? (
                <IDHFormattedMessage
                  id="ws_field_title"
                  defaultMessage="Field title"
                />
              ) : (
                inputLabelText
              )}
            </CustomInputLabel>
            <CustomInput
              id="fieldTitle"
              type="text"
              value={fieldName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setState("nameEdited", true);
                setState("fieldName", e.target.value);
              }}
              placeholder={translateMessage({
                intl,
                id: "ws_manage_field_modal_title_placeholder",
                defaultMessage: "e.g., Total Reach, Budget, Timing",
              })}
              disabled={isGlobalMetaField && !data?.description}
            />
          </ModalColumn>

          <ModalColumn>
            <CustomInputLabel htmlFor="fieldType">
              <IDHFormattedMessage id="ws_type" defaultMessage="Type" />
            </CustomInputLabel>
            <CustomSelect
              id="fieldType"
              value={state.selectedOption}
              options={metaFieldTypeOptions}
              placeholder={`${translateMessage({
                intl,
                id: "ws_select",
                defaultMessage: "Select",
              })}...`}
              onChange={(newValue: SelectOption) =>
                setState("selectedOption", newValue)
              }
              disabled={data?.type}
              required
            />
          </ModalColumn>
        </div>

        {(globalFieldMode || data?.description) && (
          <div className="row-wrapper row-wrapper--no-border">
            <div
              className={classNames("description-wrapper", {
                "description-wrapper--full-width":
                  !globalFieldMode || dataType !== tableDataType.Task,
              })}
            >
              <CustomInputLabel htmlFor="description">
                <IDHFormattedMessage
                  id="ws_description"
                  defaultMessage="Description"
                />
              </CustomInputLabel>
              <CustomInput
                id="description"
                type="text"
                value={state.description}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setState("description", e.target.value);
                }}
                placeholder={translateMessage({
                  intl,
                  id: "ws_description_placeholder",
                  defaultMessage: "Describe fields function",
                })}
              />
            </div>
            {globalFieldMode && (
              <div className="task-type-wrapper">
                <CustomInputLabel>
                  <IDHFormattedMessage
                    id="ws_applicable_for"
                    defaultMessage="Applicable for"
                  />
                </CustomInputLabel>
                <CustomSelect
                  options={getTaskTypeOptions()}
                  value={state.selectedTaskType}
                  onChange={(newValue: SelectOption) =>
                    setState("selectedTaskType", newValue)
                  }
                />
              </div>
            )}
          </div>
        )}

        {state.selectedOption.value === "metrics" && (
          <SocialMetrics
            state={state}
            setState={setState}
            data={data}
            taskType={taskType ?? data?.taskType}
          />
        )}

        {((data?.description && data.taskType === "creator") ||
          state.selectedTaskType.value === "creator") &&
          dataType !== tableDataType.Project && (
            <UseInCreatorDatabase state={state} setState={setState} />
          )}

        {state.selectedOption.value === "member" && (
          <MemberTeams
            dataType={dataType}
            state={state}
            setState={setState}
            disabled={data?.description}
          />
        )}

        {state.selectedOption.value === "salesMetrics" && (
          <SalesMetrics state={state} setState={setState} isEdited={isEdited} />
        )}

        {state.selectedOption.value === "currency" && (
          <Currencies
            dataType={dataType}
            state={state}
            setState={setState}
            disabled={data?.description}
            isEdited={isEdited}
          />
        )}

        {state.selectedOption.value === MetaFieldType.EMV && (
          <EarnedMediaValue
            state={state}
            setState={setState}
            isEdited={isEdited}
          />
        )}

        {state.selectedOption.value === "formula" &&
          dataType !== tableDataType.Task && (
            <FormulaSwitch
              data={data}
              formulaTab={state.formulaTab}
              setFormulaTab={(newValue: FormulaTab) =>
                setState("formulaTab", newValue)
              }
            />
          )}

        {state.selectedOption.value === "formula" &&
          state.formulaTab === "table" &&
          dataType !== tableDataType.Task && (
            <Summary
              getCountConditions={getCountConditions}
              getAvailableTargets={getAvailableTargets}
              state={state}
              setState={setState}
              globalFieldMode={globalFieldMode}
            />
          )}

        {state.selectedOption.value === "formula" &&
          (state.formulaTab === "overview" ||
            dataType === tableDataType.Task) && (
            <FormulaOverview
              data={data}
              state={state}
              setState={setState}
              dataType={dataType}
              isNewOverviewForClient={isNewOverviewForClient}
              isGlobalMode={isGlobalMode}
              hasErrors={
                taskErrorMessages.length > 0 || projectErrorMessages.length > 0
              }
            />
          )}

        {state.selectedOption.value === "accessLink" && (
          <AccessLinkField
            accessLinkData={accessLinkData}
            setAccessLinkData={setAccessLinkData}
            noManageModal={defaultOption === "accessLink" && hidden}
            handleConfirm={handleConfirm}
            onManageClose={onClose}
            dataType={dataType}
          />
        )}

        {state.selectedOption.value === "relatedField" && (
          <RelatedField
            state={state}
            setState={setState}
            projectId={projectId}
            source={data?.metaFieldSource}
            disabled={isEdited}
            isGlobalMode={isGlobalMode}
            currentRelatedMetaFieldAggregationType={
              data?.metaFieldOptions?.relatedFieldAggregationType
            }
          />
        )}

        {getTaskMetaFieldType(
          state.selectedOption.value,
          {},
          identity?.workspaceDictionaryList,
        ) === "dictionaryElement" && (
          <DictionarySubFields
            dataType={dataType}
            state={state}
            setState={setState}
            disabled={data?.description}
            dictionaryUuid={state.selectedOption.value.split("_")[1] ?? null}
            isLoading={dictionaryAutocompleteLoading}
            dictionaryAutoCompletesColumns={dictionaryAutoCompletesColumns}
          />
        )}
      </form>
    </AnimatedDiv>
  );
}

export default injectIntl(CustomFields);
