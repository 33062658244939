import React, { ReactNode, useEffect } from "react";
import classNames from "classnames";
import ReactDOM from "react-dom";

import { overlaysRef } from "src/App";
import { Button, ButtonVariantDefault, ButtonVariants } from "../Button/Button";
import Loader from "../Loader/Loader";
import { ReactComponent as ArrowLeftIcon } from "../../../images/arrow-left.svg";
import IDHFormattedMessage from "../IDHFormattedMessage/IDHFormattedMessage";

import "./Modal.scss";

type ModalVariants = "small" | "medium";
type PositionActionButtons = "left" | "right" | "space-between";

export const INPUT_TYPES_TO_FOCUS = [
  "text",
  "number",
  "email",
  "search",
  "password",
];

interface ModalContentProps {
  onClose: any;
  onClick?: any;
  disableTransition?: boolean;
  children?: React.ReactNode;
  variant?: ModalVariants;
  className: string;
  disableClickOutside?: boolean;
  props?: any;
  displayCancelButton?: boolean;
  onCancelClick?: any;
  cancelButtonIcon?: React.SVGProps<SVGSVGElement>;
  closeButtonText?: React.ReactNode | string;
  closeButtonVariant?: ButtonVariants;
  onConfirmClick?: any;
  confirmButtonLoading?: boolean;
  confirmButtonDisabled?: boolean;
  confirmButtonText?: React.ReactNode | string;
  confirmButtonVariant?: ButtonVariants;
  buttonCentered?: boolean;
  buttonsHidden?: boolean;
  customButton?: React.ReactNode;
  imgSrc?: string;
  title?: any;
  description?: ReactNode;
  isLoading?: boolean;
  positionActionButtons?: PositionActionButtons;
}

interface ModalProps extends ModalContentProps {
  overlayClassName?: string;
}

class Modal extends React.Component<ModalProps> {
  el = document.createElement("div");

  componentDidMount() {
    const overlayClassNames = [
      "modal-overlay",
      ...(!this.props.disableTransition ? ["modal-overlay--hidden"] : []),
    ];

    this.el.classList.add(...overlayClassNames);
    if (this.props.overlayClassName) {
      this.el.classList.add(this.props.overlayClassName);
    }
    overlaysRef.current.appendChild(this.el);
    document.body.classList.add("block-ui");
    setTimeout(() => {
      this.el.classList.remove("modal-overlay--hidden");
    }, 10);
  }

  componentWillUnmount() {
    overlaysRef.current.removeChild(this.el);
    document.body.classList.remove("block-ui");
  }

  render() {
    const { overlayClassName, ...otherProps } = this.props;
    return ReactDOM.createPortal(<ModalContent {...otherProps} />, this.el);
  }
}

const ModalContent: React.FC<ModalContentProps> = ({
  onClose,
  onClick,
  disableTransition,
  children,
  variant,
  className,
  disableClickOutside,
  displayCancelButton,
  closeButtonText,
  closeButtonVariant,
  onCancelClick,
  cancelButtonIcon,
  onConfirmClick,
  confirmButtonLoading,
  confirmButtonDisabled,
  confirmButtonText,
  confirmButtonVariant,
  buttonCentered,
  buttonsHidden,
  customButton,
  imgSrc,
  title,
  isLoading,
  description,
  positionActionButtons = "space-between",
  ...props
}) => {
  useEffect(() => {
    const closeOnEscape = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        const modals = document.querySelectorAll(".modal-overlay .modal");
        const upperModal = modals[modals.length - 1];
        if (modals.length === 1 || upperModal.className.includes(className)) {
          onClose();
        }
      }
    };

    const focusOnFirstInput = () => {
      const input: HTMLInputElement | null = document.querySelector(
        `.${className} .modal__body input`,
      );

      if (input && INPUT_TYPES_TO_FOCUS.includes(input.type)) {
        setTimeout(() => {
          input.focus();
        }, 300);
      }
    };

    focusOnFirstInput();
    if (!onClose) return;
    window.addEventListener("keyup", closeOnEscape);
    return () => window.removeEventListener("keyup", closeOnEscape);
  }, []);

  return (
    <div
      onClick={onClick}
      className={classNames(
        "modal",
        {
          "modal--small": variant === "small",
          "modal--medium": variant === "medium",
          "modal--with-img": imgSrc,
          "modal--buttons-left": positionActionButtons === "left",
          "modal--buttons-right": positionActionButtons === "right",
          "modal--buttons-space-between":
            positionActionButtons === "space-between",
        },
        className,
      )}
      {...props}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {title && <ModalTitle>{title}</ModalTitle>}
          {description && <ModalText>{description}</ModalText>}
          {imgSrc && <img src={imgSrc} className="modal__img" />}
          <div
            className={classNames("modal__body-wrapper", {
              "modal__content-with-img": imgSrc,
            })}
          >
            <div className="modal__body">{children}</div>
            {!buttonsHidden && (
              <div
                className={classNames("modal__buttons", {
                  "modal__buttons--centered": buttonCentered,
                })}
              >
                {displayCancelButton && (
                  <Button
                    type="button"
                    variant={closeButtonVariant || "white-with-border"}
                    size="large"
                    onClick={onCancelClick || onClose}
                    data-qa="cancel-button"
                  >
                    {cancelButtonIcon || null}
                    {typeof closeButtonText !== "undefined" ? (
                      closeButtonText
                    ) : (
                      <IDHFormattedMessage
                        id="ws_close"
                        defaultMessage="Close"
                      />
                    )}
                  </Button>
                )}
                {onConfirmClick &&
                  (confirmButtonLoading ? (
                    <Loader />
                  ) : (
                    <Button
                      variant={
                        typeof confirmButtonVariant === "undefined"
                          ? ButtonVariantDefault
                          : confirmButtonVariant
                      }
                      size="large"
                      type="submit"
                      onClick={onConfirmClick}
                      disabled={confirmButtonDisabled}
                      data-qa="confirm-button"
                    >
                      {typeof confirmButtonText !== "undefined" ? (
                        confirmButtonText
                      ) : (
                        <IDHFormattedMessage
                          id="ws_confirm"
                          defaultMessage="Confirm"
                        />
                      )}
                    </Button>
                  ))}
                {customButton}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Modal;

interface ModalTitleProps {
  children: React.ReactNode;
  icon?: React.SVGProps<SVGSVGElement>;
  backBtnFunc?: () => void;
}

export const ModalTitle: React.FC<ModalTitleProps> = ({
  children,
  icon,
  backBtnFunc,
}) => {
  return (
    <h4
      className={classNames("modal__title", {
        "modal__title--with-back-btn": backBtnFunc,
      })}
    >
      {icon && icon}
      {backBtnFunc && (
        <div className="modal__title-back-btn" onClick={backBtnFunc}>
          <ArrowLeftIcon className="modal__title-back-btn-icon" />
        </div>
      )}
      {children}
    </h4>
  );
};

interface ModalTextProps {
  children: React.ReactNode;
}

export const ModalText: React.FC<ModalTextProps> = ({ children }) => {
  return <p className="modal__text">{children}</p>;
};

export const hideModal = (
  hidingFunction: () => void,
  overlayClassName?: string,
  disableTransition?: boolean,
) => {
  const className = overlayClassName || "modal-overlay";

  document
    .querySelector(`.${className}`)
    ?.classList.add("modal-overlay--hidden");

  if (disableTransition) {
    hidingFunction();
  } else {
    setTimeout(() => {
      hidingFunction();
    }, 200);
  }
};

interface ModalRowOrColumnProps {
  children: React.ReactNode;
  className?: string;
}

export const ModalRow: React.FC<ModalRowOrColumnProps> = ({
  children,
  className,
}) => {
  return <div className={classNames("modal__row", className)}>{children}</div>;
};

export const ModalColumn: React.FC<ModalRowOrColumnProps> = ({
  children,
  className,
  ...props
}) => {
  return (
    <div className={classNames("modal__column", className)} {...props}>
      {children}
    </div>
  );
};
