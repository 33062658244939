import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useReducer,
  useState,
} from "react";
import "./AddCreatorsModal.scss";
import { useDispatch, useSelector } from "react-redux";
import { uuidv7 as uuid } from "uuidv7";
import axios from "axios";
import { IntlShape, WrappedComponentProps, injectIntl } from "react-intl";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { translateMessage } from "src/app/methods/translateMessage";
import {
  createGlobalTasks,
  getGlobalTasks,
} from "src/redux/creator-database/creatorDatabaseActions";
import { GlobalTaskSocialProfile } from "src/redux/creator-database/types";
import classNames from "classnames";
import { RootState } from "src/redux/reducers";
import AutosuggestedCreatorsInput from "./components/AutosuggestedCreatorsInput";
import { showToast } from "../../methods/showToast";
import { API_URLS } from "../../../utils/API_URLS";
import { generateRankString } from "../../../utils/rankStrings";
import {
  getTasksCounters,
  getTasksList,
  openTrialExceededModal,
} from "../../../redux";
import Modal, {
  hideModal,
  ModalRow,
  ModalText,
  ModalTitle,
} from "../../components/Modal/Modal";
import { SocialProfile } from "../AddSocialProfilesModal/AddSocialProfilesModal";
import creatorDatabaseFiltersReducer, {
  creatorDatabaseFiltersReducerInitialState,
} from "../../CreatorDatabase/redux/creatorDatabaseFiltersReducer";

interface Props extends WrappedComponentProps<"intl"> {
  onClose?: any;
  projectId?: string;
  lastTaskRank?: string;
  globalTaskMode?: boolean;
  setElementToScrollUuid?: Dispatch<SetStateAction<string | null>>;
  intl: IntlShape;
}

function AddTaskModal(props: Props) {
  const {
    onClose,
    projectId,
    lastTaskRank,
    globalTaskMode,
    setElementToScrollUuid,
    intl,
  } = props;

  const [value, setValue] = useState<string>("");
  const [storedCreators, setStoredCreators] = useState([]);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const {
    creatorDatabaseReducer: { globalTaskColumns },
    projectReducer: { taskType },
    mainReducer: { activeWorkspaceUuid },
  } = useSelector((state: RootState) => state);

  const [creatorDatabaseFilters] = useReducer(
    creatorDatabaseFiltersReducer,
    creatorDatabaseFiltersReducerInitialState(globalTaskColumns),
  );
  const { filters, sortBy, sort } = creatorDatabaseFilters;

  const handleConfirm = (e: React.FormEvent) => {
    e.preventDefault();

    if (globalTaskMode) {
      createGlobalTask();
    } else {
      createTask();
    }
  };

  const createGlobalTask = async () => {
    const tasksDataFromSuggestions = storedCreators.map(
      (creator: any, index: number) => {
        const creatorId = uuid();

        if (index === 0 && setElementToScrollUuid) {
          setElementToScrollUuid(creatorId);
        }

        return {
          uuid: creatorId,
          name: creator.username,
          socialProfiles: creator.socialProfiles.map(
            (profile: GlobalTaskSocialProfile) => ({
              socialProvider: profile.socialProvider,
              socialUsername: profile.socialUsername,
              socialUrl: profile.socialUrl,
            }),
          ),
        };
      },
    );

    await dispatch(
      createGlobalTasks(
        activeWorkspaceUuid,
        "creator",
        tasksDataFromSuggestions,
      ),
    );
    dispatch(getGlobalTasks(activeWorkspaceUuid, { filters, sortBy, sort }));
    hideModal(onClose);
  };

  const createTask = () => {
    if (!projectId) {
      showToast("error", "Error", "Project ID not provided!");
      return;
    }

    setLoading(true);
    const creatorNames = value.split(/\s+/);
    let previousRank = lastTaskRank;

    const tasksDataFromSuggestions = storedCreators.map((creator: any) => {
      const newRank = generateRankString(previousRank, "");
      previousRank = newRank;

      if (creator.hasCreatorDatabaseProfile) {
        return {
          uuid: uuid(),
          name: creator.username,
          rank: newRank,
          socialProfiles: creator.socialProfiles.map(
            (profile: SocialProfile) => ({
              ...profile,
            }),
          ),
          wsGlobalTaskUuid: creator.wsGlobalTaskUuid,
        };
      }

      return {
        uuid: uuid(),
        name: creator.username,
        rank: newRank,
        socialProfiles: creator.socialProfiles.map(
          (profile: SocialProfile) => ({
            ...profile,
          }),
        ),
      };
    });

    const tasksDataFromInput = creatorNames
      .filter((name: string) => name.length)
      .map((name: string) => {
        const newRank = generateRankString(previousRank, "");
        previousRank = newRank;
        return {
          uuid: uuid(),
          name: name.trim(),
          rank: newRank,
          socialProfiles: [],
        };
      });

    const tasksData = [...tasksDataFromSuggestions, ...tasksDataFromInput];

    axios
      .post(API_URLS.createMultipleTasks, {
        wsTaskWorkspaceUuid: activeWorkspaceUuid,
        projectId,
        taskType: "creator",
        tasksData,
      })
      .then(() => {
        dispatch(getTasksCounters(projectId));
        dispatch(getTasksList(projectId, taskType));
        hideModal(onClose);
      })
      .catch((err: any) => {
        if (err?.response?.data?.statusCode == 402) {
          dispatch(openTrialExceededModal());
          hideModal(onClose);
        } else {
          if (err.response?.data?.errors?.length > 0) {
            showToast(
              "error",
              <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
              <IDHFormattedMessage id={err.response.data.errors[0].message} />,
            );
          } else {
            showToast(
              "error",
              <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
              translateMessage({
                intl,
                id: "ws_error_on_creating_task",
                defaultMessage: "Error on creating task",
              }),
            );
          }
        }
        setLoading(false);
      });
  };

  useEffect(() => {
    const textarea = document.querySelector(
      ".react-autosuggest__container .ws-textarea",
    ) as HTMLElement;

    if (textarea) {
      const id = setTimeout(() => {
        textarea.click();
      }, 150);
      return () => {
        clearTimeout(id);
      };
    }
  }, []);

  return (
    <Modal
      className={classNames("add-creators-modal", {
        "add-creators-modal--global": globalTaskMode,
      })}
      onClose={onClose}
      displayCancelButton
      closeButtonText={translateMessage({
        intl,
        id: "ws_cancel",
        defaultMessage: "Cancel",
      })}
      onConfirmClick={handleConfirm}
      confirmButtonLoading={loading}
      confirmButtonDisabled={!(value.trim() || storedCreators.length > 0)}
      confirmButtonText={translateMessage({
        intl,
        id: "ws_confirm",
        defaultMessage: "Confirm",
      })}
    >
      <ModalTitle>
        <IDHFormattedMessage id="ws_add_creator" defaultMessage="Add Creator" />
      </ModalTitle>
      <ModalText>
        <IDHFormattedMessage
          id="ws_enter_creators_name_or_paste_link"
          defaultMessage="Enter creator's name or paste the link to social profile. You can add several at once, separating names or links with spaces."
        />
      </ModalText>
      <form onSubmit={handleConfirm}>
        <ModalRow>
          <AutosuggestedCreatorsInput
            value={value}
            setValue={setValue}
            storedCreators={storedCreators}
            setStoredCreators={setStoredCreators}
            globalTaskMode={globalTaskMode}
          />
        </ModalRow>
      </form>
    </Modal>
  );
}

export default injectIntl(AddTaskModal);
