import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import { StackedLabels } from "src/app/components/StackedLabels/StackedLabels";
import { WorkspacePermissionsEnum } from "src/utils/PermissionsEnums";
import { ProfileDataText } from "../components/ProfileDataText/ProfileDataText";
import HtmlParser from "../../../../pages/CreatorDiscoveryTool/legacy/app/components/HtmlParser/HtmlParser";
import { SectionHeader } from "../components/SectionHeader";
import IDHFormattedMessage from "../../../../components/IDHFormattedMessage/IDHFormattedMessage";
import { ContactData } from "./ContactData";

export function BioWithContactData(props) {
  const {
    profileData: {
      user_data: { bio },
      user_audience_data: { influencersInterests },
    },
  } = props;

  const identity = useSelector((state) => state.mainReducer.identity);

  const interests = useMemo(
    () => influencersInterests?.map((item) => item.name) || [],
    [influencersInterests],
  );

  return (
    <div className="profile-data-tab__section-wrapper">
      <div className="profile-data-tab__section-two-boxes">
        <div>
          <SectionHeader
            text={<IDHFormattedMessage id="ws_bio" defaultMessage="Bio" />}
          />

          <div className="profile-box profile-box--block">
            {interests.length ? <StackedLabels values={interests} /> : null}
            {bio ? (
              <ProfileDataText>
                <HtmlParser html={bio} />
              </ProfileDataText>
            ) : null}
          </div>
        </div>
        {identity?.permissions?.workspace?.includes(
          WorkspacePermissionsEnum.DISPLAY_CONTACT_SECTION,
        ) && <ContactData profileData={props.profileData} />}
      </div>
    </div>
  );
}
