import React, { ChangeEvent, ReactNode } from "react";
import classNames from "classnames";
import "./CustomSwitch.scss";

type CustomSwitchSize = "small" | "big";

interface CustomSwitchProps {
  label?: ReactNode;
  checked?: boolean;
  onChange?:
    | (() => Promise<void>)
    | (() => void)
    | ((e: ChangeEvent<HTMLInputElement>) => void);
  disabled?: boolean;
  required?: boolean;
  className?: string;
  size?: CustomSwitchSize;
  dataAttributes?: { [key: string]: string };
  name?: string;
  id?: string;
}

export function CustomSwitch({
  label,
  checked,
  onChange,
  disabled,
  required,
  className,
  size = "small",
  dataAttributes,
  name,
  id,
}: CustomSwitchProps) {
  return (
    <label
      className={classNames([
        "ws-switch",
        `ws-switch--${size}`,
        disabled && "ws-switch--disabled",
        className,
      ])}
      {...dataAttributes}
    >
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        required={required}
        name={name}
        id={id}
      />
      <i />
      {label}
    </label>
  );
}
