import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import Tabs from "src/app/components/Tabs/Tabs";
import Tab from "src/app/components/Tabs/Tab";
import { RootState } from "src/redux/reducers";
import { getSettingsGlobalFields } from "src/redux";
import AnimatedDiv from "src/app/components/AnimatedDiv/AnimatedDiv";
import {
  HandleAddFieldsProps,
  HandleRemoveFieldsProps,
  HandleUpdateTemplateValueParams,
  Template,
} from "./types";
import ExtensionView from "../Extensions/ExtensionView";
import TemplateManagerAttributesTab from "./tabs/TemplateManagerAttributesTab";
import TemplateManagerGeneralTab from "./tabs/TemplateManagerGeneralTab";
import TemplateManagerOverviewTab from "./tabs/TemplateManagerOverviewTab";
import TemplateManagerTableColumnTab from "./tabs/TemplateManagerTableColumnTab";
import {
  mapFieldTypeToFieldName,
  handleUpdateProjectTemplate,
  templateWithNewFields,
  templateWithRemovedFields,
  templateWithUpdatedField,
  getProjectTemplateDetails,
} from "./utils";
import EditedTemplateTitle from "./components/EditedTemplateTitle";

enum EditedTemplateTabs {
  General = "General",
  Attributes = "Attributes",
  Overview = "Overview",
  TableColumn = "Table Column",
}

interface EditedTemplateProps {
  editedTemplate: Template;
  setEditedTemplate: Dispatch<SetStateAction<Template | null>>;
}

export default function EditedTemplate({
  editedTemplate,
  setEditedTemplate,
}: EditedTemplateProps) {
  const [activeTab, setActiveTab] = useState(EditedTemplateTabs.General);

  const {
    mainReducer: { activeWorkspaceUuid },
  } = useSelector((state: RootState) => state);

  const dispatch = useDispatch();

  const syncLocalTemplateWithServer = async (uuid: string) => {
    const templateDetails = await getProjectTemplateDetails(uuid);
    if (templateDetails) {
      setEditedTemplate(templateDetails);
    }
  };

  const handleUpdateTemplateValue = (
    params: HandleUpdateTemplateValueParams,
  ) => {
    const { uuid, valueFieldName, updatedValue, fieldType, taskType } = params;

    const fieldName = mapFieldTypeToFieldName[fieldType];

    if (!fieldName) return;

    setEditedTemplate((prevTemplate) => {
      if (!prevTemplate) return null;

      const updatedTemplate = templateWithUpdatedField(
        prevTemplate,
        fieldName,
        taskType,
        uuid,
        valueFieldName,
        updatedValue,
      );

      handleUpdateProjectTemplate({
        data: {
          wsWorkspaceUuid: activeWorkspaceUuid,
          ...updatedTemplate,
        },
        catchCallback: () => syncLocalTemplateWithServer(editedTemplate.uuid),
      });

      return updatedTemplate;
    });
  };

  const handleAddFields = (params: HandleAddFieldsProps) => {
    const { newFieldsData, fieldType, taskType } = params;

    const fieldName = mapFieldTypeToFieldName[fieldType];

    if (!fieldName) return;

    setEditedTemplate((prevTemplate) => {
      if (!prevTemplate) return null;
      const updatedTemplate = templateWithNewFields(
        prevTemplate,
        fieldName,
        taskType,
        newFieldsData,
      );

      handleUpdateProjectTemplate({
        data: {
          wsWorkspaceUuid: activeWorkspaceUuid,
          ...updatedTemplate,
        },
        catchCallback: () => {
          syncLocalTemplateWithServer(editedTemplate.uuid);
        },
      });

      return updatedTemplate;
    });
  };

  const handleRemoveFields = (params: HandleRemoveFieldsProps) => {
    const { fieldsToRemove, fieldType, taskType } = params;

    const fieldName = mapFieldTypeToFieldName[fieldType];

    if (!fieldName) return;

    setEditedTemplate((prevTemplate) => {
      if (!prevTemplate) return null;
      const updatedTemplate = templateWithRemovedFields(
        prevTemplate,
        fieldName,
        taskType,
        fieldsToRemove,
      );

      handleUpdateProjectTemplate({
        data: {
          wsWorkspaceUuid: activeWorkspaceUuid,
          ...updatedTemplate,
        },
        catchCallback: () => {
          syncLocalTemplateWithServer(editedTemplate.uuid);
        },
      });
      return updatedTemplate;
    });
  };

  const renderTabContent = () => {
    if (activeTab === EditedTemplateTabs.General) {
      return (
        <TemplateManagerGeneralTab
          editedTemplate={editedTemplate}
          setEditedTemplate={setEditedTemplate}
          syncLocalTemplateWithServer={syncLocalTemplateWithServer}
        />
      );
    }
    if (activeTab === EditedTemplateTabs.Attributes) {
      return (
        <TemplateManagerAttributesTab
          editedTemplate={editedTemplate}
          setEditedTemplate={setEditedTemplate}
          handleUpdateTemplateValue={handleUpdateTemplateValue}
          handleAddFields={handleAddFields}
          handleRemoveFields={handleRemoveFields}
          syncLocalTemplateWithServer={syncLocalTemplateWithServer}
        />
      );
    }

    if (activeTab === EditedTemplateTabs.Overview) {
      return (
        <TemplateManagerOverviewTab
          editedTemplate={editedTemplate}
          setEditedTemplate={setEditedTemplate}
          handleUpdateTemplateValue={handleUpdateTemplateValue}
          handleAddFields={handleAddFields}
          handleRemoveFields={handleRemoveFields}
          syncLocalTemplateWithServer={syncLocalTemplateWithServer}
        />
      );
    }
    if (activeTab === EditedTemplateTabs.TableColumn) {
      return (
        <TemplateManagerTableColumnTab
          editedTemplate={editedTemplate}
          setEditedTemplate={setEditedTemplate}
          handleUpdateTemplateValue={handleUpdateTemplateValue}
          handleAddFields={handleAddFields}
          handleRemoveFields={handleRemoveFields}
          syncLocalTemplateWithServer={syncLocalTemplateWithServer}
        />
      );
    }
    return null;
  };

  useEffect(() => {
    dispatch(getSettingsGlobalFields(activeWorkspaceUuid));
  }, [activeWorkspaceUuid]);

  return (
    <ExtensionView
      title={
        <EditedTemplateTitle
          editedTemplate={editedTemplate}
          setEditedTemplate={setEditedTemplate}
          syncLocalTemplateWithServer={syncLocalTemplateWithServer}
        />
      }
      handleGoBack={() => setEditedTemplate(null)}
      goBackButtonLabel={
        <IDHFormattedMessage
          id="ws_template_manager"
          defaultMessage="Template Manager"
        />
      }
    >
      <AnimatedDiv>
        <Tabs>
          <div className="extensions__tabs">
            <Tab
              tabText={
                <IDHFormattedMessage id="ws_general" defaultMessage="General" />
              }
              onClick={() => setActiveTab(EditedTemplateTabs.General)}
              active={activeTab === EditedTemplateTabs.General}
            />
            <Tab
              tabText={
                <IDHFormattedMessage
                  id="ws_attributes"
                  defaultMessage="Attributes"
                />
              }
              onClick={() => setActiveTab(EditedTemplateTabs.Attributes)}
              active={activeTab === EditedTemplateTabs.Attributes}
            />
            <Tab
              tabText={
                <IDHFormattedMessage
                  id="ws_overview"
                  defaultMessage="Overview"
                />
              }
              onClick={() => setActiveTab(EditedTemplateTabs.Overview)}
              active={activeTab === EditedTemplateTabs.Overview}
            />
            <Tab
              tabText={
                <IDHFormattedMessage
                  id="ws_table_column"
                  defaultMessage="Table Column"
                />
              }
              onClick={() => setActiveTab(EditedTemplateTabs.TableColumn)}
              active={activeTab === EditedTemplateTabs.TableColumn}
            />
          </div>
        </Tabs>
        {renderTabContent()}
      </AnimatedDiv>
    </ExtensionView>
  );
}
