import React, { ReactNode, PropsWithChildren } from "react";

import { ModalTitle } from "src/app/components/Modal/Modal";

interface ExtensionViewProps {
  title: ReactNode;
  handleGoBack?: () => void;
  goBackButtonLabel?: ReactNode;
}

export default function ExtensionView({
  title,
  handleGoBack,
  goBackButtonLabel,
  children,
}: PropsWithChildren<ExtensionViewProps>) {
  return (
    <>
      <div className="extensions__header">
        {handleGoBack && (
          <button
            onClick={handleGoBack}
            className="extensions__back-btn"
            aria-label=""
            type="button"
          >
            {goBackButtonLabel}
          </button>
        )}
        <ModalTitle>{title}</ModalTitle>
      </div>
      {children}
    </>
  );
}
