import React, { Dispatch, SetStateAction } from "react";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import Tab from "src/app/components/Tabs/Tab";
import Tabs from "src/app/components/Tabs/Tabs";
import { TaskType } from "src/types";

interface TaskTypeTabsProps {
  selectedTaskType: TaskType;
  setSelectedTaskType: Dispatch<SetStateAction<TaskType>>;
}

export default function TaskTypeTabs({
  selectedTaskType,
  setSelectedTaskType,
}: TaskTypeTabsProps) {
  return (
    <Tabs>
      <div className="extensions__tabs extensions__tabs--small">
        <Tab
          tabText={
            <IDHFormattedMessage id="ws_creators" defaultMessage="Creators" />
          }
          active={selectedTaskType === TaskType.Creator}
          onClick={() => setSelectedTaskType(TaskType.Creator)}
        />
        <Tab
          tabText={
            <IDHFormattedMessage id="ws_content" defaultMessage="Content" />
          }
          active={selectedTaskType === TaskType.Content}
          onClick={() => setSelectedTaskType(TaskType.Content)}
        />
        <Tab
          tabText={
            <IDHFormattedMessage
              id="ws_publications"
              defaultMessage="Publications"
            />
          }
          active={selectedTaskType === TaskType.Publication}
          onClick={() => setSelectedTaskType(TaskType.Publication)}
        />
        <Tab
          tabText={
            <IDHFormattedMessage id="ws_payments" defaultMessage="Payments" />
          }
          active={selectedTaskType === TaskType.Payment}
          onClick={() => setSelectedTaskType(TaskType.Payment)}
        />
        <Tab
          tabText={
            <IDHFormattedMessage id="ws_actions" defaultMessage="Actions" />
          }
          active={selectedTaskType === TaskType.Action}
          onClick={() => setSelectedTaskType(TaskType.Action)}
        />
      </div>
    </Tabs>
  );
}
