import React, { useEffect, useState } from "react";
import "./TaskMetaFields.scss";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import classNames from "classnames";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { SettingsModal } from "src/app/SettingsModal/SettingsModal";
import { ExtensionsDropdown } from "src/app/dropdowns/ExtensionsDropdown/ExtensionsDropdown";
import { closeSettingsModal } from "src/app/SettingsModal/methods";
import { useHistory } from "react-router";
import { MetaFieldType } from "src/app/methods/getMetaFieldTypeOptions";
import { AppLocation } from "src/redux/main/mainReducer";
import { RootState } from "src/redux/reducers";
import SingleMetaField from "./SingleMetaField";

import { Metadata } from "../../Subtask/Subtask";
import { tableDataType } from "../../../components/Table/Table";
import { Button } from "../../../components/Button/Button";
import ManageFieldModal from "../../../modals/ManageFieldModal/ManageFieldModal";
import CustomizeFieldsDropdown from "../../../dropdowns/CustomizeFieldsDropdown/CustomizeFieldsDropdown";
import DeleteMetaFieldModal from "../../../modals/DeleteMetaFieldModal/DeleteMetaFieldModal";

import { ReactComponent as PlusIcon } from "../../../../images/plus-transparent.svg";
import { getAutomationList } from "../../../../redux/automation/automationActions";
import { TaskMetaFieldName } from "./TaskMetaFieldName";
import { SettingsContextDataProps } from "../../tabs/ParticipationDetails/ParticipationDetails";

export const TASK_FIELDS_BLACKLIST = [
  "description",
  "title",
  "owner",
  "completed",
  "socialProfiles",
  "publication",
  "accessLink",
];

interface Props {
  data: Metadata[];
  dataType: tableDataType;
  settingsContextData: SettingsContextDataProps;
}

export const HIGHER_FIELDS = [
  MetaFieldType.Text,
  MetaFieldType.File,
  MetaFieldType.SingleSelect,
  MetaFieldType.MultiSelect,
  MetaFieldType.AggregatedSelect,
];

const TaskMetaFields: React.FC<Props> = (props) => {
  const { data, dataType, settingsContextData } = props;

  const [showAddFieldModal, setShowAddFieldModal] = useState(false);
  const [showFieldSetupModal, setShowFieldSetupModal] = useState(false);
  const [editedFieldData, setEditedFieldData] = useState<any>({});
  const [showDeleteMetaFieldModal, setShowDeleteMetaFieldModal] =
    useState(false);
  const [showMore, setShowMore] = useState(false);

  const {
    mainReducer: {
      settingsModalData: { location },
    },
  } = useSelector((state: RootState) => state);
  const {
    projectReducer: { projectDetails },
  } = useSelector((state: RootState) => state);
  const {
    automationReducer: { editedAutomationData },
  } = useSelector((state: RootState) => state);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (
      projectDetails?.projectId &&
      projectDetails?.permissions?.project?.includes(
        "display_project_configuration",
      )
    ) {
      dispatch(getAutomationList(projectDetails?.projectId));
    }
  }, []);

  const moreFields = data
    ? data.filter(
        (field) =>
          field.isVisible &&
          !TASK_FIELDS_BLACKLIST.includes(field.key as string),
      )
    : [];

  const lessFields = moreFields.filter(
    (field) =>
      field.valueRequired ||
      !_.isEmpty(field.value) ||
      !_.isEmpty(field.overrideValue?.value),
  );

  return (
    <>
      <section className="task-meta-fields">
        {dataType === tableDataType.ProjectDetails && (
          <div className="task-meta-fields__header">
            <IDHFormattedMessage id="ws_details" defaultMessage="Details" />
            {projectDetails?.permissions?.project.includes(
              "project_meta_fields_management",
            ) && (
              <div className="task-meta-fields__header-right">
                <Button
                  size="thin"
                  variant="bare"
                  onClick={() => setShowAddFieldModal(true)}
                >
                  <PlusIcon />
                  <IDHFormattedMessage
                    id="ws_add_attribute"
                    defaultMessage="Add Attribute"
                  />
                </Button>
                {data.length > 0 && (
                  <CustomizeFieldsDropdown
                    setShowAddFieldModal={setShowAddFieldModal}
                    setShowFieldSetupModal={setShowFieldSetupModal}
                    fieldData={editedFieldData}
                    setFieldData={setEditedFieldData}
                    fields={data.map((field) => ({
                      ...field,
                      title: field.name,
                    }))}
                    dataType={dataType}
                    title={
                      <IDHFormattedMessage
                        id="ws_campaign_attributes"
                        defaultMessage="Campaign attributes"
                      />
                    }
                    subheader={
                      <IDHFormattedMessage
                        id="ws_attributes"
                        defaultMessage="Attributes"
                      />
                    }
                    addBtnText={
                      <IDHFormattedMessage
                        id="ws_add_attribute"
                        defaultMessage="Add attribute"
                      />
                    }
                  />
                )}

                <ExtensionsDropdown location={AppLocation.TaskMetaFields} />
              </div>
            )}
          </div>
        )}
        {(showMore ? moreFields : lessFields).map((field: Metadata) => (
          <div
            className={classNames("task-meta-fields__row", {
              "task-meta-fields__row--high": HIGHER_FIELDS.includes(field.type),
            })}
            key={field.uuid}
            data-column-name={field.name}
            data-column-key={field.key}
          >
            <TaskMetaFieldName field={field} />
            <SingleMetaField
              metaData={field.overrideValue || field}
              dataType={dataType}
              overrideValue={Boolean(field.overrideValue)}
              blockManualEdit={Boolean(field.blockManualEdit)}
            />
          </div>
        ))}
        {moreFields.length > lessFields.length && (
          <div
            className="task-meta-fields__show-more"
            onClick={() => setShowMore((prev) => !prev)}
          >
            {showMore ? (
              <IDHFormattedMessage
                id="ws_show_less"
                defaultMessage="Show less"
              />
            ) : (
              <IDHFormattedMessage
                id="ws_show_more"
                defaultMessage="Show more"
              />
            )}
          </div>
        )}
      </section>

      {showAddFieldModal &&
        dataType === tableDataType.ProjectDetails &&
        projectDetails?.projectId && (
          <ManageFieldModal
            onClose={() => setShowAddFieldModal(false)}
            showMore={() => setShowMore(true)}
            dataType={dataType}
            title={
              <IDHFormattedMessage
                id="ws_add_campaign_attribute"
                defaultMessage="Add campaign attribute"
              />
            }
            inputLabelText={
              <IDHFormattedMessage
                id="ws_attribute_title"
                defaultMessage="Attribute title"
              />
            }
            projectId={projectDetails.projectId}
          />
        )}

      {showFieldSetupModal &&
        dataType === tableDataType.ProjectDetails &&
        projectDetails?.projectId && (
          <ManageFieldModal
            data={editedFieldData}
            onClose={() => setShowFieldSetupModal(false)}
            dataType={dataType}
            title={
              <IDHFormattedMessage
                id="ws_add_campaign_attribute"
                defaultMessage="Add campaign attribute"
              />
            }
            inputLabelText={
              <IDHFormattedMessage
                id="ws_attribute_title"
                defaultMessage="Attribute title"
              />
            }
            projectId={projectDetails.projectId}
          />
        )}
      {showDeleteMetaFieldModal && (
        <DeleteMetaFieldModal
          uuid={editedFieldData.uuid}
          fieldName={editedFieldData.title}
          onClose={() => setShowDeleteMetaFieldModal(false)}
        />
      )}

      {location === AppLocation.TaskMetaFields && (
        <SettingsModal
          onClose={() => closeSettingsModal(history)}
          automations={{
            automationData: editedAutomationData,
          }}
          {...settingsContextData}
        />
      )}
    </>
  );
};

export default TaskMetaFields;
