import React from "react";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ReactComponent as CrossGreyIcon } from "src/images/close.svg";
import classNames from "classnames";

function AudienceFilterLabel({
  children,
  id,
  defaultMessage,
  removeFilter,
  disabled,
}) {
  return (
    <div
      key={id}
      className={classNames(
        "audience-search__label audience-search__label--grey",
        {
          "audience-search__label--disabled": disabled,
        },
      )}
      data-filter-type={id}
      data-filter-value={
        children && typeof children[0] !== "undefined" ? children[0] : ""
      }
    >
      {children ? (
        <>
          {defaultMessage && (
            <>
              <IDHFormattedMessage id={id} defaultMessage={defaultMessage} />:
              &nbsp;
            </>
          )}
          {children}
        </>
      ) : (
        <>
          {defaultMessage && (
            <IDHFormattedMessage id={id} defaultMessage={defaultMessage} />
          )}
        </>
      )}
      <CrossGreyIcon
        onClick={removeFilter}
        className="audience-search__label__close"
        width={16}
        height={16}
      />
    </div>
  );
}

export default AudienceFilterLabel;
