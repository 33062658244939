import React, { Dispatch, SetStateAction } from "react";

import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { getProjectsList, getTasksList } from "src/redux";
import { addExistingTasksToDatabase } from "src/redux/creator-database/creatorDatabaseActions";
import { showToast } from "src/app/methods/showToast";
import { ReactComponent as RocketIcon } from "src/images/rocket-grey.svg";
import { ReactComponent as CreatorIcon } from "src/images/creator-discovery.svg";
import { SelectedCreator } from "src/app/modals/AddCreatorsCDTWorkspaceProjectModal/AddCreatorsCDTWorkspaceProjectModal";
import { SocialProfile } from "src/app/modals/AddSocialProfilesModal/AddSocialProfilesModal";
import { RootState } from "src/redux/reducers";
import { IProject } from "src/redux/project/types";
import { DropdownMenuItem } from "../../Dropdown/Dropdown";
import { AddToDropdown } from "../../AddToDropdown/AddToDropdown";
import IDHFormattedMessage from "../../IDHFormattedMessage/IDHFormattedMessage";

interface Props {
  permissions: IProject["permissions"] | undefined;
  workspacePermissions: any | undefined;
  setSelectedCreators: Dispatch<SetStateAction<SelectedCreator[]>>;
  setShowModalAddCreatorsToWorkspaceProject: Dispatch<SetStateAction<boolean>>;
}

export const AddToDropdownSelectedTasksBar: React.FC<Props> = (props) => {
  const {
    permissions,
    workspacePermissions,
    setSelectedCreators,
    setShowModalAddCreatorsToWorkspaceProject,
  } = props;

  const {
    mainReducer: { activeWorkspaceUuid },
    projectReducer: { projectsList, taskType },
    taskReducer: { selectedTasks },
  } = useSelector((state: RootState) => state);

  const dispatch = useDispatch();
  const params = useParams<{ projectId: string }>();

  const openAddCreatorsToCampaignModal = () => {
    setShowModalAddCreatorsToWorkspaceProject(true);
    if (!projectsList.length) {
      dispatch(getProjectsList(activeWorkspaceUuid));
    }
    setSelectedCreators(
      selectedTasks.map(
        (item: {
          taskId: string;
          title: string;
          socialProfiles: SocialProfile[];
        }) => ({
          uuid: item.taskId,
          name: item.title,
          socialProfiles: item.socialProfiles.map((profile) => ({
            ...profile,
          })),
        }),
      ),
    );
  };

  const addCreatorsToDatabase = () => {
    const uuids = selectedTasks.map((item: { taskId: string }) => item.taskId);
    dispatch(addExistingTasksToDatabase(uuids));
    showToast(
      "success",
      <IDHFormattedMessage id="ws_success" defaultMessage="Success" />,
      <IDHFormattedMessage
        id="ws_creators_added_to_database"
        defaultMessage="Creators successfully added to Database"
      />,
    );
    dispatch(getTasksList(params.projectId, taskType));
  };

  return (
    <AddToDropdown>
      {workspacePermissions?.workspace?.includes("creator_database") && (
        <DropdownMenuItem onClick={addCreatorsToDatabase}>
          <CreatorIcon />
          <IDHFormattedMessage
            id="ws_creator_database"
            defaultMessage="Creator database"
          />
        </DropdownMenuItem>
      )}
      {permissions?.project.includes("task_management") && (
        <DropdownMenuItem onClick={openAddCreatorsToCampaignModal}>
          <RocketIcon />
          <IDHFormattedMessage id="ws_campaign" defaultMessage="Campaign" />
        </DropdownMenuItem>
      )}
    </AddToDropdown>
  );
};
