import React, { useEffect } from "react";
import "./Automations.scss";

import { useDispatch, useSelector } from "react-redux";
import {
  getAutomationList,
  setEditedAutomationData,
} from "src/redux/automation/automationActions";
import { useHistory } from "react-router";
import { RootState } from "src/redux/reducers";
import { AutomationsMain } from "./components/AutomationsMain";
import PublicationsDetection from "./components/PublicationsDetection/PublicationsDetection";
import { PublicationsSynchronization } from "./components/PublicationsSynchronization/PublicationsSynchronization";
import { changeSettingsSection } from "../../methods";

export enum AutomationsSection {
  AutomationsMain = "automations-main",
  PublicationsDetection = "publications-detection",
  PublicationsSynchronization = "publications-synchronization",
}
export enum AutomationAggregationTypes {
  AndType = "and",
  OrType = "or",
}

interface Props {
  onClose: () => void;
  data: any;
}

export const Automations: React.FC<Props> = (props) => {
  const { onClose, data } = props;

  const {
    mainReducer: { settingsModalData },
    automationReducer: { automationList, automationListLoading },
  } = useSelector((state: RootState) => state);

  const { section } = settingsModalData;

  const history = useHistory();

  const setSection = (newSection: AutomationsSection) => {
    changeSettingsSection(history, newSection);
  };

  const { projectId } = data;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAutomationList(projectId));
  }, []);

  useEffect(() => {
    if (automationListLoading) return;

    if (section === AutomationsSection.PublicationsDetection) {
      const publicationSynchronizationList = automationList.filter(
        (a) => a.wsAutomationName === "PublicationDetectionRunnableAutomation",
      );

      if (publicationSynchronizationList[0]) {
        dispatch(setEditedAutomationData(publicationSynchronizationList[0]));
      }
    }

    if (section === AutomationsSection.PublicationsSynchronization) {
      const publicationSynchronizationList = automationList.filter(
        (a) =>
          a.wsAutomationName === "PublicationSynchronizationRunnableAutomation",
      );

      if (publicationSynchronizationList[0]) {
        dispatch(setEditedAutomationData(publicationSynchronizationList[0]));
      }
    }
  }, [automationListLoading]);

  const renderAutomationsSection = () => {
    switch (section) {
      case AutomationsSection.AutomationsMain:
        return (
          <AutomationsMain
            onClose={onClose}
            setSection={setSection}
            basicData={data}
          />
        );

      case AutomationsSection.PublicationsDetection:
        return (
          <PublicationsDetection setSection={setSection} basicData={data} />
        );

      case AutomationsSection.PublicationsSynchronization:
        return (
          <PublicationsSynchronization
            setSection={setSection}
            basicData={data}
          />
        );

      default:
        return null;
    }
  };

  return <>{renderAutomationsSection()}</>;
};
