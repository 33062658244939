import React from "react";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import XssFilter from "../../../../components/XssFilter/XssFilter";
import InfoBox from "./InfoBox";

function Caption(props) {
  const { caption } = props;
  return (
    <InfoBox
      title={<IDHFormattedMessage id="ws_caption" defaultMessage="Caption" />}
    >
      <div
        className="info-box__text"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: XssFilter(caption) }}
      />
    </InfoBox>
  );
}

export { Caption };
