import React, { useState } from "react";
import "./InfluencersList.scss";

import { Link, withRouter } from "react-router-dom";
import { Button } from "src/app/components/Button/Button";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import moment from "moment";

import { connect, useSelector } from "react-redux";
import { showToast } from "src/app/methods/showToast";
import { downloadUrl } from "src/app/pages/CreatorDiscoveryTool/utils/downloadUrl";
import { ReactComponent as MagnifierIcon } from "src/images/magnifier-blue.svg";
import { ReactComponent as PlusIcon } from "src/images/plus-white.svg";
import { RemoveModal } from "src/app/modals/RemoveModal/RemoveModal";
import { injectIntl } from "react-intl";
import CampaignInfoCell from "./cells/CampaignInfoCell/CampaignInfoCell";
import Emoji from "../../components/Emoji/Emoji";
import {
  CDTable,
  CDTableColumn,
  CDTableRow,
} from "../../components/CDTable/CDTable";
import METHODS from "../../helpers/methods";
import {
  createInfluencersList,
  deleteInfluencersList,
  duplicateList,
  exportFullList,
  getInfluencersList,
} from "../audiencesManagerActions";
import CONSTS from "../../helpers/consts";
import DeleteListModal from "../DeleteListModal/DeleteListModal";
import DuplicateModal from "../DuplicateModal/DuplicateModal";
import AfterExportModal from "../../profile/AfterExportModal/AfterExportModal";
import InfluencersListAuthorsDropdown from "./partials/InfluencersListAuthorsDropdown/InfluencersListAuthorsDropdown";

import { AuthorCell } from "./cells/AuthorCell/AuthorCell";
import { InfluencerPhotosCell } from "./cells/InfluencerPhotosCell/InfluencerPhotosCell";
import { ActionCell } from "./cells/ActionCell/ActionCell";
import { InfluencersListRow } from "./partials/InfluencersListRow/InfluencersListRow";
import CreateEmptyAudienceModal from "../CreateAudienceModal/CreateEmptyAudienceModal";

function InfluencersList({
  influencersList,
  hasAccessToInfluencerInsightsToolVelocityIndex,
  exportFullList,
  history,
  deleteInfluencersList,
  getInfluencersList,
  duplicateList,
  createAudiencesList,
  influencerProviders,
  intl,
}) {
  const [listIdToDelete, setListIdToDelete] = useState(null);
  const [showDeleteListModal, setShowDeleteListModal] = useState(false);

  const [listIdToDuplicate, setListIdToDuplicate] = useState(null);
  const [nameToDuplicate, setNameToDuplicate] = useState(null);
  const [showDuplicateListModal, setShowDuplicateModal] = useState(false);
  const [showAfterExportModal, setShowAfterExportModal] = useState(false);
  const [confirmButtonLoading, setConfirmButtonLoading] = useState(false);
  const [showCreateAudienceModal, setShowCreateAudienceModal] = useState(false);

  const {
    mainReducer: { activeWorkspaceUuid, identity },
  } = useSelector((state) => state);

  const initialFiltersValue = () => {
    const array = [];

    influencersList.forEach((item) => {
      if (!array.find((i) => i === item.wsMemberName)) {
        array.push(item.wsMemberName);
      }
    });
    return array;
  };

  const [initialFilters] = useState(() => initialFiltersValue());
  const [filters, setFilters] = useState(initialFilters);

  const columns = [
    {
      id: "front_list",
      defaultMessage: "List",
      width: "92px",
    },
    {
      id: "front_name",
      width: "30%",
      empty: true,
    },
    {
      id: "front_creator",
      defaultMessage: "Creator",
      width: "10%",
    },
    {
      id: "front_avg_er",
      defaultMessage: "avg. ER",
      width: "10%",
      tooltip: {
        id: "front_avg_er_tooltip",
        defaultMessage: "avg. ER tooltip",
      },
    },
    hasAccessToInfluencerInsightsToolVelocityIndex
      ? {
          id: "front_avg_velocity_index",
          defaultMessage: "avg. velocity index",
          width: "10%",
          tooltip: {
            id: "front_avg_velocity_index_tooltip",
            defaultMessage: "avg. velocity index tooltip",
          },
        }
      : {
          id: "front_avg_relevance",
          defaultMessage: "avg. relevance",
          width: "10%",
          tooltip: {
            id: "front_avg_relevance_tooltip",
            defaultMessage: "avg. relevance tooltip",
          },
        },
    { id: "front_date", defaultMessage: "Date", width: "10%" },
    {
      id: "front_brand_safety_email_notification",
      defaultMessage: "Brand Safety Email Notification",
      width: "10%",
    },
    {
      id: "front_author",
      defaultMessage: "Author",
      width: "10%",
      afterTextComponent: (
        <InfluencersListAuthorsDropdown
          filters={filters}
          setFilters={setFilters}
          initialFilters={initialFilters}
        />
      ),
    },
    {
      id: "front_action",
      defaultMessage: "Action",
      width: "100px",
      empty: true,
    },
  ];

  const goToDetails = (listId) => {
    history.push(
      `/workspace/${activeWorkspaceUuid}/creator-discovery-tool/list/${listId}?activeTab=selected`,
    );
  };

  const onDuplicateClick = (name, listId) => {
    setNameToDuplicate(name);
    setListIdToDuplicate(listId);
    setShowDuplicateModal(true);
  };

  const confirmDuplicateModal = async (name) => {
    setConfirmButtonLoading(true);
    if (name.length > CONSTS.audienceListNameMaxLength) {
      showToast(
        "error",
        <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
        <IDHFormattedMessage
          id="front_audience_name_too_long"
          defaultMessage="Please use up to 300 characters"
        />,
      );
    } else {
      await duplicateList(name, listIdToDuplicate).then((data) => {
        setConfirmButtonLoading(false);
        if (data.error) {
          METHODS.responseErrorHandler(data);
        } else {
          setShowDuplicateModal(false);
        }
      });
      await getInfluencersList();
    }
  };

  const onDeleteListClick = (listId) => {
    setListIdToDelete(listId);
    setShowDeleteListModal(true);
  };

  const confirmDeleteList = async () => {
    setConfirmButtonLoading(true);
    await deleteInfluencersList(listIdToDelete);
    setConfirmButtonLoading(false);
    setShowDeleteListModal(false);
    await getInfluencersList();
  };

  const getSocialProviders = () => {
    const enabledSocialProviders = influencerProviders || [];
    return enabledSocialProviders.map((provider) => ({
      value: provider,
      label: intl.formatMessage({
        id: `front_${provider}`,
        defaultMessage: METHODS.capitalizeFirstLetter(provider),
      }),
    }));
  };

  const exportList = (type, listItem) => {
    // TODO: create one file responsible for both exports (exportList & exportFullList)
    const { listId, listName, usersCount } = listItem;

    if (type === "xls") {
      const url =
        `${CONSTS.exportFullListXls}?listId=${listId}` +
        `&listUsersCount=${usersCount}` +
        `&listName=${listName}`;
      downloadUrl(url, "creators.xlsx");
    } else {
      exportFullList(type, listId).then((data) => {
        if (data?.payload?.status === 200) {
          setShowAfterExportModal(true);
        } else if (data.error) {
          METHODS.responseErrorHandler(data);
        }
      });
    }
  };

  const lastEditedCompareFunction = (a, b) => {
    const lastEditedA = a.lastEdited;
    const lastEditedB = b.lastEdited;
    if (lastEditedA > lastEditedB) {
      return -1;
    }
    if (lastEditedA < lastEditedB) {
      return 1;
    }

    return 0;
  };

  const confirmCreateAudience = (name, influencerSocialProvider) => {
    if (name.length > CONSTS.audienceListNameMaxLength) {
      showToast(
        "error",
        <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
        <IDHFormattedMessage
          id="front_audience_name_too_long"
          defaultMessage="Please use up to 300 characters"
        />,
      );
    } else {
      setConfirmButtonLoading(true);

      createAudiencesList({ influencerSocialProvider }, [], name).then(
        (data) => {
          if (data.error) {
            METHODS.responseErrorHandler(data);
          } else {
            const {
              status_code: statusCode,
              content: { listId },
            } = data?.payload?.data;
            if (statusCode === 200) {
              setShowCreateAudienceModal(false);
              history.push(
                `/workspace/${activeWorkspaceUuid}/creator-discovery-tool/list/${listId}?activeTab=selected`,
              );
            }
          }

          setConfirmButtonLoading(false);
        },
      );
    }
  };

  const hideAfterExportModal = () => {
    setShowAfterExportModal(false);
  };
  return (
    <>
      <div className="influencers-list__actions">
        <div>
          <div className="influencers-list__heading">
            <IDHFormattedMessage
              id="ws_creator_list"
              defaultMessage="Creator lists"
            />
          </div>
          <div className="influencers-list__description">
            <IDHFormattedMessage
              id="ws_creator_description"
              defaultMessage="Here you can create and manage lists of creators."
            />
          </div>
        </div>
        <div className="influencers-list__actions-btns">
          <Button
            variant="blue"
            onClick={() => setShowCreateAudienceModal(true)}
          >
            <PlusIcon width={20} height={20} />
            <IDHFormattedMessage id="front_create" defaultMessage="Create" />
          </Button>
          <Link
            to={`/workspace/${activeWorkspaceUuid}/creator-discovery-tool/search`}
          >
            <Button variant="transparent">
              <MagnifierIcon width={20} height={20} />
              <IDHFormattedMessage
                id="front_search_for_creators"
                defaultMessage="Search for Creators"
              />
            </Button>
          </Link>
        </div>
      </div>
      <div className="influencers-list">
        <CDTable
          columns={columns}
          className="influencers-list-table"
          autoLayout
          showNoResults={!filters.length}
          customNoResults={
            <div>
              <Emoji icon="👀" />
              <IDHFormattedMessage
                id="front_posts_empty_tab_filters"
                defaultMessage="Seems like there's nothing to be displayed here, since no author has been selected."
              />
            </div>
          }
        >
          {influencersList
            ?.sort(lastEditedCompareFunction)
            .filter((listItem) => filters.includes(listItem.wsMemberName))
            .map((listItem) => (
              <InfluencersListRow
                activeWorkspaceUuid={activeWorkspaceUuid}
                listItem={listItem}
                exportList={exportList}
                goToDetails={goToDetails}
                onDeleteListClick={onDeleteListClick}
                onDuplicateClick={onDuplicateClick}
              />
            ))}
        </CDTable>

        {showDeleteListModal && (
          <RemoveModal
            onClose={() => setShowDeleteListModal(false)}
            objectNames={[
              influencersList?.find(
                (listItem) => listIdToDelete === listItem.listId,
              )?.listName ?? "",
            ]}
            removeFunction={confirmDeleteList}
            loading={confirmButtonLoading}
          />
        )}

        {showCreateAudienceModal && (
          <CreateEmptyAudienceModal
            onClose={() => {
              setShowCreateAudienceModal(false);
            }}
            onConfirmClick={confirmCreateAudience}
            confirmButtonLoading={confirmButtonLoading}
            getSocialProviders={getSocialProviders}
          />
        )}

        {showDuplicateListModal && (
          <DuplicateModal
            onClose={() => setShowDuplicateModal(false)}
            onConfirmClick={confirmDuplicateModal}
            name={nameToDuplicate}
            confirmButtonLoading={confirmButtonLoading}
          />
        )}

        {showAfterExportModal && (
          <AfterExportModal onClose={hideAfterExportModal} />
        )}
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  influencersList: state.audiencesManagerReducer.influencersList,
  influencerProviders: state.audiencesManagerReducer.influencerProviders,
  hasAccessToInfluencerInsightsToolVelocityIndex:
    state.audiencesManagerReducer
      .hasAccessToInfluencerInsightsToolVelocityIndex,
});

const mapDispatchToProps = (dispatch) => {
  return {
    deleteInfluencersList: (listId) => dispatch(deleteInfluencersList(listId)),
    getInfluencersList: () => dispatch(getInfluencersList()),
    duplicateList: (name, listId) => dispatch(duplicateList(name, listId)),
    exportFullList: (type, listId) => dispatch(exportFullList(type, listId)),
    createAudiencesList: (searchData, influencersList, name) =>
      dispatch(createInfluencersList(searchData, influencersList, name)),
  };
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(InfluencersList)),
);
