import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import "./AudiencesSearch.scss";
import "../AudiencesManager.scss";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import classNames from "classnames";
import axios from "axios";
import EmojiPicker, { Emoji } from "@indahash/emoji-picker-react";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import CustomSelect from "src/app/components/CustomSelect/CustomSelect";
import { ReactComponent as TickIcon } from "src/images/tick.svg";
import { SelectTags } from "src/app/components/SelectTags/SelectTags";
import { Chevron } from "src/app/components/Chevron/Chevron";
import { SelectedLabels } from "src/app/components/SelectTags/partials/SelectedLabels/SelectedLabels";
import { MaterialTooltip } from "src/app/components/MaterialTooltip/MaterialTooltip";
import { CustomSwitch } from "src/app/components/CustomSwitch/CustomSwitch";
import { ReactComponent as ErrorIcon } from "src/images/post-check-alert.svg";
import {
  isValidUrl,
  generateCreatorFromString,
  replaceOldTikTokProviderName,
} from "src/app/modals/AddCreatorsModal/components/GenerateCreatorFromString";
import CustomCheckbox from "src/app/components/CustomCheckbox/CustomCheckbox";
import {
  changeSearchAudienceFilters,
  setCountryDisabled,
  clearDisabledCountries,
  setListSocialProvider,
  setIsIncorrectProviderError,
} from "../audiencesManagerActions";
import useSelectWithoutLabel from "../../../utils/useSelectWithoutLabel";

import METHODS from "../../helpers/methods";
import CONSTS from "../../helpers/consts";
import { useOnClickOutside } from "../../base/utils/useOnOutsideClick";
import { SelectedFiltersCounter } from "./partials/SelectedFiltersCounter/SelectedFiltersCounter";
import { countActiveFields } from "./utils/countActiveFields";
import { applyFiltersBtnRef } from "../views/AudiencesResultsContainer";
import { getPercentOptions } from "./utils/getPercentOptions";
import FollowersCountRange from "./partials/FollowersCountRange/FollowersCountRange";
import { CriteriaOptionSelector } from "./components/CriteriaOptionSelector/CriteriaOptionSelector";
import { FlexibleSearchCriteriaModal } from "./components/FlexibleSearchCriteriaModal/FlexibleSearchCriteriaModal";

function AudiencesSearch(props) {
  const {
    intl,
    preSelect,
    setCountryDisabled,
    clearDisabledCountries,
    influencerProviders,
    showFSCModal,
    setShowFSCModal,
    isIncorrectProviderError,
    setIsIncorrectProviderError,
  } = props;
  const [searchTopic, setSearchTopic] = useState("");
  const [chosenTopics, setChosenTopics] = useState([]);
  const foundTopics = useRef({ count: 0, name: null });

  const [searchInfluencerIgCategories, setSearchInfluencerIgCategories] =
    useState("");
  const [searchInfluencerInterest, setSearchInfluencerInterest] = useState("");
  const [chosenInfluencerIgCategories, setChosenInfluencerIgCategories] =
    useState([]);
  const [chosenInfluencerInterests, setChosenInfluencerInterests] = useState(
    [],
  );
  const foundInfluencerIgCategories = useRef({ count: 0, name: null });
  const foundInfluencerInterests = useRef({ count: 0, name: null });

  const [searchBrand, setSearchBrand] = useState("");
  const [chosenBrands, setChosenBrands] = useState([]);
  const foundBrands = useRef({ count: 0, name: null });

  const [searchKeyword, setSearchKeyword] = useState("");
  const [chosenKeywords, setChosenKeywords] = useState([]);
  const foundKeywords = useRef({ count: 0, name: null });

  const [audienceCredibilityScore, setAudienceCredibilityScore] =
    useState(null);
  const [paidPostPerformanceMin, setPaidPostPerformanceMin] = useState(null);
  const [excludeCreatorsFromList, setExcludeCreatorsFromList] = useState(false);
  const [hasContactData, setHasContactData] = useState(false);
  const [excludeCreatorsFromDatabase, setExcludeCreatorsFromDatabase] =
    useState(false);
  const [currentListId, setCurrentListId] = useState(null);
  const [minimumEr, setMinimumEr] = useState(null);
  const [audienceCountry, setAudienceCountry] = useState(null);
  const [searchAudienceCountry, setSearchAudienceCountry] = useState("");
  const [selectedAudienceCountries, setSelectedAudienceCountries] = useState(
    [],
  );
  const [audienceGender, setAudienceGender] = useState(null);
  const [audienceGenderDensity, setAudienceGenderDensity] = useState(null);
  const [audienceAgeFrom, setAudienceAgeFrom] = useState(null);
  const [audienceAgeTo, setAudienceAgeTo] = useState(null);
  const [audienceAgeDensity, setAudienceAgeDensity] = useState(null);
  const [influencerSocialProvider, setInfluencerSocialProvider] = useState(
    preSelect?.influencerSocialProvider
      ? {
          ...preSelect?.influencerSocialProvider,
          default: true,
        }
      : {
          value: "instagram",
          label: intl.formatMessage({
            id: "front_instagram",
            defaultMessage: "Instagram",
          }),
          default: true,
        },
  );
  const [influencerSocialUsername, setInfluencerSocialUsername] = useState("");
  const [influencerGender, setInfluencerGender] = useState(null);
  const [influencerAgeFrom, setInfluencerAgeFrom] = useState(null);
  const [influencerAgeTo, setInfluencerAgeTo] = useState(null);
  const [influencerCountry, setInfluencerCountry] = useState(null);
  const [selectedInfluencerCountries, setSelectedInfluencerCountries] =
    useState([]);
  const [influencerFollowersFrom, setInfluencerFollowersFrom] = useState(null);
  const [influencerFollowersTo, setInfluencerFollowersTo] = useState(null);
  const [disableEffects, setDisableEffects] = useState(true);
  const [disableEffectsId, setDisableEffectsId] = useState(null);
  const [lastPostsWithin, setLastPostsWithin] = useState(null);
  const [searchContents, setSearchContents] = useState([]);
  const [searchHashtags, setSearchHashtags] = useState([]);
  const [searchMentions, setSearchMentions] = useState([]);
  const [searchBioKeywords, setSearchBioKeywords] = useState([]);
  const [contentsOption, setContentsOption] = useState(null);
  const [hashtagsOption, setHashtagsOption] = useState(null);
  const [mentionsOption, setMentionsOption] = useState(null);
  const [searchBioKeywordsOption, setSearchBioKeywordsOption] = useState(null);
  const [aiTagsOption, setAiTagsOption] = useState(null);
  const [audienceInterestsOption, setAudienceInterestsOption] = useState(null);
  const [audienceBrandAffinityOption, setAudienceBrandAffinityOption] =
    useState(null);
  const [searchByContentInputValue, setSearchByContentInputValue] =
    useState("");
  const [hashtagsInputValue, setHashtagsInputValue] = useState("");
  const [mentionsInputValue, setMentionsInputValue] = useState("");
  const [countriesInputValue, setCountriesInputValue] = useState("");
  const [bioInputValue, setBioInputValue] = useState("");
  const [showInfluencerMetrics, setShowInfluencerMetrics] = useState(true);
  const [showSearchByContent, setShowSearchByContent] = useState(true);
  const [advancedSearch, setAdvancedSearch] = useState(false);
  const [showHashtags, setShowHashtags] = useState(true);
  const [showMentions, setShowMentions] = useState(true);
  const [showSearchInBio, setShowSearchInBio] = useState(true);
  const [showAiImageRecognition, setShowAiImageRecognition] = useState(false);
  const [showAudienceData, setShowAudienceData] = useState(false);
  const [showAudienceInterests, setShowAudienceInterests] = useState(false);
  const [showAudienceBrandAffinity, setShowAudienceBrandAffinity] =
    useState(false);
  const [popularHashtags, setPopularHashtags] = useState(null);
  const [popularMentions, setPopularMentions] = useState(null);

  const [citiesInputValue, setCitiesInputValue] = useState("");
  const [influencerCities, setInfluencerCities] = useState([]);
  const [selectedInfluencerCities, setSelectedInfluencerCities] = useState([]);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [bioInputFocused, setBioInputFocused] = useState(false);
  const [showLastPostsWithin, setShowLastPostsWithin] = useState(false);

  const foundCities = useRef({ count: 0, name: null });

  const [timerId, setTimerId] = useState(null);

  const audienceDataTick =
    selectedAudienceCountries.length ||
    audienceGender ||
    audienceAgeFrom ||
    audienceAgeTo;
  const audienceInterestsTick = chosenTopics.length > 0;

  const searchInBioTick = searchBioKeywords.length > 0;
  const audienceBrandAffinityTick = chosenBrands.length > 0;

  const influencerMetricsCount = countActiveFields([
    selectedInfluencerCountries.length,
    influencerSocialUsername,
    influencerGender,
    influencerAgeFrom,
    influencerAgeTo,
    influencerCountry,
    influencerFollowersFrom,
    influencerFollowersTo,
    audienceCredibilityScore,
    paidPostPerformanceMin,
    minimumEr,
    excludeCreatorsFromList,
    excludeCreatorsFromDatabase,
    hasContactData,
  ]);
  const hashtagsCount = countActiveFields([searchHashtags.length]);
  const mentionsCount = countActiveFields([searchMentions.length]);
  const audienceDataCount = countActiveFields([
    selectedAudienceCountries.length,
    audienceGender,
    audienceAgeFrom,
    audienceAgeTo,
  ]);

  const params = useParams();

  useEffect(() => {
    if (audienceBrandAffinityTick) {
      setShowAudienceBrandAffinity(true);
    }
  }, [audienceBrandAffinityTick]);

  useEffect(() => {
    if (audienceDataTick) {
      setShowAudienceData(true);
    }
  }, [audienceDataTick]);

  useEffect(() => {
    if (audienceInterestsTick) {
      setShowAudienceInterests(true);
    }
  }, [audienceInterestsTick]);

  useEffect(() => {
    if (searchInBioTick) {
      setShowSearchInBio(true);
    }
  }, [searchInBioTick]);

  useEffect(() => {
    // There is a little trick for preSelect correct setting state
    // There was a bug => density in preSelect was always set to 10%
    // It happened because of useEffect for age etc., because there was
    // logic to set 10% density when main param is set.
    // But when there is preSelect flow it also was changing density to 10%
    // due to useEffect logic.
    // So there is a workaround to disable useEffect for 1s, to set values
    // correctly. It may cause problems when user will really fast change
    // some fields, but it is really unlikely to happen.
    setDisableEffectsId(
      setTimeout(() => {
        setDisableEffects(false);
        setDisableEffectsId(null);
      }, 1000),
    );

    return () => {
      if (disableEffectsId) {
        clearTimeout(disableEffectsId);
      }
    };
  }, []);

  useEffect(() => {
    clearDisabledCountries();

    if (preSelect) {
      setSelectedAudienceCountries(preSelect.selectedAudienceCountries || []);
      setChosenTopics(preSelect.chosenTopics || []);
      setChosenInfluencerIgCategories(
        preSelect.chosenInfluencerIgCategories || [],
      );
      setChosenInfluencerInterests(preSelect.chosenInfluencerInterests || []);
      setAudienceCredibilityScore(preSelect.audienceCredibilityScore || null);
      setPaidPostPerformanceMin(preSelect.paidPostPerformanceMin || null);
      setMinimumEr(preSelect.minimumEr || null);
      setExcludeCreatorsFromList(preSelect.excludeCreatorsFromList || false);
      setExcludeCreatorsFromDatabase(
        preSelect.excludeCreatorsFromDatabase || false,
      );
      setHasContactData(preSelect.hasContactData || false);
      setCurrentListId(preSelect.currentListId || null);
      setAudienceGender(preSelect.audienceGender || null);
      setAudienceGenderDensity(preSelect.audienceGenderDensity || null);
      setAudienceAgeFrom(preSelect.audienceAgeFrom || null);
      setAudienceAgeTo(preSelect.audienceAgeTo || null);
      setAudienceAgeDensity(preSelect.audienceAgeDensity || null);
      setInfluencerSocialUsername(preSelect.influencerSocialUsername || "");
      setInfluencerGender(preSelect.influencerGender || null);
      setInfluencerAgeFrom(preSelect.influencerAgeFrom || null);
      setInfluencerAgeTo(preSelect.influencerAgeTo || null);
      setSelectedInfluencerCountries(
        preSelect.selectedInfluencerCountries || [],
      );
      setSelectedInfluencerCities(preSelect.selectedInfluencerCities || []);
      setInfluencerFollowersFrom(preSelect.influencerFollowersFrom || null);
      setInfluencerFollowersTo(preSelect.influencerFollowersTo || null);
      setChosenBrands(preSelect.chosenBrands || []);
      setLastPostsWithin(preSelect.lastPostsWithin || null);
      setSearchContents(preSelect.searchContents || []);
      setSearchHashtags(preSelect.searchHashtags || []);
      setSearchMentions(preSelect.searchMentions || []);
      setSearchBioKeywords(preSelect.searchBioKeywords || []);
      setHashtagsOption(preSelect.hashtagsOption || { value: "or" });
      setContentsOption(preSelect.contentsOption || { value: "or" });
      setMentionsOption(preSelect.mentionsOption || { value: "or" });
      setSearchBioKeywordsOption(
        preSelect.searchBioKeywordsOption || { value: "or" },
      );
      setAiTagsOption(preSelect.aiTagsOption || { value: "or" });
      setAudienceInterestsOption(
        preSelect.audienceInterestsOption || { value: "or" },
      );
      setAudienceBrandAffinityOption(
        preSelect.audienceBrandAffinityOption || { value: "or" },
      );
      setChosenKeywords(preSelect.chosenKeywords || []);
      if (preSelect.chosenKeywords && preSelect.chosenKeywords.length > 0)
        setShowAiImageRecognition(true);
      if (preSelect.selectedAudienceCountries !== undefined) {
        preSelect.selectedAudienceCountries.forEach((country) => {
          setCountryDisabled("audience", country, "yes");
        });
      }

      if (
        !preSelect.searchContents?.length &&
        (preSelect.searchHashtags?.length ||
          preSelect.searchMentions?.length ||
          preSelect.searchBioKeywords?.length ||
          preSelect.chosenKeywords?.length)
      ) {
        setAdvancedSearch(true);
      }
    }
  }, []);

  useEffect(() => {
    props.changeSearchAudienceFilters({
      chosenTopics,
      chosenInfluencerIgCategories,
      chosenInfluencerInterests:
        influencerSocialProvider?.value === "youtube"
          ? chosenInfluencerInterests
          : undefined,
      chosenBrands,
      audienceCredibilityScore,
      paidPostPerformanceMin,
      minimumEr,
      selectedAudienceCountries,
      audienceGender,
      audienceGenderDensity,
      audienceAgeFrom,
      audienceAgeTo,
      audienceAgeDensity,
      influencerSocialProvider,
      influencerSocialUsername,
      influencerGender,
      influencerAgeFrom,
      influencerAgeTo,
      selectedInfluencerCountries,
      selectedInfluencerCities,
      influencerFollowersFrom,
      influencerFollowersTo,
      lastPostsWithin,
      searchContents,
      hashtagsOption,
      contentsOption,
      mentionsOption,
      searchBioKeywordsOption,
      aiTagsOption,
      audienceInterestsOption,
      audienceBrandAffinityOption,
      searchHashtags,
      searchMentions,
      searchBioKeywords,
      chosenKeywords,
      excludeCreatorsFromList,
      excludeCreatorsFromDatabase,
      currentListId,
      hasContactData,
    });
  }, [
    chosenTopics,
    chosenInfluencerIgCategories,
    chosenInfluencerInterests,
    chosenBrands,
    audienceCredibilityScore,
    paidPostPerformanceMin,
    minimumEr,
    selectedAudienceCountries,
    audienceGender,
    audienceGenderDensity,
    audienceAgeFrom,
    audienceAgeTo,
    audienceAgeDensity,
    influencerSocialProvider,
    influencerSocialUsername,
    influencerGender,
    influencerAgeFrom,
    influencerAgeTo,
    selectedInfluencerCountries,
    selectedInfluencerCities,
    influencerFollowersFrom,
    influencerFollowersTo,
    lastPostsWithin,
    searchContents,
    hashtagsOption,
    contentsOption,
    mentionsOption,
    searchBioKeywordsOption,
    aiTagsOption,
    audienceInterestsOption,
    audienceBrandAffinityOption,
    searchHashtags,
    searchMentions,
    searchBioKeywords,
    chosenKeywords,
    excludeCreatorsFromList,
    excludeCreatorsFromDatabase,
    currentListId,
    hasContactData,
  ]);

  useEffect(() => {
    if (!disableEffects) {
      if (audienceGender && !audienceGenderDensity) {
        setAudienceGenderDensity({
          value: `10`,
          label: `10 %`,
        });
      }
      if (!audienceGender && audienceGenderDensity) {
        setAudienceGenderDensity(null);
      }
    }
  }, [audienceGender]);

  useEffect(() => {
    if (!disableEffects) {
      if ((audienceAgeFrom || audienceAgeTo) && !audienceAgeDensity) {
        setAudienceAgeDensity({
          value: `10`,
          label: `10 %`,
        });
      }

      if (!(audienceAgeFrom || audienceAgeTo) && audienceAgeDensity) {
        setAudienceAgeDensity(null);
      }
    }
  }, [audienceAgeFrom, audienceAgeTo]);

  const topicClick = (id) => {
    if (chosenTopics.indexOf(id) > -1) {
      setChosenTopics(chosenTopics.filter((itemId) => itemId !== id));
    } else {
      setChosenTopics([...chosenTopics, id]);
    }

    if (!audienceInterestsOption) {
      setAudienceInterestsOption({ value: "or" });
    }
  };

  const influencerIgCategoryClick = (id) => {
    if (chosenInfluencerIgCategories.indexOf(id) > -1) {
      setChosenInfluencerIgCategories(
        chosenInfluencerIgCategories.filter((itemId) => itemId !== id),
      );
    } else {
      setChosenInfluencerIgCategories([...chosenInfluencerIgCategories, id]);
    }
  };

  const influencerInterestClick = (id) => {
    if (chosenInfluencerInterests.indexOf(id) > -1) {
      setChosenInfluencerInterests(
        chosenInfluencerInterests.filter((itemId) => itemId !== id),
      );
    } else {
      setChosenInfluencerInterests([...chosenInfluencerInterests, id]);
    }
  };

  const brandClick = (name) => {
    if (chosenBrands.indexOf(name) > -1) {
      setChosenBrands(chosenBrands.filter((itemName) => itemName !== name));
    } else {
      setChosenBrands([...chosenBrands, name]);
    }

    if (!audienceBrandAffinityOption) {
      setAudienceBrandAffinityOption({ value: "or" });
    }
  };

  const keywordClick = (id) => {
    if (chosenKeywords.indexOf(id) > -1) {
      setChosenKeywords(chosenKeywords.filter((itemId) => itemId !== id));
    } else {
      setChosenKeywords([...chosenKeywords, id]);
    }

    if (!aiTagsOption) {
      setAiTagsOption({ value: "or" });
    }
  };

  const hashtagClick = (name) => {
    if (searchHashtags.indexOf(name) > -1) {
      setSearchHashtags(searchHashtags.filter((itemName) => itemName !== name));
    } else {
      setSearchHashtags([...searchHashtags, name]);
    }

    if (!hashtagsOption) {
      setHashtagsOption({ value: "or" });
    }
  };

  const contentClick = (name) => {
    if (searchContents.indexOf(name) > -1) {
      setSearchContents(searchContents.filter((itemName) => itemName !== name));
    } else {
      setSearchContents([...searchContents, name]);
    }

    if (!contentsOption) {
      setContentsOption({ value: "or" });
    }
  };

  const mentionClick = (name) => {
    if (searchMentions.indexOf(name) > -1) {
      setSearchMentions(searchMentions.filter((itemName) => itemName !== name));
    } else {
      setSearchMentions([...searchMentions, name]);
    }

    if (!mentionsOption) {
      setMentionsOption({ value: "or" });
    }
  };

  const cityClick = (name) => {
    if (selectedInfluencerCities.indexOf(name) > -1) {
      setSelectedInfluencerCities(
        selectedInfluencerCities.filter((itemName) => itemName !== name),
      );
    } else {
      setSelectedInfluencerCities([...selectedInfluencerCities, name]);
    }
  };

  const audienceCountrySet = (country) => {
    const uniqCountries = [...new Set([...selectedAudienceCountries, country])];
    setSelectedAudienceCountries(uniqCountries);
    setSearchAudienceCountry("");
  };

  const audienceCountryUnset = (label) => {
    const uniqCountries = selectedAudienceCountries.filter(
      (c) => c.label !== label.substring(0, label.length - 4),
    );
    setSelectedAudienceCountries(uniqCountries);
  };

  const matchCountry = (label) => {
    return props.influencerCountriesByProvider[
      influencerSocialProvider?.value
    ].find((c) => c.label === label);
  };

  const countrySet = (label) => {
    const newValue = matchCountry(label);
    const uniqCountries = [
      ...new Set([...selectedInfluencerCountries, newValue]),
    ];
    setSelectedInfluencerCountries(uniqCountries);

    getPopularHashtags(
      hashtagsInputValue,
      uniqCountries.map((el) => el.value),
    );
    getPopularMentions(
      mentionsInputValue,
      uniqCountries.map((el) => el.value),
    );
    setCountryDisabled("influencer", newValue, "yes");
    setInfluencerCountry(null);
  };

  const countryUnset = (label) => {
    const newValue = matchCountry(label);
    const uniqCountries = selectedInfluencerCountries.filter(
      (c) => c.label !== label,
    );
    setSelectedInfluencerCountries(uniqCountries);

    getPopularHashtags(
      hashtagsInputValue,
      uniqCountries.map((el) => el.value),
    );
    getPopularMentions(
      mentionsInputValue,
      uniqCountries.map((el) => el.value),
    );
    setCountryDisabled("influencer", newValue, "noe");
    setInfluencerCountry(null);
  };

  const includesSearchTopic = (topicText) => {
    return topicText.toLowerCase().includes(searchTopic.toLowerCase());
  };

  const includesSearchInfluencerIgCategories = (searchText) => {
    return searchText
      .toLowerCase()
      .includes(searchInfluencerIgCategories.toLocaleLowerCase());
  };

  const includesSearchInfluencerInterest = (searchText) => {
    return searchText
      .toLowerCase()
      .includes(searchInfluencerInterest.toLocaleLowerCase());
  };

  const includesSearchBrand = (brandText) => {
    return brandText.toLowerCase().includes(searchBrand.toLowerCase());
  };

  const includesSearchKeyword = (keywordText) => {
    return keywordText.toLowerCase().includes(searchKeyword.toLowerCase());
  };

  const getLastPostsWithinOptions = () => {
    const numbers = [1, 3, 6];
    return numbers.map((number) => ({
      value: number,
      label: `${number} month${number !== 1 ? "s" : ""}`,
    }));
  };

  const getMinimumErOptions = () => {
    const options = [];
    for (let i = 1; i < 41; i++) {
      options.push({
        value: `${i / 2}`,
        label: `≥ ${i / 2}%`,
      });
    }
    return options;
  };

  const getAges = (range, selectedOpposed) => {
    const optionsFrom = [13, 18, 25, 35, 46];
    const optionsTo = [17, 24, 34, 45, 65];
    const options = range === "from" ? optionsFrom : optionsTo;

    return options.map((item) => {
      return {
        value: item,
        label: item,
        isDisabled:
          range === "from" && selectedOpposed
            ? item >= selectedOpposed?.value
            : item <= selectedOpposed?.value,
      };
    });
  };

  const getSocialProviders = () => {
    const enabledSocialProviders = influencerProviders || [];
    return enabledSocialProviders.map((provider) => ({
      value: provider,
      label: intl.formatMessage({
        id: `front_${provider}`,
        defaultMessage: METHODS.capitalizeFirstLetter(provider),
      }),
    }));
  };

  const getGenders = () => {
    return [
      {
        value: "male",
        label: intl.formatMessage({
          id: "front_male",
          defaultMessage: "Male",
        }),
      },
      {
        value: "female",
        label: intl.formatMessage({
          id: "front_female",
          defaultMessage: "Female",
        }),
      },
    ];
  };

  const getMatchTypeOptions = () => {
    return [
      {
        value: "or",
        label: intl.formatMessage({
          id: "front_at_least_one_of_defined_options",
          defaultMessage: "At least one of defined options",
        }),
      },
      {
        value: "and",
        label: intl.formatMessage({
          id: "front_all_defined_options",
          defaultMessage: "All defined options",
        }),
      },
    ];
  };

  const getFullInterestObj = (subTag) => {
    return props.interests.find((interest) => interest.value == subTag);
  };

  const getFullInfluencerIgCategoriesObj = (subTag) => {
    return props.influencerIgCategories.find(
      (category) => category.value === subTag,
    );
  };

  const getFullInfluencerInterestObj = (subTag) => {
    return props.influencerInterests.find(
      (influencerInterest) => influencerInterest.value === subTag,
    );
  };

  const addHashtagOrBio = (e, eventName, labelType) => {
    let value;

    if (labelType === "hashtag") {
      value = hashtagsInputValue.replace(/,\s*$/, "").trim();
    } else if (labelType === "bio") {
      value = bioInputValue.replace(/,\s*$/, "").trim();
    } else if (labelType === "mention") {
      value = mentionsInputValue.replace(/,\s*$/, "").trim();
    } else if (labelType === "content") {
      value = searchByContentInputValue.replace(/,\s*$/, "").trim();
    }

    const bioTriggers =
      (e.key === "Enter" ||
        e.key === " " ||
        e.key === "," ||
        eventName === "blur") &&
      (labelType === "bio" || labelType === "mention");

    const hashtagsTriggers =
      (e.key === "Enter" || e.key === " " || e.key === ",") &&
      labelType === "hashtag";

    const contentTriggers =
      (e.key === "Enter" || e.key === " " || e.key === ",") &&
      labelType === "content";

    if (value.length) {
      if (bioTriggers || hashtagsTriggers || contentTriggers) {
        let pastedArray;
        if (value.indexOf(", ") > -1) {
          pastedArray = value.split(", ");
        } else if (value.indexOf(",") > -1) {
          pastedArray = value.split(",");
        } else if (value.indexOf(" ") > -1) {
          pastedArray = value.split(" ");
        } else {
          pastedArray = [value];
        }

        const connectedArrays =
          labelType === "hashtag"
            ? [...searchHashtags, ...pastedArray]
            : labelType === "bio"
              ? [...searchBioKeywords, ...pastedArray]
              : labelType === "content"
                ? [...searchContents, ...pastedArray]
                : labelType === "mention" && [
                    ...searchMentions,
                    ...pastedArray,
                  ];

        const tagPrefixes = ["#", "@"];

        connectedArrays.forEach((hashtag, index, arr) => {
          arr[index] = tagPrefixes.includes(hashtag.charAt(0))
            ? hashtag.slice(1)
            : hashtag;
        });
        const uniqueLabels = [...new Set(connectedArrays)];

        if (labelType === "hashtag") {
          setSearchHashtags(uniqueLabels);
          setHashtagsInputValue("");
          setPopularHashtags(null);
          setTimerId(setTimeout(() => getPopularHashtags(""), 500));
          if (!hashtagsOption) {
            setHashtagsOption({ value: "or" });
          }
        } else if (labelType === "mention") {
          setSearchMentions(uniqueLabels);
          setMentionsInputValue("");
          if (!mentionsOption) {
            setMentionsOption({ value: "or" });
          }
        } else if (labelType === "bio") {
          setSearchBioKeywords(uniqueLabels);
          setBioInputValue("");
          if (!searchBioKeywordsOption) {
            setSearchBioKeywordsOption({ value: "or" });
          }
        } else if (labelType === "content") {
          setSearchContents(uniqueLabels);
          setSearchByContentInputValue("");
          if (!contentsOption) {
            setContentsOption({ value: "or" });
          }
        }
      }
    }
  };

  const addEmojiToBio = (emoji) => {
    const uniqueLabels = [...new Set([...searchBioKeywords, emoji])];
    setSearchBioKeywords(uniqueLabels);
  };

  const deleteLabel = (indexToDelete, labelType) => {
    if (labelType === "hashtag") {
      const hashtagsArr = [...searchHashtags];
      hashtagsArr.splice(indexToDelete, 1);
      setSearchHashtags(hashtagsArr);
      if (!hashtagsArr.length) {
        setHashtagsOption(null);
      }
    } else if (labelType === "content") {
      const contentsArr = [...searchContents];
      contentsArr.splice(indexToDelete, 1);
      setSearchContents(contentsArr);
      if (!contentsArr.length) {
        setContentsOption(null);
      }
    } else if (labelType === "bio") {
      setSearchBioKeywords(
        searchBioKeywords.filter((b) => b !== indexToDelete),
      );
      if (!searchBioKeywords.length) {
        setSearchBioKeywordsOption(null);
      }
    } else if (labelType === "mention") {
      const searchMentionsArr = [...searchMentions];
      searchMentionsArr.splice(indexToDelete, 1);
      setSearchMentions(searchMentionsArr);
      if (!searchMentionsArr.length) {
        setMentionsOption(null);
      }
    } else if (labelType === "influencer-country") {
      setCountryDisabled(
        "influencer",
        selectedInfluencerCountries[indexToDelete],
        "no",
      );
      const selectedInfluencerCountriesArr = [...selectedInfluencerCountries];
      selectedInfluencerCountriesArr.splice(indexToDelete, 1);
      getPopularHashtags(
        hashtagsInputValue,
        selectedInfluencerCountriesArr.map((el) => el.value),
      );
      getPopularMentions(
        mentionsInputValue,
        selectedInfluencerCountriesArr.map((el) => el.value),
      );
      setSelectedInfluencerCountries(selectedInfluencerCountriesArr);
    } else if (labelType === "influencer-city") {
      const selectedInfluencerCitiesArr = [...selectedInfluencerCities];
      selectedInfluencerCitiesArr.splice(indexToDelete, 1);
      setSelectedInfluencerCities(selectedInfluencerCitiesArr);
    } else if (labelType === "audience-country") {
      setCountryDisabled(
        "audience",
        selectedAudienceCountries[indexToDelete],
        "no",
      );
      const selectedAudienceCountriesArr = [...selectedAudienceCountries];
      selectedAudienceCountriesArr.splice(indexToDelete, 1);
      setSelectedAudienceCountries(selectedAudienceCountriesArr);
    } else if (labelType === "brand") {
      const brandsArr = [...chosenBrands];
      brandsArr.splice(indexToDelete, 1);
      setChosenBrands(brandsArr);
      if (!brandsArr.length) {
        setAudienceBrandAffinityOption(null);
      }
    } else if (labelType === "keyword") {
      const keywordsArr = [...chosenKeywords];
      keywordsArr.splice(indexToDelete, 1);
      setChosenKeywords(keywordsArr);
      if (!keywordsArr.length) {
        setAiTagsOption(null);
      }
    } else if (labelType === "bio") {
    } else if (labelType === "influencerInterest") {
      const influInterestsArr = [...chosenInfluencerInterests];
      influInterestsArr.splice(indexToDelete, 1);
      setChosenInfluencerInterests(influInterestsArr);
      if (!influInterestsArr.length) {
        setAudienceInterestsOption(null);
      }
    }
  };

  useSelectWithoutLabel(audienceGender, setAudienceGender, getGenders());
  useSelectWithoutLabel(
    audienceGenderDensity,
    setAudienceGenderDensity,
    getPercentOptions(70),
  );
  useSelectWithoutLabel(
    influencerSocialProvider,
    setInfluencerSocialProvider,
    getSocialProviders(),
  );
  useSelectWithoutLabel(influencerGender, setInfluencerGender, getGenders());
  useSelectWithoutLabel(
    influencerCountry,
    setInfluencerCountry,
    props.influencerCountries,
  );
  useSelectWithoutLabel(
    audienceCountry,
    setAudienceCountry,
    props.audienceCountries,
  );
  useSelectWithoutLabel(
    contentsOption,
    setContentsOption,
    getMatchTypeOptions(),
  );
  useSelectWithoutLabel(
    hashtagsOption,
    setHashtagsOption,
    getMatchTypeOptions(),
  );
  useSelectWithoutLabel(
    mentionsOption,
    setMentionsOption,
    getMatchTypeOptions(),
  );
  useSelectWithoutLabel(
    searchBioKeywordsOption,
    setSearchBioKeywordsOption,
    getMatchTypeOptions(),
  );
  useSelectWithoutLabel(aiTagsOption, setAiTagsOption, getMatchTypeOptions());
  useSelectWithoutLabel(
    audienceBrandAffinityOption,
    setAudienceBrandAffinityOption,
    getMatchTypeOptions(),
  );
  useSelectWithoutLabel(
    audienceInterestsOption,
    setAudienceInterestsOption,
    getMatchTypeOptions(),
  );

  const filterSelectedCountriesByProvider = () => {
    return selectedInfluencerCountries.filter((selectedCountry) => {
      const foundCountry = props.influencerCountriesByProvider[
        influencerSocialProvider?.value
      ].find(
        (providerCountry) => providerCountry.value === selectedCountry.value,
      );

      if (foundCountry) {
        return selectedCountry;
      }
    });
  };

  const clearFiltersNotApplicableForTikTok = () => {
    setInfluencerCities([]);
    setAudienceCredibilityScore(null);
    setPaidPostPerformanceMin(null);
    setExcludeCreatorsFromList(false);
    setExcludeCreatorsFromDatabase(false);
    setHasContactData(false);
    setCurrentListId(null);
    setChosenInfluencerInterests([]);
    setSearchKeyword("");
    setChosenKeywords([]);
    setSearchBrand("");
    setChosenBrands([]);
    setSearchTopic("");
    setChosenTopics([]);
    setSelectedInfluencerCountries(filterSelectedCountriesByProvider());
    setSelectedInfluencerCities([]);
  };

  const clearFiltersNotApplicableForDouyin = () => {
    setInfluencerCities([]);
    setAudienceCredibilityScore(null);
    setPaidPostPerformanceMin(null);
    setExcludeCreatorsFromList(false);
    setExcludeCreatorsFromDatabase(false);
    setHasContactData(false);
    setCurrentListId(null);
    setChosenInfluencerInterests([]);
    setSearchKeyword("");
    setChosenKeywords([]);
    setSearchBrand("");
    setChosenBrands([]);
    setSearchTopic("");
    setChosenTopics([]);
    setSelectedInfluencerCountries([]);
    setSearchAudienceCountry("");
    setSelectedAudienceCountries([]);
    clearDisabledCountries();
    setSelectedInfluencerCities([]);
    setAudienceCountry(null);
  };

  const clearFiltersNotApplicableForYoutube = () => {
    setInfluencerCities([]);
    setInfluencerAgeFrom(null);
    setInfluencerAgeTo(null);
    setPaidPostPerformanceMin(null);
    setExcludeCreatorsFromList(false);
    setExcludeCreatorsFromDatabase(false);
    setHasContactData(false);
    setCurrentListId(null);
    setAudienceCredibilityScore(null);
    setSearchKeyword("");
    setChosenKeywords([]);
    setSearchBrand("");
    setChosenBrands([]);
    setSearchTopic("");
    setSelectedAudienceCountries([]);
    clearDisabledCountries();
    setSelectedInfluencerCities([]);
    setSelectedInfluencerCountries(filterSelectedCountriesByProvider());
  };

  const clearFiltersNotApplicableForInstagram = () => {
    const isAudienceDataEmpty = !audienceDataTick;
    isAudienceDataEmpty && setShowAudienceData(false);
  };

  const handleEnterPress = (e, listType) => {
    if (e.key === "Enter") {
      if (listType === "keywords" && foundKeywords.current.count === 1) {
        foundKeywords.current.count = 0;
        keywordClick(foundKeywords.current.name);
        setSearchKeyword("");
      } else if (listType === "brands" && foundBrands.current.count === 1) {
        foundBrands.current.count = 0;
        brandClick(foundBrands.current.name);
        setSearchBrand("");
      } else if (listType === "interests" && foundTopics.current.count === 1) {
        foundTopics.current.count = 0;
        topicClick(foundTopics.current.name);
        setSearchTopic("");
      } else if (
        listType === "influencerInterests" &&
        foundInfluencerInterests.current.count === 1
      ) {
        foundInfluencerInterests.current.count = 0;
        influencerInterestClick(foundInfluencerInterests.current.name);
        setSearchInfluencerInterest("");
      } else if (listType === "cities" && influencerCities.length === 1) {
        cityClick(influencerCities[0]);
        setCitiesInputValue("");
        getinfluencerCities("");
      }
    }
  };

  const handleSocialUsernameInputEnterPress = (e) => {
    if (e.key === "Enter" && influencerSocialUsername) {
      applyFiltersBtnRef.current.click();
    }
  };

  const handlePasteInfluencer = (e) => {
    e.preventDefault();
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData("text/plain");

    const pastedObjects = pastedData.trim().split(/\s+/)[0];

    if (isValidUrl(pastedObjects)) {
      setIsIncorrectProviderError(false);

      const generatedCreatorFromString =
        generateCreatorFromString(pastedObjects);

      const foundSocialProfile = generatedCreatorFromString.socialProfiles.find(
        (socialProfile) =>
          socialProfile.socialProvider ===
          replaceOldTikTokProviderName(influencerSocialProvider.value),
      );

      if (foundSocialProfile) {
        setInfluencerSocialUsername(
          generateCreatorFromString(pastedObjects).username,
        );
      } else {
        // Raw link
        setInfluencerSocialUsername(pastedObjects.trim());
        setIsIncorrectProviderError(true);
      }
    } else {
      setInfluencerSocialUsername(pastedObjects.trim());
    }
  };

  const handleOnSocialProviderChange = (newValue) => {
    if (!newValue) return;

    if (newValue.value === "tik_tok") {
      clearFiltersNotApplicableForTikTok();
    } else if (newValue.value === "douyin") {
      clearFiltersNotApplicableForDouyin();
    } else if (newValue.value === "youtube") {
      clearFiltersNotApplicableForYoutube();
    } else if (newValue.value === "instagram") {
      clearFiltersNotApplicableForInstagram();
    }
  };

  useEffect(() => {
    // if state is different in reducer set global val
    if (
      !influencerSocialProvider?.default &&
      props.listSocialProvider !== influencerSocialProvider?.value
    ) {
      handleOnSocialProviderChange(influencerSocialProvider);
      props.setListSocialProvider(influencerSocialProvider?.value);
      // clear selected countires
      setSelectedAudienceCountries([]);
      clearDisabledCountries();
      setSelectedInfluencerCountries([]);
    }
    // This is used to toggle audience data section visibility
    if (influencerSocialProvider?.value !== "instagram") {
      setShowAudienceData(true);
      setInfluencerCities([]);
      setSelectedInfluencerCities([]);
      setChosenInfluencerIgCategories([]);
    }
    if (influencerSocialProvider?.value !== "youtube") {
      setChosenInfluencerInterests([]);
    }
  }, [influencerSocialProvider]);

  useEffect(() => {
    if (selectedInfluencerCountries && selectedInfluencerCountries.length > 0)
      getinfluencerCities();
    else {
      setInfluencerCities([]);
    }
    setCitiesInputValue("");
  }, [selectedInfluencerCountries]);

  const getPopularHashtags = async (value, uniqCountries) => {
    const params = new URLSearchParams({
      searchString: value || "",
      provider: influencerSocialProvider?.value,
      countries:
        uniqCountries || selectedInfluencerCountries.map((el) => el.value),
    }).toString();

    const {
      data: { content: resPopularHashtags },
    } = await axios.get(CONSTS.getPopularHashtags + params);
    setPopularHashtags(resPopularHashtags);
  };

  const getPopularMentions = async (value, uniqCountries) => {
    const params = new URLSearchParams({
      searchString: value || "",
      provider: influencerSocialProvider?.value,
      countries:
        uniqCountries || selectedInfluencerCountries.map((el) => el.value),
    }).toString();

    const {
      data: { content: resPopularMentions },
    } = await axios.get(CONSTS.getPopularMentions + params);
    setPopularMentions(resPopularMentions);
  };

  const getinfluencerCities = async (value, uniqCountries) => {
    if (influencerSocialProvider?.value === "instagram") {
      const params = new URLSearchParams({
        searchString: value || "",
        provider: influencerSocialProvider?.value,
        countries:
          uniqCountries || selectedInfluencerCountries.map((el) => el.value),
      }).toString();

      const {
        data: { content: resinfluencerCities },
      } = await axios.get(CONSTS.getinfluencerCities + params);
      setInfluencerCities(resinfluencerCities);
    }
  };

  useEffect(() => {
    // This setTimeout is hack to fire getPopularHashtags only once on influencer list's search tab.
    if (timerId) {
      clearTimeout(timerId);
    }

    setTimerId(setTimeout(getPopularHashtags, 500));
    setTimerId(setTimeout(getPopularMentions, 500));
  }, [influencerSocialProvider]);

  const handleHashtagsInputValueChange = (e) => {
    const { value } = e.target;
    setHashtagsInputValue(value);

    if (timerId) {
      clearTimeout(timerId);
    }
    setTimerId(setTimeout(() => getPopularHashtags(value), 500));
  };

  const handleSearchByContentValueChange = (e) => {
    const { value } = e.target;
    setSearchByContentInputValue(value);

    if (timerId) {
      clearTimeout(timerId);
    }
    setTimerId(
      setTimeout(() => {
        getPopularHashtags(value);
      }, 500),
    );
  };

  const handleCitiesInputValueChange = (cityVal) => {
    setCitiesInputValue(cityVal);
    if (timerId) {
      clearTimeout(timerId);
    }
    setTimerId(setTimeout(() => getinfluencerCities(cityVal), 500));
    foundCities.current.count = 0;
  };

  const clearSecondColumn = () => {
    setLastPostsWithin(null);

    setSearchContents([]);
    setSearchByContentInputValue("");

    setSearchHashtags([]);
    setHashtagsInputValue([]);

    setSearchMentions([]);
    setMentionsInputValue([]);

    setSearchBioKeywords([]);
    setBioInputValue("");

    setChosenKeywords([]);
    setSearchKeyword("");
  };

  useEffect(() => {
    setInfluencerSocialUsername("");
    setIsIncorrectProviderError(false);
  }, [influencerSocialProvider.value]);

  useEffect(() => {
    if (influencerSocialUsername.length === 0) {
      setIsIncorrectProviderError(false);
    }
  }, [influencerSocialUsername]);

  const emojiPickerWrapperRef = useRef();

  useOnClickOutside(emojiPickerWrapperRef, () => {
    setShowEmojiPicker(false);
  });

  const isFacebookProvider = () => {
    return influencerSocialProvider?.value === CONSTS.socialProvider.facebook;
  };

  return (
    <div className="audience-search">
      <div className="audience-search__content">
        <div className="audience-search__column">
          <div className="audience-search__title">
            <div className="audience-search__title__text">
              <IDHFormattedMessage
                id="front_influencer_metrics"
                defaultMessage="Influencer metrics"
              />
              <Chevron
                expanded={showInfluencerMetrics}
                setExpanded={setShowInfluencerMetrics}
              />
            </div>
            <SelectedFiltersCounter amount={influencerMetricsCount} />
          </div>

          {showInfluencerMetrics && (
            <>
              <div
                className="audience-search__field"
                data-filter-name="social_provider"
              >
                <div className="idh-label idh-label--with-tooltip">
                  <IDHFormattedMessage
                    id="front_social_platform"
                    defaultMessage="Social platform"
                  />
                  <MaterialTooltip
                    id="front_influencer_social_provider_tooltip_1"
                    content={
                      <>
                        <span className="audience-search__tooltip--white-bold">
                          <IDHFormattedMessage
                            id="front_influencer_social_provider_tooltip_1"
                            defaultMessage="Here you can select the platform on which you want to search for creators."
                          />
                        </span>
                        <br />
                        <br />
                        <div>
                          <span className="audience-search__tooltip--bold">
                            <IDHFormattedMessage
                              id="front_influencer_social_provider_tooltip_2"
                              defaultMessage="Important: "
                            />
                          </span>
                          <IDHFormattedMessage
                            id="front_influencer_social_provider_tooltip_3"
                            defaultMessage="Once you create the list, you will not be able to change the selected platform for it."
                          />
                        </div>
                      </>
                    }
                  />
                </div>
                <CustomSelect
                  placeholder={intl.formatMessage({
                    id: "front_select_social_platform",
                    defaultMessage: "Select social platform",
                  })}
                  value={influencerSocialProvider}
                  options={getSocialProviders()}
                  onChange={(newValue) => setInfluencerSocialProvider(newValue)}
                  isDisabled={params.listId}
                  dataAttr="social-platform"
                />
              </div>

              <div className="audience-search__field">
                <div className="idh-label idh-label--with-tooltip">
                  <IDHFormattedMessage
                    id="front_search_by_social_login_topic"
                    defaultMessage="Search by social login"
                  />
                  <MaterialTooltip
                    id="front_influencer_social_login_tooltip_1"
                    content={
                      <>
                        <span className="audience-search__tooltip--white-bold">
                          <IDHFormattedMessage
                            id="front_influencer_social_login_tooltip_1"
                            defaultMessage="Here you can find a specific creator by entering his nickname from the selected platform."
                          />
                        </span>
                        <br />
                        <br />
                        <div>
                          <span className="audience-search__tooltip--bold">
                            <IDHFormattedMessage
                              id="front_influencer_social_login_tooltip_2"
                              defaultMessage="Important: "
                            />
                          </span>
                          <IDHFormattedMessage
                            id="front_influencer_social_login_tooltip_3"
                            defaultMessage="When searching for a creator by entering its nickname from a given platform, you cannot use the other filters."
                          />
                        </div>
                        <br />
                        <br />
                        <div>
                          <span
                            className="audience-search__tooltip--bold"
                            style={{ marginRight: "4px" }}
                          >
                            <IDHFormattedMessage
                              id="front_influencer_social_login_tooltip_4"
                              defaultMessage="Tip: "
                            />
                          </span>
                          <IDHFormattedMessage
                            id="front_influencer_social_login_tooltip_5"
                            defaultMessage='If you want to search for given username exactly, put it in quotes (")'
                          />
                        </div>
                      </>
                    }
                  />
                </div>
                <div
                  className={`audience-search__input ${isIncorrectProviderError && "audience-search__input--error"}`}
                >
                  <input
                    placeholder={intl.formatMessage({
                      id: "front_search_by_social_login_placeholder",
                      defaultMessage:
                        "Check a specific influencer by pasting a social login",
                    })}
                    value={influencerSocialUsername}
                    onChange={(e) =>
                      setInfluencerSocialUsername(e.target.value)
                    }
                    onPaste={handlePasteInfluencer}
                    onKeyPress={(e) => handleSocialUsernameInputEnterPress(e)}
                    data-cdt-input="influencer-username"
                  />
                  {isIncorrectProviderError && (
                    <div className="audience-search__input__error-message">
                      <ErrorIcon />
                      <IDHFormattedMessage
                        id="front_incorrect_provider_error"
                        defaultMessage="Incorrect link has been entered"
                      />
                    </div>
                  )}
                </div>
              </div>

              {influencerSocialProvider?.value !== "snapchat" &&
                !isFacebookProvider() && (
                  <div className="audience-search__field">
                    <div className="idh-label idh-label--with-tooltip">
                      <IDHFormattedMessage
                        id="front_gender"
                        defaultMessage="Gender"
                      />
                      <MaterialTooltip
                        id="front_influencer_gender_tooltip"
                        content={
                          <IDHFormattedMessage
                            id="front_influencer_gender_tooltip"
                            defaultMessage="Here you can specify what influencer gender(s) you are searching for."
                          />
                        }
                      />
                    </div>
                    <CustomSelect
                      placeholder={intl.formatMessage({
                        id: "front_select_gender",
                        defaultMessage: "Both",
                      })}
                      isClearable
                      value={influencerGender}
                      options={getGenders()}
                      onChange={(newValue) => setInfluencerGender(newValue)}
                      isDisabled={influencerSocialUsername.length}
                      dataAttr="influencer-gender"
                    />
                  </div>
                )}

              {influencerSocialProvider?.value !== "youtube" &&
                influencerSocialProvider?.value !== "snapchat" &&
                !isFacebookProvider() && (
                  <div className="audience-search__field">
                    <div className="idh-label idh-label--with-tooltip">
                      <IDHFormattedMessage
                        id="front_age"
                        defaultMessage="Age"
                      />
                      <MaterialTooltip
                        id="front_influencer_age_tooltip"
                        content={
                          <IDHFormattedMessage
                            id="front_influencer_age_tooltip"
                            defaultMessage="Here, you can specify the influencer’s age.  "
                          />
                        }
                      />
                    </div>
                    <div className="audience-search__field-columns">
                      <div className="audience-search__field-col-1">
                        <div className="input-wrapper">
                          <div className="input-wrapper__range">
                            <IDHFormattedMessage
                              id="front_from"
                              defaultMessage="From"
                            />
                          </div>
                          <div className="input-wrapper__item">
                            <CustomSelect
                              placeholder={intl.formatMessage({
                                id: "front_select_age_from",
                                defaultMessage: "Select",
                              })}
                              isClearable
                              value={influencerAgeFrom}
                              options={getAges("from", influencerAgeTo)}
                              onChange={(newValue) =>
                                setInfluencerAgeFrom(newValue)
                              }
                              isDisabled={influencerSocialUsername.length}
                              dataAttr="influencer-age-from"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="audience-search__field-col-1">
                        <div className="input-wrapper">
                          <div className="input-wrapper__range">
                            <IDHFormattedMessage
                              id="front_to"
                              defaultMessage="To"
                            />
                          </div>
                          <div className="input-wrapper__item">
                            <CustomSelect
                              placeholder={intl.formatMessage({
                                id: "front_select_age_to",
                                defaultMessage: "Select",
                              })}
                              isClearable
                              value={influencerAgeTo}
                              options={getAges("to", influencerAgeFrom)}
                              onChange={(newValue) =>
                                setInfluencerAgeTo(newValue)
                              }
                              isDisabled={influencerSocialUsername.length}
                              dataAttr="influencer-age-to"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

              {influencerSocialProvider?.value !== "douyin" && (
                <>
                  <div className="audience-search__input">
                    <div className="idh-label idh-label--with-tooltip">
                      <IDHFormattedMessage
                        id="front_influencer_country"
                        defaultMessage="Influencer country"
                      />
                      <MaterialTooltip
                        id="front_influencer_country_tooltip"
                        content={
                          <IDHFormattedMessage
                            id="front_influencer_country_tooltip"
                            defaultMessage="Here, you can specify the country of the influencer."
                          />
                        }
                      />
                    </div>
                    <input
                      placeholder={intl.formatMessage({
                        id: "front_enter_country_name_placeholder",
                        defaultMessage: "Enter country name",
                      })}
                      value={countriesInputValue}
                      onChange={(e) => setCountriesInputValue(e.target.value)}
                      data-cdt-input="countries"
                      disabled={influencerSocialUsername.length}
                    />
                  </div>
                  <div className="audience-search__input">
                    <SelectTags
                      feed={props.influencerCountriesByProvider[
                        influencerSocialProvider?.value
                      ]
                        ?.filter((c) =>
                          c.label
                            .toLocaleLowerCase()
                            .includes(countriesInputValue.toLowerCase()),
                        )
                        .map((c) => c.label)}
                      values={selectedInfluencerCountries?.map((c) => c.label)}
                      setValue={countrySet}
                      unsetValue={countryUnset}
                      clearValues={() => setSelectedInfluencerCountries([])}
                      disabled={influencerSocialUsername.length}
                    />
                  </div>
                </>
              )}

              {influencerSocialProvider?.value === "instagram" &&
                !isFacebookProvider() && (
                  <>
                    <div className="audience-search__field">
                      <div className="audience-search__input">
                        <div className="idh-label idh-label--with-tooltip">
                          <IDHFormattedMessage
                            id="front_influencer_city"
                            defaultMessage="Creator city"
                          />
                          <MaterialTooltip
                            id="front_influencer_city_tooltip"
                            content={
                              <IDHFormattedMessage
                                id="front_influencer_city_tooltip"
                                defaultMessage="Here you can specify the city of the creator."
                              />
                            }
                          />
                        </div>
                        <MaterialTooltip
                          id="front_influencer_city_input_tooltip"
                          content={
                            <IDHFormattedMessage
                              id="front_influencer_city_input_tooltip"
                              defaultMessage="Before selecting a Creator city, you must select a Creator country."
                            />
                          }
                          contentHidden={selectedInfluencerCountries.length > 0}
                        >
                          <input
                            placeholder={intl.formatMessage({
                              id: "front_influencer_city_placeholder",
                              defaultMessage: "Enter city name",
                            })}
                            value={citiesInputValue}
                            onKeyPress={(e) => handleEnterPress(e, "cities")}
                            onChange={(e) =>
                              handleCitiesInputValueChange(e.target.value)
                            }
                            disabled={
                              !selectedInfluencerCountries.length > 0 ||
                              influencerSocialUsername.length
                            }
                            data-cdt-input="cities"
                          />
                        </MaterialTooltip>
                      </div>
                    </div>
                    <div className="audience-search__field">
                      <SelectTags
                        feed={influencerCities}
                        values={selectedInfluencerCities}
                        setValue={cityClick}
                        unsetValue={cityClick}
                        clearValues={() => setSelectedInfluencerCities([])}
                        disabled={influencerSocialUsername.length}
                      />
                    </div>
                  </>
                )}

              {influencerSocialProvider?.value === "instagram" &&
                !isFacebookProvider() && (
                  <div className="audience-search-content-wrapper">
                    <div className="cdt-search-input">
                      <div className="idh-label idh-label--with-tooltip">
                        <IDHFormattedMessage
                          id="front_search_category"
                          defaultMessage="Influencer categories"
                        />
                        <MaterialTooltip
                          id="front_search_category_tooltip"
                          content={
                            <IDHFormattedMessage
                              id="front_search_category_tooltip"
                              defaultMessage="Search by influencer category."
                            />
                          }
                        />
                      </div>
                      <input
                        placeholder={intl.formatMessage({
                          id: "front_search_category_placeholder",
                          defaultMessage: "Search category...",
                        })}
                        value={searchInfluencerIgCategories}
                        onKeyPress={(e) =>
                          handleEnterPress(e, "influencerIgCategories")
                        }
                        onChange={(e) => {
                          setSearchInfluencerIgCategories(e.target.value);
                          foundInfluencerIgCategories.current.count = 0;
                        }}
                        disabled={influencerSocialUsername.length}
                        data-cdt-input="creator-interests"
                      />
                    </div>

                    <SelectTags
                      feed={props.influencerIgCategories.filter((item) =>
                        includesSearchInfluencerIgCategories(
                          intl.formatMessage({
                            id: item.name,
                            defaultMessage: item.value,
                          }),
                        ),
                      )}
                      values={chosenInfluencerIgCategories}
                      setValue={(item) => {
                        foundInfluencerIgCategories.current.count = 0;
                        influencerIgCategoryClick(item.value);
                      }}
                      unsetValue={(item) => {
                        foundInfluencerIgCategories.current.count = 0;
                        influencerIgCategoryClick(item);
                      }}
                      clearValues={() => setChosenInfluencerIgCategories([])}
                      translative
                      getFullObject={getFullInfluencerIgCategoriesObj}
                      disabled={influencerSocialUsername.length}
                    />
                  </div>
                )}

              {influencerSocialProvider?.value === "youtube" &&
                !isFacebookProvider() && (
                  <div className="audience-search-content-wrapper">
                    <div className="cdt-search-input">
                      <div className="idh-label idh-label--with-tooltip">
                        <IDHFormattedMessage
                          id="front_search_topic"
                          defaultMessage="Search topic"
                        />
                        <MaterialTooltip
                          id="front_audience_interests_tooltip_bottom"
                          content={
                            <>
                              <IDHFormattedMessage
                                id="front_influencer_interests_tooltip_top"
                                defaultMessage="Interests are determined by analyzing captions, @mentions, #hashtags, and locations used by the creator in the latest 150 posts (or more, if they all have been published within the last 2 months) and compared with Instagram users on average. Additionally, the following factors are taken into consideration:"
                              />
                              <br />
                              <br />
                              <div className="tooltip-content-wrapper">
                                <TickIcon className="tooltip-tick" />
                                <IDHFormattedMessage
                                  id="front_audience_interests_tooltip_bottom_a"
                                  defaultMessage={`Brand Affinity aspect - For example, if a person mentioned "McDonald’s", "KFC", this indicates interest in food. `}
                                />
                              </div>
                              <div className="tooltip-content-wrapper">
                                <TickIcon className="tooltip-tick" />
                                <IDHFormattedMessage
                                  id="front_audience_interests_tooltip_bottom_b"
                                  defaultMessage={`Other food-related keywords, such as "food", "cakes" etc.`}
                                />
                              </div>
                            </>
                          }
                        />
                      </div>
                      <input
                        placeholder={intl.formatMessage({
                          id: "front_search_topic_placeholder",
                          defaultMessage: "Search topic...",
                        })}
                        value={searchInfluencerInterest}
                        onKeyPress={(e) =>
                          handleEnterPress(e, "influencerInterests")
                        }
                        onChange={(e) => {
                          setSearchInfluencerInterest(e.target.value);
                          foundInfluencerInterests.current.count = 0;
                        }}
                        disabled={influencerSocialUsername.length}
                        data-cdt-input="creator-interests"
                      />
                    </div>

                    <SelectTags
                      feed={props.influencerInterests.filter((item) =>
                        includesSearchInfluencerInterest(
                          intl.formatMessage({
                            id: item.name,
                            defaultMessage: item.value,
                          }),
                        ),
                      )}
                      values={chosenInfluencerInterests}
                      setValue={(item) => {
                        foundInfluencerInterests.current.count = 0;
                        influencerInterestClick(item.value);
                      }}
                      unsetValue={(item) => {
                        foundInfluencerInterests.current.count = 0;
                        influencerInterestClick(item);
                      }}
                      clearValues={() => setChosenInfluencerInterests([])}
                      translative
                      getFullObject={getFullInfluencerInterestObj}
                      disabled={influencerSocialUsername.length}
                    />
                  </div>
                )}

              {influencerSocialProvider?.value !== "snapchat" &&
                !isFacebookProvider() && (
                  <div className="audience-search__field">
                    <div className="idh-label idh-label--with-tooltip">
                      <IDHFormattedMessage
                        id="front_minimum_er"
                        defaultMessage="Minimum ER"
                      />
                      <MaterialTooltip
                        id="front_minimum_er_tooltip"
                        content={
                          <IDHFormattedMessage
                            id="front_minimum_er_tooltip"
                            defaultMessage="Discover creators by their ER (engagement rate). Select its minimal value. Avg. ER is average likes of their recent posts divided by their followers."
                          />
                        }
                      />
                    </div>
                    <CustomSelect
                      placeholder={intl.formatMessage({
                        id: "front_select_minimum_er",
                        defaultMessage: "Select",
                      })}
                      isClearable
                      value={minimumEr}
                      options={getMinimumErOptions()}
                      onChange={(newValue) => setMinimumEr(newValue)}
                      disabled={influencerSocialUsername.length}
                      dataAttr="min-er"
                    />
                  </div>
                )}
              <FollowersCountRange
                influencerFollowersFrom={influencerFollowersFrom}
                setInfluencerFollowersFrom={setInfluencerFollowersFrom}
                influencerFollowersTo={influencerFollowersTo}
                setInfluencerFollowersTo={setInfluencerFollowersTo}
                influencerSocialUsername={influencerSocialUsername}
                paidPostPerformanceMin={paidPostPerformanceMin}
                setPaidPostPerformanceMin={setPaidPostPerformanceMin}
                audienceCredibilityScore={audienceCredibilityScore}
                setAudienceCredibilityScore={setAudienceCredibilityScore}
                influencerSocialProvider={influencerSocialProvider}
              />
            </>
          )}

          <CustomCheckbox
            id="show-only-creators-with-contact-data-checkbox"
            checked={hasContactData}
            onChange={() => {
              const newValue = !hasContactData;
              setHasContactData(newValue);
            }}
            label={
              <IDHFormattedMessage
                id="front_show_only_results_with_contact_data"
                defaultMessage="Show only results with contact data"
              />
            }
          />

          <CustomCheckbox
            id="exclude-creators-from-list-checkbox"
            checked={excludeCreatorsFromList}
            onChange={() => {
              const newValue = !excludeCreatorsFromList;
              setExcludeCreatorsFromList(newValue);
              if (newValue === true && props.influencersInListInfo?.listId) {
                setCurrentListId(props.influencersInListInfo?.listId);
              } else {
                setCurrentListId(null);
              }
            }}
            label={
              <IDHFormattedMessage
                id="front_exclude_creators_already_added_to_the_list"
                defaultMessage="Exclude creators already added to the list"
              />
            }
          />

          {/* <CustomCheckbox
            id="exclude-creators-from-database-checkbox"
            checked={excludeCreatorsFromDatabase}
            onChange={() => setExcludeCreatorsFromDatabase((v) => !v)}
            label={
              <IDHFormattedMessage
                id="front_exclude_creators_already_added_to_the_creator_database"
                defaultMessage="Exclude creators already added to the Creator Database"
              />
            }
          /> */}
        </div>

        {/* second column */}

        <div className="audience-search__column">
          {!isFacebookProvider() && (
            <div className="audience-search__title">
              <div className="audience-search__title__text">
                <IDHFormattedMessage
                  id="front_search_by_content"
                  defaultMessage="Search by Content"
                />
                <Chevron
                  expanded={showSearchByContent}
                  setExpanded={setShowSearchByContent}
                />
              </div>
            </div>
          )}

          {showSearchByContent && !isFacebookProvider() && (
            <>
              {!advancedSearch && (
                <>
                  <div className="audience-search__input">
                    <div className="idh-label">
                      <IDHFormattedMessage
                        id="front_discover_influencers_who"
                        defaultMessage="Discover influencers who"
                      />
                      :
                      <ul>
                        <li>
                          <IDHFormattedMessage
                            id="front_use_specific_hashtags"
                            defaultMessage="use specific hashtags in their posts"
                          />
                        </li>
                        <li>
                          <IDHFormattedMessage
                            id="front_mention_specific_accounts_including_brands"
                            defaultMessage="mention specific accounts (including brands that have been tagged) in their posts"
                          />
                        </li>
                        <li>
                          <IDHFormattedMessage
                            id="front_based_on_specific_physical_objects"
                            defaultMessage="based on specific physical objects that can be seen in their posts (i.e. baby, garden)"
                          />
                        </li>
                        <li>
                          <IDHFormattedMessage
                            id="front_have_specific_keywords_in_their_bio"
                            defaultMessage="have specific keywords in their Bio"
                          />
                        </li>
                      </ul>
                    </div>

                    <div className="audience-search__input-wrapper">
                      <input
                        placeholder={intl.formatMessage({
                          id: "front_enter_keywords_or_hashtags",
                          defaultMessage: "Enter keywords or hashtags",
                        })}
                        value={searchByContentInputValue}
                        onChange={handleSearchByContentValueChange}
                        onBlur={(e) => addHashtagOrBio(e, "blur", "content")}
                        onKeyUp={(e) => addHashtagOrBio(e, "keyup", "content")}
                        disabled={influencerSocialUsername.length}
                        data-cdt-input="search-by-content"
                      />

                      <CriteriaOptionSelector
                        value={contentsOption?.value}
                        setValue={setContentsOption}
                      />
                    </div>
                  </div>

                  <SelectTags
                    feed={popularHashtags}
                    values={searchContents}
                    setValue={contentClick}
                    unsetValue={contentClick}
                    clearValues={clearSecondColumn}
                    disabled={influencerSocialUsername.length}
                  />
                </>
              )}

              <div className="audience-search__switch">
                <CustomSwitch
                  checked={advancedSearch}
                  onChange={() => {
                    setAdvancedSearch((v) => !v);
                    clearSecondColumn();
                  }}
                  dataAttributes={{
                    "data-qa": "advanced-search-switch",
                  }}
                />
                <span>
                  <div className="audience-search__switch-text">
                    <IDHFormattedMessage
                      id="front_advanced_search"
                      defaultMessage="Advanced Search"
                    />
                  </div>
                  <div className="audience-search__switch-subtext">
                    <IDHFormattedMessage
                      id="front_allows_you_to_search_by_specific_attributes_separately"
                      defaultMessage="Allows you to search by specific attributes separately."
                    />
                  </div>
                </span>
              </div>

              {advancedSearch && (
                <>
                  <div className="audience-search__title">
                    <div className="audience-search__title__text">
                      <IDHFormattedMessage
                        id="front_hashtags"
                        defaultMessage="Hashtags"
                      />
                      <Chevron
                        expanded={showHashtags}
                        setExpanded={setShowHashtags}
                      />
                    </div>
                    <SelectedFiltersCounter amount={hashtagsCount} />
                  </div>

                  {showHashtags && (
                    <>
                      <div className="audience-search__input">
                        <div className="idh-label idh-label--with-tooltip">
                          <IDHFormattedMessage
                            id="front_hashtag_topic"
                            defaultMessage="Hashtags"
                          />
                          <MaterialTooltip
                            id="front_hashtags_topic_tooltip"
                            content={
                              <IDHFormattedMessage
                                id="front_hashtags_topic_tooltip"
                                defaultMessage="Here you can specify what hashtags should most often be added to the caption of the content published by the Influencers you are looking for. Search results will return only those accounts that have those hashtags found in the captions."
                              />
                            }
                          />
                        </div>

                        <div className="audience-search__input-wrapper">
                          <input
                            placeholder={intl.formatMessage({
                              id: "front_enter_hashtags_placeholder",
                              defaultMessage:
                                "Enter hashtags separate by comas",
                            })}
                            value={hashtagsInputValue}
                            onChange={handleHashtagsInputValueChange}
                            onBlur={(e) =>
                              addHashtagOrBio(e, "blur", "hashtag")
                            }
                            onKeyUp={(e) =>
                              addHashtagOrBio(e, "keyup", "hashtag")
                            }
                            disabled={influencerSocialUsername.length}
                            data-cdt-input="hashtags"
                          />

                          <CriteriaOptionSelector
                            value={hashtagsOption?.value}
                            setValue={setHashtagsOption}
                          />
                        </div>
                      </div>

                      <SelectTags
                        feed={popularHashtags}
                        values={searchHashtags}
                        setValue={hashtagClick}
                        unsetValue={hashtagClick}
                        clearValues={() => setSearchHashtags([])}
                        disabled={influencerSocialUsername.length}
                      />
                    </>
                  )}

                  {influencerSocialProvider?.value !== "snapchat" && (
                    <div className="audience-search__title">
                      <div className="audience-search__title__text">
                        <IDHFormattedMessage
                          id="front_mentions"
                          defaultMessage="Mentions"
                        />
                        <Chevron
                          expanded={showMentions}
                          setExpanded={setShowMentions}
                        />
                      </div>
                      <SelectedFiltersCounter amount={mentionsCount} />
                    </div>
                  )}

                  {showMentions &&
                    influencerSocialProvider?.value !== "snapchat" && (
                      <>
                        <div className="audience-search__input">
                          <div className="idh-label idh-label--with-tooltip">
                            <IDHFormattedMessage
                              id="front_mentions_topic"
                              defaultMessage="Mentions"
                            />
                            <MaterialTooltip
                              id="front_mentions_topic_tooltip"
                              content={
                                <IDHFormattedMessage
                                  id="front_mentions_topic_tooltip"
                                  defaultMessage="Here you can specify what mentions should..."
                                />
                              }
                            />
                          </div>

                          <div className="audience-search__input-wrapper">
                            <input
                              placeholder={intl.formatMessage({
                                id: "front_enter_mentions_placeholder",
                                defaultMessage:
                                  "Enter mentions separated by comas",
                              })}
                              value={mentionsInputValue}
                              onChange={(e) =>
                                setMentionsInputValue(e.target.value)
                              }
                              onKeyUp={(e) =>
                                addHashtagOrBio(e, "keyup", "mention")
                              }
                              onBlur={(e) => {
                                addHashtagOrBio(e, "blur", "mention");
                              }}
                              disabled={influencerSocialUsername.length}
                              data-cdt-input="mention"
                            />

                            <CriteriaOptionSelector
                              value={mentionsOption?.value}
                              setValue={setMentionsOption}
                            />
                          </div>
                        </div>

                        {searchMentions.length > 0 && (
                          <SelectedLabels
                            values={searchMentions}
                            unsetValue={(name) => mentionClick(name)}
                            clearValues={() => setSearchMentions([])}
                            disabled={influencerSocialUsername.length}
                          />
                        )}
                      </>
                    )}

                  <div className="audience-search__title">
                    <div className="audience-search__title__text">
                      <IDHFormattedMessage
                        id="front_search_by_bio"
                        defaultMessage="Search by bio"
                      />
                      <Chevron
                        expanded={showSearchInBio}
                        setExpanded={setShowSearchInBio}
                      />
                    </div>
                    <SelectedFiltersCounter amount={searchBioKeywords.length} />
                  </div>

                  {showSearchInBio && (
                    <>
                      <div className="audience-search__input">
                        <div className="idh-label idh-label--with-tooltip">
                          <IDHFormattedMessage
                            id="front_search_in_bio_topic"
                            defaultMessage="Search in Bio"
                          />
                          <MaterialTooltip
                            id="front_search_in_bio_topic_tooltip"
                            content={
                              <IDHFormattedMessage
                                id="front_search_in_bio_topic_tooltip"
                                defaultMessage="Find Creators with specific keywords used in Bio sections."
                              />
                            }
                          />
                        </div>

                        <div className="audience-search__input-wrapper audience-search__input-wrapper--with-emoji">
                          <input
                            placeholder={intl.formatMessage({
                              id: "front_enter_keywords_placeholder",
                              defaultMessage: "Enter keywords",
                            })}
                            value={bioInputValue}
                            onChange={(e) => setBioInputValue(e.target.value)}
                            onFocus={() => setBioInputFocused(true)}
                            onKeyUp={(e) => addHashtagOrBio(e, "keyup", "bio")}
                            onBlur={(e) => {
                              addHashtagOrBio(e, "blur", "bio");
                              setBioInputFocused(false);
                            }}
                            disabled={influencerSocialUsername.length}
                            data-cdt-input="bio"
                            className={
                              showEmojiPicker && "bio-input-with-emoji-picker"
                            }
                          />

                          <div
                            className={classNames("emoji-button", {
                              "emoji-button--active":
                                bioInputFocused || showEmojiPicker,
                            })}
                          >
                            <div
                              onClick={() => {
                                setShowEmojiPicker((prev) => !prev);
                              }}
                            >
                              <Emoji unified="1f642" size="20" />
                            </div>
                          </div>

                          {showEmojiPicker && (
                            <div
                              className="emoji-picker-wrapper"
                              ref={emojiPickerWrapperRef}
                            >
                              <EmojiPicker
                                onEmojiClick={(emojiData, event) => {
                                  addEmojiToBio(emojiData.emoji);
                                  setShowEmojiPicker(false);
                                }}
                                previewConfig={{
                                  defaultEmoji: "1f446",
                                  defaultCaption: "Pick an emoji...",
                                  showPreview: true,
                                }}
                              />
                            </div>
                          )}

                          <CriteriaOptionSelector
                            value={searchBioKeywordsOption?.value}
                            setValue={setSearchBioKeywordsOption}
                          />
                        </div>
                      </div>

                      {searchBioKeywords.length > 0 && (
                        <SelectedLabels
                          values={searchBioKeywords}
                          unsetValue={(index) => deleteLabel(index, "bio")}
                          clearValues={() => setSearchBioKeywords([])}
                          disabled={influencerSocialUsername.length}
                        />
                      )}
                    </>
                  )}

                  {influencerSocialProvider?.value === "instagram" && (
                    <div className="audience-search__title">
                      <div className="audience-search__title__text">
                        <IDHFormattedMessage
                          id="front_ai_image_recognition"
                          defaultMessage="AI image recognition"
                        />
                        <Chevron
                          expanded={showAiImageRecognition}
                          setExpanded={setShowAiImageRecognition}
                        />
                      </div>
                      <SelectedFiltersCounter amount={chosenKeywords.length} />
                    </div>
                  )}

                  {showAiImageRecognition &&
                    influencerSocialProvider?.value === "instagram" && (
                      <div className="audience-search-content-wrapper">
                        <div className="cdt-search-input">
                          <div className="idh-label idh-label--with-tooltip">
                            <IDHFormattedMessage
                              id="front_keywords_topic"
                              defaultMessage="Keywords"
                            />
                            <MaterialTooltip
                              id="front_keywords_topic_tooltip"
                              content={
                                <IDHFormattedMessage
                                  id="front_keywords_topic_tooltip"
                                  defaultMessage="Here you can define the keywords that will best describe the content published by the influencers you are looking for. Every piece of content published by Influencers present in our database are tagged with keywords automatically based on image recognition."
                                />
                              }
                            />
                          </div>

                          <div className="audience-search__input-wrapper">
                            <input
                              placeholder={intl.formatMessage({
                                id: "front_keywords_topic_placeholder",
                                defaultMessage: "Search keywords...",
                              })}
                              value={searchKeyword}
                              onKeyPress={(e) =>
                                handleEnterPress(e, "keywords")
                              }
                              onChange={(e) => {
                                setSearchKeyword(
                                  e.target.value.charAt(0) === "#"
                                    ? e.target.value.slice(1)
                                    : e.target.value,
                                );
                                foundKeywords.current.count = 0;
                              }}
                              disabled={influencerSocialUsername.length}
                              data-cdt-input="keywords"
                            />

                            <CriteriaOptionSelector
                              value={aiTagsOption?.value}
                              setValue={setAiTagsOption}
                            />
                          </div>
                        </div>
                        <SelectTags
                          feed={props.keywords?.filter(includesSearchKeyword)}
                          values={chosenKeywords}
                          setValue={(item) => {
                            keywordClick(item);
                            foundKeywords.current.count = 0;
                          }}
                          unsetValue={(item) => {
                            keywordClick(item);
                            foundKeywords.current.count = 0;
                          }}
                          clearValues={() => setChosenKeywords([])}
                          disabled={influencerSocialUsername.length}
                        />
                      </div>
                    )}
                </>
              )}

              <div className="audience-search__title">
                <div className="audience-search__title__text">
                  <IDHFormattedMessage
                    id="front_last_posts_within"
                    defaultMessage="Last post within"
                  />
                  <Chevron
                    expanded={showLastPostsWithin}
                    setExpanded={setShowLastPostsWithin}
                  />
                </div>
              </div>

              {showLastPostsWithin && (
                <div className="audience-search__field">
                  <div className="idh-label">
                    <IDHFormattedMessage
                      id="front_last_posts_within_tooltip"
                      defaultMessage="Specify a time range of the latest publication."
                    />
                  </div>
                  <CustomSelect
                    placeholder={intl.formatMessage({
                      id: "front_select",
                      defaultMessage: "Select",
                    })}
                    isClearable
                    value={lastPostsWithin}
                    options={getLastPostsWithinOptions()}
                    onChange={(newValue) => setLastPostsWithin(newValue)}
                    disabled={influencerSocialUsername.length}
                    dataAttr="last-posts-within"
                  />
                </div>
              )}
            </>
          )}
        </div>

        {/* third column */}

        {influencerSocialProvider?.value !== "snapchat" &&
          !isFacebookProvider() && (
            <div className="audience-search__column">
              <div className="audience-search__title">
                <div className="audience-search__title__text">
                  <IDHFormattedMessage
                    id="front_audience_data"
                    defaultMessage="Audience data"
                  />
                  <Chevron
                    expanded={showAudienceData}
                    setExpanded={setShowAudienceData}
                  />
                </div>
                <SelectedFiltersCounter amount={audienceDataCount} />
              </div>

              {showAudienceData && (
                <>
                  {influencerSocialProvider?.value !== "douyin" && (
                    <>
                      <div className="cdt-search-input">
                        <div className="idh-label idh-label--with-tooltip">
                          <IDHFormattedMessage
                            id="front_audience_country"
                            defaultMessage="Audience country"
                          />
                          <MaterialTooltip
                            id="front_audience_country_tooltip"
                            content={
                              <IDHFormattedMessage
                                id="front_audience_country_tooltip"
                                defaultMessage="Discover the best influencers by countries their followers reside."
                              />
                            }
                          />
                        </div>
                        <input
                          placeholder={intl.formatMessage({
                            id: "front_search_country_placeholder",
                            defaultMessage: "Search country...",
                          })}
                          value={searchAudienceCountry}
                          onChange={(e) =>
                            setSearchAudienceCountry(e.target.value)
                          }
                          disabled={influencerSocialUsername.length}
                          data-cdt-input="audience-country"
                        />
                      </div>

                      <div className="audience-search__input">
                        <SelectTags
                          feed={props.audienceCountries[
                            influencerSocialProvider?.value
                          ]?.filter((c) =>
                            c.label
                              .toLocaleLowerCase()
                              .includes(searchAudienceCountry.toLowerCase()),
                          )}
                          values={selectedAudienceCountries}
                          setValue={audienceCountrySet}
                          unsetValue={audienceCountryUnset}
                          clearValues={() => setSelectedAudienceCountries([])}
                          selectDensity
                          disabled={influencerSocialUsername.length}
                        />
                      </div>
                    </>
                  )}

                  <div className="audience-search__field">
                    <div className="idh-label idh-label--with-tooltip">
                      <IDHFormattedMessage
                        id="front_gender"
                        defaultMessage="Gender"
                      />
                      <MaterialTooltip
                        id="front_gender_tooltip"
                        content={
                          <IDHFormattedMessage
                            id="front_gender_tooltip"
                            defaultMessage="Discover best influencers by their audience gender. Select gender and % density for this gender group among influencers."
                          />
                        }
                      />
                    </div>
                    <CustomSelect
                      placeholder={intl.formatMessage({
                        id: "front_select_gender",
                        defaultMessage: "Select",
                      })}
                      isClearable
                      value={audienceGender}
                      options={getGenders()}
                      onChange={(newValue) => setAudienceGender(newValue)}
                      isDisabled={influencerSocialUsername.length}
                      dataAttr="audience-gender"
                    />
                  </div>

                  {audienceGender && audienceGender?.value !== "both" && (
                    <div className="audience-search__field audience-search__field--big-margin">
                      <div className="idh-label">
                        <IDHFormattedMessage
                          id="front_density"
                          defaultMessage="Density"
                        />
                      </div>
                      <div className="audience-search__field-columns">
                        <div className="audience-search__field-col-1">
                          <CustomSelect
                            placeholder={intl.formatMessage({
                              id: "front_select_gender_density",
                              defaultMessage: "Select",
                            })}
                            value={audienceGenderDensity}
                            options={getPercentOptions(70)}
                            onChange={(newValue) =>
                              setAudienceGenderDensity(newValue)
                            }
                            isDisabled={influencerSocialUsername.length}
                            dataAttr="audience-gender-density"
                          />
                        </div>
                        <div className="audience-search__field-col-1" />
                      </div>
                    </div>
                  )}

                  <div className="audience-search__field">
                    <div className="audience-search__field-columns">
                      <div className="audience-search__field-col-1">
                        <div className="idh-label idh-label--with-tooltip">
                          <IDHFormattedMessage
                            id="front_age"
                            defaultMessage="Audience age"
                          />
                          <MaterialTooltip
                            id="front_age_tooltip"
                            content={
                              <IDHFormattedMessage
                                id="front_age_tooltip"
                                defaultMessage="Discover best influencers by their audience age. Select min. and max. age and % density for this age group among influencers."
                              />
                            }
                          />
                        </div>
                        <div className="input-wrapper">
                          <div className="input-wrapper__range">
                            <IDHFormattedMessage
                              id="front_from"
                              defaultMessage="From"
                            />
                          </div>
                          <div className="input-wrapper__item">
                            <CustomSelect
                              placeholder={intl.formatMessage({
                                id: "front_select_age_from",
                                defaultMessage: "Select",
                              })}
                              isClearable
                              value={audienceAgeFrom}
                              options={getAges("from", audienceAgeTo)}
                              onChange={(newValue) =>
                                setAudienceAgeFrom(newValue)
                              }
                              isDisabled={influencerSocialUsername.length}
                              dataAttr="audience-age-from"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="audience-search__field-col-1">
                        <div className="idh-label">&nbsp;</div>
                        <div className="input-wrapper">
                          <div className="input-wrapper__range">
                            <IDHFormattedMessage
                              id="front_to"
                              defaultMessage="To"
                            />
                          </div>
                          <div className="input-wrapper__item">
                            <CustomSelect
                              placeholder={intl.formatMessage({
                                id: "front_select_age_to",
                                defaultMessage: "Select",
                              })}
                              isClearable
                              value={audienceAgeTo}
                              options={getAges("to", audienceAgeFrom)}
                              onChange={(newValue) =>
                                setAudienceAgeTo(newValue)
                              }
                              isDisabled={influencerSocialUsername.length}
                              dataAttr="audience-age-to"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {((audienceAgeFrom?.value && audienceAgeFrom?.value !== "any") ||
                (audienceAgeTo?.value && audienceAgeTo?.value !== "any")) && (
                <div className="audience-search__field audience-search__field--big-margin">
                  <div className="idh-label">
                    <IDHFormattedMessage
                      id="front_density"
                      defaultMessage="Density"
                    />
                  </div>
                  <div className="audience-search__field-columns">
                    <div className="audience-search__field-col-1">
                      <CustomSelect
                        placeholder={intl.formatMessage({
                          id: "front_select_age_density",
                          defaultMessage: "Select",
                        })}
                        value={audienceAgeDensity}
                        options={getPercentOptions(100)}
                        onChange={(newValue) => setAudienceAgeDensity(newValue)}
                        isDisabled={influencerSocialUsername.length}
                        dataAttr="audience-age-density"
                      />
                    </div>
                    <div className="audience-search__field-col-1" />
                  </div>
                </div>
              )}

              {influencerSocialProvider?.value === "instagram" && (
                <div className="audience-search__title">
                  <div className="audience-search__title__text">
                    <IDHFormattedMessage
                      id="front_audience_interests"
                      defaultMessage="Audience interests"
                    />
                    <MaterialTooltip
                      id="front_audience_interests_tooltip_top"
                      content={
                        <>
                          <IDHFormattedMessage
                            id="front_audience_interests_tooltip_top"
                            defaultMessage="Interests are determined by analysing the captions, @mentions, #hashtags and locations that are used by the influencer's audience in the latest 150 posts (or more than 150 posts if they have been published within the last 2 months). Additionally, the following factors are included:"
                          />
                          <br />
                          <br />
                          <div className="tooltip-content-wrapper">
                            <TickIcon className="tooltip-tick" />
                            <IDHFormattedMessage
                              id="front_audience_interests_tooltip_bottom_a"
                              defaultMessage={`Brand Affinity aspect - For example, if a person mentioned "McDonald’s", "KFC", this indicates interest in food. `}
                            />
                          </div>
                          <div className="tooltip-content-wrapper">
                            <TickIcon className="tooltip-tick" />
                            <IDHFormattedMessage
                              id="front_audience_interests_tooltip_bottom_b"
                              defaultMessage={`Other food-related keywords, such as "food", "cakes" etc.`}
                            />
                          </div>
                        </>
                      }
                    />
                    <Chevron
                      expanded={showAudienceInterests}
                      setExpanded={setShowAudienceInterests}
                    />
                  </div>

                  <SelectedFiltersCounter amount={chosenTopics.length} />
                </div>
              )}

              {showAudienceInterests &&
                influencerSocialProvider?.value === "instagram" && (
                  <>
                    <div className="cdt-search-input">
                      <div className="idh-label">
                        <IDHFormattedMessage
                          id="front_search_topic"
                          defaultMessage="Search topic"
                        />
                      </div>

                      <div className="audience-search__input-wrapper">
                        <input
                          placeholder={intl.formatMessage({
                            id: "front_search_topic_placeholder",
                            defaultMessage: "Search topic...",
                          })}
                          value={searchTopic}
                          onKeyPress={(e) => handleEnterPress(e, "interests")}
                          onChange={(e) => {
                            setSearchTopic(e.target.value);
                            foundTopics.current.count = 0;
                          }}
                          disabled={influencerSocialUsername.length}
                          data-cdt-input="audience-interests"
                        />

                        <CriteriaOptionSelector
                          value={audienceInterestsOption?.value}
                          setValue={setAudienceInterestsOption}
                        />
                      </div>
                    </div>
                    <SelectTags
                      feed={props.interests.filter((item) =>
                        includesSearchTopic(
                          intl.formatMessage({
                            id: item.name,
                            defaultMessage: item.value,
                          }),
                        ),
                      )}
                      values={chosenTopics}
                      setValue={(item) => {
                        foundTopics.current.count = 0;
                        topicClick(item.value);
                        setSearchTopic("");
                      }}
                      unsetValue={(item) => {
                        foundTopics.current.count = 0;
                        topicClick(item);
                        setSearchTopic("");
                      }}
                      clearValues={() => setChosenTopics([])}
                      translative
                      getFullObject={getFullInterestObj}
                      disabled={influencerSocialUsername.length}
                    />
                  </>
                )}

              {influencerSocialProvider?.value === "instagram" && (
                <div className="audience-search__title">
                  <div className="audience-search__title__text">
                    <IDHFormattedMessage
                      id="front_audience_brand_affinity"
                      defaultMessage="Audience brand affinity"
                    />
                    <MaterialTooltip
                      id="front_search_audience_brand_affinity_tooltip"
                      content={
                        <IDHFormattedMessage
                          id="front_search_audience_brand_affinity_tooltip"
                          defaultMessage="Brand affinity is measured as a percentage of the audience showing interest in this particular brand, determined by analyzing captions, @mentions, #hashtags, and locations used by the influencer's audience in the latest 150 posts (or more, if they all have been published within the last 2 months) and compared to Instagram users on average."
                        />
                      }
                    />
                    <Chevron
                      expanded={showAudienceBrandAffinity}
                      setExpanded={setShowAudienceBrandAffinity}
                    />
                  </div>
                  <SelectedFiltersCounter amount={chosenBrands.length} />
                </div>
              )}
              {showAudienceBrandAffinity &&
                influencerSocialProvider?.value === "instagram" && (
                  <div className="audience-search-content-wrapper">
                    <div className="cdt-search-input">
                      <div className="idh-label">
                        <IDHFormattedMessage
                          id="front_search_brand"
                          defaultMessage="Search"
                        />
                      </div>

                      <div className="audience-search__input-wrapper">
                        <input
                          placeholder={intl.formatMessage({
                            id: "front_search_brand_placeholder",
                            defaultMessage: "Search brand...",
                          })}
                          value={searchBrand}
                          onKeyPress={(e) => handleEnterPress(e, "brands")}
                          onChange={(e) => {
                            setSearchBrand(e.target.value);
                            foundBrands.current.count = 0;
                          }}
                          disabled={influencerSocialUsername.length}
                          data-cdt-input="audience-brand-affinity"
                        />

                        <CriteriaOptionSelector
                          value={audienceBrandAffinityOption?.value}
                          setValue={setAudienceBrandAffinityOption}
                        />
                      </div>
                    </div>
                    <SelectTags
                      feed={props.audienceBrandAffinity.filter((item) =>
                        includesSearchBrand(item),
                      )}
                      values={chosenBrands}
                      setValue={(item) => {
                        foundBrands.current.count = 0;
                        brandClick(item);
                        setSearchBrand("");
                      }}
                      unsetValue={(item) => {
                        foundBrands.current.count = 0;
                        brandClick(item);
                        setSearchBrand("");
                      }}
                      clearValues={() => setChosenBrands([])}
                      disabled={influencerSocialUsername.length}
                    />
                  </div>
                )}
            </div>
          )}
      </div>

      {showFSCModal && (
        <FlexibleSearchCriteriaModal onClose={() => setShowFSCModal(false)} />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  interests: state.audiencesManagerReducer.interests,
  influencerIgCategories: state.audiencesManagerReducer.influencerIgCategories,
  influencerInterests: state.audiencesManagerReducer.influencerInterests,
  audienceCountries: state.audiencesManagerReducer.audienceCountries,
  influencerCountries: state.audiencesManagerReducer.influencerCountries,
  influencerCountriesByProvider:
    state.audiencesManagerReducer.influencerCountriesByProvider,
  influencerCities: state.audiencesManagerReducer.influencerCities,
  influencerProviders: state.audiencesManagerReducer.influencerProviders,
  audienceBrandAffinity: state.audiencesManagerReducer.audienceBrandAffinity,
  keywords: state.audiencesManagerReducer.keywords,
  listSocialProvider: state.audiencesManagerReducer.listSocialProvider,
  influencersInListInfo: state.audiencesManagerReducer.influencersInListInfo,
  isIncorrectProviderError:
    state.audiencesManagerReducer.isIncorrectProviderError,
});

const mapDispatchToProps = (dispatch) => {
  return {
    changeSearchAudienceFilters: (data) =>
      dispatch(changeSearchAudienceFilters(data)),
    setCountryDisabled: (type, country, disabled) =>
      dispatch(setCountryDisabled(type, country, disabled)),
    clearDisabledCountries: () => dispatch(clearDisabledCountries()),
    setListSocialProvider: (provider) =>
      dispatch(setListSocialProvider(provider)),
    setIsIncorrectProviderError: (isIncorrectProviderError) =>
      dispatch(setIsIncorrectProviderError(isIncorrectProviderError)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(AudiencesSearch));
