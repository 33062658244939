import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setReportLayout } from "../../../../redux";

import CustomCheckbox from "../../../components/CustomCheckbox/CustomCheckbox";
import { addElementToLayout } from "../../functions/addElementToLayout";

export function ToolbarListItem(props) {
  const {
    item,
    provider,
    format,
    toolbarListProps,
    isReport,
    single,
    tooltip,
    sectionId,
  } = props;

  const [visible, setVisible] = useState(false);
  const [field, setField] = useState(null);

  const dispatch = useDispatch();

  const {
    layout,
    gridElements,
    hiddenGridElements,
    setLayout,
    setGridElements,
    setHiddenGridElements,
  } = toolbarListProps;

  const prefix =
    provider !== "comments-analysis" && !item.value.includes(provider)
      ? `${provider}-`
      : "";
  const id = `${prefix}${item.value}`;

  useEffect(() => {
    if (!gridElements?.length) {
      setField(null);
    } else {
      setField(
        gridElements?.find((el) => {
          return el.label === id;
        }),
      );
    }
  }, [gridElements, hiddenGridElements]);

  useEffect(() => {
    if (!field) {
      setVisible(false);
      return;
    }

    setVisible(true);
  }, [field]);

  const toggleFieldVisibility = () => {
    if (visible) {
      hideElement();
    } else {
      showElement();
    }
  };

  const hideElement = () => {
    const newGridElements = gridElements.filter(
      (item) =>
        item.label !== id &&
        item.label !== `header-${provider}` &&
        (id === "glossary" ? item.label !== "glossary-two" : true),
    );
    const hiddenGridElementsToAdd = gridElements?.filter(
      (item) =>
        item.label === id ||
        item.label === `header-${provider}` ||
        (id === "glossary" && item.label === "glossary-two"),
    );

    dispatch(setGridElements(newGridElements));
    dispatch(
      setHiddenGridElements([
        ...hiddenGridElements,
        ...hiddenGridElementsToAdd,
      ]),
    );
  };

  const showElement = () => {
    const gridElementsToAdd = hiddenGridElements.filter(
      (item) =>
        item.label === id ||
        item.label === `header-${provider}` ||
        (id === "glossary" && item.label === "glossary-two"),
    );
    const newHiddenGridElements = hiddenGridElements.filter(
      (item) =>
        item.label !== id &&
        item.label !== `header-${provider}` &&
        (id === "glossary" ? item.label !== "glossary-two" : true),
    );

    dispatch(setGridElements([...gridElements, ...gridElementsToAdd]));
    dispatch(setHiddenGridElements(newHiddenGridElements));

    if (id === "table-of-contents") {
      addTableOfContentsToLayout();
    } else if (single) {
      const newLayout = [...layout];

      gridElementsToAdd.forEach((item) => {
        addElementToLayout(
          item,
          newLayout,
          dispatch,
          format,
          setReportLayout,
          hiddenGridElements,
          isReport,
          true,
        );
      });

      dispatch(setLayout(newLayout));

      const generator = document.querySelector(".template-generator");
      const scrollDiff = isReport ? -3500 : 0;
      generator.scroll({
        top: generator.clientHeight + generator.scrollHeight + scrollDiff,
        left: 0,
        behavior: "smooth",
      });
    } else {
      const gridElement = hiddenGridElements.find(
        (el) => el.label === item.value,
      );

      addElementToLayout(
        gridElement,
        layout,
        dispatch,
        format,
        setLayout,
        hiddenGridElements,
        isReport,
      );
    }
  };

  const addTableOfContentsToLayout = () => {
    let newLayout = [...layout];

    newLayout = newLayout.map((item) => ({
      ...item,
      y: item.y >= 17 ? item.y + 17 : item.y,
    }));

    newLayout.push({
      i: "table-of-contents",
      x: 0,
      y: 17,
      w: 12,
      h: 16,
      static: true,
    });

    dispatch(setReportLayout(newLayout));
    const generator = document.querySelector(".template-generator");
    generator.scroll({
      top: 1200,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <div
      className={classNames("toolbar-list__list-item", "draggable-item", {
        "toolbar-list__list-item--single": single,
      })}
    >
      <CustomCheckbox
        id={id}
        checked={visible}
        onChange={toggleFieldVisibility}
        blue={single}
      />

      <span
        className={classNames("toolbar-list__list-item-text", {
          "toolbar-list__list-item-text--dark": single,
        })}
      >
        {sectionId ? <a href={sectionId}>{item.label}</a> : item.label}
      </span>
      {tooltip}
    </div>
  );
}
