import { MetaFieldOptions, Metadata } from "src/app/Task/Subtask/Subtask";
import { IMember } from "src/app/project/ProjectTypes";
import { MetaFieldType } from "src/app/methods/getMetaFieldTypeOptions";
import { TaskType, WsExtension } from "src/types";
import { CurrencySettings } from "../dictionary/types";
import { WsAutomation } from "../automation/types";

interface ProjectPermissions {
  project: string[];
}

export interface CoverImage {
  id: string;
  name: string;
  mimeType: string;
  thumbnails: {
    big: string;
    original: string;
    small: string;
    tiny: string;
  };
  downloadUrl: string;
  publicUrl: string;
  isRemovable: boolean;
  rank: string;
  parentUuid: string;
}

export interface IProject {
  projectId: string;
  projectName: string;
  cover: CoverImage | null;
  data: CurrencySettings;
  metadata: Metadata[];
  members: IMember[];
  permissions: ProjectPermissions;
  clientMode: boolean;
  seeAsClientMode: boolean;
}

export interface TasksCounters {
  actions: number;
  content: number;
  creators: number;
  payments: number;
  publications: number;
}

export interface ProjectBasicData extends IProject {
  wsAutomations: WsAutomation[];
  wsExtensions: WsExtension[];
}

export enum ProjectTemplateType {
  SocialProvider = "social_provider",
  Base = "base",
}

export interface ProjectTemplate {
  uuid: string;
  name: string;
  isSubmissionFormEnabled: boolean;
  type: ProjectTemplateType.SocialProvider | ProjectTemplateType.Base;
  projectMetaFields: Metadata[];
  description: string;
}

export interface ProjectMetaData {
  uuid: string;
  name: string;
  type: MetaFieldType;
  key: string;
  value: string | null;
  taskType: TaskType;
  isVisible: boolean;
  isRequired: boolean;
  isSystem: boolean;
  rank: string;
  data: MetaFieldOptions;
  valueSource: string | null;
  shared: boolean;
  wsGlobalProjectMetaFieldUuid: string | null;
  valueRequired: boolean;
}

export interface IProjectDetails {
  cover: CoverImage;
  created: string;
  isBarter: boolean;
  members: IMember[];
  metadata: ProjectMetaData[];
  permissions: any;
  projectId: string;
  title: string;
  workspaceId: string;
  ownerWsMemberUuid: string;
}
