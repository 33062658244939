export const addSpaceIfNeeded = (
  fieldType,
  newLayout,
  format,
  overrideWidth,
  overrideHeight,
) => {
  const lastEl = newLayout.sort((a, b) => a.y - b.y)[newLayout.length - 1];

  const spaceOnPage = format.rows;
  const currentRow = lastEl.y + lastEl.h;

  let nextElHeight = 0;
  let nextElWidth = 0;

  if (overrideWidth && overrideHeight) {
    nextElWidth = overrideWidth;
    nextElHeight = overrideHeight;
  } else {
    nextElWidth = getElementWidth(fieldType, format);
    nextElHeight = getElementHeight(fieldType);
  }

  const spaceLeftY =
    currentRow % spaceOnPage === 0
      ? 0
      : spaceOnPage - (currentRow % spaceOnPage) - 1;
  const spaceLeftX = format.cols - (lastEl.x + lastEl.w);
  const conditionX = nextElWidth > spaceLeftX;
  const conditionY = nextElHeight > spaceLeftY;
  const condition = conditionX && conditionY;

  const y = currentRow + spaceLeftY;

  if (condition) {
    const key = `separator-${y}`;
    const separatorData = {
      i: key,
      x: 0,
      y,
      w: format.cols,
      h: 1,
      static: true,
    };

    return separatorData;
  }

  return lastEl;
};

const getElementHeight = (fieldType) => {
  switch (fieldType) {
    case "bio":
    case "text":
    case "creator":
      return 2;

    case "metric":
    case "audience-age":
    case "audience-data":
    case "female-per-age":
    case "geographical-reach":
    case "languages":
    case "location-by-city":
    case "male-per-age":
    case "creator-interests":
    case "creators-brand-affinity":
    case "audience-interests":
    case "audience-brand-affinity":
    case "hashtags":
    case "keywords":
    case "creator-growth":
    case "creator-insights":
    case "avg-views":
      return 3;

    case "recently-published":
      return 4;

    case "comments":
      return 5;

    case "publication":
      return 7;

    case "table-summary":
      return 9;

    default:
      return 1;
  }
};

const getElementWidth = (fieldType, format) => {
  switch (fieldType) {
    case "text":
    case "creator":
    case "header":
    case "subheader":
    case "table-summary":
      return format.cols;

    case "currency":
    case "singleSelect":
    case "multiSelect":
    case "percent":
    case "number":
    case "comments":
      return format.cols / 2;

    case "publication":
    case "recently-published":
    case "hashtags":
    case "keywords":
      return format.cols / 3;

    case "audience-age":
    case "audience-data":
    case "credibility":
    case "er":
    case "female-per-age":
    case "followers":
    case "geographical-reach":
    case "languages":
    case "location-by-city":
    case "male-per-age":
    case "creator-interests":
    case "creators-brand-affinity":
    case "audience-interests":
    case "audience-brand-affinity":
    case "creator-growth":
    case "creator-insights":
    case "avg-views":
      return 2;

    default:
      return 6;
  }
};
