import React, { useEffect, useState } from "react";
import axios from "axios";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { CustomInputLabel } from "src/app/components/CustomInput/CustomInput";
import CustomSelect from "src/app/components/CustomSelect/CustomSelect";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { API_URLS } from "src/utils/API_URLS";
import {
  capitalizeFirstLetter,
  isStringNumeric,
  showErrorToast,
} from "src/utils/methods";
import { RootState } from "src/redux/reducers";
import { TasksColumn } from "src/redux/task/taskReducer";
import { TaskType } from "src/types";
import { MetaFieldOptions } from "src/app/Task/Subtask/Subtask";
import { SelectOption } from "../../SocialMetrics";
import { MFState, MFStateEntries } from "../../types";
import { MetaFieldType } from "../../../../methods/getMetaFieldTypeOptions";

import "./RelatedField.scss";
import {
  GlobalProjectMetaField,
  GlobalTaskMetaField,
} from "src/redux/settings/settingsReducer";

const ALLOWED_ELEMENT_TYPES = [
  MetaFieldType.Number,
  MetaFieldType.Percent,
  MetaFieldType.Currency,
  MetaFieldType.Date,
  MetaFieldType.Text,
  MetaFieldType.SingleSelect,
  MetaFieldType.MultiSelect,
];

const aggregationTypeOptions = [
  {
    label: (
      <IDHFormattedMessage
        id="ws_related_field_aggregation_first"
        defaultMessage="First match"
      />
    ),
    value: "first",
  },
  {
    label: (
      <IDHFormattedMessage
        id="ws_related_field_aggregation_sum"
        defaultMessage="Sum values"
      />
    ),
    value: "sum",
  },
  {
    label: (
      <IDHFormattedMessage
        id="ws_related_field_aggregation_avg"
        defaultMessage="Values average"
      />
    ),
    value: "avg",
  },
  {
    label: (
      <IDHFormattedMessage
        id="ws_related_field_aggregation_count"
        defaultMessage="Count items"
      />
    ),
    value: "count",
  },
];

const globalFieldsFormatting = (
  field: GlobalTaskMetaField | GlobalProjectMetaField,
) => ({
  metaFieldId: field.uuid,
  metaFieldName: field.name,
  metaFieldType: field.type,
  metaFieldTaskType: field.taskType,
  data: field.data,
});

interface Field {
  metaFieldId: string;
  metaFieldName: string;
  metaFieldType: MetaFieldType;
  metaFieldTaskType: TaskType | null;
  data: MetaFieldOptions;
  isGlobalProjectField?: boolean;
}

interface Props {
  state: MFState;
  setState: (type: MFStateEntries, value: any) => void;
  projectId: string;
  source?: string;
  disabled?: boolean;
  isGlobalMode?: boolean;
  currentRelatedMetaFieldAggregationType?: string | null;
}

export default function RelatedField(props: Props) {
  const {
    state: {
      relatedMetaField,
      relatedMetaFieldAggregationType,
      selectedTaskType,
    },
    setState,
    projectId,
    source,
    disabled,
    isGlobalMode,
    currentRelatedMetaFieldAggregationType,
  } = props;

  const [fields, setFields] = useState<Field[]>([]);
  const [options, setOptions] = useState<SelectOption[]>([]);

  const {
    projectReducer: { taskType },
    settingsReducer: { globalTaskMetaFields, globalProjectMetaFields },
  } = useSelector((state: RootState) => state);
  const intl = useIntl();

  useEffect(() => {
    if (!isGlobalMode) return;

    const getGlobalFields = () => {
      const formattedGlobalTaskFields = globalTaskMetaFields
        .filter((field) => field.taskType)
        .map((field) => globalFieldsFormatting(field));

      const formattedGlobalProjectFields = globalProjectMetaFields.map(
        (field) => {
          return {
            ...globalFieldsFormatting(field),
            isGlobalProjectField: true,
          };
        },
      );

      setFields([
        ...formattedGlobalTaskFields,
        ...formattedGlobalProjectFields,
      ]);
    };
    getGlobalFields();
  }, [isGlobalMode, globalTaskMetaFields, globalProjectMetaFields]);

  useEffect(() => {
    if (isGlobalMode) return;

    const getLocalFields = () => {
      const url = API_URLS.getAllTaskMetaFieldsForProject.replace(
        ":wsProjectUuid:",
        projectId,
      );

      axios
        .get(url)
        .then((response) => {
          const {
            data: { content },
          }: { data: { content: TasksColumn[] } } = response;

          setFields(content);
        })
        .catch((error) => {
          console.error(error);
          showErrorToast();
        });
    };
    getLocalFields();
  }, [isGlobalMode, projectId]);

  useEffect(() => {
    setState("relatedMetaField", {});

    const generatedOptions = fields
      .filter((item) => {
        const isAllowedType = ALLOWED_ELEMENT_TYPES.includes(
          item.metaFieldType,
        );

        if (!isAllowedType) return false;

        if (isGlobalMode) {
          const isGlobalCondition =
            selectedTaskType.value === null
              ? item.isGlobalProjectField
              : item.metaFieldTaskType !== selectedTaskType.value &&
                !item.isGlobalProjectField;

          return isGlobalCondition;
        }

        return item.metaFieldTaskType !== taskType;
      })
      .map((item) => {
        let label;
        if (selectedTaskType.value === null && isGlobalMode) {
          label = item.metaFieldTaskType ? "Overview" : "Project";
        } else {
          label = capitalizeFirstLetter(item.metaFieldTaskType);
        }

        return {
          label: `${label} > ${item.metaFieldName}`,
          value: {
            id: item.metaFieldId,
            name: item.metaFieldName,
            currencyCode: item?.data?.currencyCode,
          },
        };
      });

    setOptions(generatedOptions);
  }, [fields, taskType, isGlobalMode, selectedTaskType]);

  useEffect(() => {
    if (!source || relatedMetaField.value || !options?.length) {
      const selectedRelatedAggregationType = aggregationTypeOptions.find(
        (o) => o.value === "first",
      );

      if (selectedRelatedAggregationType?.value) {
        setState(
          "relatedMetaFieldAggregationType",
          selectedRelatedAggregationType,
        );
      }

      return;
    }

    const relatedUuid = source.split(".")[2];
    const selectedOption = options?.find((o) => o.value.id === relatedUuid);

    if (selectedOption?.value) {
      setState("relatedMetaField", selectedOption);

      const selectedRelatedAggregationType = aggregationTypeOptions.find(
        (o) => o.value === (currentRelatedMetaFieldAggregationType ?? "first"),
      );

      if (selectedRelatedAggregationType?.value) {
        setState(
          "relatedMetaFieldAggregationType",
          selectedRelatedAggregationType,
        );
      }
    }
  }, [options, source]);

  return (
    <div className="related-field">
      <div className="row-wrapper">
        <div className="modal__column">
          <CustomInputLabel>
            <IDHFormattedMessage
              id="ws_related_column"
              defaultMessage="Related column"
            />
          </CustomInputLabel>

          <CustomSelect
            value={relatedMetaField}
            onChange={(newValue: SelectOption) =>
              setState("relatedMetaField", newValue)
            }
            options={options}
            isSearchable
            placeholder={intl.formatMessage({
              id: "ws_select_field",
              defaultMessage: "Select field",
            })}
            isValidNewOption={(inputValue: string) =>
              isStringNumeric(inputValue)
            }
            disabled={disabled}
          />
        </div>
        <div className="modal__column">
          <CustomInputLabel>
            <IDHFormattedMessage
              id="ws_related_column_aggregation_type"
              defaultMessage="Aggregation column type"
            />
          </CustomInputLabel>

          <CustomSelect
            value={relatedMetaFieldAggregationType}
            onChange={(newValue: SelectOption) =>
              setState("relatedMetaFieldAggregationType", newValue)
            }
            options={aggregationTypeOptions}
            placeholder={intl.formatMessage({
              id: "ws_select_aggregation_type_field",
              defaultMessage: "Select aggregation type",
            })}
            isValidNewOption={(inputValue: string) =>
              isStringNumeric(inputValue)
            }
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );
}
