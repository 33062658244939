import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import {
  ChangeSettingsSectionParam,
  removePropertyFromViewParam,
} from "src/app/SettingsModal/methods";
import { useSetCoverImageFile } from "src/app/components/CroppedImageUploader/utils/useUploadCoverImage";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import Loader from "src/app/components/Loader/Loader";
import {
  clearAvailableProjectMetaFields,
  clearAvailableWorkspaceGlobalTaskMetaFields,
  getExtensionList,
  getProject,
  getWorkspaceExtensionList,
} from "src/redux";
import { AppLocation } from "src/redux/main/mainReducer";
import {
  MetafieldAccessMode,
  RecruitmentFormMetaFieldAvailable,
  ExtensionTypeEnum,
  RecruitmentFormDetails,
  WsExtension,
} from "src/types/";
import { uuidv7 as uuid } from "uuidv7";

import axios from "axios";
import { SettingsButtons } from "src/app/SettingsModal/components/SettingsTabContent/SettingsTabContent";
import { CustomSwitch } from "src/app/components/CustomSwitch/CustomSwitch";
import { showToast } from "src/app/methods/showToast";
import { identify } from "src/redux/main/mainActions";
import { API_URLS } from "src/utils/API_URLS";
import {
  ProjectPermissionsEnum,
  WorkspacePermissionsEnum,
} from "src/utils/PermissionsEnums";
import { RootState } from "src/redux/reducers";
import {
  ExtensionsSection,
  getMetaFieldTypeByExtensionType,
} from "../../Extensions";
import { uploadExtensionCover } from "./components/Buttons/uploadExtensionCover";
import { HowItWorksModal } from "./components/HowItWorksModal/HowItWorksModal";
import {
  Field,
  RecruitmentFormFields,
} from "./components/RecruitmentFormFields/RecruitmentFormFields";
import { RecruitmentFormGeneral } from "./components/RecruitmentFormGeneral/RecruitmentFormGeneral";
import { RecruitmentFormNotifications } from "./components/RecruitmentFormNotifications/RecruitmentFormNotifications";
import RecruitmentFormShare from "./components/RecruitmentFormShare/RecruitmentFormShare";
import RecruitmentFormTabs from "./RecruitmentFormTabs";
import { ActiveTabs, RecruitmentFormUserData } from "./types";
import {
  extensionDetailsInitialState,
  getAvailableMetaFields,
  initialUserData,
} from "./utils";
import { getExtensionDetailsByUuid } from "../../utils";
import ExtensionView from "../../ExtensionView";

import "./RecruitmentForm.scss";
import { showErrorToast } from "src/utils/methods";

interface RecruitmentFormProps {
  setExtensionsSection: (section: ChangeSettingsSectionParam) => void;
  onClose: () => void;
  contextData: any;
  recruitmentListItem: WsExtension;
  extensionType: ExtensionTypeEnum;
}

export default function RecruitmentForm({
  setExtensionsSection,
  onClose,
  contextData,
  recruitmentListItem,
  extensionType,
}: RecruitmentFormProps) {
  const [activeTab, setActiveTab] = useState<ActiveTabs>(ActiveTabs.General);
  const [isLoading, setIsLoading] = useState(false);
  const [isCoverPhotoRemoved, setIsCoverPhotoRemoved] = useState(false);

  const [userData, setUserData] =
    useState<RecruitmentFormUserData>(initialUserData);
  const [availableMetaFields, setAvailableMetaFields] = useState<Field[]>([]);
  const [extensionDetails, setExtensionDetails] =
    useState<RecruitmentFormDetails>(extensionDetailsInitialState);
  const [isExtensionDetailsLoading, setIsExtensionDetailsLoading] =
    useState(false);

  const { coverImageFile, setNewImage, setCoverImageFile } =
    useSetCoverImageFile();

  const dispatch = useDispatch();
  const history = useHistory();

  const {
    mainReducer: { howItWorksModalData },
    extensionReducer: {
      availableProjectMetaFields,
      availableWorkspaceGlobalTaskMetaFields,
    },
  } = useSelector((state: RootState) => state);

  const handleCloseHowItWorksModal = () => {
    removePropertyFromViewParam(history, "howItWorks");
  };

  const renderContent = () => {
    switch (activeTab) {
      case ActiveTabs.Share:
        return (
          <RecruitmentFormShare
            setRecruitmentFormDetails={setExtensionDetails}
            recruitmentFormDetails={extensionDetails}
            permissions={contextData?.permissions}
          />
        );
      case ActiveTabs.General:
        return (
          <RecruitmentFormGeneral
            userData={userData}
            setUserData={setUserData}
            coverImageFile={coverImageFile}
            setNewImage={setNewImage}
            setCoverImageFile={setCoverImageFile}
            recruitmentFormDetails={extensionDetails}
            contextData={contextData}
            extensionUuid={extensionDetails.uuid}
            extensionType={extensionType}
            setIsLoading={setIsLoading}
            isCoverPhotoRemoved={isCoverPhotoRemoved}
            setIsCoverPhotoRemoved={setIsCoverPhotoRemoved}
          />
        );
      case ActiveTabs.Fields:
        return (
          <RecruitmentFormFields
            setUserData={setUserData}
            userData={userData}
            extensionUuid={extensionDetails.uuid}
            permissions={contextData?.permissions}
            contextData={contextData}
            extensionType={extensionType}
            setAvailableMetaFields={setAvailableMetaFields}
            availableMetaFields={availableMetaFields}
            setIsLoading={setIsLoading}
          />
        );
      case ActiveTabs.Notifications:
        return (
          <RecruitmentFormNotifications
            setUserData={setUserData}
            userData={userData}
            extensionUuid={extensionDetails.uuid}
            permissions={contextData?.permissions}
          />
        );
      default:
        return null;
    }
  };

  const prepareData = async (
    metaFields: RecruitmentFormMetaFieldAvailable[],
  ) => {
    const requiredMetaFields = metaFields
      .filter((item) => item.isRequired)
      .map((item) => ({
        wsMetaFieldName: item.name,
        wsMetaFieldType: item.type,
        wsMetaFieldUuid: item.uuid,
        wsMetaFieldAccessMode: MetafieldAccessMode.write,
        wsMetaFieldIsRequired: true,
        wsMetaFieldIsNotDeletable: true,
        wsMetaFieldDescription: "",
      }));

    setExtensionDetails((prev) => ({
      ...prev,
      recruitmentFormMetaFields: requiredMetaFields,
      recruitmentFormMetaFieldsAvailable: metaFields,
    }));
  };

  useEffect(() => {
    setAvailableMetaFields(
      extensionDetails.recruitmentFormMetaFieldsAvailable.map((item) => ({
        label: item.name,
        metafieldType: getMetaFieldTypeByExtensionType(extensionType),
        value: item.uuid,
        isNotDeletable: item.isRequired,
        wsMetaFieldDescription: item.wsMetaFieldDescription ?? null,
      })),
    );
    setUserData({
      recruitmentFormMetaFields: {
        metaFields: extensionDetails.recruitmentFormMetaFields.map((item) => ({
          wsMetaFieldType: getMetaFieldTypeByExtensionType(extensionType),
          wsMetaFieldUuid: item.wsMetaFieldUuid,
          wsMetaFieldAccessMode: MetafieldAccessMode.write,
          wsMetaFieldIsRequired: item.wsMetaFieldIsRequired,
          wsMetaFieldDescription: item.wsMetaFieldDescription,
          wsMetaFieldIsNotDeletable: item.wsMetaFieldIsNotDeletable,
        })),
        membersWithNotification:
          extensionDetails.recruitmentFormMembersWithNotification?.map(
            (item) => ({
              wsMemberUuid: item.wsMemberUuid,
              wsTeamUuid: item.wsTeamUuid,
              type: item.type,
            }),
          ) ?? [],
        caption: extensionDetails.recruitmentFormCaption,
        description: extensionDetails.recruitmentFormDescription,
      },
      wsAccessLinkCover: extensionDetails.wsAccessLinkCover,
      recruitmentFormUuid: extensionDetails.recruitmentFormUuid,
      wsExtensionType: extensionType,
    });
  }, [extensionDetails, extensionType]);

  useEffect(() => {
    setActiveTab(
      recruitmentListItem?.enabled ? ActiveTabs.Share : ActiveTabs.General,
    );
  }, [recruitmentListItem?.enabled]);

  useEffect(() => {
    if (recruitmentListItem.uuid) {
      const getRecruitmentFormDetails = async () => {
        const data = await getExtensionDetailsByUuid<{
          content: RecruitmentFormDetails;
        }>({
          uuid: recruitmentListItem.uuid,
          initialCallback: () => setIsExtensionDetailsLoading(true),
          finallyCallback: () => setIsExtensionDetailsLoading(false),
        });
        if (data) {
          setExtensionDetails(data.content);
        }
      };
      getRecruitmentFormDetails();
    }
  }, [recruitmentListItem.uuid]);

  useEffect(() => {
    if (contextData?.uuid && !recruitmentListItem.uuid) {
      getAvailableMetaFields({
        dispatch,
        contextUuid: contextData.uuid,
        extensionType,
      });
    }
  }, [contextData?.uuid, recruitmentListItem.uuid, extensionType]);

  useEffect(() => {
    let metaFields: RecruitmentFormMetaFieldAvailable[] = [];

    if (extensionType === ExtensionTypeEnum.RecruitmentForm) {
      metaFields = availableProjectMetaFields;
    } else if (
      extensionType === ExtensionTypeEnum.CreatorDatabaseSubmissionForm
    ) {
      metaFields = availableWorkspaceGlobalTaskMetaFields;
    }

    if (!metaFields.length) return;
    prepareData(metaFields);
  }, [
    availableProjectMetaFields,
    availableWorkspaceGlobalTaskMetaFields,
    extensionType,
  ]);

  useEffect(() => {
    return () => {
      dispatch(clearAvailableProjectMetaFields());
      dispatch(clearAvailableWorkspaceGlobalTaskMetaFields());
    };
  }, []);

  const updateRecruitmentFormStatus = async (
    enabled: boolean,
    wsExtensionUuid: string,
  ) => {
    try {
      await axios.put(
        API_URLS.updateRecruitmentFormStatus.replace(
          ":wsExtensionUuid:",
          wsExtensionUuid,
        ),
        { enabled },
      );

      if (enabled) {
        showToast(
          "info",
          <IDHFormattedMessage
            id="ws_extension_enabled"
            defaultMessage="Extension enabled"
          />,
          <IDHFormattedMessage
            id="ws_recruitment_form_enabled"
            defaultMessage="Recruitment form has been enabled."
          />,
        );
        setActiveTab(ActiveTabs.Share);
      } else {
        showToast(
          "info",
          <IDHFormattedMessage
            id="ws_extension_disabled"
            defaultMessage="Extension disabled"
          />,
          <IDHFormattedMessage
            id="ws_recruitment_form_disabled"
            defaultMessage="Recruitment form has been disabled."
          />,
        );
        setActiveTab(ActiveTabs.General);
      }

      if (extensionType === ExtensionTypeEnum.RecruitmentForm) {
        dispatch(getExtensionList(contextData.uuid));
        dispatch(getProject(contextData.uuid));
      }

      if (extensionType === ExtensionTypeEnum.CreatorDatabaseSubmissionForm) {
        dispatch(getWorkspaceExtensionList(contextData.uuid));

        if (extensionDetails.wsWorkspaceUuid) {
          dispatch(identify(extensionDetails.wsWorkspaceUuid));
        }
      }
    } catch (err) {
      console.error(err);
      showErrorToast();
    }
  };

  const createNewCreatorDatabaseSubmissionFormExtension = async (
    wsWorkspaceUuid: string,
  ) => {
    const wsExtensionUuid = uuid();
    const wsRecruitmentFormUuid = uuid();
    let realName;

    if (coverImageFile) {
      realName = await uploadExtensionCover(
        coverImageFile,
        contextData.workspaceUuid,
        wsRecruitmentFormUuid,
      );
    }

    try {
      await axios.post(API_URLS.createNewExtension, {
        recruitmentFormMetaFields: userData.recruitmentFormMetaFields,
        wsWorkspaceUuid,
        wsExtensionUuid,
        wsRecruitmentFormUuid,
        wsExtensionType: ExtensionTypeEnum.CreatorDatabaseSubmissionForm,
        wsAccessLinkCover:
          coverImageFile && realName
            ? {
                parentUuid: wsRecruitmentFormUuid,
                name: coverImageFile?.name,
                mimeType: coverImageFile?.type,
                realName,
                size: coverImageFile?.size,
              }
            : null,
      });
      dispatch(getWorkspaceExtensionList(wsWorkspaceUuid));
    } catch (err) {
      console.error(err);
      showErrorToast();
    }
  };

  const createNewRecruitmentFormExtension = async (projectUuid: string) => {
    const wsExtensionUuid = uuid();
    const wsRecruitmentFormUuid = uuid();
    let realName;
    try {
      if (coverImageFile) {
        realName = await uploadExtensionCover(
          coverImageFile,
          contextData.workspaceUuid,
          wsRecruitmentFormUuid,
        );
      }

      await axios.post(API_URLS.createNewExtension, {
        recruitmentFormMetaFields: userData.recruitmentFormMetaFields,
        wsProjectUuid: projectUuid,
        wsExtensionUuid,
        wsRecruitmentFormUuid,
        wsAccessLinkCover:
          coverImageFile && realName
            ? {
                parentUuid: wsRecruitmentFormUuid,
                name: coverImageFile?.name,
                mimeType: coverImageFile?.type,
                realName,
                size: coverImageFile?.size,
              }
            : null,
      });

      dispatch(getExtensionList(contextData.uuid));
      dispatch(getProject(contextData.uuid));
      showToast(
        "success",
        <IDHFormattedMessage id="ws_success" defaultMessage="Success" />,
        <IDHFormattedMessage
          id="ws_recruitment_form_created"
          defaultMessage="Recruitment form has been created."
        />,
      );
    } catch (err) {
      console.error(err);
      showErrorToast();
    }
  };

  const createNewExtension = (wsModelUuid: string): void => {
    if (extensionType === ExtensionTypeEnum.RecruitmentForm) {
      createNewRecruitmentFormExtension(wsModelUuid);
    }

    if (extensionType === ExtensionTypeEnum.CreatorDatabaseSubmissionForm) {
      createNewCreatorDatabaseSubmissionFormExtension(wsModelUuid);
    }
  };

  const renderTitle = () => {
    if (extensionType === ExtensionTypeEnum.CreatorDatabaseSubmissionForm) {
      return (
        <IDHFormattedMessage
          id="ws_creator_registration_form"
          defaultMessage="Creator Registration Form"
        />
      );
    }

    return (
      <IDHFormattedMessage
        id="ws_campaign_recruitment_form"
        defaultMessage="Campaign Registration Form"
      />
    );
  };

  return (
    <ExtensionView
      goBackButtonLabel={
        <IDHFormattedMessage id="ws_extensions" defaultMessage="Extensions" />
      }
      handleGoBack={() =>
        setExtensionsSection(ExtensionsSection.ExtensionsList)
      }
      title={
        <>
          {renderTitle()}
          <CustomSwitch
            checked={extensionDetails.enabled}
            className="recruitment-form__switch"
            label={extensionDetails.enabled ? "Active" : "Disabled"}
            onChange={() => {
              if (extensionDetails.uuid) {
                updateRecruitmentFormStatus(
                  !extensionDetails.enabled,
                  extensionDetails.uuid,
                );
              } else {
                createNewExtension(contextData.uuid);
              }
            }}
            disabled={
              !contextData?.permissions?.project?.includes(
                ProjectPermissionsEnum.MANAGE_PROJECT_CONFIGURATION,
              ) &&
              !contextData?.permissions?.workspace?.includes(
                WorkspacePermissionsEnum.CREATOR_DATABASE_CONFIGURATION,
              )
            }
          />
        </>
      }
    >
      <div className="recruitment-form">
        {isExtensionDetailsLoading ? (
          <Loader />
        ) : (
          <>
            <div className="recruitment-form__content">
              <RecruitmentFormTabs
                extensionDetails={extensionDetails}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                extensionType={extensionType}
                isLoading={isLoading}
              />
              {renderContent()}
            </div>
            <SettingsButtons onClose={onClose} />
          </>
        )}
      </div>
      {howItWorksModalData.location === AppLocation.SettingsModal &&
        recruitmentListItem.type === ExtensionTypeEnum.RecruitmentForm && (
          <HowItWorksModal onClose={handleCloseHowItWorksModal} />
        )}
    </ExtensionView>
  );
}
