import React, { FormEvent, useEffect, useRef, useState } from "react";

import axios from "axios";
import { IntlShape, WrappedComponentProps, injectIntl } from "react-intl";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { uuidv7 as uuid } from "uuidv7";
import { isEmpty } from "lodash";

import { SettingsModal } from "src/app/SettingsModal/SettingsModal";
import { SettingsContext, SettingsTab } from "src/app/SettingsModal/enums";
import { Metadata } from "src/app/Task/Subtask/Subtask";
import { CoverImageUploader } from "src/app/components/CoverImageUploader/CoverImageUploader";
import { useSetCoverImageFile } from "src/app/components/CroppedImageUploader/utils/useUploadCoverImage";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { UploadFile } from "src/app/libs/uploadFile";
import { translateMessage } from "src/app/methods/translateMessage";
import { clearCreateNewProjectData } from "src/redux/main/mainActions";
import {
  AppLocation,
  CreateNewProjectData,
  SettingsModalDataProps,
} from "src/redux/main/mainReducer";
import { RootState } from "src/redux/reducers";
import responseCodes from "src/utils/responseCodes";
import { MAX_SIZE_PROJECT_COVER } from "src/utils/uploadFunctionsUtils";
import { ProjectTemplate, ProjectTemplateType } from "src/redux/project/types";
import { parseErrorMessages, showErrorToast } from "src/utils/methods";
import {
  ActionReturnType,
  clearGlobalMetaFieldProjectWizardFields,
  clearProjectBasicData,
  clearTasksCounters,
  getProjectTemplates,
  getProjectsList,
  openTrialExceededModal,
  setGlobalMetaFieldProjectWizardFields,
  setTasksLoading,
} from "../../../redux";
import { API_URLS } from "../../../utils/API_URLS";
import { ROUTES, getRoute, getUrlWithQueryParams } from "../../../utils/ROUTES";
import {
  closeSettingsModal,
  openSettingsModal,
} from "../../SettingsModal/methods";
import { Button } from "../../components/Button/Button";
import CustomInput, {
  CustomInputLabel,
} from "../../components/CustomInput/CustomInput";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import { currencySearchFunction } from "../../components/CustomSelect/functions/currencySearchFunction";
import FullscreenModal from "../../components/FullscreenModal/FullscreenModal";
import Loader from "../../components/Loader/Loader";
import { ModalColumn } from "../../components/Modal/Modal";
import PageHeader from "../../components/PageHeader/PageHeader";
import { showToast } from "../../methods/showToast";
import { CurrencyOption } from "../ManageFieldModal/types";
import SingleWizardField from "./components/SingleWizardField";
import { ProjectSetupType, SelectOption } from "./types";
import {
  getProjectSetupOptions,
  getProjectTemplateOptions,
  getProjectTemplateFields,
  isSubmissionFormEnabled,
} from "./utils";

import "./CreateNewProjectModal.scss";

interface Props extends WrappedComponentProps<"intl"> {
  projectsList: any;
  projectTemplates: ProjectTemplate[];
  getProjectsList: any;
  getProjectTemplates: (wsWorkspaceUuid: string) => ActionReturnType;
  globalMetaFieldProjectWizardFields: Metadata[];
  intl: IntlShape;
}

function CreateNewProjectModal({
  projectsList,
  getProjectsList,
  projectTemplates,
  getProjectTemplates,
  globalMetaFieldProjectWizardFields,
  intl,
}: Props) {
  const [newProjectName, setNewProjectName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCurrencyOption, setSelectedCurrencyOption] =
    useState<SelectOption | null>(null);
  const [currentProjectSetupOption, setCurrentProjectSetupOption] = useState(
    getProjectSetupOptions(intl)[0],
  );
  const [selectedBaseTemplateOption, setSelectedBaseTemplateOption] =
    useState<SelectOption | null>(null);
  const [
    selectedSocialPlatformTemplateOptions,
    setSelectedSocialPlatformTemplateOptions,
  ] = useState<SelectOption[]>([]);
  const [selectedExistingProjectOption, setSelectedExistingProjectOption] =
    useState<SelectOption | null>(null);
  const [currencyOptions, setCurrencyOptions] = useState<CurrencyOption[]>([]);
  const [showUploadLogoModal, setShowUploadLogoModal] =
    useState<boolean>(false);

  const projectUuid = useRef(uuid());

  const history = useHistory();
  const dispatch = useDispatch();

  const { coverImageFile, setNewImage, setCoverImageFile } =
    useSetCoverImageFile();

  const {
    activeWorkspaceUuid,
    settingsModalData,
    identity,
    createNewProjectData,
  }: {
    activeWorkspaceUuid: string;
    settingsModalData: SettingsModalDataProps;
    identity: any;
    createNewProjectData: CreateNewProjectData;
  } = useSelector((state: RootState) => state.mainReducer);

  const { selectedCreatorsPayload } = createNewProjectData;

  const getCurrencies = async () => {
    try {
      const { data } = await axios.get(API_URLS.getCurrencies);
      const currenciesData = data.content;
      setCurrencyOptions(
        Object.keys(currenciesData).map((code) => ({
          key: code,
          label: (
            <span className="currency-option">
              <span className="currency-code">{code}</span>
              {currenciesData[code]}
            </span>
          ),
          value: code,
        })),
      );
    } catch (error) {
      showToast(
        "error",
        translateMessage({
          intl,
          id: "ws_error",
          defaultMessage: "Error",
        }),
        translateMessage({
          intl,
          id: "ws_something_went_wrong",
          defaultMessage: "Something went wrong",
        }),
      );
    }
  };

  const navigateToProjects = () => {
    history.push(
      getRoute(ROUTES.PROJECTS, {
        wsWorkspaceUuid: activeWorkspaceUuid,
      }),
    );
  };

  const allSocialPlatformTemplateOptions = getProjectTemplateOptions(
    projectTemplates,
    ProjectTemplateType.SocialProvider,
  );

  const allBaseOptions = getProjectTemplateOptions(
    projectTemplates,
    ProjectTemplateType.Base,
  );

  const projectListOptions = projectsList.map(
    (project: { projectId: string; projectName: string }) => ({
      value: project.projectId,
      label: project.projectName,
    }),
  );

  const areRequiredFieldsNotFilled = () => {
    switch (currentProjectSetupOption.value) {
      case ProjectSetupType.Blank:
        return !selectedCurrencyOption?.value;
      case ProjectSetupType.Template: {
        const doRequiredFieldsHaveValue =
          globalMetaFieldProjectWizardFields.every((field) => {
            if (field.valueRequired) {
              return !isEmpty(field.defaultValue) || !isEmpty(field.value);
            }
            return true;
          });
        return (
          !selectedBaseTemplateOption?.value ||
          !selectedCurrencyOption?.value ||
          !doRequiredFieldsHaveValue
        );
      }
      case ProjectSetupType.BasedOnExistingProject:
        return !selectedExistingProjectOption;
      default:
        return true;
    }
  };

  let coverImageUrl = null;
  if (coverImageFile) {
    coverImageUrl = URL.createObjectURL(coverImageFile);
  } else {
    coverImageUrl = null;
  }

  const createCampaignButtonDisabled =
    !newProjectName.length || areRequiredFieldsNotFilled();

  const createProject = async (e: FormEvent<HTMLFormElement>) => {
    if (createCampaignButtonDisabled) return;

    e.preventDefault();
    setIsLoading(true);

    const wsProjectTemplatesUuids = [];

    if (selectedBaseTemplateOption) {
      wsProjectTemplatesUuids.push(selectedBaseTemplateOption.value);
    }

    if (selectedSocialPlatformTemplateOptions.length) {
      selectedSocialPlatformTemplateOptions.forEach((option) =>
        wsProjectTemplatesUuids.push(option.value),
      );
    }

    const fieldsFormattedForBackend = globalMetaFieldProjectWizardFields.map(
      (field) => ({
        wsGlobalProjectMetaFieldUuid: field.uuid,
        value: field.value || field.defaultValue,
      }),
    );

    const baseProjectSetupOptions = {
      wsProjectUuid: projectUuid.current,
      projectName: newProjectName.trim(),
      wsWorkspaceUuid: activeWorkspaceUuid,
      selectedCreatorsPayload,
    };

    try {
      if (
        currentProjectSetupOption.value ===
        ProjectSetupType.BasedOnExistingProject
      ) {
        await axios.post(API_URLS.createNewProjectBasedOnExistingOne, {
          ...baseProjectSetupOptions,
          originalWsProjectUuid: selectedExistingProjectOption?.value,
        });
      } else {
        await axios.post(API_URLS.createNewProject, {
          ...baseProjectSetupOptions,
          currencyCode: selectedCurrencyOption?.value,
          wsProjectTemplatesUuids,
          wsProjectMetaFieldsValues: fieldsFormattedForBackend,
        });
      }

      if (coverImageFile) {
        await uploadMedia();
      }

      dispatch(setTasksLoading(true));
      dispatch(clearProjectBasicData());
      dispatch(clearTasksCounters());

      if (
        isSubmissionFormEnabled(
          selectedBaseTemplateOption,
          selectedSocialPlatformTemplateOptions,
          projectTemplates,
        )
      ) {
        const url = new URL(window.location.href);
        url.searchParams.set(
          "view",
          JSON.stringify({
            howItWorks: { location: AppLocation.SingleProject },
          }),
        );
        history.push(
          `/workspace/${activeWorkspaceUuid}/projects/${projectUuid.current}/${url.search}`,
        );
      } else {
        history.push(
          getRoute(ROUTES.PROJECT, {
            wsWorkspaceUuid: activeWorkspaceUuid,
            wsProjectUuid: projectUuid.current,
          }),
        );
      }

      showToast(
        "success",
        translateMessage({
          intl,
          id: "ws_success",
          defaultMessage: "Success",
        }),
        translateMessage({
          intl,
          id: "ws_campaign_has_been_created",
          defaultMessage: "Campaign has been created",
        }),
      );
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.data?.errors) {
        if (
          error.response.data.statusCode ===
          responseCodes["402_PAYMENT_REQUIRED"]
        ) {
          dispatch(openTrialExceededModal());
        } else {
          const errorMessage = parseErrorMessages(error.response.data.errors)[0]
            .message;
          showErrorToast({
            id: errorMessage,
            defaultMessage: errorMessage,
          });
        }
      } else {
        showToast(
          "error",
          translateMessage({
            intl,
            id: "ws_error",
            defaultMessage: "Error",
          }),
          translateMessage({
            intl,
            id: "ws_campaign_has_not_been_created",
            defaultMessage: "Campaign hasn't been created",
          }),
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  const uploadMedia = async () => {
    const file = new UploadFile(
      coverImageFile as File,
      [],
      MAX_SIZE_PROJECT_COVER,
      getUrlWithQueryParams(API_URLS.presignedRequestForProjectCover, {
        wsProjectUuid: projectUuid.current,
        wsWorkspaceUuid: activeWorkspaceUuid,
      }),
      projectUuid.current,
      "ws_project",
      activeWorkspaceUuid,
    );

    try {
      await file.sendAllRequests();
    } catch (err) {
      console.error(err);
      showToast("error", "Error", "An error occurred when uploading the file.");
    }
  };

  useEffect(() => {
    if (currentProjectSetupOption.value !== ProjectSetupType.Template) {
      setSelectedBaseTemplateOption(null);
      setSelectedSocialPlatformTemplateOptions([]);
    }
  }, [currentProjectSetupOption]);

  useEffect(() => {
    if (currentProjectSetupOption.value === ProjectSetupType.Template) {
      const requiredFields = getProjectTemplateFields(
        selectedBaseTemplateOption,
        selectedSocialPlatformTemplateOptions,
        projectTemplates,
      );
      dispatch(setGlobalMetaFieldProjectWizardFields(requiredFields));
    } else {
      dispatch(setGlobalMetaFieldProjectWizardFields([]));
    }
  }, [
    selectedBaseTemplateOption,
    selectedSocialPlatformTemplateOptions,
    projectTemplates,
    currentProjectSetupOption,
  ]);

  useEffect(() => {
    getCurrencies();
    getProjectsList(activeWorkspaceUuid);
    getProjectTemplates(activeWorkspaceUuid);
  }, [activeWorkspaceUuid]);

  useEffect(() => {
    return () => {
      dispatch(clearGlobalMetaFieldProjectWizardFields());
      dispatch(clearCreateNewProjectData());
    };
  }, []);

  return (
    <>
      <FullscreenModal
        className="create-new-project-modal"
        onClose={navigateToProjects}
      >
        <div className="create-new-project-modal__wrapper">
          <PageHeader
            title={
              <IDHFormattedMessage
                id="ws_new_campaign"
                defaultMessage="Create a campaign"
              />
            }
          />
          <form
            className="create-new-project-modal__form"
            onSubmit={createProject}
          >
            <ModalColumn>
              <div className="create-new-project-modal__subtitle-wrapper">
                <h2>
                  <IDHFormattedMessage
                    id="ws_general"
                    defaultMessage="General"
                  />
                </h2>
              </div>
              <CustomInputLabel>
                <IDHFormattedMessage
                  id="ws_cover_photo"
                  defaultMessage="Cover photo"
                />
              </CustomInputLabel>
              <CoverImageUploader
                showUploadLogoModal={showUploadLogoModal}
                uploadFunction={setNewImage}
                openModal={() => setShowUploadLogoModal(true)}
                closeModal={() => setShowUploadLogoModal(false)}
                coverImageUrl={coverImageUrl}
                removeImage={() => {
                  setCoverImageFile(null);
                }}
              />
            </ModalColumn>
            <ModalColumn>
              <CustomInputLabel htmlFor="projectName" required>
                <IDHFormattedMessage
                  id="ws_campaign_name"
                  defaultMessage="Name"
                />
              </CustomInputLabel>
              <CustomInput
                id="projectName"
                type="text"
                value={newProjectName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setNewProjectName(e.target.value.trimStart())
                }
                placeholder={translateMessage({
                  intl,
                  id: "ws_type_campaign_name",
                  defaultMessage: "Type campaign name",
                })}
                wide
              />
            </ModalColumn>

            {currentProjectSetupOption.value !==
              ProjectSetupType.BasedOnExistingProject && (
              <ModalColumn>
                <div className="create-new-project-modal__subtitle-wrapper">
                  <h2>
                    <IDHFormattedMessage
                      id="ws_settings"
                      defaultMessage="Settings"
                    />
                  </h2>
                </div>
                <CustomInputLabel htmlFor="currency" required>
                  <IDHFormattedMessage
                    id="ws_currency"
                    defaultMessage="Currency"
                  />
                </CustomInputLabel>
                <CustomSelect
                  id="currency"
                  isSearchable
                  value={selectedCurrencyOption}
                  onChange={setSelectedCurrencyOption}
                  options={currencyOptions}
                  placeholder={translateMessage({
                    intl,
                    id: "ws_select_currency",
                    defaultMessage: "Select currency",
                  })}
                  filterOption={currencySearchFunction}
                />
              </ModalColumn>
            )}

            <ModalColumn>
              <div className="create-new-project-modal__subtitle-wrapper">
                <h2>
                  <IDHFormattedMessage
                    id="ws_table_columns"
                    defaultMessage="Table columns"
                  />
                </h2>
              </div>
              <CustomInputLabel htmlFor="setup" required>
                <IDHFormattedMessage id="ws_setup" defaultMessage="Setup" />
              </CustomInputLabel>
              <CustomSelect
                id="setup"
                value={currentProjectSetupOption}
                onChange={setCurrentProjectSetupOption}
                options={getProjectSetupOptions(intl)}
              />
            </ModalColumn>

            {currentProjectSetupOption.value !== ProjectSetupType.Blank && (
              <>
                <ModalColumn className="select-project-structure">
                  <CustomInputLabel htmlFor="template" required>
                    <IDHFormattedMessage
                      id="ws_template"
                      defaultMessage="Template"
                    />
                  </CustomInputLabel>
                  {currentProjectSetupOption.value ===
                    ProjectSetupType.Template && (
                    <CustomSelect
                      id="template"
                      closeMenuOnSelect
                      value={selectedBaseTemplateOption}
                      options={allBaseOptions}
                      placeholder={translateMessage({
                        intl,
                        id: "ws_select_template",
                        defaultMessage: "Select template",
                      })}
                      onChange={setSelectedBaseTemplateOption}
                    />
                  )}
                  {currentProjectSetupOption.value ===
                    ProjectSetupType.BasedOnExistingProject && (
                    <CustomSelect
                      id="template"
                      value={selectedExistingProjectOption}
                      options={projectListOptions}
                      isSearchable
                      placeholder={translateMessage({
                        intl,
                        id: "ws_select_campaign",
                        defaultMessage: "Select campaign",
                      })}
                      onChange={setSelectedExistingProjectOption}
                    />
                  )}
                </ModalColumn>
                {currentProjectSetupOption.value ===
                  ProjectSetupType.Template && (
                  <ModalColumn className="select-project-structure">
                    <CustomInputLabel htmlFor="select-social-platforms">
                      <IDHFormattedMessage
                        id="ws_select_social_platforms"
                        defaultMessage="Select Social Platforms"
                      />
                    </CustomInputLabel>
                    <CustomSelect
                      id="select-social-platforms"
                      isMulti
                      closeMenuOnSelect={false}
                      value={selectedSocialPlatformTemplateOptions}
                      options={allSocialPlatformTemplateOptions}
                      placeholder={translateMessage({
                        intl,
                        id: "ws_select_platforms",
                        defaultMessage: "Select platforms",
                      })}
                      onChange={setSelectedSocialPlatformTemplateOptions}
                    />
                  </ModalColumn>
                )}
              </>
            )}

            {globalMetaFieldProjectWizardFields.length > 0 && (
              <ModalColumn>
                <div className="create-new-project-modal__subtitle-wrapper">
                  <h2>
                    <IDHFormattedMessage
                      id="ws_details"
                      defaultMessage="Details"
                    />
                  </h2>
                </div>
                <div className="create-new-project-modal__wizard-fields">
                  {globalMetaFieldProjectWizardFields.map((field) => (
                    <SingleWizardField field={field} key={field.uuid} />
                  ))}
                </div>
              </ModalColumn>
            )}

            <div className="create-new-project-modal__required-info">
              <span className="create-new-project-modal__required-info">*</span>
              <IDHFormattedMessage id="ws_required" defaultMessage="Required" />
            </div>

            <div>
              {isLoading ? (
                <Loader />
              ) : (
                <Button
                  type="submit"
                  size="medium"
                  variant="blue"
                  disabled={createCampaignButtonDisabled}
                >
                  <IDHFormattedMessage
                    id="ws_create_campaign"
                    defaultMessage="Create campaign"
                  />
                </Button>
              )}
            </div>
          </form>
        </div>
      </FullscreenModal>
      {settingsModalData?.location === AppLocation.CreateNewProject && (
        <SettingsModal
          onClose={() => closeSettingsModal(history)}
          members={{
            openShareModal: () =>
              openSettingsModal(
                history,
                AppLocation.CreateNewProject,
                SettingsContext.Project,
                SettingsTab.Members,
              ),
          }}
          contextTargetUuid={identity?.lastWorkspaceUuid || ""}
          disableTransition
        />
      )}
    </>
  );
}

const mapStateToProps = (state: any) => ({
  projectsList: state.projectReducer.projectsList,
  projectTemplates: state.projectReducer.projectTemplates,
  projectTemplatesLoading: state.projectReducer.projectTemplatesLoading,
  globalMetaFieldProjectWizardFields:
    state.projectReducer.globalMetaFieldProjectWizardFields,
});

const mapDispatchToProps = (dispatch: any) => ({
  getProjectsList: (id: string) => dispatch(getProjectsList(id)),
  getProjectTemplates: (id: string) => dispatch(getProjectTemplates(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(CreateNewProjectModal));
