import React, { useEffect, useState } from "react";

import { useIntl } from "react-intl";

import Modal, { ModalTitle } from "src/app/components/Modal/Modal";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import CustomInput, {
  CustomInputLabel,
} from "src/app/components/CustomInput/CustomInput";
import { CustomTokenInput } from "src/app/components/CustomTokenInput/CustomTokenInput";
import { translateMessage } from "src/app/methods/translateMessage";
import { useDispatch, useSelector } from "react-redux";
import { getCouponGroupDetails } from "src/redux/coupon/couponActions";
import { uuidv7 as uuid } from "uuidv7";
import { Coupon, CouponGroup } from "src/redux/coupon/couponReducer";
import axios from "axios";
import { RootState } from "src/redux/reducers";
import { API_URLS } from "../../../../utils/API_URLS";
import { showToast } from "../../../methods/showToast";

interface Props {
  onClose: () => void;
  groupData: CouponGroup;
}

export const CouponGroupSettingsModal: React.FC<Props> = (props) => {
  const { onClose, groupData } = props;

  const {
    couponReducer: { couponsList, couponGroupDetails },
    mainReducer: { activeWorkspaceUuid },
  } = useSelector((state: RootState) => state);

  const [name, setName] = useState(groupData.name);
  const [codes, setCodes] = useState<string[]>([]);
  const [confirmButtonLoading, setConfirmButtonLoading] =
    useState<boolean>(false);
  const [confirmButtonDisabled, setConfirmButtonDisabled] =
    useState<boolean>(true);

  const intl = useIntl();
  const dispatch = useDispatch();

  useEffect(() => {
    const setCouponData = async () => {
      if (couponGroupDetails.uuid === groupData.uuid) {
        await setConfirmButtonDisabled(true);
        setCodes(couponsList.map((item: Coupon) => item.code) || []);
        setConfirmButtonDisabled(false);
      }
    };

    setCouponData();
  }, [couponsList]);

  const handleConfirm = async () => {
    if (confirmButtonLoading) {
      return;
    }

    setConfirmButtonLoading(true);
    const coupons = codes.map((code) => {
      const coupon = couponsList.find((coupon: Coupon) => coupon.code === code);
      if (coupon) return coupon;

      return {
        campaign: null,
        code,
        task: null,
        uuid: uuid(),
        isAssigned: false,
        hasAccess: true,
      };
    });

    axios
      .post(API_URLS.updateCouponGroup, {
        name,
        coupons,
        wsCouponGroupUuid: groupData.uuid,
        wsWorkspaceUuid: activeWorkspaceUuid,
      })
      .then((response) => {
        const {
          data: { content },
        } = response;
        if (content?.duplicateCoupons?.length > 0) {
          showToast(
            "warning",
            <IDHFormattedMessage id="ws_warning" defaultMessage="Warning" />,
            <IDHFormattedMessage
              id="ws_coupon_duplicates"
              defaultMessage="Coupon duplicates"
            />,
          );
        }

        if (content?.addedCoupons?.length > 0) {
          showToast(
            "success",
            <IDHFormattedMessage id="ws_success" defaultMessage="Success" />,
            <IDHFormattedMessage
              id="ws_coupon_added"
              defaultMessage="Coupon added"
            />,
          );
        }

        dispatch(getCouponGroupDetails(groupData.uuid));
        onClose();
      })
      .catch((error) => {
        const errorMessages = error?.response?.data?.errors;

        if (errorMessages?.length > 0) {
          for (let i = 0; i < errorMessages.length; i += 1) {
            showToast(
              "error",
              <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
              <IDHFormattedMessage
                id={errorMessages[i].message}
                defaultMessage={
                  errorMessages[i].message ?? "Something went wrong"
                }
              />,
            );
          }
        } else {
          showToast(
            "error",
            <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
            <IDHFormattedMessage
              id="ws_something_went_wrong"
              defaultMessage="Something went wrong"
            />,
          );
        }
      })
      .finally(() => setConfirmButtonLoading(false));
  };

  return (
    <Modal
      className="add-coupon-group-modal"
      onClose={onClose}
      onConfirmClick={handleConfirm}
      confirmButtonLoading={confirmButtonLoading}
      confirmButtonDisabled={confirmButtonDisabled}
      confirmButtonText={
        <IDHFormattedMessage id="ws_save" defaultMessage="Save" />
      }
      displayCancelButton
    >
      <ModalTitle>
        <IDHFormattedMessage
          id="ws_coupon_group_settings"
          defaultMessage="Coupon group settings"
        />
      </ModalTitle>

      <div className="add-coupon-group-modal__input-wrapper">
        <CustomInputLabel htmlFor="name">
          <IDHFormattedMessage id="ws_name" defaultMessage="Name" />
        </CustomInputLabel>
        <CustomInput
          id="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder={translateMessage({
            intl,
            id: "ws_enter_group_name",
            defaultMessage: "Enter group name",
          })}
        />
      </div>

      <div className="add-coupon-group-modal__input-wrapper">
        <CustomInputLabel htmlFor="codes">
          <IDHFormattedMessage
            id="ws_promo_codes"
            defaultMessage="Promo Codes"
          />
        </CustomInputLabel>
        <CustomTokenInput
          value={codes}
          onChange={(newValue: any) => setCodes(newValue)}
          placeholder={
            !codes.length
              ? translateMessage({
                  intl,
                  id: "ws_enter_promo_codes_separated_by_spaces",
                  defaultMessage: "Enter promo codes separated by spaces",
                })
              : ""
          }
          separators={[" ", "\n"]}
        />
      </div>
    </Modal>
  );
};
