import React from "react";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";

export const GLOSSARY_DATA_PART_ONE = {
  left: [
    {
      icon: "actionRate",
      title: (
        <IDHFormattedMessage
          id="ws_report_glossary_actionRate_title"
          defaultMessage="Action Rate"
        />
      ),
      text: (
        <IDHFormattedMessage
          id="ws_report_glossary_actionRate_text"
          defaultMessage="Ratio that measures sum of sticker taps and link clicks in relation to the real reach."
        />
      ),
    },
    {
      icon: "avgEr",
      title: (
        <IDHFormattedMessage
          id="ws_report_glossary_avgEr_title"
          defaultMessage="Avg. ER"
        />
      ),
      text: (
        <IDHFormattedMessage
          id="ws_report_glossary_avgEr_text"
          defaultMessage="Average Engagement Rate is the mean average ratio of engagements in relation to the follower number."
        />
      ),
    },
    {
      icon: "avgEer",
      title: (
        <IDHFormattedMessage
          id="ws_report_glossary_avgEer_title"
          defaultMessage="Avg. eER"
        />
      ),
      text: (
        <IDHFormattedMessage
          id="ws_report_glossary_avgEer_text"
          defaultMessage="Average Effective Engagements Rate is the mean average ratio of engagements in relation to the real reach."
        />
      ),
    },
    {
      icon: "avgVr",
      title: (
        <IDHFormattedMessage
          id="ws_report_glossary_avgVr_title"
          defaultMessage="Avg. VR"
        />
      ),
      text: (
        <IDHFormattedMessage
          id="ws_report_glossary_avgVr_text"
          defaultMessage="Average Viewability Rate is the ratio of real reach (unique views) in relation to the followers."
        />
      ),
    },
    {
      icon: "avgViewPercentage",
      title: (
        <IDHFormattedMessage
          id="ws_report_glossary_avgViewPercentage_title"
          defaultMessage="Avg. Percentage Viewed"
        />
      ),
      text: (
        <IDHFormattedMessage
          id="ws_report_glossary_avgViewPercentage_text"
          defaultMessage="Average Percentage Viewed is the total watch-time of a video divided by the number of video plays (including replays) video gets."
        />
      ),
    },
    {
      icon: "avgEngagements",
      title: (
        <IDHFormattedMessage
          id="ws_report_glossary_avgEngagements_title"
          defaultMessage="Avg. Engagements"
        />
      ),
      text: (
        <IDHFormattedMessage
          id="ws_report_glossary_avgEngagements_text"
          defaultMessage="Average Engagements is the mean average number of engagements."
        />
      ),
    },
    {
      icon: "avgImpressions",
      title: (
        <IDHFormattedMessage
          id="ws_report_glossary_avgImpressions_title"
          defaultMessage="Avg. Impressions"
        />
      ),
      text: (
        <IDHFormattedMessage
          id="ws_report_glossary_avgImpressions_text"
          defaultMessage="Average Impressions is the mean average number of impressions."
        />
      ),
    },
    {
      icon: "avgViews",
      title: (
        <IDHFormattedMessage
          id="ws_report_glossary_avgViews_title"
          defaultMessage="Avg. Views"
        />
      ),
      text: (
        <IDHFormattedMessage
          id="ws_report_glossary_avgViews_text"
          defaultMessage="Average Views is the mean average number of views."
        />
      ),
    },
    {
      icon: "comments",
      title: (
        <IDHFormattedMessage
          id="ws_report_glossary_comments_title"
          defaultMessage="Comments"
        />
      ),
      text: (
        <IDHFormattedMessage
          id="ws_report_glossary_comments_text"
          defaultMessage="Number of Comments on the publication."
        />
      ),
    },
    {
      icon: "dislikes",
      title: (
        <IDHFormattedMessage
          id="ws_report_glossary_dislikes_title"
          defaultMessage="Dislikes"
        />
      ),
      text: (
        <IDHFormattedMessage
          id="ws_report_glossary_dislikes_text"
          defaultMessage="Number of times that users indicated that they disliked a video by giving it a negative rating."
        />
      ),
    },
    {
      icon: "er",
      title: (
        <IDHFormattedMessage
          id="ws_report_glossary_er_title"
          defaultMessage="ER"
        />
      ),
      text: (
        <IDHFormattedMessage
          id="ws_report_glossary_er_text"
          defaultMessage="Ratio that measures engagements as the sum of likes and comments in relation to the number of followers."
        />
      ),
    },
    {
      icon: "eer",
      title: (
        <IDHFormattedMessage
          id="ws_report_glossary_eer_title"
          defaultMessage="eER"
        />
      ),
      text: (
        <IDHFormattedMessage
          id="ws_report_glossary_eer_text"
          defaultMessage="Ratio that measures engagements as the sum of likes and comments in relation to the real reach."
        />
      ),
    },
  ],
  right: [
    {
      icon: "impressions",
      title: (
        <IDHFormattedMessage
          id="ws_report_glossary_impressions_title"
          defaultMessage="Impressions"
        />
      ),
      text: (
        <IDHFormattedMessage
          id="ws_report_glossary_impressions_text"
          defaultMessage="Number of times content was shown to users."
        />
      ),
    },
    {
      icon: "estimatedMinutesWatched",
      title: (
        <IDHFormattedMessage
          id="ws_report_glossary_estimatedMinutesWatched_title"
          defaultMessage="Estimated Minutes Watched"
        />
      ),
      text: (
        <IDHFormattedMessage
          id="ws_report_glossary_estimatedMinutesWatched_text"
          defaultMessage="The number of minutes that users watched videos for the specified channel, content owner, video, or playlist."
        />
      ),
    },
    {
      icon: "linkClicks",
      title: (
        <IDHFormattedMessage
          id="ws_report_glossary_linkClicks_title"
          defaultMessage="Link Clicks"
        />
      ),
      text: (
        <IDHFormattedMessage
          id="ws_report_glossary_linkClicks_text"
          defaultMessage="Number of times users swipe-up on a story to follow a link."
        />
      ),
    },
    {
      icon: "likes",
      title: (
        <IDHFormattedMessage
          id="ws_report_glossary_likes_title"
          defaultMessage="Likes"
        />
      ),
      text: (
        <IDHFormattedMessage
          id="ws_report_glossary_likes_text"
          defaultMessage="Total number of likes on the publication."
        />
      ),
    },
    {
      icon: "realReach",
      title: (
        <IDHFormattedMessage
          id="ws_report_glossary_realReach_title"
          defaultMessage="Real Reach"
        />
      ),
      text: (
        <IDHFormattedMessage
          id="ws_report_glossary_realReach_text"
          defaultMessage="The total number of users that have seen the publications."
        />
      ),
    },
    {
      icon: "realReachToFollowersRatio",
      title: (
        <IDHFormattedMessage
          id="ws_report_glossary_realReachToFollowersRatio_title"
          defaultMessage="Real Reach to Followers Ratio"
        />
      ),
      text: (
        <IDHFormattedMessage
          id="ws_report_glossary_realReachToFollowersRatio_text"
          defaultMessage="Ratio that measures real reach in relation to the number of followers."
        />
      ),
    },
    {
      icon: "saves",
      title: (
        <IDHFormattedMessage
          id="ws_report_glossary_saves_title"
          defaultMessage="Saves"
        />
      ),
      text: (
        <IDHFormattedMessage
          id="ws_report_glossary_saves_text"
          defaultMessage="Number of unique accounts that saved the publications."
        />
      ),
    },
    {
      icon: "savesToRealReachRatio",
      title: (
        <IDHFormattedMessage
          id="ws_report_glossary_savesToRealReachRatio_title"
          defaultMessage="Saves to Real Reach ratio"
        />
      ),
      text: (
        <IDHFormattedMessage
          id="ws_report_glossary_savesToRealReachRatio_text"
          defaultMessage="Ratio that measures number of saves in relation to the Real Reach."
        />
      ),
    },
    {
      icon: "shares",
      title: (
        <IDHFormattedMessage
          id="ws_report_glossary_shares_title"
          defaultMessage="Shares"
        />
      ),
      text: (
        <IDHFormattedMessage
          id="ws_report_glossary_shares_text"
          defaultMessage="Number of times the publications have been shared."
        />
      ),
    },
    {
      icon: "stickerTaps",
      title: (
        <IDHFormattedMessage
          id="ws_report_glossary_stickerTaps_title"
          defaultMessage="Story Sticker Taps"
        />
      ),
      text: (
        <IDHFormattedMessage
          id="ws_report_glossary_stickerTaps_text"
          defaultMessage="Number of interactions with the stickers placed in the publication."
        />
      ),
    },
    {
      icon: "views",
      title: (
        <IDHFormattedMessage
          id="ws_report_glossary_views_title"
          defaultMessage="Views"
        />
      ),
      text: (
        <IDHFormattedMessage
          id="ws_report_glossary_views_text"
          defaultMessage="Number of times the publication has been viewed."
        />
      ),
    },
    {
      icon: "vr",
      title: (
        <IDHFormattedMessage
          id="ws_report_glossary_vr_title"
          defaultMessage="VR"
        />
      ),
      text: (
        <IDHFormattedMessage
          id="ws_report_glossary_vr_text"
          defaultMessage="Viewability Rate is the ratio that measures the real reach (unique views) in relation to the followers."
        />
      ),
    },
    {
      icon: "watchedFullVideo",
      title: (
        <IDHFormattedMessage
          id="ws_report_glossary_watchedFullVideo_title"
          defaultMessage="Watched Full Video"
        />
      ),
      text: (
        <IDHFormattedMessage
          id="ws_report_glossary_watchedFullVideo_text"
          defaultMessage="Number of times the publication has been watched in full."
        />
      ),
    },
  ],
};

export const GLOSSARY_DATA_PART_TWO = {
  left: [
    {
      icon: "storySwipeForward",
      title: (
        <IDHFormattedMessage
          id="ws_report_glossary_storySwipeForward_title"
          defaultMessage="Story Swipe Forward"
        />
      ),
      text: (
        <IDHFormattedMessage
          id="ws_report_glossary_storySwipeForward_text"
          defaultMessage="Indicates the ratio of comments relating to the product."
        />
      ),
    },
    {
      icon: "storyTapsBack",
      title: (
        <IDHFormattedMessage
          id="ws_report_glossary_storyTapsBack_title"
          defaultMessage="Story Taps Back"
        />
      ),
      text: (
        <IDHFormattedMessage
          id="ws_report_glossary_storyTapsBack_text"
          defaultMessage="Indicates..."
        />
      ),
    },
    {
      icon: "tapsForward",
      title: (
        <IDHFormattedMessage
          id="ws_report_glossary_tapsForward_title"
          defaultMessage="Taps Forward"
        />
      ),
      text: (
        <IDHFormattedMessage
          id="ws_report_glossary_tapsForward_text"
          defaultMessage="Indicates..."
        />
      ),
    },
    {
      icon: "emv",
      title: (
        <IDHFormattedMessage
          id="ws_report_glossary_emv_title"
          defaultMessage="EMV"
        />
      ),
      text: (
        <IDHFormattedMessage
          id="ws_report_glossary_emv_text"
          defaultMessage="Earned Media Value is the estimated monetary value of publications."
        />
      ),
    },
    {
      icon: "positive",
      title: (
        <IDHFormattedMessage
          id="ws_report_glossary_positiveSentiment_title"
          defaultMessage="Positive Sentiment"
        />
      ),
      text: (
        <IDHFormattedMessage
          id="ws_report_glossary_positiveSentiment_text"
          defaultMessage="Indicates the ratio of positively categorized comments."
        />
      ),
    },
    {
      icon: "negative",
      title: (
        <IDHFormattedMessage
          id="ws_report_glossary_negativeSentiment_title"
          defaultMessage="Negative Sentiment"
        />
      ),
      text: (
        <IDHFormattedMessage
          id="ws_report_glossary_negativeSentiment_text"
          defaultMessage="Indicates the ratio of negatively categorized comments."
        />
      ),
    },
  ],
  right: [
    {
      icon: "storyExits",
      title: (
        <IDHFormattedMessage
          id="ws_report_glossary_storyExits_title"
          defaultMessage="Story Exits"
        />
      ),
      text: (
        <IDHFormattedMessage
          id="ws_report_glossary_storyExits_text"
          defaultMessage="Indicates..."
        />
      ),
    },
    {
      icon: "storyFollows",
      title: (
        <IDHFormattedMessage
          id="ws_report_glossary_storyFollows_title"
          defaultMessage="Story Follows"
        />
      ),
      text: (
        <IDHFormattedMessage
          id="ws_report_glossary_storyFollows_text"
          defaultMessage="Indicates how many accounts have started following you."
        />
      ),
    },
    {
      icon: "storyReplies",
      title: (
        <IDHFormattedMessage
          id="ws_report_glossary_storyReplies_title"
          defaultMessage="Story Replies"
        />
      ),
      text: (
        <IDHFormattedMessage
          id="ws_report_glossary_storyReplies_text"
          defaultMessage="Indicates..."
        />
      ),
    },
    {
      icon: "neutral",
      title: (
        <IDHFormattedMessage
          id="ws_report_glossary_neutralSentiment_title"
          defaultMessage="Neutral Sentiment"
        />
      ),
      text: (
        <IDHFormattedMessage
          id="ws_report_glossary_neutralSentiment_text"
          defaultMessage="Indicates the ratio of neutrally categorized comments."
        />
      ),
    },
    {
      icon: "productMentions",
      title: (
        <IDHFormattedMessage
          id="ws_report_glossary_productMentions_title"
          defaultMessage="Product Mentions"
        />
      ),
      text: (
        <IDHFormattedMessage
          id="ws_report_glossary_productMentions_text"
          defaultMessage="Indicates the ratio of neutrally categorized comments."
        />
      ),
    },
  ],
};
