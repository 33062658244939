import React from "react";

import { SocialProfileFlags, SocialProfileStatus } from "src/types";
import { SocialProfile } from "src/app/modals/AddSocialProfilesModal/AddSocialProfilesModal";
import IDHFormattedMessage from "../../IDHFormattedMessage/IDHFormattedMessage";

export const getSocialProfileStatusNameBasedOnStatus = (
  status: SocialProfileStatus,
): string => {
  if (status === SocialProfileStatus.Fresh) {
    return "Fresh";
  }

  if (status === SocialProfileStatus.Verified) {
    return "Verified";
  }

  if (status === SocialProfileStatus.Error) {
    return "Error";
  }

  if (status === SocialProfileStatus.NotFound) {
    return "NotFound";
  }

  return "unknown";
};

export const getTooltipBySocialProfileStatus = (
  status: SocialProfileStatus,
  flags: SocialProfileFlags[],
) => {
  if (status === SocialProfileStatus.Fresh) {
    return (
      <div>
        <strong>
          <IDHFormattedMessage
            id="ws_social_profile_fresh_header"
            defaultMessage="Please wait patiently..."
          />
        </strong>
        <br />
        <IDHFormattedMessage
          id="ws_social_profile_status_fresh"
          defaultMessage="We are gathering data for this account. It will be available within a few minutes."
        />
      </div>
    );
  }

  if (status === SocialProfileStatus.Error) {
    return (
      <IDHFormattedMessage
        id="ws_social_profile_status_error"
        defaultMessage={getSocialProfileStatusNameBasedOnStatus(status)}
      />
    );
  }

  if (status === SocialProfileStatus.NotFound) {
    return (
      <div>
        <strong>
          <IDHFormattedMessage
            id="ws_social_profile_not_found_header"
            defaultMessage="Profile data for this account was not found."
          />
        </strong>
        <br />
        <IDHFormattedMessage
          id="ws_social_profile_not_found"
          defaultMessage="The account does not exist. Please check the correctness of the account name or link."
        />
      </div>
    );
  }

  if (flags.includes(SocialProfileFlags.Restricted)) {
    return (
      <div>
        <strong>
          <IDHFormattedMessage
            id="ws_social_profile_restricted_header"
            defaultMessage="Profile data for this account is unavailable."
          />
        </strong>
        <br />
        <IDHFormattedMessage
          id="ws_social_profile_restricted"
          defaultMessage="The account is restricted."
        />
      </div>
    );
  }

  if (flags.includes(SocialProfileFlags.Private)) {
    return (
      <div>
        <strong>
          <IDHFormattedMessage
            id="ws_social_profile_private_header"
            defaultMessage="Profile data for this account is unavailable."
          />
        </strong>
        <br />
        <IDHFormattedMessage
          id="ws_social_profile_private"
          defaultMessage="The account is private."
        />
      </div>
    );
  }

  if (flags.includes(SocialProfileFlags.ApiPaired)) {
    return (
      <IDHFormattedMessage
        id="ws_social_profile_api_paired"
        defaultMessage="Api Paired"
      />
    );
  }

  return (
    <IDHFormattedMessage
      id="ws_social_profile_status_unknown"
      defaultMessage={getSocialProfileStatusNameBasedOnStatus(status)}
    />
  );
};

export const socialProfileStatusesWithDecreasedOpacity = [
  SocialProfileStatus.NotFound,
  SocialProfileStatus.Error,
  SocialProfileStatus.Fresh,
];

export const socialProfileStatusesWithWarning = [
  SocialProfileStatus.Error,
  SocialProfileStatus.NotFound,
];

export const socialProfileStatusesWithTooltip = [
  SocialProfileStatus.Fresh,
  SocialProfileStatus.Error,
  SocialProfileStatus.NotFound,
];

export const socialProfileFlagsToDisplayWarningIcon = [
  SocialProfileFlags.Restricted,
  SocialProfileFlags.Private,
];

export const existAtLeastOneSocialProfileWithWarningIcon = (
  arr: SocialProfile[],
  statuses: SocialProfileStatus[],
): boolean => {
  return arr.some(
    (item) =>
      statuses.includes(item.status) ||
      item?.flags?.some((flag) =>
        socialProfileFlagsToDisplayWarningIcon.includes(flag),
      ),
  );
};

export const existAtLeastOneSocialProfileWithStatus = (
  status: SocialProfileStatus,
  arr: SocialProfile[],
): boolean => {
  return arr.some((item) => item.status === status);
};
