import { Metadata } from "src/app/Task/Subtask/Subtask";
import { ReactNode } from "react";
import { API_URLS } from "../../utils/API_URLS";
import {
  CLEAR_REPORT_STATE,
  GET_GENERATOR_DATA,
  SET_APPLY_BOXES_CONTENT,
  SET_CREATORS_METADATA,
  SET_GENERATOR_DATA,
  SET_GRID_ELEMENTS,
  SET_HIDDEN_GRID_ELEMENTS,
  SET_LAYOUT,
  SET_LOADER_TEXT,
  SET_LOADING,
  SET_NEWLY_ADDED_PAGES,
  SET_NUMBERED_HEADERS,
  SET_PRINTING,
  SET_PUBLICATIONS_METADATA,
  SET_REPORT_SAVED_TEMPLATE,
  SET_REPORT_SELECTED_TEMPLATE,
  SET_SHOW_LOADER,
  SET_REPORT_SEPARATORS,
  SET_SUMMARY_STATISTICS,
  SET_PUBLICATIONS_SUMMARY_STATS,
} from "./reportTypes";

export function getGeneratorData(projectId: string) {
  const url = API_URLS.getPublicationReportData.replace(
    ":projectUuid:",
    projectId,
  );

  return {
    type: GET_GENERATOR_DATA,
    payload: {
      request: {
        url,
      },
    },
  };
}

export function setGeneratorData(newGeneratorData: any) {
  const {
    creatorsList,
    projectData,
    creatorInsights,
    audienceData,
    publicationsList,
    publicationsSummary,
  } = newGeneratorData;

  return {
    type: SET_GENERATOR_DATA,
    payload: {
      creatorsList,
      projectData,
      creatorInsights,
      audienceData,
      publicationsList,
      publicationsSummary,
    },
  };
}

export function setReportLayout(layout: any) {
  return {
    type: SET_LAYOUT,
    payload: { layout },
  };
}

export function setReportGridElements(gridElements: any) {
  return {
    type: SET_GRID_ELEMENTS,
    payload: { gridElements },
  };
}

export function setReportHiddenGridElements(hiddenGridElements: any) {
  return {
    type: SET_HIDDEN_GRID_ELEMENTS,
    payload: { hiddenGridElements },
  };
}

export function setReportSeparators(separators: any) {
  return {
    type: SET_REPORT_SEPARATORS,
    payload: { separators },
  };
}

export function setReportSavedTemplate(savedTemplate: any[]) {
  return {
    type: SET_REPORT_SAVED_TEMPLATE,
    payload: { savedTemplate },
  };
}

export function setReportSelectedTemplate(selectedTemplate: any[]) {
  return {
    type: SET_REPORT_SELECTED_TEMPLATE,
    payload: { selectedTemplate },
  };
}

export function clearReportState() {
  return {
    type: CLEAR_REPORT_STATE,
  };
}

export function setReportLoading(loading: boolean) {
  return {
    type: SET_LOADING,
    payload: { loading },
  };
}

export function setReportPrinting(printing: boolean) {
  return {
    type: SET_PRINTING,
    payload: { printing },
  };
}

export function setShowReportLoader(showLoader: boolean) {
  return {
    type: SET_SHOW_LOADER,
    payload: { showLoader },
  };
}

export function setReportLoaderText(loaderText: string | ReactNode) {
  return {
    type: SET_LOADER_TEXT,
    payload: { loaderText },
  };
}

export function setNumberedHeaders(numberedHeaders: any[]) {
  return {
    type: SET_NUMBERED_HEADERS,
    payload: { numberedHeaders },
  };
}

export function setNewlyAddedPages(newlyAddedPages: any) {
  return {
    type: SET_NEWLY_ADDED_PAGES,
    payload: { newlyAddedPages },
  };
}

export function setApplyBoxesContent(applyBoxesContent: boolean) {
  return {
    type: SET_APPLY_BOXES_CONTENT,
    payload: { applyBoxesContent },
  };
}

export function setCreatorsMetadata(creatorsMetadata: Metadata[]) {
  return {
    type: SET_CREATORS_METADATA,
    payload: { creatorsMetadata },
  };
}

export function setPublicationSummaryStats(
  provider: string,
  publicationSummaryStats: any,
) {
  return {
    type: SET_PUBLICATIONS_SUMMARY_STATS,
    payload: { provider, publicationSummaryStats },
  };
}

export function setPublicationsMetadata(publicationsMetadata: Metadata[]) {
  return {
    type: SET_PUBLICATIONS_METADATA,
    payload: { publicationsMetadata },
  };
}

export function setSummaryStatistics(provider: string, summaryStatistics: any) {
  return {
    type: SET_SUMMARY_STATISTICS,
    payload: { provider, summaryStatistics },
  };
}
