export const addGlossaryToLayout = (newLayout) => {
  const lastEl = newLayout[newLayout.length - 1];

  const x = 0;
  const y = lastEl.y + lastEl.h;
  const w = 12;
  const h = 15;

  newLayout.push({ i: "glossary", x, y, w, h });
  newLayout.push({ i: "glossary-two", x, y: y + h + 1, w, h });
};
