import React, { useState } from "react";
import { useSelector } from "react-redux";

import { RootState } from "src/redux/reducers";
import TemplatesList from "./TemplatesList";
import { Template } from "./types";
import EditedTemplate from "./EditedTemplate";
import { SettingsButtons } from "../../components/SettingsTabContent/SettingsTabContent";
import { SettingsTab } from "../../enums";

import "./TemplateManager.scss";

interface TemplateManagerProps {
  onClose: () => void;
}

function TemplateManager({ onClose }: TemplateManagerProps) {
  const [editedTemplate, setEditedTemplate] = useState<Template | null>(null);

  const { settingsModalData } = useSelector(
    (state: RootState) => state.mainReducer,
  );

  const { tab } = settingsModalData;

  return (
    <div className="template-manager">
      {editedTemplate && (
        <EditedTemplate
          editedTemplate={editedTemplate}
          setEditedTemplate={setEditedTemplate}
        />
      )}
      {tab === SettingsTab.TemplateManager && !editedTemplate && (
        <TemplatesList setEditedTemplate={setEditedTemplate} />
      )}
      <SettingsButtons onClose={onClose} />
    </div>
  );
}

export default TemplateManager;
