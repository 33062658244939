import React, { useEffect, useMemo, useState } from "react";
import "./ReportToolbar.scss";

import axios from "axios";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";

import { useParams } from "react-router";
import { ManagePagesModal } from "src/app/generator-data/modals/ManagePagesModal/ManagePagesModal";
import { Button } from "src/app/components/Button/Button";
import { formatDistance } from "date-fns";
import moment from "moment";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { injectIntl } from "react-intl";
import { translateMessage } from "src/app/methods/translateMessage";
import { Img } from "src/app/components/Img/Img";
import { API_URLS } from "../../../../utils/API_URLS";
import {
  getImageSource,
  scrollbarVisible,
  showErrorToast,
  sleep,
} from "../../../../utils/methods";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";

import {
  setApplyBoxesContent,
  setCreatorsMetadata,
  setGeneratorData,
  setPublicationsMetadata,
  setReportGridElements,
  setReportHiddenGridElements,
  setReportLayout,
  setReportSelectedTemplate,
  setShowReportLoader,
  setSelectedTemplate,
  setReportLoaderText,
  setSummaryStatistics,
} from "../../../../redux";
import { showToast } from "../../../methods/showToast";
import ToolbarList from "../../../generator-data/components/ToolbarList/ToolbarList";
import RemoveTemplateModal from "../../../CreatorShowcase/modals/RemoveTemplateModal/RemoveTemplateModal";
import SaveAsModal from "../../../CreatorShowcase/modals/SaveAsModal/SaveAsModal";
import { AddElementsToReportDropdown } from "../../dropdowns/AddElementsToReportDropdown/AddElementsToReportDropdown";
import { ToolbarListWrapper } from "../../../generator-data/components/ToolbatListWrapper/ToolbarListWrapper";
import { ToolbarListItem } from "../../../generator-data/components/ToolbarListItem/ToolbarListItem";
import Tooltip from "../../../components/Tooltip/Tooltip";

import { ReactComponent as ManageIcon } from "../../../../images/manage.svg";
import publicationPlaceholder from "../../../../images/publication-placeholder2.svg";
import { TopPublicationsOnToolbar } from "../ReportTemplate/components/TopPublicationsOnToolbar/TopPublicationsOnToolbar";
import { shouldDisplaySentiments } from "../../functions/shouldDisplaySentiments";
import XssFilter from "../../../components/XssFilter/XssFilter";

function ReportToolbar(props) {
  const {
    showcaseRef,
    data,
    format,
    setFormat,
    metricsData,
    setMetricsData,
    coverImage,
    setCoverImage,
    intl,
  } = props;

  const [draft, setDraft] = useState(null);
  const [templateOptions, setTemplateOptions] = useState([]);
  const [coverOptions, setCoverOptions] = useState([]);
  const [showSaveAsModal, setShowSaveAsModal] = useState(false);
  const [showRemoveTemplateModal, setShowRemoveTemplateModal] = useState(false);
  const [templateToRemoveUuid, setTemplateToRemoveUuid] = useState("");
  const [showManagePagesModal, setShowManagePagesModal] = useState(false);

  const dispatch = useDispatch();

  const {
    mainReducer: { activeWorkspaceUuid, notifications },
    reportReducer: {
      loading,
      layout,
      gridElements,
      hiddenGridElements,
      selectedTemplate,
      creatorsList,
      projectData,
      creatorInsights,
      audienceData,
      publicationsList,
      creatorsMetadata,
      publicationsMetadata,
      publicationsSummary,
      summaryStatistics,
      sentimentValues,
    },
  } = useSelector((state) => state);

  const noProviders = Object.keys(publicationsSummary).length === 0;

  const TOOLBAR_LISTS = [
    { provider: "metadata" },
    { provider: "creator-insights" },
    { provider: "creator", disableExpanding: !creatorsMetadata?.length },
    { provider: "audience-data" },
    { provider: "post-data" },
    {
      provider: "publication",
      disableExpanding: !publicationsMetadata?.length && noProviders,
    },
    {
      provider: "table-summary",
      disableExpanding: noProviders,
      hideCheckbox: true,
    },
  ];

  const toolbarListProps = {
    layout,
    gridElements,
    hiddenGridElements,
    setLayout: setReportLayout,
    setGridElements: setReportGridElements,
    setHiddenGridElements: setReportHiddenGridElements,
  };

  const { projectId } = useParams();

  useEffect(() => {
    getDraft();
  }, []);

  useEffect(() => {
    if (!templateOptions.length) return;

    if (
      templateOptions?.find(
        (item) => item.value.uuid === selectedTemplate.value.uuid,
      )
    )
      return;

    const defaultTemplate = templateOptions?.filter(
      (item) => item.value.type === "default",
    )[0];

    dispatch(setSelectedTemplate(defaultTemplate));
  }, [templateOptions]);

  useEffect(() => {
    const newCoverOptions = publicationsList
      ?.filter((item) => item?.cover?.thumbnails)
      ?.map((item) => ({
        label: (
          <span className="report-toolbar__cover-option">
            <Img
              src={getImageSource(item?.cover, "tiny")}
              fallbackImageSrc={publicationPlaceholder}
            />
            <span
              className="report-toolbar__cover-option-text"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: XssFilter(item.title) }}
            />
          </span>
        ),
        value: item,
      }));

    setCoverOptions(newCoverOptions);
    if (!coverImage?.value) {
      setCoverImage(newCoverOptions[0]);
    }
  }, [publicationsList]);

  useEffect(() => {
    if (!activeWorkspaceUuid) return;

    getTemplates();
  }, [activeWorkspaceUuid]);

  const getTemplates = async () => {
    const url = API_URLS.getReportTemplates.replace(
      ":workspaceUuid:",
      activeWorkspaceUuid,
    );
    try {
      const {
        data: { content },
      } = await axios.get(url, { params: { type: "publication" } });
      const newTemplateOptions = content.map((item) => {
        return {
          label: item.name,
          value: {
            uuid: item.uuid,
            type: item.wsMemberUuid ? "custom" : "default",
            layout: JSON.parse(item.layout),
          },
        };
      });
      setTemplateOptions([
        {
          label: "Default",
          value: { type: "default", layout: null, uuid: "1" },
        },
        ...newTemplateOptions,
      ]);
    } catch (error) {
      console.error(error);
      showErrorToast();
    }
  };

  const createDraft = async () => {
    const savedBoxes = JSON.parse(localStorage.getItem("reportSavedBoxes"));

    const reportData = {
      publicationsList,
      publicationsSummary,
      creatorsList,
      projectData,
      creatorInsights,
      audienceData,
      summaryStatistics,
    };

    const url = API_URLS.getOrCreateReportDraft.replace(
      ":projectUuid:",
      projectId,
    );

    try {
      await axios.post(url, {
        coverImage: coverImage?.value,
        creatorsMetadata,
        publicationsMetadata,
        publicationsSummary,
        gridElements,
        hiddenGridElements,
        summaryStatistics,
        layout,
        reportData,
        savedBoxes,
      });

      getDraft();
    } catch (error) {
      console.error(error);
      showErrorToast();
    }
  };

  const getDraft = async () => {
    try {
      const url = API_URLS.getOrCreateReportDraft.replace(
        ":projectUuid:",
        projectId,
      );

      const {
        data: {
          content: { wsProjectReportDrafts },
        },
      } = await axios.get(url);

      setDraft(wsProjectReportDrafts);
    } catch (err) {
      console.error(err);
      showToast(
        "error",
        <IDHFormattedMessage id="ws_error" defaultTemplate="Error" />,
        <IDHFormattedMessage
          id="ws_could_not_get_draft"
          defaultTemplate="Could not get draft."
        />,
      );
    }
  };

  const translationsToStrings = (arr) =>
    arr?.map((item) => {
      if (typeof item.name === "string") return item;

      return {
        ...item,
        name: item?.name?.props?.defaultMessage || "",
      };
    }) || [];

  const applyDraft = async (draftData) => {
    if (!draftData) return;

    const {
      draftContent: {
        coverImage: draftCoverImage,
        creatorsMetadata: draftCreatorsMetadata,
        publicationsMetadata: draftPublicationsMetadata,
        summaryStatistics: draftSummaryStatistics,
        gridElements: draftGridElements,
        hiddenGridElements: draftHiddenGridElements,
        layout: draftLayout,
        savedBoxes,
        reportData,
      },
    } = draftData;

    await dispatch(setGeneratorData(reportData));

    await sleep(1);

    if (draftCoverImage) {
      setCoverImage({
        label: (
          <span className="report-toolbar__cover-option">
            <Img
              src={getImageSource(draftCoverImage?.cover, "tiny")}
              fallbackImageSrc={publicationPlaceholder}
            />
            <span className="report-toolbar__cover-option-text">
              {draftCoverImage.title}
            </span>
          </span>
        ),
        value: draftCoverImage,
      });
    }

    if (draftGridElements) {
      dispatch(setReportGridElements(translationsToStrings(draftGridElements)));
    }
    if (draftHiddenGridElements) {
      dispatch(
        setReportHiddenGridElements(
          translationsToStrings(draftHiddenGridElements),
        ),
      );
    }
    dispatch(setReportLayout(draftLayout));

    await sleep(1);

    dispatch(setCreatorsMetadata(draftCreatorsMetadata));
    dispatch(setPublicationsMetadata(draftPublicationsMetadata));
    dispatch(setSummaryStatistics(draftSummaryStatistics));

    await sleep(1);

    localStorage.setItem("reportSavedBoxes", JSON.stringify(savedBoxes));
    dispatch(setApplyBoxesContent(true));
  };

  const getUpdatedArray = (currentArray, newArray, key) => {
    return currentArray?.map((item) => {
      const newItem = newArray.find((el) => el[key] === item[key]);

      return newItem || item;
    });
  };

  const synchronizeData = async () => {
    dispatch(setShowReportLoader(true));
    dispatch(
      setReportLoaderText(
        `${translateMessage({
          intl,
          id: "ws_synchronizing_data",
          defaultMessage: "Synchronizing data",
        })}...`,
      ),
    );

    const url = API_URLS.getPublicationReportData.replace(
      ":projectUuid:",
      projectId,
    );

    try {
      const {
        data: { content },
      } = await axios.get(url);

      const {
        audienceData: newAudienceData,
        creators,
        creatorsInsights: newCreatorInsights,
        project: newProjectData,
        publications,
        publicationsSummary: newPublicationsSummary,
      } = content;

      const newCreatorsList = getUpdatedArray(creatorsList, creators, "taskId");
      const newPublicationsList = getUpdatedArray(
        publicationsList,
        publications,
        "taskId",
      );

      const newGeneratorData = {
        creatorsList: newCreatorsList,
        projectData: newProjectData,
        creatorInsights: newCreatorInsights,
        audienceData: newAudienceData,
        publicationsList: newPublicationsList,
        publicationsSummary: newPublicationsSummary,
      };

      const reportData = {
        coverImage: coverImage?.value,
        creatorsMetadata,
        publicationsMetadata,
        gridElements,
        hiddenGridElements,
        layout,
        reportData: newGeneratorData,
        savedBoxes: [],
      };

      const newData = {
        ...draft,
        draftContent: reportData,
      };

      await applyDraft(newData);
      dispatch(setShowReportLoader(false));
    } catch (error) {
      console.error(error);
      showErrorToast();
    }
  };

  const handleApplyDraft = async () => {
    await dispatch(setShowReportLoader(true));
    await dispatch(
      setReportLoaderText(
        `${translateMessage({
          intl,
          id: "ws_applying_draft",
          defaultMessage: "Applying draft",
        })}...`,
      ),
    );
    await sleep(500);
    await applyDraft(draft);
    dispatch(setShowReportLoader(false));
  };

  return (
    <div
      className={classNames("report-toolbar", {
        "report-toolbar--scrollbar-space": scrollbarVisible(
          showcaseRef?.current,
        ),
      })}
      style={{ top: 76 + notifications.length * 44 }}
    >
      <div className="report-toolbar__content">
        <div className="report-toolbar__row report-toolbar__row--vertical">
          <IDHFormattedMessage
            id="ws_cover_image"
            defaultMessage="Cover image"
          />
          <CustomSelect
            options={coverOptions}
            value={coverImage}
            onChange={(newValue) => setCoverImage(newValue)}
            placeholder={`${translateMessage({
              intl,
              id: "ws_select_publication",
              defaultMessage: "Select publication",
            })}...`}
            disabled={loading}
            noOptionsMessage={translateMessage({
              intl,
              id: "ws_add_at_least_one_pub_to_use_in_report",
              defaultMessage:
                "Add at least one publication to use it in report",
            })}
          />
          <button
            className="report-toolbar__row-button"
            onClick={() => setShowManagePagesModal(true)}
          >
            <ManageIcon />
            <IDHFormattedMessage
              id="ws_manage_pages"
              defaultMessage="Manage pages"
            />
          </button>
        </div>

        {draft && (
          <div className="report-toolbar__row report-toolbar__row--vertical">
            <IDHFormattedMessage id="ws_draft" defaultMessage="Draft" />

            <Button
              className="report-toolbar__draft-button"
              size="medium"
              variant="white-with-black-border"
              onClick={handleApplyDraft}
            >
              <IDHFormattedMessage
                id="ws_apply_draft"
                defaultMessage="Apply draft"
              />
            </Button>
            <span className="report-toolbar__draft-date">
              <IDHFormattedMessage
                id="ws_latest_draft"
                defaultMessage="latest draft"
              />
              :&nbsp;
              {formatDistance(
                new Date(
                  moment(new Date(draft.updated.date)).tz(
                    draft.updated.timezone,
                  ),
                ),
                new Date(),
                {
                  addSuffix: true,
                },
              )}
            </span>
          </div>
        )}

        <div className="report-toolbar__row report-toolbar__row--vertical">
          <IDHFormattedMessage
            id="ws_synchronization"
            defaultMessage="Synchronization"
          />
          <Button
            className="report-toolbar__draft-button"
            size="medium"
            variant="white-with-black-border"
            onClick={synchronizeData}
          >
            <IDHFormattedMessage
              id="ws_synchronize_data"
              defaultMessage="Synchronize data"
            />
          </Button>
        </div>

        <div className="report-toolbar__metrics">
          <div className="report-toolbar__wrapper">
            <span className="report-toolbar__header">
              <IDHFormattedMessage id="ws_content" defaultMessage="Content" />
            </span>

            <AddElementsToReportDropdown format={format.value} />
          </div>

          <ToolbarListWrapper loading={loading}>
            <div className="toolbar-list">
              <ToolbarListItem
                key="table-of-contents"
                item={{
                  label: (
                    <IDHFormattedMessage
                      id="ws_table_of_contents"
                      defaultMessage="Table of contents"
                    />
                  ),
                  value: "table-of-contents",
                }}
                provider="table-of-contents"
                format={format}
                toolbarListProps={toolbarListProps}
                sectionId="#table-of-contents-container"
                isReport
                single
                tooltip={
                  <Tooltip
                    center
                    content={
                      <IDHFormattedMessage
                        id="ws_tooltip_table_of_contents_toolbar"
                        defaultMessage="Here you can enable or disable the glossary with publication metric definitions. It will appear on the penultimate page of the report."
                      />
                    }
                  />
                }
              />
            </div>
          </ToolbarListWrapper>

          {TOOLBAR_LISTS?.map((item) => (
            <ToolbarListWrapper key={item.provider} loading={loading}>
              <ToolbarList
                provider={item.provider}
                format={format.value}
                open={item.open}
                toolbarListProps={toolbarListProps}
                disableExpanding={item.disableExpanding}
                hideCheckbox={item.hideCheckbox}
                isReport
              />
            </ToolbarListWrapper>
          ))}

          <TopPublicationsOnToolbar
            loading={loading}
            format={format}
            toolbarListProps={toolbarListProps}
            publicationsList={publicationsList}
          />

          {shouldDisplaySentiments(sentimentValues) && (
            <ToolbarListWrapper key="comments-analysis" loading={loading}>
              <ToolbarList
                provider="comments-analysis"
                format={format.value}
                toolbarListProps={toolbarListProps}
                isReport
              />
            </ToolbarListWrapper>
          )}

          <ToolbarListWrapper loading={loading}>
            <div className="toolbar-list">
              <ToolbarListItem
                key="summary-learnings"
                item={{
                  label: (
                    <IDHFormattedMessage
                      id="ws_summary_learnings"
                      defaultMessage="Summary & Learnings"
                    />
                  ),
                  value: "summary-learnings",
                }}
                provider="summary-learnings"
                format={format.value}
                toolbarListProps={toolbarListProps}
                sectionId="#header-summary-learnings"
                isReport
                single
              />
            </div>
          </ToolbarListWrapper>

          <ToolbarListWrapper loading={loading}>
            <div className="toolbar-list">
              <ToolbarListItem
                key="glossary"
                item={{
                  label: (
                    <IDHFormattedMessage
                      id="ws_glossary"
                      defaultMessage="Glossary"
                    />
                  ),
                  value: "glossary",
                }}
                provider="glossary"
                format={format.value}
                toolbarListProps={toolbarListProps}
                sectionId="#header-glossary"
                isReport
                single
                tooltip={
                  <Tooltip
                    center
                    content={
                      <IDHFormattedMessage
                        id="ws_tooltip_glossary_toolbar"
                        defaultMessage="Here you can enable or disable the glossary with publication metric definitions. It will appear on the penultimate page of the report."
                      />
                    }
                  />
                }
              />
            </div>
          </ToolbarListWrapper>
        </div>
      </div>

      <div className="report-toolbar__buttons">
        <div className="report-toolbar__buttons-bottom">
          {/* {showSaveButton && ( */}
          <Button
            variant="white-with-border"
            size="large"
            onClick={createDraft}
            disabled={loading}
          >
            <IDHFormattedMessage
              id="ws_save_draft"
              defaultMessage="Save draft"
            />
          </Button>
        </div>
      </div>

      {showManagePagesModal && (
        <ManagePagesModal
          onClose={() => setShowManagePagesModal(false)}
          format={format.value}
        />
      )}

      {showSaveAsModal && (
        <SaveAsModal
          onClose={() => setShowSaveAsModal(false)}
          getTemplates={getTemplates}
          layout={layout}
          dataType="publication"
          setSelectedTemplate={(templateData) =>
            dispatch(setReportSelectedTemplate(templateData))
          }
        />
      )}

      {showRemoveTemplateModal && (
        <RemoveTemplateModal
          onClose={() => setShowRemoveTemplateModal(false)}
          templateUuid={templateToRemoveUuid}
          getTemplates={getTemplates}
        />
      )}
    </div>
  );
}

export default injectIntl(ReportToolbar);
