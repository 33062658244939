import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import "moment-timezone";
import classNames from "classnames";
import { useIntl } from "react-intl";

import { ReactComponent as InviteIcon } from "src/images/share-plus.svg";
import {
  ProjectPermissionsEnum,
  WorkspacePermissionsEnum,
} from "src/utils/PermissionsEnums";
import { AppLocation } from "src/redux/main/mainReducer";
import { RootState } from "src/redux/reducers";
import { ReactComponent as MoneyIcon } from "src/images/money.svg";
import PersonInfo from "../components/PersonInfo/PersonInfo";
import PersonProfilePicture from "../components/PersonProfilePicture/PersonProfilePicture";
import { CustomTextareaLabel } from "../components/CustomTextarea/CustomTextarea";
import {
  Breadcrumbs,
  BreadcrumbsItem,
} from "../components/Breadcrumbs/Breadcrumbs";
import { Metadata } from "./Subtask/Subtask";
import TaskMenuDropdown from "../dropdowns/TaskMenuDropdown/TaskMenuDropdown";
import {
  getTaskDetails,
  createEmptySubtask,
  clearTaskDetails,
  setShowTask,
  getTasksAutocomplete,
  clearTasksAutocomplete,
  changeTaskMetaFieldValue,
  getTasksList,
  getProject,
  clearContentProposalFilterStatus,
  clearProjectBasicData,
} from "../../redux";
import { IMember } from "../project/ProjectTypes";
import { SkeletonText } from "../components/Skeleton/Skeleton";
import SubtasksList from "./SubtasksList/SubtasksList";
import { showToast } from "../methods/showToast";
import { Button } from "../components/Button/Button";
import { ReactComponent as CloseIcon } from "../../images/cross-alt.svg";
import { ReactComponent as PlusIcon } from "../../images/plus.svg";
import { convertToLocalTimezone } from "../methods/convertToLocalTimezone";
import Attachments from "./Attachments/Attachments";
import { TaskType } from "../../types";
import { getImageSource } from "../../utils/methods";
import Creators from "./Creators/Creators";
import Content from "./Content/Content";
import { Publication } from "./Publication/Publication";
import RenameModal from "../modals/RenameModal/RenameModal";
import { tableDataType } from "../components/Table/Table";
import { TextBoxWrapper } from "../components/TextBoxWrapper/TextBoxWrapper";
import IDHFormattedMessage from "../components/IDHFormattedMessage/IDHFormattedMessage";
import { SettingsModal } from "../SettingsModal/SettingsModal";
import { SettingsContext, SettingsTab } from "../SettingsModal/enums";
import {
  closeSettingsModal,
  openSettingsModal,
} from "../SettingsModal/methods";
import { ParticipationDetails } from "./tabs/ParticipationDetails/ParticipationDetails";
import AvatarPlaceholder from "../../images/avatar-purple.svg";
import Comments from "./Comments/Comments";
import WsContentProposalElementsSummary from "./ContentSummary/WsContentProposalElementsSummary";
import { UserContent } from "../../redux/comment/commentReducer";
import { translateMessage } from "../methods/translateMessage";
import TaskFooter from "./components/TaskFooter/TaskFooter";
import { removeCommentIdFromUrl } from "./utils/methods";
import SendPaymentModal from "./SendPaymentModal/SendPaymentModal";
import { AppConfig } from "../../config/AppConfig";
import { Pane } from "../components/Pane/Pane";

import "./Task.scss";

export interface ITaskParent {
  id: string;
  title: string;
  onMyTasks?: boolean;
}

export enum ContentTaskTab {
  ContentData = "content-data",
  Actions = "actions",
  Publications = "publications",
}

export enum CommentDisplayType {
  ContentProposal = "content-proposal",
  Comment = "comment",
}

interface TaskProps {
  onCloseHandler: () => void;
  onMyTasks?: boolean;
}

function Task({ onCloseHandler, onMyTasks }: TaskProps) {
  const [loading, setLoading] = useState(true);
  const [isMounted, setIsMounted] = useState<boolean>(false);
  const [coverUrl, setCoverUrl] = useState<string | null>(null);
  const [description, setDescription] = useState<string | null>("");
  const [descriptionField, setDescriptionField] = useState<any>({});
  const [showRenameModal, setShowRenameModal] = useState<boolean>(false);
  const [
    contentProposalFieldNotSubmitted,
    setContentProposalFieldNotSubmitted,
  ] = useState<boolean>(false);
  const [dateCreated, setDateCreated] = useState<moment.Moment | null>(null);
  const [activeContentTaskTab, setActiveContentTaskTab] =
    useState<ContentTaskTab>(ContentTaskTab.ContentData);
  const [showAddContentProposalField, setShowAddContentProposalField] =
    useState(false);
  const [showAllComments, setShowAllComments] = useState(false);
  const [showUpdatePublicationLinkModal, setShowUpdatePublicationLinkModal] =
    useState<boolean>(false);
  const [showSendPaymentModal, setShowSendPaymentModal] =
    useState<boolean>(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const intl = useIntl();

  const {
    mainReducer: { activeWorkspaceUuid, identity, settingsModalData },
    projectReducer: {
      membersList,
      activeMembersList,
      projectBasicData,
      taskType: taskTypeFromParams,
    },
    taskReducer: { taskDetails },
  } = useSelector((state: RootState) => state);

  const {
    userAcceptedContent,
    userRejectedContent,
  }: { userAcceptedContent: UserContent; userRejectedContent: UserContent } =
    useSelector((state: RootState) => state.commentReducer);

  const { subtasks: subtasksList, taskType } = taskDetails;

  const params = useParams<{
    workspaceUuid: string;
    projectId: string;
    taskId: string;
    commentId?: string;
  }>();

  const { taskId, commentId } = params;
  const taskContainerRef = useRef<HTMLDivElement>(null);

  const projectId = params.projectId || taskDetails.parentProjectId;

  const hasAccessToCreatorDatabase = identity?.permissions?.workspace?.includes(
    WorkspacePermissionsEnum.CREATOR_DATABASE,
  );

  const hasAccessToXtrmPayments =
    identity?.permissions?.workspace?.includes(
      WorkspacePermissionsEnum.EXTENSION_XTRM_PAYMENT_TRANSFER,
    ) && identity?.wsExtensionXtrmPaymentsUuid;

  const hasAccessToCreateContentProposal =
    taskDetails.permissions?.project.includes(
      ProjectPermissionsEnum.CREATE_CONTENT_PROPOSAL,
    );

  const hasAccessToRemoveWsTaskComment =
    taskDetails.permissions?.project.includes(
      ProjectPermissionsEnum.REMOVE_ALL_TASK_COMMENTS,
    );

  const addingCommentsEnabled = () => {
    return (
      (taskType !== TaskType.Creator &&
        taskType !== TaskType.Publication &&
        activeContentTaskTab === ContentTaskTab.ContentData) ||
      (taskType === TaskType.Action &&
        activeContentTaskTab === ContentTaskTab.Actions)
    );
  };

  const commentDisplayType =
    taskType === TaskType.Content
      ? CommentDisplayType.ContentProposal
      : CommentDisplayType.Comment;

  const onClickContentProposalSummaryElement = (wsTaskCommentUuid: string) => {
    if (taskContainerRef.current !== null) {
      setShowAllComments(true);
      setTimeout(() => {
        const foundComment = document.querySelector(
          `[data-comment-id="${wsTaskCommentUuid}"]`,
        ) as HTMLElement;
        taskContainerRef.current!.scrollTop = foundComment.offsetTop - 78;
      }, 100);
    }
  };

  useEffect(() => {
    if (!commentId) return;

    const scrollToComment = () => {
      if (taskContainerRef.current !== null) {
        setTimeout(() => {
          let foundComment = document.querySelector<HTMLElement>(
            `[data-comment-id="${commentId}"]`,
          );

          if (!foundComment) {
            setShowAllComments(true);
            foundComment = document.querySelector<HTMLElement>(
              `[data-comment-id="${commentId}"]`,
            );
          }

          removeCommentIdFromUrl();

          if (foundComment) {
            taskContainerRef.current!.scrollTop = foundComment.offsetTop - 78;
          }
        }, 1000);
      }
    };

    scrollToComment();
  }, [taskContainerRef.current, commentId]);

  const getAllTasksAutocomplete = () => {
    dispatch(
      getTasksAutocomplete(taskDetails.parentProjectId, TaskType.Creator),
    );
    dispatch(
      getTasksAutocomplete(taskDetails.parentProjectId, TaskType.Content),
    );
    dispatch(
      getTasksAutocomplete(taskDetails.parentProjectId, TaskType.Publication),
    );
  };

  useEffect(() => {
    if (onMyTasks) {
      dispatch(clearTasksAutocomplete());

      if (taskDetails?.parentProjectId) {
        getAllTasksAutocomplete();
      }
    }
  }, [taskDetails.parentProjectId]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    getNewData();
  }, [location.pathname, membersList]);

  useEffect(() => {
    if (taskDetails) {
      setCoverUrl(getImageSource(taskDetails?.cover, "small"));
    }
  }, [taskDetails]);

  useEffect(() => {
    if (taskDetails.metadata) {
      const descriptionFieldData = taskDetails.metadata.find(
        (item: any) => item.key === "description",
      );

      if (!descriptionFieldData) return;

      if (descriptionFieldData.uuid) {
        setDescriptionField(descriptionFieldData);
        setDescription(descriptionFieldData.value);
      }
    }
  }, [taskDetails]);

  const onDescriptionChange = (
    newValue: string,
    currentValue: string | null = null,
  ) => {
    if (!currentValue) {
      currentValue = "";
    }

    if (currentValue !== newValue && descriptionField.uuid) {
      dispatch(
        changeTaskMetaFieldValue(taskId, descriptionField.uuid, newValue),
      );
    }
  };

  const getNewData = async () => {
    if (!taskId) return;
    setLoading(true);

    if (membersList?.length) {
      await dispatch(getTaskDetails(taskId));
      setLoading(false);
    }
  };

  useEffect(() => {
    if (typeof projectId !== "string") return;
    if (
      projectId !== "null" &&
      (membersList === null || projectBasicData === null)
    ) {
      dispatch(getProject(projectId));
    }
    if (projectId === "null") {
      dispatch(clearProjectBasicData());
    }
  }, [projectId]);

  const existsAtLeastOneContentProposalElementNotSubmitted = () => {
    return (
      userAcceptedContent.contentProposalElements.length > 0 ||
      userRejectedContent.contentProposalElements.length > 0 ||
      contentProposalFieldNotSubmitted
    );
  };

  const handleClose = () => {
    if (!isMounted) return;

    if (existsAtLeastOneContentProposalElementNotSubmitted()) {
      if (
        !confirm(
          translateMessage({
            intl,
            id: "ws_confirm_exit_task",
            defaultMessage:
              "You have unsaved changes. Are you sure you wish to clear changes?",
          }),
        )
      ) {
        return;
      }
    }

    setIsMounted(false);
    setTimeout(() => {
      const currentLocation = history.location.pathname;

      const previousView = currentLocation.substring(
        0,
        currentLocation.lastIndexOf("/"),
      );

      if (currentLocation.includes("my-actions")) {
        onCloseHandler();
        history.push(`/workspace/${activeWorkspaceUuid}/my-actions`);

        return;
      }

      onCloseHandler();
      history.push({
        pathname: previousView,
        search: `taskType=${taskTypeFromParams}`,
      });
    }, 500);
  };

  useEffect(() => {
    return () => {
      dispatch(clearTaskDetails());
      dispatch(clearContentProposalFilterStatus());
    };
  }, []);

  useEffect(() => {
    if (taskDetails?.created) {
      const dateValue = taskDetails?.created?.date;

      if (dateValue !== null) {
        setDateCreated(convertToLocalTimezone(dateValue));
      }
    }
  }, [taskDetails?.created]);

  const handleAddGoalClick = () => {
    if (!subtasksList.length || subtasksList[subtasksList.length - 1].title) {
      dispatch(createEmptySubtask());
    }
  };

  const copyLink = (id: string) => {
    const shareLink = `${AppConfig.getAppRoute()}/${activeWorkspaceUuid}/projects/${params.projectId}/${id}${window.location.search}`;
    navigator.clipboard.writeText(shareLink).then(
      () => {
        showToast(
          "success",
          <IDHFormattedMessage id="ws_success" defaultMessage="Success" />,
          <IDHFormattedMessage
            id="ws_link_copied"
            defaultMessage="Link copied!"
          />,
        );
      },
      (err) => {
        showToast(
          "error",
          <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
          <IDHFormattedMessage
            id="ws_error_while_copying"
            defaultMessage="Error while copying!"
          />,
        );
        console.error("Async: Could not copy text: ", err);
      },
    );
  };

  const openShareModal = () => {
    openSettingsModal(
      history,
      AppLocation.Task,
      SettingsContext.Task,
      SettingsTab.Members,
    );
  };

  if (!taskDetails.taskId) {
    return null;
  }

  const taskOwner = membersList.find(
    (member) => member.id === taskDetails.ownerWsMemberUuid,
  );

  const userAvatar = (
    <img
      src={identity.avatarUrl || AvatarPlaceholder}
      alt="User avatar"
      className="task__author-avatar"
    />
  );

  return (
    <div>
      <Pane
        className="task"
        backdropClassName="task-backdrop"
        hidePane={handleClose}
        showPane={isMounted}
      >
        <div className="task__top-bar">
          <Breadcrumbs>
            <BreadcrumbsItem
              text={
                projectBasicData?.projectName || (
                  <IDHFormattedMessage
                    id="ws_campaign"
                    defaultMessage="Campaign"
                  />
                )
              }
              to={
                projectBasicData?.projectId
                  ? `/workspace/${activeWorkspaceUuid}/projects/${projectId}`
                  : "/workspace/${activeWorkspaceUuid}/projects/"
              }
              onClick={() => dispatch(setShowTask(false))}
              disabled={!projectBasicData}
            />
            {taskDetails?.parents
              ?.slice()
              .reverse()
              .map((parent: ITaskParent) => (
                <BreadcrumbsItem
                  key={parent.id}
                  text={parent.title}
                  to={`/workspace/${activeWorkspaceUuid}/projects/${projectId}/${parent?.id}`}
                />
              ))}
            <BreadcrumbsItem
              text={taskDetails?.title}
              to={`/workspace/${activeWorkspaceUuid}/projects/${projectId}/${taskDetails?.taskId}`}
            />
          </Breadcrumbs>
          <div className="task__toolbar">
            {hasAccessToXtrmPayments && taskType === TaskType.Payment && (
              <Button
                variant="blue"
                size="small"
                onClick={() => setShowSendPaymentModal(true)}
              >
                <MoneyIcon />
                <IDHFormattedMessage
                  id="ws_send_payment"
                  defaultMessage="Send Payment"
                />
              </Button>
            )}
            <TaskMenuDropdown
              data={taskDetails}
              taskId={taskId}
              editTask={() => setShowRenameModal(true)}
              copyLink={() => copyLink(taskId)}
              showDeleteButton
            />
            <CloseIcon className="task__toolbar-icon" onClick={handleClose} />
          </div>
        </div>
        <div className="task__container" ref={taskContainerRef}>
          <div className="task__container__inner-wrap">
            <div
              className={classNames("task__person", {
                "task__person--publication":
                  taskType === TaskType.Publication && taskDetails.publication,
              })}
            >
              {taskType !== TaskType.Action &&
                taskType !== TaskType.Payment && (
                  <PersonProfilePicture
                    src={coverUrl}
                    updateData={() => {
                      dispatch(getTasksList(projectId, taskType));
                      dispatch(getTaskDetails(taskId));
                    }}
                    item={taskDetails}
                    parentLoading={loading}
                  />
                )}
              <PersonInfo
                projectId={projectId}
                taskType={taskType}
                onMyTasks={onMyTasks}
                hasAccessToCreatorDatabase={hasAccessToCreatorDatabase}
                showUpdatePublicationLinkModal={showUpdatePublicationLinkModal}
                setShowUpdatePublicationLinkModal={
                  setShowUpdatePublicationLinkModal
                }
              />
              {taskType !== TaskType.Creator &&
                taskType !== TaskType.Publication && (
                  <>
                    <div
                      className="projects__topbar-right-members-avatars"
                      onClick={openShareModal}
                    >
                      {taskDetails?.members
                        ?.slice(0, 3)
                        .map((member: IMember) => (
                          <div className="avatar" key={member.id}>
                            <img
                              src={member.avatarUrl || AvatarPlaceholder}
                              alt="avatar"
                            />
                          </div>
                        ))}
                    </div>

                    <Button
                      variant="white-with-grey-border"
                      size="square"
                      onClick={openShareModal}
                      className="task__btn-share"
                    >
                      <InviteIcon />
                    </Button>
                  </>
                )}
            </div>

            {/* Temporary solution */}
            {taskDetails.taskId === params.taskId &&
              taskType !== TaskType.Creator && (
                <ParticipationDetails
                  metadata={taskDetails.metadata}
                  dataType={tableDataType.Task}
                  settingsContextData={{
                    context: SettingsContext.Task,
                    contextTargetUuid: taskDetails.taskId,
                  }}
                  dateCreated={dateCreated}
                  taskOwner={taskOwner}
                />
              )}

            {taskType !== TaskType.Creator && (
              <>
                {loading ? (
                  <SkeletonText width="100%" height={50} />
                ) : (
                  taskType === TaskType.Action && (
                    <>
                      <CustomTextareaLabel
                        htmlFor="task-description"
                        text={descriptionField?.name}
                      />

                      <TextBoxWrapper
                        className="task__description"
                        onChange={setDescription}
                        value={description || ""}
                        onBlur={(newValue: string) =>
                          onDescriptionChange(newValue)
                        }
                        enableEmoji
                      />
                    </>
                  )
                )}

                {subtasksList?.length > 0 && (
                  <>
                    <div className="task__goals">
                      <IDHFormattedMessage
                        id="ws_action_points"
                        defaultMessage="Action points"
                      />
                    </div>
                    <div className="subtask-list">
                      <SubtasksList
                        projectId={projectId}
                        subtasksList={subtasksList}
                        taskDetails={taskDetails}
                      />
                    </div>
                  </>
                )}

                {taskType === TaskType.Action &&
                  taskDetails.permissions?.project.includes(
                    "task_management",
                  ) && (
                    <Button
                      size="large"
                      variant="bare"
                      className="task__add-goal"
                      onClick={handleAddGoalClick}
                    >
                      <PlusIcon />
                      <IDHFormattedMessage
                        id="ws_add_action_point"
                        defaultMessage="Add action point"
                      />
                    </Button>
                  )}
              </>
            )}

            {taskType === TaskType.Creator &&
              taskDetails.socialProfiles !== undefined &&
              taskDetails.taskId === params.taskId && (
                <Creators
                  taskDetails={taskDetails}
                  taskType={taskType}
                  taskOwner={taskOwner}
                  hasAccessToCreatorDatabase={hasAccessToCreatorDatabase}
                />
              )}

            {taskType === TaskType.Content && descriptionField.uuid && (
              <Content
                description={description}
                setDescription={setDescription}
                onDescriptionChange={onDescriptionChange}
                setActiveContentTaskTab={setActiveContentTaskTab}
                activeContentTaskTab={activeContentTaskTab}
                taskType={taskType}
                permissions={taskDetails.permissions}
                tasks={{
                  actionTasks: taskDetails.relatedActionTasks,
                  publicationTasks: taskDetails.relatedPublicationTasks,
                }}
              />
            )}

            {taskType === TaskType.Publication && taskDetails.publication && (
              <Publication
                publication={taskDetails.publication}
                setShowUpdatePublicationLinkModal={
                  setShowUpdatePublicationLinkModal
                }
              />
            )}

            {addingCommentsEnabled() &&
              (commentDisplayType === CommentDisplayType.ContentProposal ? (
                <WsContentProposalElementsSummary
                  wsTaskUuid={taskId}
                  wsProjectUuid={projectId}
                  onClickContentProposalSummaryElement={
                    onClickContentProposalSummaryElement
                  }
                  numberOfComments={taskDetails?.comments.length ?? 0}
                />
              ) : (
                <Attachments
                  attachmentsCount={taskDetails?.attachments?.length}
                  attachments={taskDetails.attachments}
                  taskType={taskType}
                />
              ))}

            {taskType !== TaskType.Creator &&
              activeContentTaskTab === ContentTaskTab.ContentData && (
                <Comments
                  comments={taskDetails?.comments}
                  membersList={membersList}
                  taskType={taskType}
                  commentDisplayType={commentDisplayType}
                  setShowAddContentProposalField={() =>
                    setShowAddContentProposalField(true)
                  }
                  hasAccessToCreateContentProposal={
                    hasAccessToCreateContentProposal
                  }
                  showAllComments={showAllComments}
                  setShowAllComments={setShowAllComments}
                  taskContainerRef={taskContainerRef}
                  hasAccessToRemoveWsTaskComment={
                    hasAccessToRemoveWsTaskComment
                  }
                />
              )}
          </div>
        </div>

        {addingCommentsEnabled() && (
          <TaskFooter
            commentDisplayType={commentDisplayType}
            taskId={taskId}
            userAvatar={userAvatar}
            showAddContentProposalField={showAddContentProposalField}
            setShowAddContentProposalField={setShowAddContentProposalField}
            activeWorkspaceUuid={activeWorkspaceUuid}
            taskDetails={taskDetails}
            hasAccessToCreateContentProposal={hasAccessToCreateContentProposal}
            setContentProposalFieldNotSubmitted={
              setContentProposalFieldNotSubmitted
            }
            membersList={activeMembersList}
          />
        )}
      </Pane>

      {showSendPaymentModal && (
        <SendPaymentModal
          onClose={() => setShowSendPaymentModal(false)}
          wsExtensionXtrmPaymentsUuid={identity.wsExtensionXtrmPaymentsUuid}
          activeWorkspaceUuid={activeWorkspaceUuid}
          taskId={taskDetails.taskId}
        />
      )}

      {settingsModalData.location === AppLocation.Task && (
        <SettingsModal
          onClose={() => closeSettingsModal(history)}
          members={{
            openShareModal,
          }}
          contextTargetUuid={taskId}
        />
      )}

      {showRenameModal && (
        <RenameModal
          onClose={() => setShowRenameModal(false)}
          objectId={taskId}
          taskName={taskDetails.title}
          titleFieldId={
            taskDetails.metadata.find(
              (metafield: Metadata) => metafield.key === "title",
            ).uuid
          }
          projectId={params.projectId}
          dataType={tableDataType.Task}
        />
      )}
    </div>
  );
}

export default Task;
