import React, { Dispatch, SetStateAction } from "react";
import { useSelector } from "react-redux";

import { RootState } from "src/redux/reducers";
import {
  HandleAddFieldsProps,
  HandleRemoveFieldsProps,
  HandleUpdateTemplateValueParams,
  Template,
} from "../types";
import FieldsTab from "../components/FieldsTab";

interface TemplateManagerTableColumnTabProps {
  editedTemplate: Template;
  handleUpdateTemplateValue: (params: HandleUpdateTemplateValueParams) => void;
  handleAddFields: (params: HandleAddFieldsProps) => void;
  handleRemoveFields: (params: HandleRemoveFieldsProps) => void;
  setEditedTemplate: Dispatch<SetStateAction<Template | null>>;
  syncLocalTemplateWithServer: (uuid: string) => Promise<void>;
}

export default function TemplateManagerTableColumnTab({
  editedTemplate,
  handleUpdateTemplateValue,
  handleAddFields,
  handleRemoveFields,
  setEditedTemplate,
  syncLocalTemplateWithServer,
}: TemplateManagerTableColumnTabProps) {
  const {
    settingsReducer: { globalTaskMetaFields },
  } = useSelector((state: RootState) => state);

  return (
    <FieldsTab
      editedTemplate={editedTemplate}
      setEditedTemplate={setEditedTemplate}
      handleUpdateTemplateValue={handleUpdateTemplateValue}
      handleAddFields={handleAddFields}
      handleRemoveFields={handleRemoveFields}
      fieldType="task"
      key="task"
      fields={globalTaskMetaFields}
      syncLocalTemplateWithServer={syncLocalTemplateWithServer}
    />
  );
}
