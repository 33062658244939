import React from "react";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import AvatarPlaceholder from "src/images/avatar-bg.svg";
import { Img } from "src/app/components/Img/Img";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Tooltip from "../../../../components/Tooltip/Tooltip";
import ProfileBox, {
  PBContent,
  PBHeader,
} from "../components/ProfileBox/ProfileBox";
import { ReactComponent as SimilarProfilesIcon } from "../../../../../images/profile-data/similar-profiles.svg";

export function SimilarProfiles(props) {
  const { similarProfiles, height } = props;

  const {
    mainReducer: { activeWorkspaceUuid },
  } = useSelector((state) => state);

  const location = window.location.pathname + window.location.search;
  const isCDT = location.includes(`/creator-discovery-tool`);

  const showContent = !height || height > 1;
  const onShowcase = Boolean(height);

  return (
    <ProfileBox>
      <PBHeader
        icon={<SimilarProfilesIcon />}
        title={
          <IDHFormattedMessage
            id="ws_similar_profiles"
            defaultMessage="Similar profiles"
          />
        }
        tooltip={
          !onShowcase && (
            <Tooltip
              center
              content={
                <IDHFormattedMessage
                  id="ws_tooltip_leading_creators_from_the_same_country"
                  defaultMessage="Leading creators from the same country posting content on similar topics."
                />
              }
            />
          )
        }
        onShowcase={onShowcase}
      />

      {showContent && (
        <PBContent column>
          <div className="profile-box__similar-profiles-list">
            {similarProfiles.map((user) => {
              let linkProps = {};
              if (isCDT) {
                const params = new URLSearchParams(window.location.search);
                params.delete("displayGlobalTask");
                linkProps = {
                  to: {
                    pathname: `${
                      window.location.pathname.split("/profile")[0]
                    }/profile/instagram/${user.username}`,
                    search: params.toString(),
                  },
                };
              } else {
                linkProps = {
                  to: {
                    pathname: `/workspace/${activeWorkspaceUuid}/creator-discovery-tool/search-results/profile/instagram/${user.username}`,
                  },
                  target: "_blank",
                };
              }

              return (
                <div className="profile-box__profile" key={user.username}>
                  <Img
                    src={user.profilePictureUrl}
                    fallbackImageSrc={AvatarPlaceholder}
                    alt="avatar"
                  />
                  <Link {...linkProps}>{user.username}</Link>
                </div>
              );
            })}
          </div>
        </PBContent>
      )}
    </ProfileBox>
  );
}
