import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomCheckbox from "src/app/components/CustomCheckbox/CustomCheckbox";
import { setPublicationsMetadata } from "src/redux";
import { ToolbarListWrapper } from "../ToolbatListWrapper/ToolbarListWrapper";
import ToolbarList from "../ToolbarList/ToolbarList";

export function ToolbarListPublicationMetaFields({ format, toolbarListProps }) {
  const {
    reportReducer: {
      publicationsMetadata,
      publicationsSummary,
      summaryStatistics,
    },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const publicationProviders = useMemo(
    () =>
      Object.keys(publicationsSummary || {})?.map((provider) => ({
        provider: `publication-summary-${provider}`,
        disableExpanding: !publicationsSummary[provider]?.summary?.length,
      })) || [],
    [publicationsSummary, summaryStatistics],
  );

  const toggleFieldVisibility = (newValue, uuid) => {
    const newPublicationsMetadata = publicationsMetadata?.map((item) => {
      if (item.uuid === uuid) {
        return {
          ...item,
          reportVisible: newValue,
        };
      }
      return item;
    });

    dispatch(setPublicationsMetadata(newPublicationsMetadata));
  };

  return (
    <div>
      {publicationsMetadata?.map((item) => {
        return (
          <div className="toolbar-list__list-item">
            <CustomCheckbox
              id={item.uuid}
              checked={item.reportVisible}
              onChange={() =>
                toggleFieldVisibility(!item.reportVisible, item.uuid)
              }
            />
            {item.name}
          </div>
        );
      })}

      {publicationProviders?.map((item, index) => (
        <ToolbarListWrapper key={item.provider} loading={false}>
          <ToolbarList
            provider={item.provider}
            format={format.value}
            open={item.open}
            toolbarListProps={toolbarListProps}
            disableExpanding={item.disableExpanding}
            noBottomBorder={index === publicationProviders.length - 1}
            isReport
            hideCheckbox
          />
        </ToolbarListWrapper>
      ))}
    </div>
  );
}
