import React, {
  useState,
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useCallback,
} from "react";
import { useIntl } from "react-intl";
import {
  DraggingStyle,
  DroppableProvided,
  DroppableStateSnapshot,
  DropResult,
  NotDraggingStyle,
  Draggable,
} from "react-beautiful-dnd";
import { uuidv7 } from "uuidv7";
import axios, { AxiosResponse } from "axios";
import { useSelector } from "react-redux";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { Button } from "src/app/components/Button/Button";
import { ReactComponent as PlusIcon } from "src/images/plus-transparent.svg";
import AnimatedDiv from "src/app/components/AnimatedDiv/AnimatedDiv";
import CustomInput from "src/app/components/CustomInput/CustomInput";
import { translateMessage } from "src/app/methods/translateMessage";
import FieldsList from "src/app/modals/components/FieldsList/FieldsList";
import { Coordinates } from "src/app/modals/components/FieldsList/types";
import { ReactComponent as GridIcon } from "src/images/grid.svg";
import { CustomSwitch } from "src/app/components/CustomSwitch/CustomSwitch";
import { ReactComponent as LightningIcon } from "src/images/lightning.svg";
import { ReactComponent as EditIcon } from "src/images/edit-gray.svg";
import { ReactComponent as TrashCanIcon } from "src/images/trash-can-gray.svg";
import { ReactComponent as CopyIcon } from "src/images/duplicate-gray.svg";
import { API_URLS } from "src/utils/API_URLS";
import { parseErrorMessages, showErrorToast } from "src/utils/methods";
import { RootState } from "src/redux/reducers";
import NoResultsMessage from "src/app/components/NoResultsMessage/NoResultsMessage";
import { RemoveModal } from "src/app/modals/RemoveModal/RemoveModal";
import { generateRankString } from "src/utils/rankStrings";
import Loader from "src/app/components/Loader/Loader";
import responseCodes from "src/utils/responseCodes";
import ExtensionView from "../Extensions/ExtensionView";
import { Template } from "./types";
import {
  handleUpdateProjectTemplate,
  handleUpdateProjectTemplateRank,
} from "./utils";

interface TemplatesListProps {
  setEditedTemplate: Dispatch<SetStateAction<Template | null>>;
}

function TemplatesList({ setEditedTemplate }: TemplatesListProps) {
  const [value, setValue] = useState("");
  const [coordinates, setCoordinates] = useState<Coordinates | null>(null);
  const [templateToRemove, setTemplateToRemove] = useState<Template | null>(
    null,
  );
  const [templatesList, setTemplatesList] = useState<Template[]>([]);
  const [isTemplatesListLoading, setIsTemplatesListLoading] = useState(false);

  const [sortedTemplatesList, setSortedTemplatesList] = useState<Template[]>(
    [],
  );

  const intl = useIntl();

  const { activeWorkspaceUuid } = useSelector(
    (state: RootState) => state.mainReducer,
  );

  const handleAddNewTemplate = async () => {
    const templateUuid = uuidv7();

    const lastElementRank =
      templatesList[templatesList.length - 1]?.rank || "aaa";

    const newRank = generateRankString(lastElementRank, "");

    const newTemplateData = {
      uuid: templateUuid,
      name: "New Template",
      description: "",
      globalProjectMetaFieldsUuids: {},
      globalTaskMetaFieldsUuids: {},
      automationsEnabled: [],
      extensionsEnabled: [],
      rank: newRank,
      enabled: false,
    };

    setEditedTemplate(newTemplateData);

    try {
      const response = await axios.post(API_URLS.createProjectTemplate, {
        ...newTemplateData,
        rank: null,
        wsWorkspaceUuid: activeWorkspaceUuid,
      });
      if (response.status !== responseCodes["200_OK"]) {
        throw new Error("Could not create template");
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.data?.errors) {
        const errorMessage = parseErrorMessages(error.response.data.errors)[0]
          .message;
        showErrorToast({
          id: errorMessage,
          defaultMessage: errorMessage,
        });
      } else {
        showErrorToast({
          id: "ws_could_not_create_project_template",
          defaultMessage: "An error occurred while creating the template",
        });
      }
      setEditedTemplate(null);
      console.error(error);
    }
  };

  const getProjectTemplatesList = useCallback(async (workspaceUuid: string) => {
    setIsTemplatesListLoading(true);
    try {
      const res: AxiosResponse<{
        content: { wsProjectTemplates: Template[] };
      }> = await axios.get(
        API_URLS.getSettingsProjectTemplates.replace(
          ":wsWorkspaceUuid:",
          workspaceUuid,
        ),
      );
      if (res.status === responseCodes["200_OK"]) {
        const templates = res.data.content.wsProjectTemplates;
        setTemplatesList(templates);
      } else {
        throw new Error("Could not load templates");
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.data?.errors) {
        const errorMessage = parseErrorMessages(error.response.data.errors)[0]
          .message;
        showErrorToast({
          id: errorMessage,
          defaultMessage: errorMessage,
        });
      } else {
        showErrorToast({
          id: "ws_could_not_load_templates",
          defaultMessage: "An error occurred while loading templates",
        });
      }
      console.error(error);
    } finally {
      setIsTemplatesListLoading(false);
    }
  }, []);

  const displayedTemplatesList = sortedTemplatesList.filter((template) =>
    template.name.toLowerCase().includes(value.toLocaleLowerCase()),
  );

  const handleDuplicateTemplate = async (duplicatedTemplate: Template) => {
    const templateUuid = uuidv7();
    setTemplatesList((prev) => [
      ...prev,
      {
        ...duplicatedTemplate,
        name: `Copy of ${duplicatedTemplate.name}`,
        uuid: templateUuid,
      },
    ]);
    try {
      const response = await axios.post(API_URLS.duplicateProjectTemplate, {
        name: `Copy of ${duplicatedTemplate.name}`,
        uuid: templateUuid,
        sourceWsProjectTemplateUuid: duplicatedTemplate.uuid,
      });
      if (response.status !== responseCodes["200_OK"]) {
        throw new Error("Could not duplicate template");
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.data?.errors) {
        const errorMessage = parseErrorMessages(error.response.data.errors)[0]
          .message;
        showErrorToast({
          id: errorMessage,
          defaultMessage: errorMessage,
        });
      } else {
        showErrorToast({
          id: "ws_could_not_duplicate_template",
          defaultMessage: "An error occurred while duplicating the template",
        });
      }
      console.error(error);
      getProjectTemplatesList(activeWorkspaceUuid);
    }
  };

  const handleDeleteTemplate = async (removedTemplateUuid: string) => {
    try {
      const response = await axios.delete(API_URLS.deleteProjectTemplate, {
        data: {
          uuid: removedTemplateUuid,
        },
      });
      if (response.status === responseCodes["200_OK"]) {
        setTemplatesList((prev) =>
          prev.filter((t) => t.uuid !== removedTemplateUuid),
        );
      } else {
        throw new Error("Could not delete template");
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.data?.errors) {
        const errorMessage = parseErrorMessages(error.response.data.errors)[0]
          .message;
        showErrorToast({
          id: errorMessage,
          defaultMessage: errorMessage,
        });
      } else {
        showErrorToast({
          id: "ws_could_not_remove_template",
          defaultMessage: "An error ocurred while removing the template",
        });
      }
      console.error(error);
      getProjectTemplatesList(activeWorkspaceUuid);
    }
  };

  const handleEnableTemplate = (
    e: ChangeEvent<HTMLInputElement>,
    template: Template,
  ) => {
    const { checked } = e.target;

    setTemplatesList((prev) => {
      return prev.map((t) => {
        if (t.uuid === template.uuid) {
          return { ...t, enabled: checked };
        }
        return t;
      });
    });
    handleUpdateProjectTemplate({
      data: {
        ...template,
        wsWorkspaceUuid: activeWorkspaceUuid,
        enabled: checked,
      },
      catchCallback: () =>
        setTemplatesList((prev) => {
          return prev.map((t) => {
            if (t.uuid === template.uuid) {
              return { ...t, enabled: !checked };
            }
            return t;
          });
        }),
    });
  };

  const handleDragEnd = (result: DropResult) => {
    if (
      !result.destination ||
      result.destination.index === result.source.index
    ) {
      return;
    }
    const { destination, source } = result;

    const sourceField = displayedTemplatesList[source.index];
    const targetField = displayedTemplatesList[destination.index];
    const fieldIndex = displayedTemplatesList.findIndex(
      (field) => field.uuid === targetField.uuid,
    );

    let newRank = "";
    if (destination.index < source.index) {
      newRank = generateRankString(
        displayedTemplatesList[fieldIndex - 1]?.rank || "",
        displayedTemplatesList[fieldIndex].rank,
      );
      // move bottom
    } else {
      newRank = generateRankString(
        displayedTemplatesList[fieldIndex].rank,
        displayedTemplatesList[fieldIndex + 1]?.rank || "",
      );
    }
    setCoordinates(null);

    setTemplatesList((prevTemplates) => {
      return prevTemplates.map((template) => {
        if (template.uuid === sourceField.uuid) {
          return { ...template, rank: newRank };
        }

        return template;
      });
    });

    handleUpdateProjectTemplateRank({ uuid: sourceField.uuid, rank: newRank });
  };

  const listRenderer = (
    provided: DroppableProvided,
    snapshot: DroppableStateSnapshot,
    getElementTop: (
      style: DraggingStyle | NotDraggingStyle | undefined,
    ) => number | "auto",
  ) => {
    return (
      <ul
        ref={provided.innerRef}
        className="template-manager__list template-manager__list--templates-list"
        {...provided.droppableProps}
      >
        {displayedTemplatesList.length > 0 ? (
          displayedTemplatesList.map((template, index) => {
            return (
              <Draggable
                key={template.uuid}
                draggableId={template.uuid}
                index={index}
              >
                {(provided) => (
                  <li
                    ref={provided.innerRef}
                    className="template-manager__list-item grid--templates-list"
                    key={template.uuid}
                    {...provided.draggableProps}
                    style={{
                      ...provided.draggableProps.style,
                      top: getElementTop(provided.draggableProps.style),
                    }}
                    data-qa-field-name={template.name}
                  >
                    <div className="template-manager__text">
                      <div {...provided.dragHandleProps}>
                        <GridIcon />
                      </div>

                      <div className="template-manager__list-col">
                        <div className="template-manager__name">
                          <span className="template-manager__name-text">
                            {template.name}
                          </span>
                        </div>
                        <div className="template-manager__description">
                          {template.description}
                        </div>
                      </div>
                    </div>
                    <CustomSwitch
                      checked={template.enabled}
                      onChange={(e) => handleEnableTemplate(e, template)}
                    />
                    <div className="template-manager__list-actions">
                      <div className="template-manager__extensions-count">
                        <LightningIcon />
                        {template.extensionsEnabled.length +
                          template.automationsEnabled.length}
                      </div>
                      <button
                        className="button-styleReset"
                        type="button"
                        aria-label="Edit template"
                        onClick={() => setEditedTemplate(template)}
                      >
                        <EditIcon />
                      </button>
                      <button
                        aria-label="Duplicate template"
                        type="button"
                        className="button-styleReset"
                        onClick={() => handleDuplicateTemplate(template)}
                      >
                        <CopyIcon />
                      </button>
                      <button
                        aria-label="Remove template"
                        type="button"
                        className="button-styleReset"
                        onClick={() => setTemplateToRemove(template)}
                      >
                        <TrashCanIcon />
                      </button>
                    </div>
                  </li>
                )}
              </Draggable>
            );
          })
        ) : (
          <NoResultsMessage />
        )}
        {provided.placeholder}
        {coordinates && snapshot.isDraggingOver && (
          <AnimatedDiv
            style={{
              top: coordinates?.clientY,
              left: coordinates?.clientX,
              height: coordinates?.clientHeight,
              width: coordinates?.clientWidth,
            }}
          />
        )}
      </ul>
    );
  };

  useEffect(() => {
    const templatesListCopy = [...templatesList];

    templatesListCopy.sort((a, b) => a.rank.localeCompare(b.rank));

    setSortedTemplatesList(templatesListCopy);
  }, [templatesList]);

  useEffect(() => {
    getProjectTemplatesList(activeWorkspaceUuid);
  }, [activeWorkspaceUuid, getProjectTemplatesList]);

  return (
    <>
      <ExtensionView
        title={
          <div className="template-">
            <IDHFormattedMessage
              id="ws_template_manager"
              defaultMessage="Template Manager"
            />
          </div>
        }
      >
        <AnimatedDiv>
          <div className="global-fields__input-wrapper">
            <CustomInput
              type="text"
              placeholder={translateMessage({
                intl,
                id: "ws_search_for_template",
                defaultMessage: "Search for a template",
              })}
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
            <Button size="large" variant="blue" onClick={handleAddNewTemplate}>
              <PlusIcon />
              <IDHFormattedMessage
                id="ws_create_template"
                defaultMessage="Create Template"
              />
            </Button>
          </div>
          <div className="template-manager__headers grid--templates-list">
            <div>
              <IDHFormattedMessage id="ws_template" defaultMessage="Template" />
            </div>
            <div>
              <IDHFormattedMessage id="ws_active" defaultMessage="Active" />{" "}
            </div>
            <div>
              <IDHFormattedMessage id="ws_actions" defaultMessage="Actions" />
            </div>
          </div>
          {isTemplatesListLoading ? (
            <Loader />
          ) : (
            <FieldsList
              listRenderer={listRenderer}
              onDragEnd={handleDragEnd}
              setCoordinates={setCoordinates}
            />
          )}
        </AnimatedDiv>
      </ExtensionView>
      {templateToRemove && (
        <RemoveModal
          objectNames={[templateToRemove.name]}
          onClose={() => setTemplateToRemove(null)}
          removeFunction={() => handleDeleteTemplate(templateToRemove.uuid)}
        />
      )}
    </>
  );
}

export default TemplatesList;
