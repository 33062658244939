import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { RootState } from "src/redux/reducers";
import { ProjectMetaData } from "src/redux/project/types";
import { TasksColumn } from "src/redux/task/taskReducer";
import { ProjectReducerState } from "src/redux/project/projectReducer";
import {
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
} from "../components/Dropdown/Dropdown";

import { ReactComponent as EditIcon } from "../../images/edit.svg";
import { ReactComponent as HideIcon } from "../../images/hide.svg";
import { ReactComponent as MoveLeftIcon } from "../../images/move-left.svg";
import { ReactComponent as MoveRightIcon } from "../../images/move-right.svg";
import { ReactComponent as DeleteIcon } from "../../images/trash-can.svg";
import { ReactComponent as ClientsOrangeIcon } from "../../images/client-orange.svg";
import { ReactComponent as MembersLightGreyIcon } from "../../images/members-light-grey.svg";
import { ReactComponent as DotsIcon } from "../../images/three-dots-grey.svg";
import { ReactComponent as GridIcon } from "../../images/grid-grey.svg";
import {
  changeFieldVisibility,
  changeProjectFieldVisibility,
  deleteField,
  deleteProjectMetaField,
  getTasksList,
  setEditedCellId,
  updateProjectMetaFieldRank,
  updateTaskMetaFieldRank,
} from "../../redux";
import { generateRankString } from "../../utils/rankStrings";
import useOnClickOutside from "../methods/useOnClickOutside";
import { tableDataType } from "../components/Table/Table";
import ManageFieldModal from "../modals/ManageFieldModal/ManageFieldModal";
import DeleteMetaFieldModal from "../modals/DeleteMetaFieldModal/DeleteMetaFieldModal";
import IDHFormattedMessage from "../components/IDHFormattedMessage/IDHFormattedMessage";
import { ColumnHeaderTitle } from "./ColumnHeaderTitle";

interface Props {
  field: any;
  changeFieldVisibility: any;
  dragHandleProps?: any;
  deleteField: (id: string) => void;
  deleteProjectMetaField: (projectId: string) => void;
  tasksColumns: TasksColumn[];
  projectMetaFields: ProjectMetaData[];
  updateTaskMetaFieldRank: (uuid: string, newRank: string) => void;
  updateProjectMetaFieldRank: (
    projectId: string,
    uuid: string,
    newRank: string,
  ) => void;
  getTasksList: any;
  taskType: string;
  dataType: tableDataType;
  projectBasicData: ProjectReducerState["projectBasicData"];
}

const ColumnOptionsDropdown: React.FC<Props> = (props) => {
  const {
    field,
    tasksColumns,
    dragHandleProps,
    projectMetaFields,
    taskType,
    dataType,
    projectBasicData,
  } = props;

  const params = useParams<{ projectId: string }>();
  const [showSetupModal, setShowSetupModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [columnAbsolute, setColumnAbsolute] = useState(false);

  const dropdownRef = useRef<any>();

  const {
    projectReducer: { editedCellId },
  } = useSelector((state: RootState) => state);

  const uuid = dataType === tableDataType.Task ? field.id : field.uuid;
  const cellId = `dropdown-${uuid}`;
  const isCellIdEdited = editedCellId.length && editedCellId !== cellId;

  const getColumns = () => {
    switch (dataType) {
      case tableDataType.Task:
        return tasksColumns.map((field) => ({
          uuid: field.metaFieldId,
          rank: field.metaFieldRank,
        }));
      case tableDataType.Project:
        return projectMetaFields.filter(
          (item) => item.taskType === taskType && item.isVisible,
        );
      default:
        return projectMetaFields.filter(
          (item) => item.taskType === taskType && item.isVisible,
        );
    }
  };

  const changeVisibility = async (newVisibilityValue: boolean) => {
    switch (dataType) {
      case tableDataType.Task:
        dispatch(changeFieldVisibility(field.id, newVisibilityValue));
        break;
      case tableDataType.Project:
        dispatch(changeProjectFieldVisibility(field.uuid, newVisibilityValue));
        break;
      default:
        console.error("Unknown data type.");
        break;
    }
  };

  const dispatch = useDispatch();

  const moveRight = () => {
    const columns = getColumns();

    const colIndex = columns.findIndex((x) => x.uuid === uuid);
    const columnsLength = columns.length;
    let newRank = "";

    if (colIndex === columnsLength - 1) {
      return;
    }
    newRank = generateRankString(
      columns[colIndex + 1].rank,
      columns[colIndex + 2]?.rank || "",
    );

    updateMetaFieldRank(uuid, newRank);
  };

  const moveLeft = () => {
    const columns = getColumns();
    const colIndex = columns.findIndex((x) => x.uuid === uuid);

    let newRank = "";

    if (colIndex === 0) {
      return;
    }
    newRank = generateRankString(
      columns[colIndex - 2]?.rank || "",
      columns[colIndex - 1].rank,
    );

    updateMetaFieldRank(uuid, newRank);
  };

  const updateMetaFieldRank = (uuid: string, newRank: string) => {
    switch (dataType) {
      case tableDataType.Project:
        props.updateProjectMetaFieldRank(params.projectId, uuid, newRank);
        break;

      case tableDataType.Task:
        props.updateTaskMetaFieldRank(uuid, newRank);
        break;
    }
  };

  const table = document.getElementById("table-scroll") as HTMLElement;

  useEffect(() => {
    if (isCellIdEdited) return;

    if (columnAbsolute) {
      table.onscroll = () => {
        dropdownRef.current.style.transform = `translateX(-${table.scrollLeft}px)`;
      };
    } else {
      dispatch(setEditedCellId(""));
      table.onscroll = null;
    }
  }, [table, table?.scrollLeft, columnAbsolute, dropdownRef.current]);

  useEffect(() => {
    columnToStatic();
  }, [field]);

  const columnToAbsolute = async () => {
    if (columnAbsolute) {
      return;
    }

    dispatch(setEditedCellId(cellId));
    setColumnAbsolute(true);
    dropdownRef.current.style.transform = `translateX(-${table.scrollLeft}px)`;
  };

  const columnToStatic = () => {
    if (!columnAbsolute) {
      return;
    }

    setTimeout(() => {
      dropdownRef.current.style.transform = `unset`;
      setColumnAbsolute(false);
      dropdownRef.current.blur();
    }, 200);
  };

  useOnClickOutside(dropdownRef, columnToStatic);

  return (
    <div
      style={{ position: columnAbsolute ? "absolute" : "static", zIndex: 100 }}
      ref={dropdownRef}
    >
      {projectBasicData?.permissions?.project.includes(
        "project_meta_fields_management",
      ) ? (
        <Dropdown className="ws-table__header-dropdown">
          {(isOpen, setIsOpen) => (
            <>
              <div className="ws-overview-fields-table__header-data">
                <span
                  className="ws-overview-fields-table__header-drag"
                  {...dragHandleProps}
                >
                  <GridIcon />
                </span>
                {/* <Tooltip center content={field.name} contentHidden={false}>
                  <span className="ws-overview-fields-table__header-title">
                    {field.name}
                  </span>
                </Tooltip> */}
                <ColumnHeaderTitle field={field} />

                <span
                  className="ws-overview-fields-table__header-dropdown-button"
                  onClick={() => {
                    setIsOpen(true);
                    columnToAbsolute();
                  }}
                >
                  <DotsIcon />
                </span>
                {projectBasicData?.clientMode && (
                  <>
                    {field.shared ? (
                      <ClientsOrangeIcon className="ws-table__header-title-icon" />
                    ) : (
                      <MembersLightGreyIcon className="ws-table__header-title-icon" />
                    )}
                  </>
                )}
              </div>
              <DropdownMenu isOpen={isOpen} onClick={() => setIsOpen(false)}>
                <DropdownMenuItem onClick={() => setShowSetupModal(true)}>
                  <EditIcon />
                  <IDHFormattedMessage id="ws_edit" defaultMessage="Edit" />
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => moveLeft()}>
                  <MoveLeftIcon />
                  <IDHFormattedMessage
                    id="ws_move_left"
                    defaultMessage="Move left"
                  />
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => moveRight()}>
                  <MoveRightIcon />
                  <IDHFormattedMessage
                    id="ws_move_right"
                    defaultMessage="Move right"
                  />
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => changeVisibility(false)}>
                  <HideIcon />
                  <IDHFormattedMessage
                    id="ws_hide_column"
                    defaultMessage="Hide column"
                  />
                </DropdownMenuItem>
                {field.name && !field.isRequired && (
                  <DropdownMenuItem
                    onClick={() => setShowDeleteModal(true)}
                    className="dropdown__menu-item--danger"
                  >
                    <DeleteIcon />
                    <IDHFormattedMessage
                      id="ws_remove"
                      defaultMessage="Remove"
                    />
                  </DropdownMenuItem>
                )}
              </DropdownMenu>
            </>
          )}
        </Dropdown>
      ) : (
        <>
          <span style={{ visibility: "hidden" }} {...dragHandleProps} />
          <span className="ws-table__header-title">{field.name}</span>
        </>
      )}

      {showDeleteModal && (
        <DeleteMetaFieldModal
          uuid={uuid}
          fieldName={field?.name ?? null}
          onClose={() => setShowDeleteModal(false)}
          dataType={dataType}
          onTable
        />
      )}

      {showSetupModal && (
        <ManageFieldModal
          data={{ ...field, title: field.name }}
          onClose={() => setShowSetupModal(false)}
          dataType={dataType}
          projectId={params.projectId}
        />
      )}
    </div>
  );
};

const mapStateToProps = (
  state: Pick<RootState, "taskReducer" | "projectReducer">,
) => ({
  tasksColumns: state.taskReducer.tasksColumns,
  projectMetaFields: state.projectReducer.projectMetaFields,
  taskType: state.projectReducer.taskType,
  projectBasicData: state.projectReducer.projectBasicData,
});

const mapDispatchToProps = (dispatch: any) => {
  return {
    getTasksList: (projectId: string, taskType: string) =>
      dispatch(getTasksList(projectId, taskType)),
    changeFieldVisibility: (id: string, isOn: boolean) =>
      dispatch(changeFieldVisibility(id, isOn)),
    deleteField: (id: string) => dispatch(deleteField(id)),
    deleteProjectMetaField: (uuid: string) =>
      dispatch(deleteProjectMetaField(uuid)),
    updateTaskMetaFieldRank: (metaFieldId: string, metaFieldRank: string) =>
      dispatch(updateTaskMetaFieldRank(metaFieldId, metaFieldRank)),
    updateProjectMetaFieldRank: (
      projectId: string,
      uuid: string,
      newRank: string,
    ) => dispatch(updateProjectMetaFieldRank(projectId, uuid, newRank)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ColumnOptionsDropdown);
