import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { ToolbarListWrapper } from "../ToolbatListWrapper/ToolbarListWrapper";
import ToolbarList from "../ToolbarList/ToolbarList";

export const ToolbarListTableSummary = ({ format, toolbarListProps }) => {
  const {
    reportReducer: { publicationsSummary, summaryStatistics },
  } = useSelector((state) => state);

  const summaryTables = useMemo(
    () =>
      Object.keys(publicationsSummary || {}).map((provider) => ({
        provider: `table-summary-${provider}`,
        disableExpanding: !publicationsSummary[provider]?.summary?.length,
      })) || [],
    [publicationsSummary, summaryStatistics],
  );

  return summaryTables.map((item, index) => (
    <ToolbarListWrapper key={item.provider} loading={false}>
      <ToolbarList
        provider={item.provider}
        format={format}
        open={item.open}
        toolbarListProps={toolbarListProps}
        disableExpanding={item.disableExpanding}
        noBottomBorder={index === summaryTables.length - 1}
        isReport
      />
    </ToolbarListWrapper>
  ));
};
