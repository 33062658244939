import React, { useEffect, useMemo, useRef, useState } from "react";
import "./ReportTemplate.scss";

import classNames from "classnames";
import RGL, { WidthProvider } from "react-grid-layout";
import { useDispatch, useSelector } from "react-redux";

import { injectIntl } from "react-intl";
import { useParams } from "react-router";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { MetaFieldType } from "src/app/methods/getMetaFieldTypeOptions";
import { translateMessage } from "src/app/methods/translateMessage";
import { addSelectedCommentsToLayout } from "src/app/generator-data/functions/addSelectedCommentsToLayout";
import { addSentimentAnalysisToLayout } from "src/app/generator-data/functions/addSentimentAnalysisToLayout";
import { addTableSummaryToLayout } from "src/app/generator-data/functions/addTableSummaryToLayout";
import {
  setNumberedHeaders,
  setReportGridElements,
  setReportHiddenGridElements,
  setReportLayout,
  setReportSavedTemplate,
  setReportSeparators,
} from "../../../../redux";
import CampaignPlaceholder from "../../../../images/project-placeholder.svg";
import { SocialProvider, TaskType } from "../../../../types";
import { getImageSource } from "../../../../utils/methods";
import { ShowcaseLoader } from "../../../CreatorShowcase/components/ShowcaseLoader/ShowcaseLoader";
import { ShowcasePrintingLoader } from "../../../CreatorShowcase/components/ShowcasePrintingLoader/ShowcasePrintingLoader";
import { ContentBox } from "../../../generator-data/components/ContentBox/ContentBox";
import { addCreatorToLayout } from "../../../generator-data/functions/addCreatorToLayout";
import { addGlossaryToLayout } from "../../../generator-data/functions/addGlossaryToLayout";
import { addMetaFieldToLayout } from "../../../generator-data/functions/addMetaFieldToLayout";
import { addPostsDataToLayout } from "../../../generator-data/functions/addPostsDataToLayout";
import { addPublicationToLayout } from "../../../generator-data/functions/addPublicationToLayout";
import { addSeparatorsToLayout } from "../../../generator-data/functions/addSeparatorsToLayout";
import { addSummaryLearningsToLayout } from "../../../generator-data/functions/addSummaryLearningsToLayouts";
import { renderMetaField } from "../../../generator-data/functions/renderMetaField";
import { addCampaignAudienceDataToLayout } from "../../functions/addCampaignAudienceDataToLayout";
import { addCreatorInsightsToLayout } from "../../functions/addCreatorInsightsToLayout";
import { addHeaderToLayout } from "../../functions/addHeaderToLayout";
import { getNumberedHeaders } from "../../functions/getNumberedHeaders";
import { getReportGridElements } from "../../functions/grid-elements/getReportGridElements";
import { renderAudienceData } from "../../functions/renderAudienceData";
import { renderCreator } from "../../functions/renderCreator";
import { renderCreatorInsights } from "../../functions/renderCreatorInsights";
import { renderHeader } from "../../functions/renderHeader";
import { renderPostsData } from "../../functions/renderPostsData";
import { renderPublication } from "../../functions/renderPublication";
import { GeneratorLoader } from "../GeneratorLoader/GeneratorLoader";
import { ReportFooter } from "../ReportFooter/ReportFooter";
import { ReportSummaryLearnings } from "../ReportSummaryLearnings/ReportSummaryLearnings";
import { CoverBox } from "./components/CoverBox/CoverBox";
import { ReportGridBox } from "./components/ReportGridBox/ReportGridBox";
import { TableOfContents } from "./components/TableOfContents/TableOfContents";
import { TitleBox } from "./components/TitleBox/TitleBox";
import { ReportGlossaryPartOne } from "../ReportGlossary/ReportGlossaryPartOne";
import { ReportGlossaryPartTwo } from "../ReportGlossary/ReportGlossaryPartTwo";
import { renderComments } from "../../functions/renderComments";
import { renderSentimentAnalysis } from "../../functions/renderSentimentAnalysis";
import { shouldDisplaySentiments } from "../../functions/shouldDisplaySentiments";
import { renderTableSummary } from "../../functions/renderTableSummary";

const ReactGridLayout = WidthProvider(RGL);

const META_TYPES_WHITELIST = [
  MetaFieldType.Text,
  MetaFieldType.Number,
  MetaFieldType.Currency,
  MetaFieldType.Percent,
  MetaFieldType.SingleSelect,
  MetaFieldType.MultiSelect,
  MetaFieldType.AggregatedSelect,
  MetaFieldType.Rating,
];

export const FIELDS_BLACKLIST = [
  "project_name",
  "title",
  "owner",
  "completed",
  "socialProfiles",
];

export const TIK_TOK_BLACKLIST = ["credibility", "location-by-city"];
export const YOUTUBE_BLACKLIST = [
  "credibility",
  "location-by-city",
  "keywords",
  "creators-brand-affinity",
  "audience-interests",
  "audience-brand-affinity",
  "creator-interests",
];

const PROVIDERS = [SocialProvider.Instagram, SocialProvider.TikTok];
export const STATIC_ELEMENTS = ["cover-box", "title-box", "separator"];

function ReportTemplate(props) {
  const {
    data,
    format,
    pages,
    setPages,
    metricsData,
    setMetricsData,
    progressValue,
    progressTarget,
    templateApplied,
    setTemplateApplied,
    coverImage,
    creatorsList,
    publicationsList,
    publicationsSummary,
    creatorInsights,
    audienceData,
    comments,
    sentimentValues,
    intl,
  } = props;

  const templateRef = useRef();

  const initialLayout = [
    { i: "cover-box", x: 0, y: 0, w: 12, h: 13, static: true },
    { i: "title-box", x: 0, y: 13, w: 12, h: 2, static: true },
  ];

  const [filteredMetadata, setFilteredMetadata] = useState([]);
  const [postsData, setPostsData] = useState([]);
  const [shouldRerenderLayout, setShouldRerenderLayout] = useState(true);

  const dispatch = useDispatch();
  const {
    projectReducer: { membersList },
    reportReducer: {
      gridElements,
      layout,
      loading,
      printing,
      showLoader,
      loaderText,
      savedTemplate,
      selectedTemplate,
      separators,
      newlyAddedPages,
    },
    taskReducer: { contentAutocomplete },
  } = useSelector((state) => state);

  const setLayout = (newLayout) => {
    dispatch(setReportLayout(newLayout));
  };

  const setGridElements = (newGridElements) => {
    dispatch(setReportGridElements(newGridElements));
  };

  const setHiddenGridElements = (newHiddenGridElements) => {
    dispatch(setReportHiddenGridElements(newHiddenGridElements));
  };

  const setSeparators = (newSeparators) => {
    dispatch(setReportSeparators(newSeparators));
  };

  const { creatorId } = useParams();

  useEffect(() => {
    if (savedTemplate.length) {
      setTemplateApplied(false);
    }
  }, [savedTemplate]);

  useEffect(() => {
    if (!templateApplied) {
      getGridElements();
      // showToast("success", "Success", "Saved template has been applied!");
    }
  }, [templateApplied]);

  useEffect(() => {
    if (!data?.metadata) return;

    // Common metafields
    setFilteredMetadata(
      data.metadata
        ?.filter(
          (field) =>
            field.taskType === null &&
            !FIELDS_BLACKLIST.includes(field.key) &&
            META_TYPES_WHITELIST.includes(field.type),
        )
        .sort((a, b) => a.rank?.localeCompare(b.rank)),
    );

    // Publication metafields
    setPostsData(
      data.metadata
        ?.filter(
          (field) =>
            field.taskType === TaskType.Publication &&
            !FIELDS_BLACKLIST.includes(field.key) &&
            META_TYPES_WHITELIST.includes(field.type),
        )
        .sort((a, b) => a.rank?.localeCompare(b.rank)),
    );
  }, [data?.metadata]);

  useEffect(() => {
    getGridElements();
  }, [
    filteredMetadata,
    metricsData,
    format,
    creatorsList,
    publicationsList,
    publicationsSummary,
    creatorInsights,
    audienceData,
    comments,
    sentimentValues,
  ]);

  useEffect(() => {
    if (selectedTemplate.value.type === "default") {
      getGridElements();
    }
  }, [selectedTemplate]);

  // Rendering

  useEffect(() => {
    if (!shouldRerenderLayout) return;

    // applying saved template
    if (savedTemplate?.length && !templateApplied) {
      applySavedLayout();
    }
    // applying default template
    // else if (selectedTemplate?.value?.type === "default") {
    //   applyChosenTemplate();
    // }
    else {
      applyDefaultLayout();
    }
    setShouldRerenderLayout(false);
  }, [gridElements, format.pageWidth]);

  const applySavedLayout = () => {
    setLayout(savedTemplate);

    dispatch(setReportSavedTemplate([]));
    setTemplateApplied(true);
  };

  const applyDefaultLayout = () => {
    const newLayout = initialLayout;
    const newSeparators = [];

    // Table of contents
    newLayout.push({
      i: "table-of-contents",
      x: 0,
      y: 17,
      w: 12,
      h: 16,
      static: true,
    });

    // Campaign details
    addHeaderToLayout("campaign-overview", newLayout, format, "header");
    addHeaderToLayout("metadata", newLayout, format, "subheader");
    gridElements
      .filter((item) => item.gridBoxType === "metadata")
      .forEach((box) => {
        addMetaFieldToLayout(box, newLayout, newSeparators, format);
      });

    // Creators insights
    addHeaderToLayout("creator-insights", newLayout, format, "subheader");
    gridElements
      .filter((item) => item.gridBoxType === "creator-insights")
      .forEach((box) => {
        addCreatorInsightsToLayout(box, newLayout, format);
      });

    // Creators in the campaign
    addHeaderToLayout("creator", newLayout, format, "subheader");
    creatorsList.forEach((creator) => {
      addCreatorToLayout(creator, newLayout, format);
    });

    // Campaign audience data
    addHeaderToLayout("audience-data", newLayout, format, "subheader");
    gridElements
      .filter((item) => item.gridBoxType === "audience-data")
      .forEach((box) => {
        addCampaignAudienceDataToLayout(box, newLayout, format);
      });

    // Campaign data
    addHeaderToLayout("campaign-data", newLayout, format, "header");
    addHeaderToLayout("post-data", newLayout, format, "subheader");
    postsData.forEach((item) => {
      addPostsDataToLayout(item, newLayout, format);
    });

    // All Publications
    addHeaderToLayout("publication", newLayout, format, "header");
    // addTopPerformingPublicationsToLayout(publicationsList, newLayout, format);
    addHeaderToLayout("publication", newLayout, format, "subheader");
    publicationsList.forEach((publication) => {
      addPublicationToLayout(publication, newLayout, format);
    });

    // Table summary
    addHeaderToLayout("table-summary", newLayout, format, "header");
    addTableSummaryToLayout(newLayout, format, publicationsSummary);

    // Comments analysis
    if (shouldDisplaySentiments(sentimentValues)) {
      addHeaderToLayout("comments-analysis", newLayout, format, "header");
      addHeaderToLayout(
        "sentiment-analysis-summary",
        newLayout,
        format,
        "subheader",
      );
      addSentimentAnalysisToLayout(sentimentValues, newLayout, format);
      addHeaderToLayout("selected-comments", newLayout, format, "subheader");
      addSelectedCommentsToLayout(comments, newLayout, format);
    }

    // Summary & Learnings
    addHeaderToLayout("summary-learnings", newLayout, format, "header");
    addSummaryLearningsToLayout(newLayout);

    // Glossary
    addHeaderToLayout("glossary", newLayout, format, "header");
    addGlossaryToLayout(newLayout);

    setLayout(newLayout);
    setSeparators(newSeparators);
  };

  useEffect(() => {
    if (!layout?.length) return;

    dispatch(setNumberedHeaders(getNumberedHeaders(layout, gridElements)));

    const functionProps = {
      dispatch,
      format,
      gridElements,
      layout,
      newlyAddedPages,
      pages,
      separators,
      //
      setPages,
      setLayout,
      setSeparators,
    };

    addSeparatorsToLayout(functionProps);
  }, [layout]);

  const formatMessage = ({ id, defaultMessage }) => {
    return translateMessage({ intl, id, defaultMessage });
  };

  const getGridElements = async () => {
    if (data?.taskId !== creatorId) return;

    const functionProps = {
      audienceData,
      comments,
      contentAutocomplete,
      creatorInsights,
      creatorsList,
      filteredMetadata,
      formatMessage,
      membersList,
      publicationsList,
      publicationsSummary,
      postsData,
      savedTemplate,
      sentimentValues,
      templateApplied,
      //
      dispatch,
      setGridElements,
      setHiddenGridElements,
      setShouldRerenderLayout,
    };

    await getReportGridElements(functionProps);
  };

  const ShowcaseBoxes = useMemo(() => {
    // if (!data.socialProfiles) return;

    return gridElements.map((box) => {
      switch (box.gridBoxType) {
        case "metadata":
          return renderMetaField(box, data, ReportGridBox);
        case "header":
          return renderHeader(box, ReportGridBox);
        case "subheader":
          return renderHeader(box, ReportGridBox, true);
        case "creator":
          return renderCreator(box, ReportGridBox, creatorsList);
        case "publication":
        case "top-publication":
          return renderPublication(
            box,
            ReportGridBox,
            publicationsList,
            creatorsList,
          );
        case "post-data":
          return renderPostsData(box, ReportGridBox, postsData);
        case "audience-data":
          return renderAudienceData(box, audienceData, layout);
        case "creator-insights":
          return renderCreatorInsights(box, creatorInsights, layout);
        case "paragraph":
          return (
            <ReportGridBox key={box.label} id={box.label}>
              <ContentBox
                key={box.label}
                fieldName="Paragraph"
                data={data}
                block
                textField
              />
            </ReportGridBox>
          );
        case "text":
          return (
            <ReportGridBox key={box.label} id={box.label}>
              <ContentBox
                key={box.label}
                fieldName="Text field"
                data={data}
                block
                textField
              />
            </ReportGridBox>
          );
        case "sentiment-analysis":
          return renderSentimentAnalysis(box, ReportGridBox);
        case "table-summary":
          return renderTableSummary(box, ReportGridBox, layout);
        case "comments":
          return renderComments(box, ReportGridBox);
        case "summary-learnings":
          return (
            <ReportGridBox id={box.label} key={box.label}>
              <ReportSummaryLearnings />
            </ReportGridBox>
          );
        case "glossary":
          return (
            <ReportGridBox id={box.label} key={box.label} smallPadding>
              <ReportGlossaryPartOne />
            </ReportGridBox>
          );
        case "glossary-two":
          return (
            <ReportGridBox id={box.label} key={box.label} smallPadding>
              <ReportGlossaryPartTwo />
            </ReportGridBox>
          );
        case "table-of-contents":
          return (
            <ReportGridBox
              id="table-of-contents"
              key="table-of-contents"
              className="table-of-contents-wrapper"
              disableEditing
            >
              <TableOfContents pages={pages} />
            </ReportGridBox>
          );
        default:
          return null;
      }
    });
  }, [
    gridElements,
    layout,
    format,
    data,
    publicationsList,
    publicationsSummary,
    creatorsList,
    creatorInsights,
    audienceData,
    comments,
    sentimentValues,
  ]);

  const onLayoutChange = (newLayout) => {
    setLayout(newLayout);
  };

  const filteredLayout = layout.filter(
    (item) =>
      gridElements?.find((box) => item.i === box.label) ||
      STATIC_ELEMENTS.some((el) => item.i.includes(el)),
  );

  return (
    <div
      ref={templateRef}
      className={classNames("report-template", {
        "report-template--loading": loading || printing,
      })}
      style={{
        paddingTop: `${format.rowHeight / 2}px`,
        width: `${format.pageWidth}px`,
      }}
    >
      {showLoader && <GeneratorLoader text={loaderText} />}

      {printing && <ShowcasePrintingLoader />}

      {loading ? (
        <ShowcaseLoader />
      ) : (
        <>
          <ReactGridLayout
            style={{ width: `${format.pageWidth}px` }}
            className="layout"
            layout={filteredLayout}
            cols={format.cols}
            rowHeight={format.rowHeight}
            compactType={null}
            margin={[24, 20]}
            preventCollision
            onLayoutChange={onLayoutChange}
            draggableHandle=".react-drag-handle"
          >
            <ReportGridBox
              id="cover-box"
              key="cover-box"
              className="cover-box-wrapper"
              disableEditing
            >
              <CoverBox
                data={coverImage}
                creatorsList={creatorsList}
                format={format}
              />
            </ReportGridBox>

            <ReportGridBox id="title-box" key="title-box" smallPadding>
              <TitleBox data={data} />
            </ReportGridBox>

            {ShowcaseBoxes}

            {separators.map((item, index) => (
              <div
                id={item.key}
                key={item.key}
                className="report-template__separator"
              >
                <div
                  className={classNames("report-template__separator-line", {
                    "report-template__separator-line--hidden": showLoader,
                  })}
                />
                <div className="report-template__separator-provider">
                  <img
                    className="report-template__separator-campaign-logo"
                    src={getImageSource(
                      data?.cover,
                      "tiny",
                      CampaignPlaceholder,
                    )}
                    alt="campaign"
                  />
                  {data.title}
                </div>
                {index + 1 >= 2 && (
                  <div className="report-template__separator-page">
                    <IDHFormattedMessage id="ws_page" defaultMessage="Page" />
                    &nbsp;|&nbsp;
                    <span className="report-template__separator-page-number">
                      {index + 1}
                    </span>
                  </div>
                )}
              </div>
            ))}
          </ReactGridLayout>

          <ReportFooter data={data} />
        </>
      )}
    </div>
  );
}

export default injectIntl(ReportTemplate);
