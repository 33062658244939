import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./SettingsTrialDropdown.scss";

import { ReactComponent as LogoutIcon } from "src/images/menu-icons/logout.svg";

import classNames from "classnames";
import { UpgradeAccountModal } from "src/app/modals/UpgradeAccountModal/UpgradeAccountModal";
import { CancelTrialModal } from "src/app/modals/CancelTrialModal.jsx/CancelTrialModal";
import { ContactSupportModal } from "src/app/modals/ContactSupportModal.jsx/ContactSupportModal";
import { openTrialLimitationsModal } from "src/redux";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { SettingsContext, SettingsTab } from "src/app/SettingsModal/enums";
import { useHistory } from "react-router";
import { openSettingsModal } from "src/app/SettingsModal/methods";
import { identify } from "src/redux/main/mainActions";
import axios from "axios";
import { API_URLS } from "src/utils/API_URLS";
import { Button } from "src/app/components/Button/Button";
import { AppLocation } from "src/redux/main/mainReducer";
import { showErrorToast } from "src/utils/methods";
import { WorkspacePermissionsEnum } from "src/utils/PermissionsEnums";
import { ReactComponent as FieldIcon } from "../../../images/field.svg";
import { ReactComponent as SettingsAltIcon } from "../../../images/settings-alt.svg";
import { ReactComponent as SettingsIcon } from "../../../images/settings.svg";
import { ReactComponent as SharePlusIcon } from "../../../images/share-plus.svg";
import { ReactComponent as AvatarIcon } from "../../../images/avatar.svg";
import { ReactComponent as SelectedIcon } from "../../../images/selected.svg";
import { ReactComponent as QuestionMarkOval } from "../../../images/question-mark-oval.svg";
import { ReactComponent as Lifebuoy } from "../../../images/lifebuoy.svg";
import { ReactComponent as CancelOval } from "../../../images/cancel-oval.svg";
import { ReactComponent as UnionYellowIcon } from "../../../images/union-yellow.svg";
import {
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuSeparator,
} from "../../components/Dropdown/Dropdown";

function SettingsTrialDropdown() {
  const [showUpgradeAccountModal, setShowUpgradeAccountModal] = useState(false);
  const [showCancelTrialModal, setShowCancelTrialModal] = useState(false);
  const [showContactSupportModal, setShowContactSupportModal] = useState(false);

  const {
    mainReducer: { activeWorkspaceUuid, workspacesList, identity },
  } = useSelector((state) => state);

  const { trialLimits } = identity || {};
  const { daysLimit } = trialLimits || {};

  const getWorkspaceDisplayGroupOrderByName = (wsName) => {
    if (wsName?.toLowerCase().includes(" (demo)")) {
      return 1;
    }
    if (wsName?.toLowerCase().includes(" (trial)")) {
      return 2;
    }
    if (wsName?.toLowerCase().includes(" (resets on")) {
      return 3;
    }

    return 0;
  };

  const dispatch = useDispatch();
  const history = useHistory();

  const handleOpenSettingsModal = (context, tab) => {
    openSettingsModal(history, AppLocation.Navbar, context, tab);
  };

  const logout = () => {
    axios
      .get(API_URLS.logout)
      .then(() => {
        dispatch(identify(activeWorkspaceUuid));
      })
      .catch((error) => {
        console.error(error);
        showErrorToast();
      });
  };

  const showTrial = trialLimits;

  return (
    <Dropdown
      className={classNames("settings-trial-dropdown", {
        "settings-trial-dropdown--trial-visible": showTrial,
      })}
      toRight
    >
      {(isOpen, setIsOpen) => (
        <>
          {showTrial && (
            <div
              className={classNames("settings-trial-dropdown__button", {
                "settings-trial-dropdown__button--yellow":
                  daysLimit <= 2 && daysLimit > 0,
                "settings-trial-dropdown__button--red": daysLimit === 0,
              })}
              onClick={() => setIsOpen(true)}
            >
              {daysLimit === 1
                ? `${daysLimit} day left`
                : `${daysLimit} days left`}
            </div>
          )}

          <Button
            variant="light-grey"
            size="square-small"
            onClick={() => setIsOpen(true)}
            className="navbar__right-menu-icon"
          >
            <SettingsIcon />
          </Button>

          <DropdownMenu isOpen={isOpen}>
            {showTrial && (
              <>
                <DropdownMenuItem
                  onClick={() => setShowUpgradeAccountModal(true)}
                >
                  <UnionYellowIcon />
                  <IDHFormattedMessage
                    id="ws_upgrade_account"
                    defaultMessage="Upgrade account"
                  />
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={() => dispatch(openTrialLimitationsModal())}
                >
                  <QuestionMarkOval />
                  <IDHFormattedMessage
                    id="ws_what_include"
                    defaultMessage="What include?"
                  />
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={() => setShowContactSupportModal(true)}
                >
                  <Lifebuoy />
                  <IDHFormattedMessage
                    id="ws_contact_support"
                    defaultMessage="Contact support"
                  />
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => setShowCancelTrialModal(true)}>
                  <CancelOval />
                  <IDHFormattedMessage
                    id="ws_cancel_trial"
                    defaultMessage="Cancel trial"
                  />
                </DropdownMenuItem>
              </>
            )}

            <>
              <div className="dropdown__menu-list-wrapper">
                {workspacesList
                  ?.sort(function (a, b) {
                    if (
                      getWorkspaceDisplayGroupOrderByName(a?.name) ===
                      getWorkspaceDisplayGroupOrderByName(b?.name)
                    ) {
                      if (a?.name?.toLowerCase() < b?.name?.toLowerCase())
                        return -1;
                      if (a?.name?.toLowerCase() > b?.name?.toLowerCase())
                        return 1;
                    }

                    return (
                      getWorkspaceDisplayGroupOrderByName(a?.name) -
                      getWorkspaceDisplayGroupOrderByName(b?.name)
                    );
                  })
                  .map((workspace) => (
                    <DropdownMenuItem
                      key={workspace.uuid}
                      disabled={workspace.uuid === activeWorkspaceUuid}
                      dark={workspace.uuid === activeWorkspaceUuid}
                      onClick={() => window.open(workspace.wsUrl, "_blank")}
                    >
                      {workspace.name}
                      {workspace.uuid === activeWorkspaceUuid && (
                        <SelectedIcon
                          className="dropdown__menu-item-tick"
                          style={{
                            marginLeft: 8,
                            marginRight: 0,
                          }}
                        />
                      )}
                    </DropdownMenuItem>
                  ))}
              </div>

              <DropdownMenuSeparator />
              <DropdownMenuItem
                onClick={() =>
                  handleOpenSettingsModal(
                    SettingsContext.Member,
                    SettingsTab.Account,
                  )
                }
              >
                <AvatarIcon />
                <IDHFormattedMessage
                  id="ws_my_account"
                  defaultMessage="My account"
                />
              </DropdownMenuItem>

              <DropdownMenuItem
                onClick={() =>
                  handleOpenSettingsModal(
                    SettingsContext.Workspace,
                    SettingsTab.Members,
                  )
                }
              >
                <SharePlusIcon />
                <IDHFormattedMessage id="ws_invite" defaultMessage="Invite" />
              </DropdownMenuItem>

              {identity?.permissions?.workspace?.includes(
                "global_meta_fields_management",
              ) && (
                <DropdownMenuItem
                  onClick={() =>
                    handleOpenSettingsModal(
                      SettingsContext.Workspace,
                      SettingsTab.ManageFields,
                    )
                  }
                >
                  <FieldIcon />
                  <IDHFormattedMessage
                    id="ws_manage_fields"
                    defaultMessage="Manage fields"
                  />
                </DropdownMenuItem>
              )}

              {identity?.permissions?.workspace?.includes(
                WorkspacePermissionsEnum.WORKSPACE_SETUP_MANAGEMENT,
              ) && (
                <DropdownMenuItem
                  onClick={() =>
                    handleOpenSettingsModal(
                      SettingsContext.Workspace,
                      SettingsTab.WorkspaceSetup,
                    )
                  }
                >
                  <SettingsAltIcon />
                  <IDHFormattedMessage
                    id="ws_workspace_settings"
                    defaultMessage="Workspace settings"
                  />
                </DropdownMenuItem>
              )}
            </>
            <DropdownMenuItem onClick={logout} data-qa="logout-button">
              <LogoutIcon />
              <IDHFormattedMessage id="ws_logout" defaultMessage="Logout" />
            </DropdownMenuItem>
          </DropdownMenu>

          {showUpgradeAccountModal && (
            <UpgradeAccountModal
              onClose={() => setShowUpgradeAccountModal(false)}
            />
          )}
          {showCancelTrialModal && (
            <CancelTrialModal onClose={() => setShowCancelTrialModal(false)} />
          )}
          {showContactSupportModal && (
            <ContactSupportModal
              onClose={() => setShowContactSupportModal(false)}
            />
          )}
        </>
      )}
    </Dropdown>
  );
}

export default SettingsTrialDropdown;
