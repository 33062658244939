export const GET_GENERATOR_DATA = "GET_GENERATOR_DATA";
export const GET_GENERATOR_DATA_SUCCESS = "GET_GENERATOR_DATA_SUCCESS";
export const SET_GENERATOR_DATA = "SET_GENERATOR_DATA";
export const SET_LAYOUT = "SET_LAYOUT";
export const SET_GRID_ELEMENTS = "SET_GRID_ELEMENTS";
export const SET_HIDDEN_GRID_ELEMENTS = "SET_HIDDEN_GRID_ELEMENTS";
export const SET_REPORT_SEPARATORS = "SET_REPORT_SEPARATORS";
export const SET_REPORT_SAVED_TEMPLATE = "SET_REPORT_SAVED_TEMPLATE";
export const SET_REPORT_SELECTED_TEMPLATE = "SET_REPORT_SELECTED_TEMPLATE";
export const CLEAR_REPORT_STATE = "CLEAR_REPORT_STATE";
export const SET_LOADING = "SET_LOADING";
export const SET_PRINTING = "SET_PRINTING";
export const SET_SHOW_LOADER = "SET_SHOW_LOADER";
export const SET_LOADER_TEXT = "SET_LOADER_TEXT";
export const SET_NUMBERED_HEADERS = "SET_NUMBERED_HEADERS";
export const SET_NEWLY_ADDED_PAGES = "SET_NEWLY_ADDED_PAGES";
export const SET_APPLY_BOXES_CONTENT = "SET_APPLY_BOXES_CONTENT";
export const SET_CREATORS_METADATA = "SET_CREATORS_METADATA";
export const SET_PUBLICATIONS_SUMMARY_STATS = "SET_PUBLICATIONS_SUMMARY_STATS";
export const SET_PUBLICATIONS_METADATA = "SET_PUBLICATIONS_METADATA";
export const SET_SUMMARY_STATISTICS = "SET_SUMMARY_STATISTICS";
