import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import axios from "axios";
import { uuidv7 as uuid } from "uuidv7";
import { useIntl } from "react-intl";

import Modal, { hideModal, ModalTitle } from "src/app/components/Modal/Modal";
import {
  getProjectDetails,
  getProject,
  setTaskFieldAddedOrUpdated,
  setProjectFieldAddedOrUpdated,
  getGlobalTaskMetaFieldList,
  updateTasksList,
  getGlobalProjectMetaFieldList,
  getSettingsGlobalFields,
  setReloadTaskFields,
  clearProjectErrorMessages,
  clearTaskErrorMessages,
} from "src/redux";
import { API_URLS } from "src/utils/API_URLS";
import {
  capitalizeFirstLetter,
  showErrorToast,
  sleep,
} from "src/utils/methods";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import Tabs from "src/app/components/Tabs/Tabs";
import Tab from "src/app/components/Tabs/Tab";
import { TaskType } from "src/types";
import { getDictionaryTypes } from "src/app/methods/getDictionaryTypes";
import { RootState } from "src/redux/reducers";
import { showToast } from "../../methods/showToast";
import { tableDataType } from "../../components/Table/Table";
import {
  filterGlobalMetaFields,
  getMetaFieldTypeOptions,
  MetaFieldType,
} from "../../methods/getMetaFieldTypeOptions";
import {
  createProjectField,
  setProjectFieldData,
  updateProjectField,
} from "./functions/projectFieldsFunctions";
import {
  createTaskField,
  setTaskFieldData,
  updateTaskField,
} from "./functions/taskFieldsFunctions";
import {
  ActiveTabs,
  IAccessLinkData,
  MetaFieldTypeOption,
  MFStateEntries,
  SelectedFormulaElement,
} from "./types";
import {
  EDITABLE_GLOBAL_FIELD_TYPES,
  generateProjectFormulaElementOptions,
  generateTaskFormulaElementOptions,
  getInitialState,
  GLOBAL_FIELD_TYPES_BLACKLIST,
  METRIC_TYPES,
} from "./utils";
import { translateMessage } from "../../methods/translateMessage";
import CustomFields from "./components/CustomFields/CustomFields";
import {
  generateGlobalProjectOptions,
  generateGlobalTaskOptions,
} from "./components/FormulaOverview/utils";
import LibraryFields from "./components/LibraryFields/LibraryFields";

import "./ManageFieldModal.scss";

interface Props {
  data?: any;
  onClose: () => void;
  dataType: tableDataType;
  title?: ReactNode;
  inputLabelText?: ReactNode;
  isNewOverviewForClient?: boolean;
  defaultOption?: MetaFieldType.AccessLink;
  defaultFieldName?: string;
  hidden?: boolean;
  showMore?: () => void;
  projectId: string;
  editingGlobalField?: boolean;
  globalFieldMode?: boolean;
  onSuccessCallback?: (uuids?: string[]) => void;
}

function ManageFieldModal({
  data,
  onClose,
  dataType,
  title,
  inputLabelText,
  isNewOverviewForClient,
  defaultOption,
  defaultFieldName,
  hidden,
  showMore,
  projectId,
  globalFieldMode,
  editingGlobalField,
  onSuccessCallback,
}: Props) {
  const {
    projectReducer: {
      taskType: taskTypeFromRedux,
      projectMetaFields,
      projectFieldAddedOrUpdated,
      globalMetaFieldsList,
      isGlobalMetaFieldsListLoading,
      errorMessages: projectErrorMessages,
    },
    taskReducer: {
      tasksColumns,
      taskFieldAddedOrUpdated,
      errorMessages: taskErrorMessages,
    },
    mainReducer: { activeWorkspaceUuid, identity },
    settingsReducer: { areGlobalMetaFieldsLoading, shouldReloadGlobalFields },
  } = useSelector((state: RootState) => state);

  const dispatch = useDispatch();

  const intl = useIntl();

  const [loading, setLoading] = useState(false);
  const [accessLinkData, setAccessLinkData] = useState<IAccessLinkData>({
    metaFields: [],
  });
  const [activeTab, setActiveTab] = useState<ActiveTabs>(ActiveTabs.Custom);

  const [state, updateState] = useState(
    getInitialState(
      data,
      defaultFieldName,
      defaultOption,
      taskTypeFromRedux,
      dataType,
    ),
  );

  const {
    fieldName,
    description,
    nameEdited,
    selectedOption,
    selectedTaskType,
    metricsData,
    salesMetricOptions,
    isAudienceReach,
    selectedMetric,
    selectedAudienceCountry,
    currenciesData,
    selectedCurrency,
    formulaValue,
    formulaTab,
    selectedSummarySources,
    conditionColumns,
    conditionValues,
    availableTargets,
    valueSourceOptions,
    selectedPlatform,
    dataApplied,
    selectedIcon,
    addIcon,
    selectedFormulaElements,
    selectedFormulaOperator,
    globalMetaFieldData,
    filterMemberByTeam,
    wsTeamUuids,
    relatedMetaField,
    relatedMetaFieldAggregationType,
    formulaElementOptions,
    emvValues,
  } = state;

  const isGlobalMode = globalFieldMode || editingGlobalField;
  const isLoading =
    isGlobalMetaFieldsListLoading || loading || areGlobalMetaFieldsLoading;

  const taskType = isGlobalMode ? selectedTaskType.value : taskTypeFromRedux;

  const metaFieldTypeOptions: MetaFieldTypeOption[] = useMemo(() => {
    const basicOptions = getMetaFieldTypeOptions(taskType, dataType).filter(
      (item) => {
        if (!data && item.value === MetaFieldType.AggregatedSelect) {
          return false;
        }

        if (globalFieldMode) {
          return !GLOBAL_FIELD_TYPES_BLACKLIST.includes(item.value);
        }
        return true;
      },
    );

    if (
      dataType === tableDataType.Project &&
      (isNewOverviewForClient || data?.shared)
    ) {
      return basicOptions;
    }

    const dictionaryOptions =
      dataType !== tableDataType.RecruitmentForm
        ? getDictionaryTypes(identity)
        : [];

    return [...basicOptions, ...dictionaryOptions];
  }, [globalFieldMode, taskType, dataType, identity.sidebarItems]);

  const shouldDisplayDefaultTabs =
    !globalFieldMode &&
    dataType !== tableDataType.Project &&
    !defaultOption &&
    !data;

  const setState = (type: MFStateEntries, value: any) => {
    updateState((state) => ({
      ...state,
      [type]: value,
    }));
  };

  /// / SETTING DATA TO EDIT
  const setBasicTypes = (fieldType: MetaFieldType, initialData: any) => {
    switch (fieldType) {
      case MetaFieldType.Currency:
      case MetaFieldType.EMV:
        const currencyCode = initialData?.currencyCode;

        setState("selectedCurrency", {
          key: currencyCode,
          label: (
            <span className="currency-option">
              <span className="currency-code">{currencyCode}</span>
              {currenciesData[currencyCode]}
            </span>
          ),
          value: currencyCode,
        });

        if (initialData.icon?.name) {
          setState("selectedIcon", {
            value: initialData.icon.name,
            label: initialData.icon.name,
          });
          setState("selectedColor", initialData.icon.color);
          setState("addIcon", true);
        }

        break;
      case MetaFieldType.Member:
        if (globalFieldMode) {
          setState(
            "filterMemberByTeam",
            initialData?.data?.wsTeamUuids?.length > 0,
          );
          setState("wsTeamUuids", initialData?.data?.wsTeamUuids);
        } else {
          setState("filterMemberByTeam", initialData?.wsTeamUuids?.length > 0);
          setState("wsTeamUuids", initialData?.wsTeamUuids);
        }
        break;
      case MetaFieldType.DictionaryElement:
        setState(
          "enableDictionarySubField",
          initialData?.wsDictionarySubFieldUuid,
        );
        setState(
          "wsDictionarySubFieldUuid",
          initialData?.wsDictionarySubFieldUuid ?? null,
        );
        break;
      case MetaFieldType.AccessLink:
        const accessLinks = {
          metaFields: initialData?.wsMetaFieldAccessLinks.metaFields,
          caption: initialData?.wsMetaFieldAccessLinks.caption,
          description: initialData?.wsMetaFieldAccessLinks.description,
        };
        setAccessLinkData(accessLinks);
        break;
    }
  };

  //
  // CREATING
  //

  const createField = async () => {
    const metaFieldType = METRIC_TYPES.includes(selectedOption.value)
      ? selectedMetric?.type
      : selectedOption.value;

    const baseFunctionProps = {
      taskType,
      dispatch,
      dataType,
      metaFieldType,
      projectId,
      state,
      globalFieldMode,
      identity,
      activeWorkspaceUuid,
      accessLinkData,
    };

    const projectFieldFunctionProps = {
      ...baseFunctionProps,
      projectMetaFields,
      isNewOverviewForClient,
    };

    const taskFieldFunctionProps = {
      ...baseFunctionProps,
      fieldName,
      tasksColumns,
      onSuccessCallback,
    };

    setLoading(true);

    try {
      switch (dataType) {
        case tableDataType.Task:
          await createTaskField(taskFieldFunctionProps);
          break;
        case tableDataType.RecruitmentForm:
          await createTaskField(
            taskFieldFunctionProps,
            tableDataType.RecruitmentForm,
          );
          break;
        case tableDataType.Project:
        case tableDataType.ProjectDetails:
          await createProjectField(projectFieldFunctionProps);
          break;
        default:
          console.error("Unknown data type");
          break;
      }
    } catch (error) {
      console.error(error);
      showToast(
        "error",
        <IDHFormattedMessage
          id="ws_something_went_wrong"
          defaultMessage="Something went wrong."
        />,
        <IDHFormattedMessage
          id="ws_could_not_create_meta_field"
          defaultMessage="Could not create meta field."
        />,
      );
    } finally {
      setLoading(false);
    }
  };

  const handleTaskSuccess = async () => {
    onClose();
    await dispatch(updateTasksList(projectId, taskType));

    await sleep(1000);

    if (dataType !== tableDataType.RecruitmentForm) {
      if (!data) {
        const scrollTarget = document.querySelector(
          ".projects--single-project",
        );
        scrollTarget?.scroll({
          left: scrollTarget.scrollWidth,
          behavior: "smooth",
        });
      }
    }
    dispatch(setTaskFieldAddedOrUpdated(false));
  };

  const handleProjectSuccess = async () => {
    if (dataType === tableDataType.Project) {
      await dispatch(getProject(projectId));
    } else {
      dispatch(getProjectDetails(projectId));
      showMore?.();
    }

    if (!data) {
      const scrollTarget = document.querySelector(
        ".ws-overview-fields-table__columns",
      );
      scrollTarget?.scroll({
        left: scrollTarget?.scrollWidth,
        behavior: "smooth",
      });
    }

    dispatch(setProjectFieldAddedOrUpdated(false));
    hideModal(onClose);
  };

  //
  // UPDATING
  //

  const updateField = async () => {
    const functionProps = {
      data,
      dispatch,
      state,
      accessLinkData,
      activeWorkspaceUuid,
      editingGlobalField,
      identity,
    };

    setLoading(true);

    try {
      switch (dataType) {
        case tableDataType.Task:
          updateTaskField(functionProps);
          break;

        case tableDataType.Project:
        case tableDataType.ProjectDetails:
          updateProjectField(functionProps);
          break;

        default:
          console.error("Unknown data type");
          break;
      }
    } catch (error) {
      console.error(error);
      dispatch(updateTasksList(projectId, taskType));
    } finally {
      setLoading(false);
    }
  };

  //
  // CONFIRM FUNCTIOn
  //

  const handleConfirm = (e: React.FormEvent) => {
    e?.preventDefault();

    dispatch(clearProjectErrorMessages());
    dispatch(clearTaskErrorMessages());

    if (data) {
      updateField();
    } else {
      createField();
    }
  };

  // GETTING ADDITIONAL DATA (METRICS, CURRENCIES ETC)

  const getSalesMetrics = async () => {
    try {
      const url = API_URLS.getProviderMetricsPossibleValues.replace(
        ":metricsType:",
        "WsCouponRedemptions",
      );

      const { data } = await axios.get(url);

      setState("salesMetrics", data.content);
    } catch (error) {
      console.error(error);
      showErrorToast();
    }
  };

  const getSummaryMetrics = async () => {
    if (state.formulaTab === "overview") return;

    const url = isGlobalMode
      ? API_URLS.getGlobalProjectProviderMetricsPossibleValues
          .replace(":wsWorkspaceUuid:", activeWorkspaceUuid)
          .replace(":taskType:", selectedTaskType.value)
      : API_URLS.getProjectProviderMetricsPossibleValues
          .replace(":metricsType:", "TableFormula")
          .replace(":projectUuid:", projectId)
          .replace(":taskType:", taskType)
          .replace(
            ":shared:",
            String((isNewOverviewForClient || data?.shared) ?? false),
          );

    try {
      const { data: response } = await axios.get(url);

      setState("metricsData", response.content);
    } catch (error) {
      console.error(error);
      showErrorToast();
    }
  };

  const getCurrencies = async () => {
    try {
      const { data } = await axios.get(API_URLS.getCurrencies);
      setState("currenciesData", data.content);
    } catch (error) {
      console.error(error);
      showErrorToast();
    }
  };

  const getTeams = async () => {
    try {
      const { data } = await axios.get(
        API_URLS.teamsForWorkspace.replace(
          ":wsWorkspaceUuid:",
          activeWorkspaceUuid,
        ),
      );
      setState("teamsData", data.content);
    } catch (error) {
      console.error(error);
      showErrorToast();
    }
  };

  const formulaValidator = () => {
    if (formulaTab === "overview" || dataType === tableDataType.Task) {
      if (!selectedFormulaElements.a.value || !selectedFormulaElements.b.value)
        return true;
    } else {
      if (!formulaValue.value || !selectedSummarySources?.length) return true;
    }
    return false;
  };

  const shouldDisableConfirmButton = () => {
    if (activeTab === ActiveTabs.Library) {
      return globalMetaFieldData.length === 0;
    }

    // basic condition
    const validateBasic = !selectedOption.value || !fieldName;
    // description condition
    const validateDescription =
      (globalFieldMode || data?.description) && !description;
    // icon condition
    const validateIcon = addIcon && !selectedIcon.value;
    // metrics condition
    const validateMetrics =
      selectedOption.value === MetaFieldType.Metrics &&
      ((isAudienceReach && !selectedAudienceCountry?.value) ||
        !selectedMetric?.value);
    // currency condition
    const validateCurrency =
      selectedOption.value === MetaFieldType.Currency &&
      !selectedCurrency?.value;
    // access link condition
    const validateAccessLink =
      selectedOption.value === MetaFieldType.AccessLink &&
      accessLinkData.metaFields.length === 0;
    // formula condition
    const validateFormula =
      selectedOption.value === MetaFieldType.Formula && formulaValidator();
    //  global metafield edit condition
    const validateGlobalMetaFieldEdit = Boolean(
      (data?.wsGlobalProjectMetaFieldUuid || data?.wsGlobalTaskMetaFieldUuid) &&
        !EDITABLE_GLOBAL_FIELD_TYPES.includes(selectedOption.value) &&
        !globalFieldMode,
    );
    // sales metrics condition
    const validateSalesMetrics =
      selectedOption.value === MetaFieldType.SalesMetrics &&
      !selectedMetric?.value;
    // member condition
    const validateMember =
      selectedOption.value === MetaFieldType.Member &&
      !(
        (filterMemberByTeam && (!wsTeamUuids || wsTeamUuids?.length > 0)) ||
        !filterMemberByTeam
      );
    // emv condition
    const validateEmv =
      selectedOption.value === MetaFieldType.EMV &&
      (Object.values(emvValues).every((value) => {
        if (value === undefined) return true;
        return value <= 0;
      }) ||
        !selectedCurrency.value);

    const conditions = [
      validateBasic,
      validateDescription,
      validateIcon,
      validateMetrics,
      validateCurrency,
      validateAccessLink,
      validateFormula,
      validateGlobalMetaFieldEdit,
      validateSalesMetrics,
      validateMember,
      validateEmv,
    ];

    return conditions.some((con: boolean) => con);
  };

  useEffect(() => {
    if (globalFieldMode) {
      setState("selectedOption", {});
    }
  }, [globalFieldMode]);

  useEffect(() => {
    if (editingGlobalField && data?.taskType) {
      setState("selectedTaskType", { value: data?.taskType });
    }
  }, [editingGlobalField, data?.taskType]);

  useEffect(() => {
    if (
      taskType !== TaskType.Publication &&
      selectedOption.value === MetaFieldType.EMV
    ) {
      setState("selectedOption", {});
    }
  }, [taskType, selectedOption.value]);

  useEffect(() => {
    if (selectedTaskType.value !== TaskType.Creator) {
      setState("showInCreatorDatabase", false);
    }

    if (selectedOption?.value) {
      const itemField: MetaFieldTypeOption | undefined =
        metaFieldTypeOptions?.find(
          (item: MetaFieldTypeOption) => selectedOption.value === item.value,
        );

      if (
        !itemField ||
        (itemField && selectedOption.value === MetaFieldType.Metrics)
      ) {
        setState("selectedOption", {});
      }
    }
  }, [selectedTaskType.value]);

  useEffect(() => {
    if (shouldReloadGlobalFields && isGlobalMode) {
      dispatch(getSettingsGlobalFields(activeWorkspaceUuid));
      dispatch(setReloadTaskFields(false));
      onClose();
    }
  }, [shouldReloadGlobalFields, isGlobalMode, activeWorkspaceUuid]);

  useEffect(() => {
    if (!isGlobalMetaFieldsListLoading && activeTab === ActiveTabs.Library) {
      const allGlobalMetaFields = filterGlobalMetaFields(
        globalMetaFieldsList,
        dataType,
      );
      const activeGlobalMetaFields = allGlobalMetaFields.filter(
        (gf) => gf.existsInProject === true,
      );

      if (
        !allGlobalMetaFields.length ||
        allGlobalMetaFields.length === activeGlobalMetaFields.length
      )
        setActiveTab(ActiveTabs.Custom);
    }
  }, [isGlobalMetaFieldsListLoading]);

  useEffect(() => {
    if (!conditionValues.length) {
      setState("conditionValues", [
        {
          column: {
            label: "",
            value: null,
          },
          value: {
            label: "",
            value: null,
          },
        },
      ]);
    }
  }, [conditionValues]);

  useEffect(() => {
    if (selectedOption.value === undefined) return;

    if (selectedOption.value !== "formula") {
      setState("formulaValue", {});
      setState("selectedSummarySources", []);
      setState("selectedFormulaElements", { a: {}, b: {} });
      setState("formulaTab", "table");
      setState("selectedFormulaOperator", {});
      setState("selectedResultFormat", {});
    } else {
      setState("formulaValue", {
        label: (
          <IDHFormattedMessage id="ws_sum_values" defaultMessage="Sum values" />
        ),
        value: "SUM",
      });
    }

    // clear icons
    if (
      selectedOption.value !== "formula" &&
      selectedOption.value !== "currency"
    ) {
      setState("addIcon", false);
      setState("selectedColor", "Instagram");
      setState("selectedIcon", {});
    }

    if (metricsData && selectedOption.value === "metrics") return;

    if (selectedOption.value !== "relatedField") {
      setState("relatedMetaField", {});
      setState("relatedMetaFieldAggregationType", {});
    }

    switch (selectedOption.value) {
      case MetaFieldType.SalesMetrics:
        getSalesMetrics();
        break;
      case MetaFieldType.Formula:
        getSummaryMetrics();
        break;
      case MetaFieldType.Currency:
      case MetaFieldType.EMV:
        getCurrencies();
        break;
      case MetaFieldType.Member:
        getTeams();
        break;
    }
  }, [selectedOption.value, selectedTaskType.value, formulaTab]);

  useEffect(() => {
    if (data) return;

    if (formulaTab === "table") {
      setState("selectedFormulaElements", { a: {}, b: {} });
      setState("selectedFormulaOperator", {});
      setState("selectedResultFormat", {});
    } else {
      setState("formulaValue", {});
      setState("valueSourceOptions", []);
      setState("selectedSummarySources", []);
      setState("targetValue", "");
      setState("availableTargetsValue", {});
      setState("availableTargets", []);
      setState("conditionColumns", []);
      setState("conditionValues", [
        {
          uuid: uuid(),
          column: {
            label: "",
            value: null,
          },
          value: {
            label: "",
            value: null,
          },
        },
      ]);
      setState("targetValueOption", "custom");
    }
  }, [formulaTab, data]);

  useEffect(() => {
    if (globalFieldMode) return;

    if (data) {
      setActiveTab(ActiveTabs.Custom);
      return;
    }
    if (!defaultOption && dataType !== tableDataType.Project) {
      setActiveTab(ActiveTabs.Library);
      switch (dataType) {
        case tableDataType.RecruitmentForm:
        case tableDataType.Task:
          dispatch(
            getGlobalTaskMetaFieldList(
              activeWorkspaceUuid,
              projectId,
              dataType === tableDataType.RecruitmentForm
                ? TaskType.Creator
                : taskType,
            ),
          );
          break;
        case tableDataType.ProjectDetails:
          dispatch(
            getGlobalProjectMetaFieldList(activeWorkspaceUuid, projectId, null),
          );
          break;
        default:
          break;
      }
    }
  }, [data, dataType, defaultOption, activeWorkspaceUuid, taskType, projectId]);

  useEffect(() => {
    if (data && !data.metaFieldSource) return;
    setState("selectedMetric", {});
  }, [selectedPlatform?.value]);

  useEffect(() => {
    if (!nameEdited) {
      setState("fieldName", "");
    }
  }, [nameEdited]);

  useEffect(() => {
    if (data && !data.metaFieldSource) return;

    if (selectedOption.value === "accessLink" && !nameEdited) {
      setState("fieldName", accessLinkData?.caption ?? defaultFieldName);
      return;
    }

    const type = selectedOption.value?.split("_")[0];
    if (selectedOption?.value?.includes("_") && !nameEdited) {
      setState("fieldName", capitalizeFirstLetter(type));
      return;
    }

    if (
      selectedOption.value === "relatedField" &&
      relatedMetaField.label &&
      !nameEdited
    ) {
      setState("fieldName", relatedMetaField.value.name);
      return;
    }

    if (nameEdited || !selectedMetric?.label) return;

    if (selectedOption.value === "metrics" && selectedPlatform?.value) {
      const fieldNameValue = `${capitalizeFirstLetter(
        translateMessage({
          intl,
          id: selectedPlatform.value?.toLowerCase(),
          defaultMessage: selectedPlatform.value,
        }),
      )} ${selectedMetric.label}${
        isAudienceReach && selectedAudienceCountry?.value
          ? ` ${selectedAudienceCountry?.value}`
          : ``
      }`;

      setState("fieldName", fieldNameValue);
    } else if (selectedOption.value === "salesMetrics") {
      setState("fieldName", selectedMetric.label);
    }
  }, [
    selectedMetric,
    selectedAudienceCountry,
    selectedOption?.value,
    accessLinkData?.caption,
    defaultFieldName,
    relatedMetaField,
    nameEdited,
  ]);

  useEffect(() => {
    if (
      data &&
      !data?.data?.arithmeticFieldOptions &&
      !data?.metaFieldOptions?.arithmeticFieldOptions
    )
      return;

    if (
      selectedFormulaElements.a.label &&
      selectedFormulaElements.b.label &&
      !nameEdited
    ) {
      setState(
        "fieldName",
        `${selectedFormulaElements.a.label} ${selectedFormulaOperator.value} ${selectedFormulaElements.b.label}`,
      );
    }
  }, [selectedFormulaElements, selectedFormulaOperator]);

  useEffect(() => {
    if (fieldName !== data?.name) return;

    if (fieldName === "") {
      setState("nameEdited", false);
    }
  }, [fieldName, data?.name]);

  useEffect(() => {
    if (!data || selectedOption.value !== MetaFieldType.Formula) return;

    const generateFormulaElementOptions = async () => {
      let options: SelectedFormulaElement[] = [];

      if (
        dataType === tableDataType.Project ||
        dataType === tableDataType.ProjectDetails
      ) {
        if (editingGlobalField) {
          options =
            (await generateGlobalProjectOptions(
              selectedTaskType.value,
              activeWorkspaceUuid,
            )) ?? [];
        } else {
          options = generateProjectFormulaElementOptions({
            projectMetaFields,
          });
        }
      }

      if (dataType === tableDataType.Task) {
        if (editingGlobalField) {
          options =
            (await generateGlobalTaskOptions(
              selectedTaskType.value,
              activeWorkspaceUuid,
            )) ?? [];
        } else {
          const taskFormulaElementOptions = generateTaskFormulaElementOptions({
            tasksColumns,
          });
          const projectFormulaElementOptions =
            generateProjectFormulaElementOptions({
              projectMetaFields,
            });
          options = [
            ...taskFormulaElementOptions,
            ...projectFormulaElementOptions,
          ];
        }
      }

      setState("formulaElementOptions", options);
    };
    generateFormulaElementOptions();
  }, [
    data,
    dataType,
    editingGlobalField,
    selectedTaskType.value,
    projectMetaFields,
    activeWorkspaceUuid,
    tasksColumns,
    selectedOption.value,
  ]);

  useEffect(() => {
    if (!data || dataApplied || loading) return;

    const basicProjectFunctionProps = {
      data,
      metaFieldTypeOptions,
      setBasicTypes,
      setState,
    };

    const projectFunctionProps = {
      ...basicProjectFunctionProps,
      availableTargets,
      conditionColumns,
      valueSourceOptions,
      formulaElementOptions,
    };

    const taskFunctionProps = {
      ...basicProjectFunctionProps,
      salesMetricOptions,
      formulaElementOptions,
    };

    switch (dataType) {
      case tableDataType.Project:
      case tableDataType.ProjectDetails:
        setProjectFieldData(projectFunctionProps);
        break;

      case tableDataType.Task:
        setTaskFieldData(taskFunctionProps);
        break;
      default:
        throw new Error("Unsupported dataType");
    }
  }, [
    data?.data,
    data?.metaFieldOptions,
    metaFieldTypeOptions,
    availableTargets,
    conditionColumns,
    valueSourceOptions,
    formulaElementOptions,
    salesMetricOptions,
  ]);

  useEffect(() => {
    if (taskFieldAddedOrUpdated) {
      handleTaskSuccess();
    }
    if (projectFieldAddedOrUpdated) {
      handleProjectSuccess();
    }
  }, [taskFieldAddedOrUpdated, projectFieldAddedOrUpdated]);

  useEffect(() => {
    if (
      (projectErrorMessages && projectErrorMessages.length > 1) ||
      (taskErrorMessages && taskErrorMessages.length > 0)
    ) {
      setLoading(false);
    }
  }, [projectErrorMessages, taskErrorMessages]);

  useEffect(() => {
    return () => {
      dispatch(clearProjectErrorMessages());
      dispatch(clearTaskErrorMessages());
    };
  }, []);

  const renderContent = () => {
    return (
      <>
        <ModalTitle>
          {data ? (
            <IDHFormattedMessage
              id="ws_field_setup"
              defaultMessage="Field setup"
            />
          ) : (
            title
          )}
        </ModalTitle>

        {/* Default tabs */}
        {shouldDisplayDefaultTabs && (
          <Tabs>
            <Tab
              tabText={
                <IDHFormattedMessage
                  id="ws_from_library"
                  defaultMessage="From library"
                />
              }
              active={activeTab === ActiveTabs.Library}
              onClick={() => setActiveTab(ActiveTabs.Library)}
            />
            <Tab
              tabText={
                <IDHFormattedMessage id="ws_custom" defaultMessage="Custom" />
              }
              active={activeTab === ActiveTabs.Custom}
              onClick={() => setActiveTab(ActiveTabs.Custom)}
            />
          </Tabs>
        )}

        {activeTab === ActiveTabs.Library && (
          <LibraryFields updateState={updateState} />
        )}

        {activeTab === ActiveTabs.Custom && (
          <CustomFields
            inputLabelText={inputLabelText}
            dataType={dataType}
            handleConfirm={handleConfirm}
            fieldName={fieldName}
            data={data}
            setState={setState}
            state={state}
            metaFieldTypeOptions={metaFieldTypeOptions}
            hidden={hidden}
            onClose={onClose}
            setAccessLinkData={setAccessLinkData}
            defaultOption={defaultOption}
            accessLinkData={accessLinkData}
            projectId={projectId}
            taskType={taskType}
            isNewOverviewForClient={isNewOverviewForClient}
            globalFieldMode={globalFieldMode}
            editingGlobalField={editingGlobalField}
          />
        )}
      </>
    );
  };

  return (
    <Modal
      isLoading={isLoading}
      onClose={onClose}
      onClick={(e: Event) => e.stopPropagation()}
      className={classNames(
        "add-field-modal",
        hidden && "add-field-modal--hidden",
      )}
      overlayClassName="add-field-modal__overlay"
      displayCancelButton
      onCancelClick={onClose}
      closeButtonText={
        <IDHFormattedMessage id="ws_cancel" defaultMessage="Cancel" />
      }
      onConfirmClick={handleConfirm}
      closeButtonVariant="white-with-border"
      confirmButtonDisabled={shouldDisableConfirmButton()}
      confirmButtonText={
        data ? (
          <IDHFormattedMessage id="ws_save" defaultMessage="Save" />
        ) : (
          <IDHFormattedMessage id="ws_add" defaultMessage="Add" />
        )
      }
    >
      {renderContent()}
    </Modal>
  );
}

export default ManageFieldModal;
