import { camelCaseToSeparatedWords } from "src/utils/methods";
import { setPublicationSummaryStats, setSummaryStatistics } from "src/redux";
import { addHeaderToArray } from "../addHeaderToArray";

export const addTableSummaryToGridElements = (
  dispatch,
  newGridElements,
  newHiddenGridElements,
  savedTemplate,
  templateApplied,
  publicationsSummary,
  formatMessage,
) => {
  if (!publicationsSummary) return;

  Object.entries(publicationsSummary).forEach((item, index) => {
    const [provider, data] = item;

    const pubCount = data.publications.length;
    const spaceForHeader = index === 0 ? 1 : 0;

    dispatch(
      setSummaryStatistics(
        provider,
        data?.summary?.map((item) => item.name) || [],
      ),
    );
    dispatch(
      setPublicationSummaryStats(
        provider,
        data?.summary
          ?.filter((item) => item.value)
          ?.map((item) => item.name)
          ?.slice(0, 9) || [],
      ),
    );

    addHeaderToArray(
      formatMessage({
        id: `ws_table_summary_${provider}`,
        defaultMessage: camelCaseToSeparatedWords(provider),
      }),
      newGridElements,
      "subheader",
      `table-summary-${provider}`,
      savedTemplate,
      templateApplied,
    );

    const tableParts = Math.ceil((pubCount + 1 + spaceForHeader) / 15);

    for (let i = 1; i <= tableParts; i++) {
      const label = `table-summary-${provider}-${i}`;

      const element = {
        label,
        gridBoxType: "table-summary",
        section: `table-summary-${provider}`,
        data: {
          provider,
          partNumber: i,
          tableParts,
          spaceForHeader: index === 0 ? 1 : 0,
          height: getTableHeight(i, pubCount, tableParts, spaceForHeader),
        },
      };

      if (savedTemplate?.length && !templateApplied) {
        if (savedTemplate.find((item) => item.i === element.label)) {
          newGridElements.push(element);
        } else {
          newHiddenGridElements.push(element);
        }
      }
      // applying default template
      else {
        newGridElements.push(element);
      }
    }
  });
};

const getTableHeight = (i, pubCount, tableParts, spaceForHeader) => {
  if (i === 1) {
    if (tableParts === 1) {
      return pubCount + 2;
    }
    return 15 - spaceForHeader;
  }

  if (i === tableParts) {
    return pubCount + 1 + tableParts + spaceForHeader - (tableParts - 1) * 15;
  }

  return 16;
};
