import React, { useState } from "react";
import Modal, { ModalColumn } from "src/app/components/Modal/Modal";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { injectIntl } from "react-intl";
import CustomSelect from "../../../../../../components/CustomSelect/CustomSelect";
import { CustomInputLabel } from "../../../../../../components/CustomInput/CustomInput";

function CreateEmptyAudienceModal(props) {
  const {
    onClose,
    onConfirmClick,
    intl,
    confirmButtonLoading,
    getSocialProviders,
  } = props;

  const [listName, setListName] = useState("");
  const [influencerSocialProvider, setInfluencerSocialProvider] = useState({
    value: "instagram",
    label: intl.formatMessage({
      id: "front_instagram",
      defaultMessage: "Instagram",
    }),
    default: true,
  });

  const onChangeHandler = (e) => {
    const text = e.target.value;
    setListName(text);
  };

  return (
    <Modal
      onClose={onClose}
      title={
        <IDHFormattedMessage
          id="front_modal_create_list_title"
          defaultMessage="Create list"
        />
      }
      closeButtonText={
        <IDHFormattedMessage id="front_bd_cancel" defaultMessage="Cancel" />
      }
      displayCancelButton
      confirmButtonLoading={confirmButtonLoading}
      onConfirmClick={() => onConfirmClick(listName, influencerSocialProvider)}
      confirmButtonDisabled={!listName.length}
    >
      <ModalColumn>
        <div className="modal__mb-20">
          <CustomInputLabel>
            <IDHFormattedMessage id="ws_list_name" defaultMessage="List name" />
          </CustomInputLabel>
          <input
            className="idh-form__input"
            placeholder={intl.formatMessage({
              id: "front_type_list_name",
              defaultMessage: "Type list name",
            })}
            data-cdt-input="create-influencer-list"
            value={listName}
            disabled={confirmButtonLoading}
            onChange={onChangeHandler}
            onKeyPress={(e) =>
              e.key === "Enter" &&
              listName.length > 0 &&
              onConfirmClick(listName)
            }
          />
        </div>
      </ModalColumn>
      <ModalColumn>
        <CustomInputLabel>
          <IDHFormattedMessage
            id="ws_social_platform"
            defaultMessage="Social platform"
          />
        </CustomInputLabel>
        <CustomSelect
          placeholder={intl.formatMessage({
            id: "front_select_social_platform",
            defaultMessage: "Select social platform",
          })}
          value={influencerSocialProvider}
          options={getSocialProviders()}
          onChange={(newValue) => setInfluencerSocialProvider(newValue)}
          dataAttr="social-platform"
          disabled={confirmButtonLoading}
        />
      </ModalColumn>
    </Modal>
  );
}

export default injectIntl(CreateEmptyAudienceModal);
