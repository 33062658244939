import { uuidv7 as uuid } from "uuidv7";

import { isStringUuid } from "src/utils/methods";
import { generateRankString } from "src/utils/rankStrings";
import { tableDataType } from "src/app/components/Table/Table";
import { Dispatch } from "redux";
import {
  createTaskMetaField,
  createGlobalTaskMetaField,
  createNewTaskGlobalField,
  updateGlobalTaskField,
  updateTaskMetaField,
} from "src/redux";
import { TaskType } from "src/types";
import { TasksColumn } from "src/redux/task/taskReducer";
import { MetaFieldType } from "src/app/methods/getMetaFieldTypeOptions";
import { IIdentity } from "../../../Navbar/Navbar";
import {
  IAccessLinkData,
  MFState,
  MFStateEntries,
  MetaFieldTypeOption,
  SelectedFormulaElement,
} from "../types";
import {
  METRIC_TYPES,
  mathOperatorOptions,
  resultFormatOptions,
} from "../utils";

interface Props {
  data: any;
  metaFieldTypeOptions: MetaFieldTypeOption[];
  salesMetricOptions: any;
  setBasicTypes: any;
  setState: (type: MFStateEntries, value: any) => void;
  formulaElementOptions: SelectedFormulaElement[];
}

export const setTaskFieldData = async (functionProps: Props) => {
  const {
    data,
    metaFieldTypeOptions,
    salesMetricOptions,
    setBasicTypes,
    setState,
    formulaElementOptions,
  } = functionProps;

  const source = data.metaFieldSource;
  const initialData = data.metaFieldOptions;

  const sourceType = source?.split(".")[0];
  const isSalesMetrics = sourceType === "wsCouponRedemptions";
  const isSocialMetrics =
    sourceType === "socialProfile" || sourceType === "publication";
  const isRelatedField = sourceType === "relatedMetaField";

  let fieldType = data.type;
  if (initialData.arithmeticFieldOptions) {
    fieldType = MetaFieldType.Formula;
  } else if (isSalesMetrics) {
    fieldType = MetaFieldType.SalesMetrics;
  } else if (isSocialMetrics) {
    fieldType = MetaFieldType.Metrics;
  } else if (isRelatedField) {
    fieldType = MetaFieldType.RelatedField;
  } else if (source === MetaFieldType.EMV) {
    fieldType = MetaFieldType.EMV;
  }

  if (data.taskType === TaskType.Creator && data.description) {
    setState("showInCreatorDatabase", data.showInCreatorDatabase);
  }

  if (data.type === MetaFieldType.DictionaryElement) {
    setState(
      "selectedOption",
      metaFieldTypeOptions.find((option) =>
        option.value.includes(initialData.wsDictionaryUuid),
      ) || {},
    );
  } else {
    setState(
      "selectedOption",
      metaFieldTypeOptions.find((option) => option.value === fieldType) || {},
    );
  }

  switch (fieldType) {
    case MetaFieldType.Formula:
      const formulaArithmeticsDataProps = {
        formulaArithmetics: initialData.arithmeticFieldOptions,
        formulaElementOptions,
        setState,
      };

      setFormulaArithmeticsData(formulaArithmeticsDataProps);

      break;

    case MetaFieldType.SalesMetrics:
      const selectedSalesMetric = salesMetricOptions?.find(
        (item: any) => item.value === source,
      );

      setState("selectedMetric", selectedSalesMetric);
      break;
    case MetaFieldType.Member:
      setState("filterMemberByTeam", initialData.wsTeamUuids?.length > 0);
      setState("wsTeamUuids", initialData.wsTeamUuids ?? []);
      break;
    case MetaFieldType.DictionaryElement:
      setState(
        "enableDictionarySubField",
        initialData?.wsDictionarySubFieldUuid,
      );
      setState(
        "wsDictionarySubFieldUuid",
        initialData?.wsDictionarySubFieldUuid ?? null,
      );
      break;
    case MetaFieldType.EMV:
      setState("emvValues", initialData.emvValues ?? {});
      setBasicTypes(fieldType, initialData);
      break;

    default:
      setBasicTypes(fieldType, initialData);
  }
};

interface FormulaArithmeticsDataProps {
  formulaArithmetics: any;
  formulaElementOptions: SelectedFormulaElement[];
  setState: (type: MFStateEntries, value: any) => void;
}

const setFormulaArithmeticsData = (props: FormulaArithmeticsDataProps) => {
  const { formulaArithmetics, formulaElementOptions, setState } = props;

  const operator = mathOperatorOptions.find(
    (item) => item.value === formulaArithmetics.operator,
  );

  const resultFormat = resultFormatOptions.find(
    (item) => item.value === formulaArithmetics.resultFormat,
  );

  let elA;
  let elB;

  if (isStringUuid(formulaArithmetics.elements[0])) {
    elA = formulaElementOptions.find(
      (item) => item?.value?.id === formulaArithmetics.elements[0],
    );
  } else {
    elA = {
      value: formulaArithmetics.elements[0],
      label: formulaArithmetics.elements[0],
    };
  }

  if (isStringUuid(formulaArithmetics.elements[1])) {
    elB = formulaElementOptions.find(
      (item) => item?.value?.id === formulaArithmetics.elements[1],
    );
  } else {
    elB = {
      value: formulaArithmetics.elements[1],
      label: formulaArithmetics.elements[1],
    };
  }

  setState("selectedFormulaOperator", operator);
  setState("selectedResultFormat", resultFormat);
  setState("selectedFormulaElements", { a: elA ?? {}, b: elB ?? {} });
};

export const getTaskMetaFieldType = (
  metaFieldType: string | undefined,
  selectedResultFormat: any,
  workspaceDictionaryList: any[],
) => {
  const type = metaFieldType?.split("_")[0];

  if (
    workspaceDictionaryList?.length > 0 &&
    workspaceDictionaryList.find(
      (item) =>
        item?.wsDictionaryElementName?.toLowerCase() === type?.toLowerCase(),
    )
  ) {
    return "dictionaryElement";
  }

  switch (type) {
    case "relatedField":
      return null;

    case "formula":
      return selectedResultFormat.value;

    case "brief":
    case "client":
    case "brand":
    case "agency":
      return "dictionaryElement";
    case MetaFieldType.EMV:
      return MetaFieldType.Currency;
    default:
      return metaFieldType || "text";
  }
};

interface CreateProps {
  taskType: TaskType;
  dataType: tableDataType;
  dispatch: Dispatch<any>;
  state: MFState;
  projectId: string;
  fieldName: string;
  tasksColumns: TasksColumn[];
  metaFieldType: any;
  accessLinkData: IAccessLinkData;
  activeWorkspaceUuid: string;
  identity: IIdentity;
  globalFieldMode?: boolean;
  onSuccessCallback?: (uuids?: string[]) => void;
}

export const createTaskField = async (
  functionProps: CreateProps,
  context?: tableDataType,
) => {
  const {
    tasksColumns,
    state: {
      description,
      selectedFormulaElements,
      selectedResultFormat,
      selectedFormulaOperator,
      selectedOption,
      selectedMetric,
      selectedCurrency,
      globalMetaFieldData,
      wsTeamUuids,
      showInCreatorDatabase,
      enableDictionarySubField,
      wsDictionarySubFieldUuid,
      relatedMetaField,
      relatedMetaFieldAggregationType,
      filterMemberByTeam,
      emvValues,
    },
    dispatch,
    projectId,
    fieldName,
    metaFieldType,
    taskType,
    dataType,
    accessLinkData,
    activeWorkspaceUuid,
    identity,
    globalFieldMode,
    onSuccessCallback,
  } = functionProps;

  const createdFieldsIds: string[] = [];

  const arithmeticFieldOptions = {
    elements: [
      selectedFormulaElements.a.value?.id || selectedFormulaElements.a.value,
      selectedFormulaElements.b.value?.id || selectedFormulaElements.b.value,
    ],
    resultFormat: selectedResultFormat.value,
    operator: selectedFormulaOperator.value,
  };
  const formulaCurrency =
    selectedFormulaElements.a.value?.currencyCode ||
    selectedFormulaElements.b.value?.currencyCode;
  const fieldType = getTaskMetaFieldType(
    metaFieldType,
    selectedResultFormat,
    identity?.workspaceDictionaryList,
  );

  let valueSource = null;
  if (METRIC_TYPES.includes(selectedOption.value)) {
    valueSource = selectedMetric?.value;
  } else if (selectedOption.value === MetaFieldType.AccessLink) {
    valueSource = MetaFieldType.AccessLink;
  } else if (selectedOption.value === MetaFieldType.EMV) {
    valueSource = MetaFieldType.EMV;
  }

  let currencyCode = null;
  if (
    formulaCurrency ||
    selectedOption.value === MetaFieldType.Currency ||
    selectedOption.value === MetaFieldType.EMV
  ) {
    currencyCode = formulaCurrency || (selectedCurrency.value ?? null);
  }

  const id = uuid();
  const rank = generateRankString(
    tasksColumns[tasksColumns.length - 1]?.metaFieldRank || "aaa",
    "",
  );

  if (globalFieldMode) {
    createdFieldsIds.push(id);
    dispatch(
      createNewTaskGlobalField({
        wsWorkspaceUuid: activeWorkspaceUuid,
        metaFieldName: fieldName,
        metaFieldUuid: id,
        metaFieldDescription: description,
        metaFieldType: fieldType,
        metaFieldTaskType: taskType,
        metaFieldValueSource: valueSource ?? null,
        metaFieldCurrency: currencyCode,
        arithmeticFieldOptions:
          selectedOption.value === MetaFieldType.Formula
            ? arithmeticFieldOptions
            : null,
        wsDictionaryUuid:
          fieldType === MetaFieldType.DictionaryElement
            ? metaFieldType.split("_")[1]
            : null,
        wsTeamUuids:
          selectedOption.value === MetaFieldType.Member && filterMemberByTeam
            ? wsTeamUuids
            : null,
        showInCreatorDatabase,
        wsDictionarySubFieldUuid:
          fieldType === MetaFieldType.DictionaryElement &&
          enableDictionarySubField
            ? wsDictionarySubFieldUuid
            : null,
        relatedMetaFieldUuid: relatedMetaField?.value?.id ?? null,
        relatedMetaFieldAggregationType:
          relatedMetaFieldAggregationType?.value ?? null,
        emvValues:
          selectedOption.value === MetaFieldType.EMV ? emvValues : null,
      }),
    );
  } else if (globalMetaFieldData.length > 0) {
    let prevRank = "";
    const globalMetaFieldDataForBackend = globalMetaFieldData.map(
      (field, i) => {
        let rank;

        if (i === 0) {
          const lastElementRank =
            tasksColumns[tasksColumns.length - 1]?.metaFieldRank || "aaa";
          rank = generateRankString(lastElementRank, "");
          prevRank = rank;
        } else {
          rank = generateRankString(prevRank, "");
          prevRank = rank;
        }

        const id = uuid();
        createdFieldsIds.push(id);
        return {
          wsGlobalTaskMetaFieldUuid: field.uuid,
          wsProjectUuid: projectId,
          wsTaskMetaFieldRank: rank,
          wsTaskMetaFieldUuid: id,
          taskType:
            dataType === tableDataType.RecruitmentForm
              ? TaskType.Creator
              : taskType,
          wsTeamUuids:
            selectedOption.value === MetaFieldType.Member && filterMemberByTeam
              ? wsTeamUuids
              : null,
        };
      },
    );

    await dispatch(createGlobalTaskMetaField(globalMetaFieldDataForBackend));
  } else {
    createdFieldsIds.push(id);
    await dispatch(
      createTaskMetaField({
        projectId,
        metaFieldName: fieldName,
        metaFieldType: fieldType,
        uuid: id,
        rank,
        taskType:
          context === tableDataType.RecruitmentForm
            ? TaskType.Creator
            : taskType,
        metaFieldValueSource: valueSource,
        currencyCode,
        iconName: selectedMetric?.icon?.name,
        iconColor: selectedMetric?.icon?.color,
        accessLinkData:
          selectedOption.value === MetaFieldType.AccessLink
            ? accessLinkData
            : null,
        arithmeticFieldOptions:
          selectedOption.value === MetaFieldType.Formula
            ? arithmeticFieldOptions
            : null,
        wsDictionaryUuid:
          fieldType === MetaFieldType.DictionaryElement
            ? metaFieldType.split("_")[1]
            : null,
        wsTeamUuids:
          selectedOption.value === MetaFieldType.Member && filterMemberByTeam
            ? wsTeamUuids
            : null,
        wsDictionarySubFieldUuid:
          fieldType === MetaFieldType.DictionaryElement &&
          enableDictionarySubField
            ? wsDictionarySubFieldUuid
            : null,
        relatedMetaFieldUuid: relatedMetaField?.value?.id,
        relatedMetaFieldAggregationType: relatedMetaFieldAggregationType?.value,
        emvValues:
          selectedOption.value === MetaFieldType.EMV ? emvValues : null,
      }),
    );
  }
  if (dataType === tableDataType.RecruitmentForm && onSuccessCallback) {
    onSuccessCallback(createdFieldsIds);
  }
};

interface UpdateProps {
  data: any;
  dispatch: Dispatch<any>;
  state: MFState;
  accessLinkData: IAccessLinkData;
  activeWorkspaceUuid: string;
  editingGlobalField?: boolean;
  identity: any;
}

export const updateTaskField = async (functionProps: UpdateProps) => {
  const {
    data,
    dispatch,
    state: {
      description,
      fieldName,
      selectedOption,
      selectedCurrency,
      selectedFormulaElements,
      selectedFormulaOperator,
      selectedMetric,
      selectedResultFormat,
      wsTeamUuids,
      showInCreatorDatabase,
      enableDictionarySubField,
      wsDictionarySubFieldUuid,
      relatedMetaField,
      relatedMetaFieldAggregationType,
      filterMemberByTeam,
      emvValues,
    },
    accessLinkData,
    activeWorkspaceUuid,
    editingGlobalField,
    identity,
  } = functionProps;

  const fieldType = getTaskMetaFieldType(
    selectedOption.value,
    selectedResultFormat,
    identity?.workspaceDictionaryList,
  );

  const arithmeticFieldOptions = {
    elements: [
      selectedFormulaElements.a.value?.id || selectedFormulaElements.a.value,
      selectedFormulaElements.b.value?.id || selectedFormulaElements.b.value,
    ],
    resultFormat: selectedResultFormat.value,
    operator: selectedFormulaOperator.value,
  };
  const formulaCurrency =
    selectedFormulaElements.a.value?.currencyCode ||
    selectedFormulaElements.b.value?.currencyCode;

  let currencyCode = null;
  if (
    formulaCurrency ||
    selectedOption.value === MetaFieldType.Currency ||
    selectedOption.value === MetaFieldType.EMV
  ) {
    currencyCode = selectedCurrency?.value;
  }

  if (editingGlobalField) {
    dispatch(
      updateGlobalTaskField({
        wsWorkspaceUuid: activeWorkspaceUuid,
        metaFieldName: fieldName,
        metaFieldUuid: data.id,
        metaFieldDescription: description,
        metaFieldCurrency: currencyCode,
        arithmeticFieldOptions:
          selectedOption.value === "formula" ? arithmeticFieldOptions : null,
        showInCreatorDatabase,
        metaFieldValueSource:
          selectedOption.value === "accessLink"
            ? "accessLink"
            : selectedMetric?.value,
        wsDictionarySubFieldUuid:
          fieldType === "dictionaryElement" && enableDictionarySubField
            ? wsDictionarySubFieldUuid
            : null,
        wsTeamUuids:
          selectedOption.value === "member" && filterMemberByTeam
            ? wsTeamUuids
            : null,
        wsDictionaryUuid:
          fieldType === "dictionaryElement"
            ? selectedOption.value.split("_")[1]
            : null,
        emvValues:
          selectedOption.value === MetaFieldType.EMV ? emvValues : null,
      }),
    );
  } else {
    dispatch(
      updateTaskMetaField({
        metaFieldId: data.id,
        metaFieldNameUpdated: fieldName,
        currencyCode:
          formulaCurrency ||
          (selectedOption.value === "currency"
            ? selectedCurrency?.value
            : null),
        metaFieldValueSource:
          selectedOption.value === "accessLink"
            ? "accessLink"
            : selectedMetric?.value,
        wsMetaFieldsToCreateAccess:
          selectedOption.value === "accessLink" && accessLinkData,
        arithmeticFieldOptions:
          selectedOption.value === "formula" ? arithmeticFieldOptions : null,
        wsTeamUuids:
          selectedOption.value === "member" && filterMemberByTeam
            ? wsTeamUuids
            : null,
        wsDictionarySubFieldUuid:
          fieldType === "dictionaryElement" && enableDictionarySubField
            ? wsDictionarySubFieldUuid
            : null,
        relatedMetaFieldUuid: relatedMetaField.value?.id,
        relatedMetaFieldAggregationType: relatedMetaFieldAggregationType?.value,
        emvValues:
          selectedOption.value === MetaFieldType.EMV ? emvValues : null,
      }),
    );
  }
};
