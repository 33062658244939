import { SocialProvider } from "../../../types";
import { CAMPAIGN_AUDIENCE_DATA } from "../../ReportGenerator/utils/variables";
import { METRIC_SIZES } from "../utils/variables";
import { addSpaceIfNeeded } from "./addSpaceIfNeeded";

export const addElementToLayout = async (
  gridElement,
  layout,
  dispatch,
  format,
  setLayout,
  hiddenGridElements,
  isReport,
  isMulti,
) => {
  const { label: id, gridBoxType: provider } = gridElement;

  let spaceY = 0;

  const filteredLayout = layout.filter((item) => !item.i.includes("separator"));
  const metricType = id.replace(`${provider}-`, "");
  const lastEl = addSpaceIfNeeded(metricType, filteredLayout, format);
  const lastSeparator = Math.max(...layout.map((item) => item.y));
  const spaceOnPage = format.rows;
  const currentRow = lastEl.y + lastEl.h;
  const spaceLeftY = spaceOnPage - (currentRow % spaceOnPage) - 1;

  const spaceX = format.cols - (lastEl.x + lastEl.w);
  if (spaceX !== 0) {
    spaceY = lastEl.h;
  }

  let size = {};

  switch (provider) {
    case "metadata":
      const field = hiddenGridElements.find((item) => item.label === id);

      size =
        field.type === "text"
          ? { i: metricType, w: format.cols, h: 2 }
          : { i: metricType, w: format.cols / 2, h: 1 };
      break;

    case SocialProvider.Instagram:
    case SocialProvider.TikTok:
    case SocialProvider.Youtube:
    case SocialProvider.Snapchat:
    case SocialProvider.Twitter:
    case SocialProvider.Facebook:
      size = METRIC_SIZES.find((item) => item.i === metricType);
      break;

    case "audience-data":
      size = CAMPAIGN_AUDIENCE_DATA.find((item) => item.value === metricType);
      break;

    case "creator-insights":
      size = { w: 4, h: 3 };
      break;

    case "header":
    case "subheader":
      size = { w: format.cols, h: 1 };
      break;

    case "creator":
    case "paragraph":
      size = { w: format.cols, h: 2 };
      break;

    case "glossary-two":
      size = { w: format.cols, h: 3 };
      break;

    case "text":
      size = { w: format.cols / 3, h: 2 };
      break;

    case "post-data":
      size = { w: format.cols / 4, h: 2 };
      break;

    case "publication":
    case "top-publication":
      size = { w: format.cols / 3, h: 7 };
      break;

    case "glossary":
    case "summary-learnings":
      size = { w: 12, h: 15 };
      break;

    case "table-summary":
      size = { w: 12, h: gridElement?.data?.height };
      break;

    default:
      return;
  }

  const i = id;
  const { w, h } = size;
  let x = 0;
  let y = 0;

  // Adding element in the right place

  const scroller = document.querySelector(".template-generator");
  const scrollTop = scroller.scrollTop - 45;
  let lastElementY = Math.floor(scrollTop / (format.rowHeight + 20));
  lastElementY = lastElementY > 0 ? lastElementY : 0;
  // lastElementY -= 1;
  const sortedLayout = layout.sort((a, b) => {
    const endYA = a.y + a.h;
    const endYB = b.y + b.h;

    if (
      a.y === b.y ||
      (a.y >= b.y && endYA <= endYB) ||
      (b.y > a.y && endYB < endYA)
    ) {
      return a.x - b.x;
    }

    return a.y - b.y;
  });

  const upperLayout = sortedLayout.filter(
    (item) => item.y + item.h <= lastElementY,
  );
  const lowerLayout = sortedLayout.filter(
    (item) => item.y + item.h > lastElementY,
  );

  const closestElement = upperLayout[upperLayout.length - 1];
  let layoutToCalculateEmptySpace = [
    ...(closestElement ? [closestElement] : []),
    ...lowerLayout,
  ];

  const publicationsInLayout = layout.filter(
    (item) =>
      item.i.slice(0, 11) === "publication" ||
      item.i.slice(0, 15) === "top-publication" ||
      item.i.slice(0, 25) === "subheader-top-publication",
  );

  if (i.includes("top-publication") && publicationsInLayout.length) {
    const lastPubY = Math.max(...publicationsInLayout.map((item) => item.y));
    const filteredLayout = sortedLayout.filter((item) => item.y >= lastPubY);
    layoutToCalculateEmptySpace = filteredLayout;
  }

  const { x: emptyX, y: emptyY } = getEmptySpacePosition(
    w,
    h,
    format.cols,
    layoutToCalculateEmptySpace,
  );

  x = emptyX;
  y = emptyY;

  if (isNaN(x) || isNaN(y)) {
    if (spaceX >= size.w && spaceY >= size.h) {
      x = lastEl.x + lastEl.w;
      y = lastEl.y;
    } else if (spaceLeftY >= size.h) {
      x = 0;
      y = lastEl.y + lastEl.h;
    } else {
      x = 0;
      y = lastSeparator + 1;
    }
  }

  if (i.includes("subheader-top-publication")) {
    if (publicationsInLayout.length) {
      const lastElementEndY =
        Math.max(...publicationsInLayout.map((item) => item.y)) + 7;
      y = lastElementEndY + (format.rows - (lastElementEndY % format.rows));
    } else {
      y = lastSeparator + 1;
    }
  }

  // end

  const newLayoutElement = { i, x, y, w, h };
  if (isMulti) {
    layout.push(newLayoutElement);
  } else {
    const scrollTarget = newLayoutElement.y * (format.rowHeight + 20) - 120;
    const generator = document.querySelector(".template-generator");
    await generator.scroll({
      top: scrollTarget,
      left: 0,
      behavior: "smooth",
    });

    const newLayout = [...layout, newLayoutElement];

    await dispatch(setLayout(newLayout));
  }
};

const getEmptySpacePosition = (w, h, cols, layout) => {
  let x;
  let y;

  for (let i = 0; i < layout.length - 1; i++) {
    const elA = layout[i];
    const elB = layout[i + 1];
    const endXA = elA.x + elA.w;
    const endYA = elA.y + elA.h;
    const endYB = elB.y + elB.h;

    const equalY = elA.y === elB.y;
    const equalH = elA.h === elB.h;

    // Equal position Y and height
    if (equalY && equalH) {
      if (elB.x - endXA >= w) {
        x = endXA;
        y = elA.y;
        break;
      }
    }

    // Element B in next row
    if (endYA <= elB.y) {
      const enoughX = cols - endXA >= w;
      const enoughY = elB.y - endYA >= h;
      if (enoughX && enoughY) {
        x = endXA;
        y = elA.y;
        break;
      } else if (
        w <= elB.x &&
        h <= elB.h &&
        (elA.x !== elB.x || endYA <= elB.y)
      ) {
        x = 0;
        y = elB.y;
        break;
      } else if (elB.y - endYA >= h) {
        x = 0;
        y = endYA;
        break;
      }
    }
    // Space between rows
    if (endXA === cols && elB.x === 0 && elB.y - endYA > 0) {
      if (elB.y - endYA >= h) {
        x = 0;
        y = endYA;
        break;
      }
    }

    if (elB.x === 0) {
      if (cols - endXA >= w && elA.h >= h) {
        x = endXA;
        y = elA.y;
        break;
      }

      if (elB.y - endYA >= h) {
        x = 0;
        y = endYA;
        break;
      }
    }

    if (endXA <= elB.x && endYB - endYA >= h) {
      x = elA.x;
      y = endYA;
      break;
    }
  }

  return { x, y };
};
