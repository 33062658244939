import React, { useState } from "react";
import {
  DraggingStyle,
  NotDraggingStyle,
  Draggable,
} from "react-beautiful-dnd";

import { ReactComponent as GridIcon } from "src/images/grid.svg";
import { CustomSwitch } from "src/app/components/CustomSwitch/CustomSwitch";
import { ReactComponent as TrashCanIcon } from "src/images/trash-can-gray.svg";
import SingleMetaField from "src/app/Task/components/TaskMetaFields/SingleMetaField";
import { tableDataType } from "src/app/components/Table/Table";
import { GlobalProjectMetaField } from "src/redux/settings/settingsReducer";
import {
  MetaFieldIcon,
  renderTooltipIcon,
} from "src/app/modals/ManageFieldModal/components/GlobalFields/utils";
import { RemoveModal } from "src/app/modals/RemoveModal/RemoveModal";
import { MetaFieldType } from "src/app/methods/getMetaFieldTypeOptions";
import {
  FieldOption,
  HandleRemoveFieldsProps,
  HandleUpdateTemplateValueParams,
  TemplateProjectField,
} from "../types";
import { ALLOWED_FIELD_TYPES } from "../utils";

interface AttributeListItemProps {
  index: number;
  option: FieldOption;
  getElementTop: (
    style: DraggingStyle | NotDraggingStyle | undefined,
  ) => number | "auto";
  globalField: GlobalProjectMetaField;
  templateField: TemplateProjectField;
  handleUpdateTemplateValue: (params: HandleUpdateTemplateValueParams) => void;
  handleRemoveFields: (params: HandleRemoveFieldsProps) => void;
}

export default function AttributeListItem({
  index,
  option,
  getElementTop,
  globalField,
  templateField,
  handleUpdateTemplateValue,
  handleRemoveFields,
}: AttributeListItemProps) {
  const [isDefaultValueChecked, setIsDefaultValueChecked] = useState(
    templateField.defaultValue !== null,
  );
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);

  const fieldType = "project";
  const taskType = "null";

  const globalFieldWithDefaultValue =
    globalField.type === MetaFieldType.Date &&
    typeof templateField.defaultValue === "string"
      ? { ...globalField, value: { date: templateField.defaultValue } }
      : { ...globalField, value: templateField.defaultValue };
  return (
    <>
      <Draggable draggableId={option.value} index={index}>
        {(provided) => (
          <li
            ref={provided.innerRef}
            className="template-manager__list-item grid--attributes"
            {...provided.draggableProps}
            style={{
              ...provided.draggableProps.style,
              top: getElementTop(provided.draggableProps.style),
            }}
            data-qa-field-name={option.label}
          >
            <div className="template-manager__text">
              <div {...provided.dragHandleProps}>
                <GridIcon />
              </div>
              <div className="template-manager__col">
                <div className="template-manager__name">
                  <MetaFieldIcon
                    icon={renderTooltipIcon(
                      globalField.type,
                      globalField.valueSource,
                    )}
                  />
                  <span className="template-manager__name-text">
                    {globalField.name}
                  </span>
                </div>
                <div className="template-manager__metaField-value">
                  {isDefaultValueChecked && (
                    <SingleMetaField
                      metaData={globalFieldWithDefaultValue}
                      dataType={tableDataType.TemplateFields}
                      handleUpdateMetaValue={(newValue) => {
                        handleUpdateTemplateValue({
                          uuid: templateField.uuid,
                          valueFieldName: "defaultValue",
                          updatedValue: newValue,
                          fieldType,
                          taskType,
                        });
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
            <CustomSwitch
              checked={templateField.showInCampaignCreator}
              disabled={!ALLOWED_FIELD_TYPES.includes(globalField.type)}
              onChange={(e) =>
                handleUpdateTemplateValue({
                  uuid: templateField.uuid,
                  valueFieldName: "showInCampaignCreator",
                  updatedValue: e.target.checked,
                  fieldType,
                  taskType,
                })
              }
            />
            <CustomSwitch
              checked={templateField.valueRequired}
              onChange={(e) =>
                handleUpdateTemplateValue({
                  uuid: templateField.uuid,
                  valueFieldName: "valueRequired",
                  updatedValue: e.target.checked,
                  fieldType,
                  taskType,
                })
              }
            />
            <div className="template-manager__list-actions">
              <CustomSwitch
                checked={isDefaultValueChecked}
                disabled={
                  !ALLOWED_FIELD_TYPES.includes(globalField.type) ||
                  globalField.valueSource !== null
                }
                onChange={(e) => {
                  const { checked } = e.target;
                  setIsDefaultValueChecked(checked);
                  if (!checked) {
                    handleUpdateTemplateValue({
                      uuid: templateField.uuid,
                      valueFieldName: "defaultValue",
                      updatedValue: null,
                      fieldType,
                      taskType,
                    });
                  }
                }}
              />
              <button
                aria-label="Remove field"
                type="button"
                className="button-styleReset"
                onClick={() => setIsRemoveModalOpen(true)}
              >
                <TrashCanIcon />
              </button>
            </div>
          </li>
        )}
      </Draggable>
      {isRemoveModalOpen && (
        <RemoveModal
          objectNames={[globalField.name]}
          onClose={() => setIsRemoveModalOpen(false)}
          removeFunction={() =>
            handleRemoveFields({
              fieldsToRemove: [templateField.uuid],
              fieldType,
              taskType: "null",
            })
          }
        />
      )}
    </>
  );
}
