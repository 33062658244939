import React, { useEffect, useState } from "react";
import "./DictionarySubFields.scss";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { WrappedComponentProps, injectIntl } from "react-intl";
import { tableDataType } from "src/app/components/Table/Table";
import { useDispatch } from "react-redux";
import { MFState, MFStateEntries } from "../../types";
import { CustomSwitch } from "../../../../components/CustomSwitch/CustomSwitch";
import CustomSelect from "../../../../components/CustomSelect/CustomSelect";
import { skeletonOptions } from "../../../../dropdowns/DictionaryElementSelector/utils";
import { SelectOption } from "../../SocialMetrics";
import { CustomInputLabel } from "../../../../components/CustomInput/CustomInput";
import { MetaFieldType } from "../../../../methods/getMetaFieldTypeOptions";
import { getDictionaryAutoComplete } from "../../../../../redux";

interface Props extends WrappedComponentProps<"intl"> {
  dataType: tableDataType;
  disabled?: boolean;
  isLoading: boolean;
  getByCode?: string;
  state: MFState;
  dictionaryUuid?: string;
  dictionaryAutoCompletesColumns: any;
  setState: (type: MFStateEntries, value: any) => void;
}

const DICTIONARY_META_FIELDS_WHITELIST = [
  MetaFieldType.Date,
  MetaFieldType.Member,
  MetaFieldType.Text,
  MetaFieldType.Number,
  MetaFieldType.Percent,
  MetaFieldType.Currency,
  MetaFieldType.DictionaryElement,
  MetaFieldType.SingleSelect,
  MetaFieldType.MultiSelect,
  MetaFieldType.AggregatedSelect,
];

function DictionarySubFields(props: Props) {
  const {
    state: { wsDictionarySubFieldUuid, enableDictionarySubField },
    dictionaryUuid,
    dictionaryAutoCompletesColumns,
    isLoading,
    setState,
  } = props;
  const [options, setOptions] = useState<SelectOption[]>([]);
  const [selectedOption, setSelectedOption] = useState<Partial<SelectOption>>(
    {},
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      !dictionaryAutoCompletesColumns[`dictionaryType_${dictionaryUuid}`] &&
      dictionaryUuid
    ) {
      dispatch(getDictionaryAutoComplete(dictionaryUuid));
    }

    if (dictionaryAutoCompletesColumns[`dictionaryType_${dictionaryUuid}`]) {
      const newOptions =
        dictionaryAutoCompletesColumns[`dictionaryType_${dictionaryUuid}`]
          .filter((item: any) =>
            DICTIONARY_META_FIELDS_WHITELIST.includes(item.type),
          )
          .map((item: any) => ({
            value: item.uuid,
            label: (
              <span className="image-select-option dictionary-element-option">
                {item.name}
              </span>
            ),
            name: item.name,
          })) || [];
      setOptions(newOptions);

      if (wsDictionarySubFieldUuid) {
        setSelectedOption(
          newOptions.find(
            (item: any) => item.value === wsDictionarySubFieldUuid,
          ) ?? {},
        );
      }
    }
  }, [dictionaryAutoCompletesColumns, dictionaryUuid]);

  return (
    <div className="dictionary-sub-field-wrapper">
      <div className="dictionary-sub-field-wrapper__column">
        <div className="dictionary-sub-field-wrapper__row">
          <div
            className="dictionary-sub-field-wrapper__switch"
            data-qa="dictionary-supporting-field-switch"
          >
            <CustomSwitch
              checked={enableDictionarySubField}
              onChange={(e) => {
                setState &&
                  setState(
                    "enableDictionarySubField",
                    !enableDictionarySubField,
                  );
              }}
            />
          </div>
          <div className="dictionary-sub-field-wrapper__switch-info">
            <div className="dictionary-sub-field-wrapper__switch-info-header">
              <IDHFormattedMessage
                id="ws_supporting_field_selection"
                defaultMessage="Supporting field"
              />
            </div>
            <div className="dictionary-sub-field-wrapper__switch-info-description">
              <IDHFormattedMessage
                id="ws_supporting_field_selection_description"
                defaultMessage="Allows you to display additional information from other fields related to each other."
              />
            </div>
          </div>
        </div>
        {enableDictionarySubField && (
          <div className="dictionary-sub-field-wrapper__row">
            <div className="dictionary-sub-field-wrapper__sub-fields">
              <CustomInputLabel htmlFor="wsDictionarySubFieldUuid">
                <IDHFormattedMessage
                  id="ws_select_field"
                  defaultMessage="Select field"
                />
              </CustomInputLabel>
              <CustomSelect
                id="wsDictionarySubFieldUuid"
                options={isLoading ? skeletonOptions : options}
                value={selectedOption}
                onChange={(newValue: any) => {
                  setSelectedOption(newValue);

                  setState &&
                    setState("wsDictionarySubFieldUuid", newValue.value);
                }}
                isSearchable
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default injectIntl(DictionarySubFields);
