import React from "react";
import classNames from "classnames";

import { TaskMetaFieldName } from "src/app/Task/components/TaskMetaFields/TaskMetaFieldName";
import SingleMetaField from "src/app/Task/components/TaskMetaFields/SingleMetaField";
import { tableDataType } from "src/app/components/Table/Table";
import { Metadata } from "src/app/Task/Subtask/Subtask";
import { MetaFieldType } from "src/app/methods/getMetaFieldTypeOptions";

interface SingleWizardFieldProps {
  field: Metadata;
}

export default function SingleWizardField({ field }: SingleWizardFieldProps) {
  const fieldWithDefaultValue =
    field.type === MetaFieldType.Date && typeof field.defaultValue === "string"
      ? { ...field, value: { date: field.defaultValue } }
      : { ...field, value: field.defaultValue };

  return (
    <div
      className={classNames(
        "task-meta-fields__row",
        field.type === MetaFieldType.MultiSelect &&
          "task-meta-fields__row--auto",
      )}
      data-column-name={field.name}
    >
      <TaskMetaFieldName field={field} required={field.valueRequired} />
      <SingleMetaField
        metaData={fieldWithDefaultValue}
        dataType={tableDataType.GlobalMetaFieldProjectWizardFields}
      />
    </div>
  );
}
