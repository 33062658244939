import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { MaterialTooltip } from "src/app/components/MaterialTooltip/MaterialTooltip";
import METHODS from "../../helpers/methods";
import AudienceFilterLabel from "../AudienceFilterLabel/AudienceFilterLabel";
import {
  changeSearchAudienceFilters,
  getInfluencersListData,
} from "../audiencesManagerActions";

export const CONTENT_KEYWORD_BLACKLIST = [
  "chosenKeywords",
  "searchHashtags",
  "searchMentions",
  "searchBioKeywords",
];

function SearchLabels(props) {
  const {
    intl,
    afterRemoveCallback,
    searchFilters,
    closeFilters,
    getInfluencersListData,
    changeSearchAudienceFilters,
    match: {
      params: { listId },
    },
  } = props;

  const tagNames = [
    {
      name: "audienceAge",
      id: "front_audience_age",
      defaultMessage: "Audience age",
      subfields: ["audienceAgeFrom", "audienceAgeTo", "audienceAgeDensity"],
      dash: true,
    },
    {
      name: "selectedAudienceCountries",
      id: "front_audience_country",
      defaultMessage: "Audience country",
      field: "label",
      secondField: "percent",
    },
    {
      name: "audienceGender",
      id: "front_audience_gender",
      defaultMessage: "Audience gender",
      subfields: ["audienceGender", "audienceGenderDensity"],
    },
    {
      name: "chosenBrands",
      id: "front_audience_brand_affinity",
      defaultMessage: "Audience brand affinity",
    },
    {
      name: "chosenTopics",
      id: "front_audience_interests",
      defaultMessage: "Audience interests",
    },
    {
      name: "chosenInfluencerIgCategories",
      id: "front_influencer_ig_categories",
      defaultMessage: "Influencer categories",
    },
    {
      name: "chosenInfluencerInterests",
      id: "front_influencer_interests",
      defaultMessage: "Influencer interests",
    },
    {
      name: "influencerAge",
      id: "front_influencer_age",
      defaultMessage: "Influencer age",
      subfields: ["influencerAgeFrom", "influencerAgeTo"],
      dash: true,
    },
    {
      name: "selectedInfluencerCountries",
      id: "front_influencer_country",
      defaultMessage: "Influencer country",
      field: "label",
    },
    {
      name: "selectedInfluencerCities",
      id: "front_influencer_city",
      defaultMessage: "Influencer city",
      field: "label",
    },
    {
      name: "influencerFollowersCount",
      id: "front_followers_count",
      defaultMessage: "Followers count",
      subfields: ["influencerFollowersFrom", "influencerFollowersTo"],
      dash: true,
    },
    {
      name: "influencerSocialProvider",
      id: "front_influencer_social_platform",
      defaultMessage: "Social platform",
      field: "label",
    },
    {
      name: "influencerSocialUsername",
      id: "front_influencer_social_username",
      defaultMessage: "Username",
      field: "label",
    },
    {
      name: "influencerGender",
      id: "front_influencer_gender",
      defaultMessage: "Influencer gender",
      field: "label",
    },
    {
      name: "audienceCredibilityScore",
      id: "front_profile_data_credibility",
      defaultMessage: "Credibility",
      field: "label",
    },
    {
      name: "paidPostPerformanceMin",
      id: "front_profile_data_paid_post_performance",
      defaultMessage: "Paid post performance",
      field: "label",
    },
    {
      name: "minimumEr",
      id: "front_minimum_er",
      defaultMessage: "Minimum ER",
      field: "label",
    },
    {
      name: "chosenKeywords",
      id: "front_keyword",
      defaultMessage: "Keyword",
    },
    {
      name: "lastPostsWithin",
      id: "front_last_posts_within",
      defaultMessage: "Last posts within",
      field: "label",
    },
    {
      name: "searchContents",
      id: "front_content_keyword",
      defaultMessage: "Content keyword",
    },
    {
      name: "searchHashtags",
      id: "front_hashtag",
      defaultMessage: "Hashtag",
      option: "hashtagsOption",
    },
    {
      name: "searchMentions",
      id: "front_mention",
      defaultMessage: "Mention",
      option: "mentionsOption",
    },
    {
      name: "searchBioKeywords",
      id: "front_search_in_bio",
      defaultMessage: "Search in Bio",
    },
    {
      name: "hasContactData",
      id: "front_show_only_results_with_contact_data",
      defaultMessage: "Show only results with contact data",
      field: "label",
    },
    {
      name: "excludeCreatorsFromList",
      id: "front_exclude_creators_already_added_to_the_list",
      defaultMessage: "Exclude creators already added to the list",
      field: "label",
    },
  ];

  const genders = [
    {
      value: "male",
      label: intl.formatMessage({
        id: "front_male",
        defaultMessage: "Male",
      }),
    },
    {
      value: "female",
      label: intl.formatMessage({
        id: "front_female",
        defaultMessage: "Female",
      }),
    },
  ];

  const socialProviders = [
    {
      value: "instagram",
      label: intl.formatMessage({
        id: "front_instagram",
        defaultMessage: "Instagram",
      }),
    },
    {
      value: "tik_tok",
      label: intl.formatMessage({
        id: "front_tiktok",
        defaultMessage: "Tiktok",
      }),
    },
    {
      value: "douyin",
      label: intl.formatMessage({
        id: "front_douyin",
        defaultMessage: "Douyin",
      }),
    },
    {
      value: "youtube",
      label: intl.formatMessage({
        id: "front_youtube",
        defaultMessage: "Youtube",
      }),
    },
    {
      value: "snapchat",
      label: intl.formatMessage({
        id: "front_snapchat",
        defaultMessage: "Snapchat",
      }),
    },
    {
      value: "facebook",
      label: intl.formatMessage({
        id: "front_facebook",
        defaultMessage: "Facebook",
      }),
    },
  ];

  const removeFilter = async (type, tagProps) => {
    closeFilters();
    const newFilters = { ...searchFilters };
    const { name, subfields, option, idx } = tagProps;

    if (name === "searchContents") {
      CONTENT_KEYWORD_BLACKLIST.map((name) => {
        const array = newFilters[name]?.filter(
          (i) => i !== searchFilters[name][idx],
        );
        if (array.length === 0) {
          newFilters[option] = null;
        }
        newFilters[name] = array;
      });
    }

    if (type === "array") {
      const array = newFilters[name]?.filter(
        (i) => i !== searchFilters[name][idx],
      );
      if (array.length === 0) {
        newFilters[option] = null;
      }
      newFilters[name] = array;
    } else if (type === "many-fields") {
      newFilters[subfields[0]] = null;
      newFilters[subfields[1]] = null;

      if (newFilters[subfields[2]]) {
        newFilters[subfields[2]] = null;
      }
    } else {
      newFilters[name] = null;
    }

    await changeSearchAudienceFilters(newFilters);

    afterRemoveCallback(tagProps.name);
  };

  const generateLabels = () => {
    let socialProviderIndex;

    const filteredTagNames = tagNames.filter(
      (tag) =>
        !(
          searchFilters.searchContents?.length &&
          CONTENT_KEYWORD_BLACKLIST.includes(tag.name)
        ),
    );

    const labels = filteredTagNames.map((tag, index) => {
      const {
        name,
        id,
        defaultMessage,
        subfields,
        field,
        secondField: arraySecondField,
        dash,
      } = tag;

      const getFullInterestObj = (subTag) => {
        return props.interests.find((interest) => interest.value == subTag);
      };
      return Array.isArray(searchFilters[name]) ? (
        searchFilters[name].map((subTag, idx) => (
          <AudienceFilterLabel
            key={subTag[field] ? subTag[field] : subTag}
            id={id}
            defaultMessage={defaultMessage}
            removeFilter={() => {
              removeFilter("array", {
                ...tag,
                idx,
              });
            }}
          >
            {subTag[field] ? (
              subTag[field]
            ) : name === "chosenTopics" ? (
              <IDHFormattedMessage
                id={getFullInterestObj(subTag)?.name || subTag}
                defaultMessage={getFullInterestObj(subTag)?.value || subTag}
              />
            ) : (
              subTag
            )}
            {subTag[arraySecondField] && (
              <>
                &nbsp;&nbsp;
                {subTag[arraySecondField]}
                &nbsp;%
              </>
            )}
          </AudienceFilterLabel>
        ))
      ) : tag.subfields &&
        (searchFilters[subfields[0]] || searchFilters[subfields[1]]) ? (
        <AudienceFilterLabel
          key={`${id}_${index}`}
          id={id}
          defaultMessage={defaultMessage}
          removeFilter={() =>
            removeFilter("many-fields", {
              ...tag,
            })
          }
        >
          {name == "audienceGender"
            ? genders.filter(
                (item) => item.value == searchFilters[subfields[0]].value,
              )[0].label
            : searchFilters[subfields[0]]?.label}
          {!dash
            ? " "
            : (searchFilters[subfields[0]] && !searchFilters[subfields[1]]) ||
                (!searchFilters[subfields[0]] && searchFilters[subfields[1]])
              ? "<"
              : "-"}
          {/* audienceGenderDensity */}
          {name == "audienceGender"
            ? `${searchFilters[subfields[1]]?.value} %`
            : searchFilters[subfields[1]]?.label}
          {searchFilters[subfields[2]] && (
            <>&nbsp; &nbsp; {searchFilters[subfields[2]].label}</>
          )}
        </AudienceFilterLabel>
      ) : name === "influencerSocialProvider" ? (
        <MaterialTooltip
          right
          content={
            <IDHFormattedMessage
              id="front_you_can_select_only_one_platform"
              defaultMessage="You can select only one platform"
            />
          }
        >
          <AudienceFilterLabel
            key={`${id}_${-index}`}
            id={id}
            disabled
            defaultMessage={defaultMessage}
            removeFilter={() =>
              removeFilter("single-field", {
                ...tag,
              })
            }
          >
            {
              socialProviders.filter((item) => {
                socialProviderIndex = index;
                return item.value === searchFilters[name].value;
              })[0][field]
            }
          </AudienceFilterLabel>
        </MaterialTooltip>
      ) : searchFilters[name] ? (
        <AudienceFilterLabel
          key={`${id}_${-index}`}
          id={id}
          disabled={name === "influencerSocialProvider"}
          name={name}
          defaultMessage={defaultMessage}
          removeFilter={() =>
            removeFilter("single-field", {
              ...tag,
            })
          }
        >
          {typeof searchFilters[name] === "boolean"
            ? null
            : name === "influencerGender"
              ? genders.filter(
                  (item) => item.value === searchFilters[name].value,
                )[0][field]
              : name === "influencerSocialUsername"
                ? searchFilters[name]
                : searchFilters[name][field]}
        </AudienceFilterLabel>
      ) : null;
    });

    METHODS.changeArrayElementOrder(labels, socialProviderIndex, 0);

    return labels;
  };

  const generateLabelsWithSocialUsername = () => {
    let socialProviderIndex;

    const labels = tagNames.map((tag, index) => {
      const { name, id, defaultMessage, field } = tag;

      return name === "influencerSocialProvider" ||
        name === "influencerSocialUsername" ? (
        <AudienceFilterLabel
          key={`${id}_${-index}`}
          id={id}
          defaultMessage={defaultMessage}
          disabled={name === "influencerSocialProvider"}
          removeFilter={() =>
            removeFilter("single-field", {
              ...tag,
            })
          }
        >
          {name === "influencerSocialProvider"
            ? socialProviders.filter((item) => {
                socialProviderIndex = index;
                return item.value === searchFilters[name].value;
              })[0][field]
            : name === "influencerSocialUsername" && searchFilters[name]}
        </AudienceFilterLabel>
      ) : null;
    });

    METHODS.changeArrayElementOrder(labels, socialProviderIndex, 0);

    return labels;
  };

  return (
    <div className="audience-search__labels">
      {searchFilters.influencerSocialUsername?.length
        ? generateLabelsWithSocialUsername()
        : generateLabels()}
    </div>
  );
}

const mapStateToProps = (state) => ({
  searchAudienceFilters: state.audiencesManagerReducer.searchAudienceFilters,
  interests: state.audiencesManagerReducer.interests,
});

const mapDispatchToProps = (dispatch) => {
  return {
    changeSearchAudienceFilters: (newFilters) =>
      dispatch(changeSearchAudienceFilters(newFilters)),
    getInfluencersListData: (listId, pageSelected, sortBy) =>
      dispatch(getInfluencersListData(listId, pageSelected, sortBy)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(injectIntl(SearchLabels)));
