import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { IProject, ProjectMetaData } from "src/redux/project/types";
import { AppLocation } from "src/redux/main/mainReducer";
import { RootState } from "src/redux/reducers";
import { Button } from "../components/Button/Button";

import {
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
} from "../components/Dropdown/Dropdown";
import { DateObject } from "./Project";
import { updateProjectMetaValue } from "../../redux";
import OverviewFieldsTable, {
  taskAvatarShape,
} from "../components/OverviewFieldsTable/OverviewFieldsTable";
import { copyProjectOrTaskLink } from "../methods/copyProjectOrTaskLink";
import { ReactComponent as RenameIcon } from "../../images/rename.svg";
import { ReactComponent as CopyLinkIcon } from "../../images/link.svg";
import { ReactComponent as LighteningIcon } from "../../images/dots.svg";
import { ReactComponent as DuplicateIcon } from "../../images/duplicate.svg";
import { ReactComponent as DeleteIcon } from "../../images/trash-can.svg";
import { ReactComponent as DownloadIcon } from "../../images/download.svg";
import { ReactComponent as ShareIcon } from "../../images/share.svg";
import { ReactComponent as SettingsIcon } from "../../images/settings.svg";
import { ReactComponent as ReportIcon } from "../../images/report.svg";
import { ReactComponent as InfoGreyIcon } from "../../images/info-grey.svg";

import { Metadata } from "../Task/Subtask/Subtask";
import RenameModal from "../modals/RenameModal/RenameModal";
import AnimatedDiv from "../components/AnimatedDiv/AnimatedDiv";
import DuplicateProjectModal from "../modals/DuplicateProjectModal/DuplicateProjectModal";
import "./SingleProjectOverviewTableWrapper.scss";
import ConfirmationOfDeleteProjectModal from "../modals/ConfirmationOfDeleteProjectModal/ConfirmationOfDeleteProjectModal";
import { AppConfig } from "../../config/AppConfig";
import DeleteMetaFieldModal from "../modals/DeleteMetaFieldModal/DeleteMetaFieldModal";
import { SeeAsClientInfoModal } from "../modals/ShareModal/SeeAsClientInfoModal/SeeAsClientInfoModal";
import { RolesInfoModal } from "../modals/ShareModal/RolesInfoModal/RolesInfoModal";
import IDHFormattedMessage from "../components/IDHFormattedMessage/IDHFormattedMessage";
import { SettingsModal } from "../SettingsModal/SettingsModal";
import { ProjectSettingsTab, SettingsContext } from "../SettingsModal/enums";
import {
  closeSettingsModal,
  openSettingsModal,
} from "../SettingsModal/methods";
import { tableDataType } from "../components/Table/Table";

interface Props {
  data: IProject[];
  singleProject?: boolean;
  openCreateNewProjectModal?: () => void;
  openAddProjectFieldModal?: () => void;
}

function SingleProjectOverviewTableWrapper(props: Props) {
  const {
    data,
    singleProject,
    openCreateNewProjectModal,
    openAddProjectFieldModal,
  } = props;

  const [filteredFields, setFilteredFields] = useState<ProjectMetaData[]>([]);

  const {
    projectReducer: { taskType, projectMetaFields },
  } = useSelector((state: RootState) => state);

  const dispatch = useDispatch();

  useEffect(() => {
    setFilteredFields(
      singleProject
        ? projectMetaFields
            .sort((a, b) => {
              return a.rank.localeCompare(b.rank);
            })
            .filter(
              (field: { taskType: string }) => field.taskType === taskType,
            )
        : [],
    );
  }, [taskType, projectMetaFields]);

  const onProjectTableValueChange = (
    projectId: string,
    fieldId: string,
    newValue: string | DateObject | boolean | null | number,
  ) => {
    if (newValue || newValue === false || newValue === null) {
      dispatch(updateProjectMetaValue(projectId, fieldId, newValue));
    }
  };

  const clientTableEmpty =
    singleProject && filteredFields.length === 0 && data[0].seeAsClientMode;

  return data && (!singleProject || !clientTableEmpty) ? (
    <AnimatedDiv className={singleProject ? "projects-list-sticky" : undefined}>
      <OverviewFieldsTable
        className="projects-list"
        data={data}
        fields={filteredFields}
        onTableValueChange={onProjectTableValueChange}
        addTableMetaField={openAddProjectFieldModal}
        taskAvatarShape={taskAvatarShape.Square}
        addTableElement={openCreateNewProjectModal}
      />
    </AnimatedDiv>
  ) : null;
}

export default SingleProjectOverviewTableWrapper;

interface ProjectMenuDropdownProps {
  data: IProject;
  singleProject?: boolean;
  openProjectDetailsPane?: () => void;
}

export function ProjectMenuDropdown(props: ProjectMenuDropdownProps) {
  const { data, singleProject, openProjectDetailsPane } = props;

  const [editedFieldData, setEditedFieldData] = useState<any>({});
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [showDuplicateProjectModal, setShowDuplicateProjectModal] =
    useState<boolean>(false);
  const [
    showConfirmationOfDeleteProjectModal,
    setShowConfirmationOfDeleteProjectModal,
  ] = useState<boolean>(false);
  const [showDeleteMetaFieldModal, setShowDeleteMetaFieldModal] =
    useState(false);
  const [showSeeAsClientInfoModal, setShowSeeAsClientInfoModal] =
    useState(false);
  const [showRulesInfoModal, setShowRolesInfoModal] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const {
    mainReducer: { activeWorkspaceUuid, settingsModalData },
  } = useSelector((state: RootState) => state);

  const onCloseDuplicateProjectModal = () => {
    setShowDuplicateProjectModal(false);
  };

  const openReportGenerator = () => {
    window.localStorage.setItem("reportGeneratorEntryUrl", location.pathname);

    history.push(
      `/workspace/${activeWorkspaceUuid}/report-generator/${data.projectId}`,
    );
  };

  const handleOpenSettingsModal = () => {
    openSettingsModal(
      history,
      AppLocation.ProjectOverviewTable,
      SettingsContext.Project,
      ProjectSettingsTab.Members,
    );
  };

  return (
    <>
      <Dropdown className="project-menu-dropdown" toRight={!singleProject}>
        {(isOpen, setIsOpen: any) => (
          <>
            {singleProject ? (
              <Button
                variant="light-grey"
                size="square-small"
                onClick={() => setIsOpen(true)}
                className="project-menu-dropdown__menu-icon"
              >
                <SettingsIcon />
              </Button>
            ) : (
              <Button
                className="projects-list__menu-button"
                variant="light"
                size="large"
                onClick={() => setIsOpen(true)}
              >
                <LighteningIcon />
              </Button>
            )}
            <DropdownMenu isOpen={isOpen}>
              {singleProject && (
                <Link
                  to={`/workspace/${activeWorkspaceUuid}/projects/${data.projectId}/details`}
                >
                  <DropdownMenuItem onClick={openProjectDetailsPane}>
                    <InfoGreyIcon />
                    <IDHFormattedMessage
                      id="ws_show_details"
                      defaultMessage="Show details"
                    />
                  </DropdownMenuItem>
                </Link>
              )}
              <DropdownMenuItem onClick={() => setShowRenameModal(true)}>
                <RenameIcon />
                <IDHFormattedMessage id="ws_rename" defaultMessage="Rename" />
              </DropdownMenuItem>
              {!singleProject && (
                <DropdownMenuItem onClick={handleOpenSettingsModal}>
                  <ShareIcon />
                  <IDHFormattedMessage id="ws_share" defaultMessage="Share" />
                </DropdownMenuItem>
              )}
              <DropdownMenuItem
                onClick={() =>
                  copyProjectOrTaskLink(activeWorkspaceUuid, data.projectId)
                }
              >
                <CopyLinkIcon />
                <IDHFormattedMessage
                  id="ws_copy_link"
                  defaultMessage="Copy link"
                />
              </DropdownMenuItem>

              {data.permissions?.project.includes("download_report") && (
                <DropdownMenuItem
                  onClick={() =>
                    (window.location.href = `${AppConfig.getApiRoute()}/project/xlsx-report-file/${
                      data.projectId
                    }`)
                  }
                >
                  <DownloadIcon />
                  <IDHFormattedMessage
                    id="ws_download_xlsx"
                    defaultMessage="Download XLSX"
                  />
                </DropdownMenuItem>
              )}

              {data.permissions?.project.includes("download_report") && (
                <DropdownMenuItem onClick={openReportGenerator}>
                  <ReportIcon />
                  <IDHFormattedMessage
                    id="ws_generate_report"
                    defaultMessage="Generate report"
                  />
                </DropdownMenuItem>
              )}

              {data.permissions?.project.includes("project_management") && (
                <>
                  <DropdownMenuItem
                    onClick={() => setShowDuplicateProjectModal(true)}
                  >
                    <DuplicateIcon />
                    <IDHFormattedMessage
                      id="ws_duplicate"
                      defaultMessage="Duplicate"
                    />
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    className="dropdown__menu-item--danger"
                    onClick={() =>
                      setShowConfirmationOfDeleteProjectModal(true)
                    }
                  >
                    <DeleteIcon />
                    <IDHFormattedMessage
                      id="ws_remove"
                      defaultMessage="Remove"
                    />
                  </DropdownMenuItem>
                </>
              )}
            </DropdownMenu>
            {showRenameModal && (
              <RenameModal
                onClose={() => setShowRenameModal(false)}
                objectId={data.projectId}
                taskName={data.projectName}
                titleFieldId={
                  data?.metadata?.find(
                    (field: Metadata) => field.key === "project_name",
                  )?.uuid || ""
                }
                projectId={data.projectId}
                dataType={tableDataType.Project}
                isListElement
              />
            )}
          </>
        )}
      </Dropdown>

      {showDuplicateProjectModal && (
        <DuplicateProjectModal
          wsProjectUuid={data.projectId}
          projectName={data.projectName}
          onClose={() => onCloseDuplicateProjectModal()}
        />
      )}

      {showConfirmationOfDeleteProjectModal && (
        <ConfirmationOfDeleteProjectModal
          wsProjectUuid={data.projectId}
          wsProjectName={data.projectName}
          wsWorkspaceUuid={activeWorkspaceUuid}
          onClose={() => setShowConfirmationOfDeleteProjectModal(false)}
          singleProject={singleProject}
        />
      )}

      {showDeleteMetaFieldModal && (
        <DeleteMetaFieldModal
          uuid={editedFieldData.uuid}
          fieldName={data.projectName}
          onClose={() => setShowDeleteMetaFieldModal(false)}
        />
      )}

      {settingsModalData.location === AppLocation.ProjectOverviewTable && (
        <SettingsModal
          onClose={() => closeSettingsModal(history)}
          members={{
            openShareModal: handleOpenSettingsModal,
            openSeeAsClientInfoModal: () => setShowSeeAsClientInfoModal(true),
            openRolesInfoModal: () => setShowRolesInfoModal(true),
          }}
          contextTargetUuid={data.projectId}
        />
      )}

      {showSeeAsClientInfoModal && (
        <SeeAsClientInfoModal
          onClose={() => setShowSeeAsClientInfoModal(false)}
          openShareModal={handleOpenSettingsModal}
        />
      )}

      {showRulesInfoModal && (
        <RolesInfoModal
          onClose={() => setShowRolesInfoModal(false)}
          openShareModal={handleOpenSettingsModal}
        />
      )}
    </>
  );
}
