import React, { useMemo } from "react";
import "./ProfileDataTab.scss";

import Loader from "../../../components/Loader/Loader";
import { EmptyProfileDataTab } from "./components/EmptyTabs/EmptyProfileDataTab";
import ProfileNotFound from "./components/EmptyTabs/ProfileNotFound";
import { AudienceData } from "./sections/AudienceData";
import { HashtagsAndKeywords } from "./sections/HashtagsAndKeywords";
import { InterestsAndBrandAffinity } from "./sections/InterestsAndBrandAffinity";
import { Posts } from "./sections/Posts";
import { ProfileData } from "./sections/ProfileData";
import { CategoriesAndBio } from "./single/CategoriesAndBio";
import GatheringProfileData from "./components/EmptyTabs/GatheringProfileData";
import { SocialProfileStatus } from "../../../../types";
import ProfileError from "./components/EmptyTabs/ProfileError";
import { BioWithContactData } from "./single/BioWithContactData";

export function ProfileDataTab(props) {
  const {
    socialProfiles,
    profileData,
    selectedSocialProvider,
    profileNotFound,
    hasAnySocialProfile,
    taskTitle,
    taskId,
  } = props;

  const selectedSocialProfile =
    socialProfiles?.find(
      (item) => item.socialProvider === selectedSocialProvider.value,
    ) || null;

  if (
    selectedSocialProfile &&
    selectedSocialProfile.status === SocialProfileStatus.Fresh
  ) {
    return <GatheringProfileData />;
  }

  const errorSocialProfileStatus = [SocialProfileStatus.Error];

  if (
    selectedSocialProfile &&
    errorSocialProfileStatus.includes(selectedSocialProfile.status)
  ) {
    return <ProfileError />; // atrybuty brakuje
  }

  if (profileNotFound) {
    return (
      <ProfileNotFound
        socialProfiles={socialProfiles}
        taskTitle={taskTitle}
        taskId={taskId}
      />
    );
  }
  if (!hasAnySocialProfile) {
    return (
      <EmptyProfileDataTab
        socialProfiles={socialProfiles}
        taskTitle={taskTitle}
        taskId={taskId}
      />
    );
  }
  if (!profileData) {
    return <Loader />;
  }

  const socialProfileData = useMemo(() => {
    return (
      socialProfiles?.find(
        (item) => item.socialProvider === selectedSocialProvider.value,
      ) || {}
    );
  }, [socialProfiles, selectedSocialProvider]);

  return (
    <div className="profile-data-tab">
      {profileData.creator_contact_data ? (
        <BioWithContactData profileData={profileData} />
      ) : (
        <CategoriesAndBio profileData={profileData} />
      )}
      <ProfileData profileData={profileData} />
      <AudienceData profileData={profileData} />
      <InterestsAndBrandAffinity profileData={profileData} />
      <HashtagsAndKeywords profileData={profileData} />
      <Posts
        profileData={profileData}
        socialUrl={
          socialProfileData?.socialUrl || socialProfileData?.socialProfileUrl
        }
        socialProvider={socialProfileData?.socialProvider}
      />
    </div>
  );
}
